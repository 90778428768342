import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

export const useFormSetup = <FormDataType>(formSchema, defaultValues) => {
  const form = useForm<FormDataType>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = form

  const formValues = useWatch({ control })

  return { form, formValues, errors, isDirty, control, handleSubmit }
}
