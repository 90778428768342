import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { AccountContactForm } from 'components/accountContact/AccountContactForm'
import {
  AccountContactFormData,
  AccountContactLabels,
  AccountContactProps,
} from 'components/accountContact/types'
import { setTempId } from 'components/accountContact/utils'
import { BusinessDirector } from '../types'

export const BusinessDirectorModal = ({
  initialData: businessDirector,
  isEditing,
  countryOptions,
  onSave,
  onDelete,
  onClose,
}: AccountContactProps<BusinessDirector>) => {
  const { t } = useTranslation()

  const handleSave = (updatedData: AccountContactFormData) => {
    const id =
      businessDirector?.id ?? setTempId(`${updatedData?.firstName} ${updatedData?.lastName}`)
    const updatedBusinessDirector = {
      ...updatedData,
      id,
    }
    onSave(updatedBusinessDirector)
  }

  const handleDelete = () => {
    onDelete(businessDirector?.id)
  }

  const businessDirectorLabels: AccountContactLabels = {
    addButton: t('verification.businessDirectors.addIndividualDirector'),
  }

  return (
    <Modal visible closeModal={onClose} allowOverFlow>
      <ModalNavBar title={t('verification.businessDirectors.addDirector')} onClose={onClose} />
      <AccountContactForm
        accountContact={businessDirector}
        countryOptions={countryOptions}
        isEditing={isEditing}
        labels={businessDirectorLabels}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </Modal>
  )
}
