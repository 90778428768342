import React, { ReactNode } from 'react'
import { DocumentTooltipList } from '../components/DocumentTooltipList'
import { DocumentCode } from './types'

export const getDocumentTooltip = (t, code: DocumentCode) => {
  const tooltips: Partial<Record<DocumentCode, ReactNode>> = {
    proofOfAddress: t('uploadDocuments.tooltip.proofOfAddress'),
    amlPolicy: t('uploadAmlPolicyTooltip'),
    certificateOfIncorporation: t('uploadDocuments.tooltip.certificateOfIncorporation'),
    memorandumArticlesOfAssociation: t('uploadDocsMemorandumArticlesOfAssociationTooltip'),
    operatingLicense: t('uploadDocsOperatingLicenseTooltip'),
    registerOfDirectors: t('uploadRegisterOfDirectorsTooltip'),
    shareholderCertificate: t('uploadDocsShareholderCertificateTooltip'),
    shareholderStructureChart: t('uploadDocsShareholderStructureChartTooltip'),
    shareholderRegister: t('uploadDocuments.tooltip.shareholderRegister'),
    sourceOfFunds: (
      <DocumentTooltipList
        introduction={t('sourceOfFundsInfoBannerContentDescription')}
        list={[
          t('sourceOfFundsInfoBannerContentLine1'),
          t('sourceOfFundsInfoBannerContentLine2'),
          t('sourceOfFundsInfoBannerContentLine3'),
          t('sourceOfFundsInfoBannerContentLine4'),
        ]}
      />
    ),
  }
  return tooltips?.[code] ?? null
}
