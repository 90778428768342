import { HighNetWorthFormData } from './schemas/highNetWorthSchema'
import { RestrictedFormData } from './schemas/restrictedSchema'
import { SophisticatedFormData } from './schemas/sophisticatedSchema'
import {
  AppropriatenessQuestionKey,
  FinpromResultType,
  FinpromVerificationStepKey,
  SelfCategorisationCategory,
} from './types'

export const finpromCoolOffPreferenceKey = 'isUkInvestmentRiskAccepted'
export const finpromRiskWarningPreferenceKey = 'finpromRiskWarning'
export const finpromSelfCategorisationPreferenceKey = 'finpromSelfCategorisation'
export const finpromAppropriatenessTestPreferenceKey = 'finpromAppropriatenessTest'

export type FinpromCoolOffStatus = Date | boolean

export type FinpromCompletedSteps = FinpromVerificationStepKey[]

export type FinpromAttempts = {
  total: number
  unsuccessful: number
}

export type FinpromStepStatus = {
  isComplete?: boolean
}

export type SelfCategorisationAnswers =
  | RestrictedFormData
  | HighNetWorthFormData
  | SophisticatedFormData

export type SelfCategorisationData = FinpromStepStatus & {
  category?: SelfCategorisationCategory
  answers?: SelfCategorisationAnswers
  result?: FinpromResultType
  attempts?: FinpromAttempts
}

export type AppropriatenessQuestion = {
  answer: string
  isCorrect: boolean
}

export type AppropriatenessQuestionsData = Record<
  AppropriatenessQuestionKey,
  AppropriatenessQuestion
>

export type AppropriatenessData = FinpromStepStatus & {
  hasReadInstructions?: boolean
  questions?: AppropriatenessQuestionsData
  correctQuestionsTotal?: number
  result?: FinpromResultType
  attempts?: FinpromAttempts
  dateCompleted?: Date
}

export interface FinpromAccountPreferenceData {
  [finpromCoolOffPreferenceKey]?: FinpromCoolOffStatus
  [finpromRiskWarningPreferenceKey]?: FinpromStepStatus
  [finpromSelfCategorisationPreferenceKey]?: SelfCategorisationData
  [finpromAppropriatenessTestPreferenceKey]?: AppropriatenessData
}
