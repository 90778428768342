import React from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { FormGetError } from 'pages/Verification/_components/FormGetError'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { AccountRepresentativeForm } from './AccountRepresentativeForm'
import { useAccountRepCrud } from './useAccountRepCrud'

export const AccountRepresentative = () => {
  const { t } = useTranslation()
  const { accountContacts: accountContactsData } = useVerificationContext()
  const { data, loading, errors, setSelectedReference, onSaveAccountRep } =
    useAccountRepCrud(accountContactsData)

  return (
    <>
      <VerificationHeader
        header={t('accountRepresentative.header')}
        subHeader={t('accountRepresentative.subHeader')}
      />
      {loading?.isLoadingForm ? (
        <SkeletonForm name="Account representative" />
      ) : errors?.isFetchingAccountContacts ? (
        <FormGetError />
      ) : (
        <AccountRepresentativeForm
          {...data}
          onAccountRepChange={updatedReference => setSelectedReference(updatedReference)}
          isSavingAccountRep={loading?.isSavingAccountRep}
          onSaveAccountRep={onSaveAccountRep}
        />
      )}
    </>
  )
}
