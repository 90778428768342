import React from 'react'
import { Box, Box as List } from '@node-space/storybook-components/dist/Box'
import TextList from '@node-space/storybook-components/dist/TextList'
import { Callout } from '@node-space/storybook-components/dist/Callout'

interface ReasonItem {
  children: string
  key: string
}
interface DocumentRejectionStatusDetailProps {
  reasonDetail: string
  additionalReasons: ReasonItem[] | []
}

export const DocumentRejectionStatusDetail = ({
  reasonDetail,
  additionalReasons,
}: DocumentRejectionStatusDetailProps) => (
  <Callout testid="document-rejection-callout" state="warning">
    <Box>{reasonDetail}</Box>
    {!!additionalReasons?.length && (
      <List tag="ul" paddingL={16} paddingT={8}>
        <TextList
          className="text-status-warning-900"
          listItems={additionalReasons}
          listStyle="bullet"
        />
      </List>
    )}
  </Callout>
)
