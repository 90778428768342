import { Query } from '@tanstack/react-query'
import { isEmpty } from 'lodash'
import { logSentryError } from '@node-space/utils'
import { QueryMetaType } from 'types/reactQuery'
import { forceLogout } from 'utils/auth/utils'

export const onQueryError = (error: unknown, query: Query) => {
  const { errorAdditionalData, isErrorFatal } = (query?.meta as QueryMetaType) || {}

  if (query?.queryKey) {
    const queryKey = query?.queryKey?.join()
    logSentryError(
      `Error with useQuery (${queryKey}):`,
      error,
      ...(!isEmpty(errorAdditionalData) ? [errorAdditionalData] : [])
    )
  }

  if (isErrorFatal) {
    forceLogout()
  }
}
