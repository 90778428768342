import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Stepper } from '@node-space/storybook-components'
import { Container } from 'components/Container'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { AppropriatenessStepList } from 'pages/Finprom/constants'
import {
  AppropriatenessData,
  finpromAppropriatenessTestPreferenceKey,
} from 'pages/Finprom/finpromPreferenceData'
import { AppropriatenessStepKey } from 'pages/Finprom/types'
import { getAppropriatenessActiveFormStep } from 'pages/Finprom/utils'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { AppropriatenessResultModal } from './AppropriatenessResultModal'
import { AppropriatenessStep } from './AppropriatenessStep'

export const AppropriatenessSteps = () => {
  const { t } = useTranslation()
  const { accountPreferences, appropriatenessActiveFormStep, setAppropriatenessActiveFormStep } =
    useFinpromContext()

  const appropriatenessData: AppropriatenessData = useMemo(
    () => accountPreferences?.[finpromAppropriatenessTestPreferenceKey],
    [accountPreferences?.[finpromAppropriatenessTestPreferenceKey]]
  )

  useEffect(() => {
    const updatedActiveFormStep = getAppropriatenessActiveFormStep(appropriatenessData)
    if (updatedActiveFormStep && updatedActiveFormStep !== appropriatenessActiveFormStep) {
      setAppropriatenessActiveFormStep(updatedActiveFormStep)
    }
  }, [appropriatenessData])

  return (
    <Container>
      <VerificationHeader header={t('finprom.steps.appropriatenessTest')} />
      <Stepper<AppropriatenessStepKey>
        stepKeys={AppropriatenessStepList}
        activeStepKey={appropriatenessActiveFormStep}
        onBack={stepKey => setAppropriatenessActiveFormStep(stepKey)}
      />
      <AppropriatenessStep activeFormStep={appropriatenessActiveFormStep} />
      {!!appropriatenessData?.result && (
        <AppropriatenessResultModal
          result={appropriatenessData?.result}
          attempt={appropriatenessData?.attempts?.unsuccessful}
        />
      )}
    </Container>
  )
}
