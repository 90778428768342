import { useNavigate } from 'react-router-dom'
import { RedirectUri } from 'constants/General'

export const useRedirect = () => {
  const navigate = useNavigate()

  const onLogout = () => navigate(RedirectUri.LOGOUT, { replace: true })

  return { onLogout }
}
