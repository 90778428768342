import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { AccountContactType } from 'components/accountContact/types'
import { getAccountContacts } from 'services/AccountContactsService'

export const useAccountContactsQuery = (types?: AccountContactType[], isEnabled = true) => {
  return useQuery({
    queryKey: reactQueryKeys.accountContacts(),
    queryFn: () => getAccountContacts(types),
    enabled: isEnabled,
  })
}
