import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { StatusPill } from '@node-space/storybook-components/dist/StatusPill'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Description } from 'components/Text'
import { DescriptionProps } from 'components/Text/Description'
import { ExternalLinks } from 'constants/ExternalLinks'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { useCountdownTimer } from 'hooks/useCountdownTimer'
import { FinpromResultModal } from 'pages/Finprom/components/FinpromResultModal'
import { finpromAppropriatenessTestPreferenceKey } from 'pages/Finprom/finpromPreferenceData'
import { useRetakeAppropriatenessTest } from 'pages/Finprom/hooks/useRetakeAppropriatenessTest'
import { TextAlignType } from 'types/text'
import { useRedirect } from 'utils/redirects'

const COOL_DOWN_PERIOD_IN_HOURS = 24

export const UnsuccessfulModalSecondAttempt = () => {
  const { t } = useTranslation()
  const { onLogout } = useRedirect()
  const { accountPreferences } = useFinpromContext()
  const { hasTimeLeft, timeLeftFormatted } = useCountdownTimer(
    accountPreferences?.[finpromAppropriatenessTestPreferenceKey]?.dateCompleted,
    COOL_DOWN_PERIOD_IN_HOURS
  )
  const { isLoadingRetake, isErrorRetake, onRetakeQuiz } = useRetakeAppropriatenessTest()

  const alignProp: Omit<DescriptionProps, 'children'> = { align: TextAlignType.CENTER }

  const exitAction: ButtonProps = {
    children: t('exit'),
    destructive: true,
    disabled: isLoadingRetake,
    onClick: onLogout,
  }

  const retakeAction: ButtonProps = {
    children: t('finprom.appropriateness.unsuccessful.retakeQuiz'),
    loading: isLoadingRetake,
    onClick: onRetakeQuiz,
  }

  const actions: ButtonProps[] = [exitAction]

  if (!hasTimeLeft) {
    actions.push(retakeAction)
  }

  return (
    <FinpromResultModal
      title={t('unsuccessful')}
      icon={<Icon name="CloudLightningIcon" />}
      actions={actions}
      showError={isErrorRetake}
    >
      <Box>
        <Description {...alignProp}>
          {t('finprom.appropriateness.unsuccessful.introLineOne')}
        </Description>
        <Description {...alignProp}>
          {t('finprom.appropriateness.unsuccessful.notPassedSecondAttempt')}
        </Description>
      </Box>
      <Description {...alignProp}>
        {t('finprom.appropriateness.unsuccessful.instructionsSecondAttempt')}
      </Description>
      <Description {...alignProp}>
        {t('finprom.appropriateness.unsuccessful.pleaseReadBefore')}
        <TextAction
          text={t('finprom.appropriateness.instructions.riskSummaryLink')}
          linkTo={ExternalLinks.riskSummaryFCA}
        />
        {t('finprom.appropriateness.unsuccessful.pleaseReadAfter')}
      </Description>
      <Box>
        <Description {...alignProp}>
          {t('finprom.appropriateness.unsuccessful.timeLeft')}
        </Description>
        <Box flex justifyContent="center" paddingT={12}>
          <StatusPill state="warning" label={timeLeftFormatted} fitContent />
        </Box>
      </Box>
    </FinpromResultModal>
  )
}
