import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getAccountPersons } from 'services/AccountContactsService'

export const useAccountsPersonsQuery = (isEnabled = true) => {
  return useQuery({
    queryKey: reactQueryKeys.getStarted(),
    queryFn: getAccountPersons,
    enabled: isEnabled,
  })
}
