import React from 'react'
import Box from '@node-space/storybook-components/dist/Box'
import TextList from '@node-space/storybook-components/dist/TextList'

export interface DocumentTooltipListProps {
  introduction?: string
  list: string[]
}

export const DocumentTooltipList = ({ introduction, list }: DocumentTooltipListProps) => {
  return (
    <Box>
      {introduction && <Box>{introduction}</Box>}
      {!!list?.length && (
        <TextList
          textSize="sm"
          textColor="inherit"
          listStyle="bullet"
          listItems={list?.map((children, index) => ({
            key: `list-item-${index}`,
            children,
          }))}
          className="pl-3"
        />
      )}
    </Box>
  )
}
