import React from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { BusinessOwners as BusinessOwnersV1 } from './BusinessOwnersV1/BusinessOwners'
import { BusinessOwners as BusinessOwnersV2 } from './BusinessOwnersV2/BusinessOwners'

export const BusinessOwners = () => {
  const { enableAccountContactsCrud } = useFeatureFlags()

  return !!enableAccountContactsCrud ? <BusinessOwnersV2 /> : <BusinessOwnersV1 />
}
