import React from 'react'
import { Text } from '@node-space/storybook-components'
import { TextAlignType } from 'types/text'

export interface HeadlineProps {
  children: React.ReactNode
  align?: TextAlignType
}

export const Headline = ({ children, align }: HeadlineProps) => {
  return (
    <Text tag="h1" size="xl" weight="semibold" align={align}>
      {children}
    </Text>
  )
}
