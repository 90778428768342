import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'

export type FormSubmitButtonProps = {
  buttonText?: string
  disabled: boolean
  loading?: boolean
  onClick?: () => void
}

export const FormSubmitButton = ({
  buttonText,
  disabled,
  loading,
  onClick,
}: FormSubmitButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      full
      iconRight
      iconElement={<Icon color="inherit" name="ArrowRightIcon" />}
      disabled={disabled}
      loading={loading}
      {...(!!onClick ? { onClick } : { type: 'submit' })}
    >
      {buttonText ? buttonText : t('continue')}
    </Button>
  )
}
