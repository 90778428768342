import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { ModalActions, ModalBody } from '@node-space/storybook-components/dist/Modal'
import { AccountContactActionsLoading } from './accountContactV2/types'

export type AccountContactActionsError = {
  isFormValuesError?: boolean
  isSavingError?: boolean
  isDeletingError?: boolean
}

export interface AccountContactModalProps {
  children: ReactNode
  isEditing: boolean
  addActionLabel: string
  actionsLoading?: AccountContactActionsLoading
  actionsError: AccountContactActionsError
  onSubmit: () => void
  onDelete: () => void
}

export const AccountContactWrapper = ({
  children,
  isEditing,
  addActionLabel,
  actionsLoading,
  actionsError,
  onSubmit,
  onDelete,
}: AccountContactModalProps) => {
  const { t } = useTranslation()

  const isDeleteActionDisabled = actionsLoading?.isSaving

  const deleteButton: ButtonProps = {
    children: t('delete'),
    destructive: !isDeleteActionDisabled,
    iconElement: <Icon name="TrashIcon" color="white" />,
    loading: actionsLoading?.isDeleting,
    disabled: isDeleteActionDisabled,
    onClick: onDelete,
  }

  const addButton: ButtonProps = {
    children: isEditing ? t('save') : addActionLabel,
    type: 'submit',
    disabled: actionsError?.isFormValuesError || actionsLoading?.isDeleting,
    loading: actionsLoading?.isSaving,
  }

  return (
    <form onSubmit={onSubmit}>
      <ModalBody spaceChildren={24}>{children}</ModalBody>
      <ModalActions actions={isEditing ? [deleteButton, addButton] : [addButton]} />
    </form>
  )
}
