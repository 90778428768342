import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Option as SelectOptionType } from '@node-space/storybook-components/dist/Select'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { useAccountRepresentativeQuery } from 'hooks/queries/useAccountRepresentativeQuery'
import { useBusinessDirectorsQuery } from 'hooks/queries/useBusinessDirectorsQuery'
import { useBusinessOwnersQuery } from 'hooks/queries/useBusinessOwnersQuery'
import { FormGetError } from 'pages/Verification/_components/FormGetError'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { AccountRepFormData } from 'pages/Verification/schemas/accountRepSchema'
import { AccountRepOption, AccountRepType, useAccountRep } from '../useAccountRep'
import { AccountRepresentativeForm } from './AccountRepresentativeForm'

export const AccountRepresentative = () => {
  const { t } = useTranslation()

  const { getDirectorOptions, getOwnerOptions, getAccountRepFields, getAccountRepOptions } =
    useAccountRep()

  const [accountRepOptions, setAccountRepOptions] = useState<AccountRepFormData[]>([])
  const [directorOptions, setDirectorOptions] = useState<SelectOptionType[]>([])
  const [ownerOptions, setOwnerOptions] = useState<SelectOptionType[]>([])
  const [selectedAccountRepData, setSelectedAccountRepData] = useState<AccountRepFormData>()
  const [isFormPopulated, setIsFormPopulated] = useState<boolean>(false)

  const {
    data: loadedBusinessOwnersData,
    isFetching: isFetchingBusinessOwners,
    isError: isOwnersGetError,
  } = useBusinessOwnersQuery()

  const {
    data: loadedBusinessDirectorsData,
    isFetching: isFetchingBusinessDirectors,
    isError: isBusinessDirectorsGetError,
  } = useBusinessDirectorsQuery()

  const {
    data: loadedAccountRepData,
    isFetching: isFetchingAccountRep,
    isError: isAccountRepGetError,
  } = useAccountRepresentativeQuery()

  const isLoadingData = useMemo(
    () => isFetchingBusinessOwners || isFetchingBusinessDirectors || isFetchingAccountRep,
    [isFetchingBusinessOwners, isFetchingBusinessDirectors, isFetchingAccountRep]
  )

  const isLoadingForm = useMemo(
    () => isLoadingData || !isFormPopulated,
    [isLoadingData, isFormPopulated]
  )

  const isGetError = useMemo(
    () => isOwnersGetError || isBusinessDirectorsGetError || isAccountRepGetError,
    [isOwnersGetError, isBusinessDirectorsGetError, isAccountRepGetError]
  )

  const handleAccountRepTypeChange = (accountRepType: AccountRepType) => {
    const data = {
      ...accountRepOptions?.find(data => data?.accountRepType === accountRepType),
      accountRep:
        accountRepType === AccountRepOption.DIRECTOR
          ? directorOptions?.[0]?.value
          : accountRepType === AccountRepOption.OWNER
            ? ownerOptions?.[0]?.value
            : '',
    }
    const updatedAccountRep: AccountRepFormData = getAccountRepFields(data)
    setSelectedAccountRepData(updatedAccountRep)
  }

  const handleAccountRepChange = (accountRepId: string) => {
    const data = {
      ...accountRepOptions?.find(data => data?.id === accountRepId),
      accountRep: accountRepId,
    }
    const updatedAccountRep: AccountRepFormData = getAccountRepFields(data)
    setSelectedAccountRepData(updatedAccountRep)
  }

  useEffect(() => {
    if (!isLoadingData) {
      const { options, selectedOption } = getAccountRepOptions(
        loadedAccountRepData,
        loadedBusinessDirectorsData,
        loadedBusinessOwnersData
      )
      const directorOptions: SelectOptionType[] = getDirectorOptions(options)
      const ownerOptions: SelectOptionType[] = getOwnerOptions(options)

      setAccountRepOptions(options)
      setDirectorOptions(directorOptions)
      setOwnerOptions(ownerOptions)
      setSelectedAccountRepData(selectedOption)
      setIsFormPopulated(true)
    }
  }, [isLoadingData, loadedAccountRepData, loadedBusinessDirectorsData, loadedBusinessOwnersData])

  return (
    <>
      <VerificationHeader
        header={t('accountRepresentative.header')}
        subHeader={t('accountRepresentative.subHeader')}
      />
      {isLoadingForm ? (
        <SkeletonForm name="Account representative" />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <AccountRepresentativeForm
          loadedAccountRepId={loadedAccountRepData?.id}
          selectedAccountRepData={selectedAccountRepData}
          directorOptions={directorOptions}
          ownerOptions={ownerOptions}
          onAccountRepTypeChange={handleAccountRepTypeChange}
          onAccountRepChange={handleAccountRepChange}
        />
      )}
    </>
  )
}
