import React from 'react'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import { useFinpromAppropriatenessForm } from 'pages/Finprom/hooks/useFinpromAppropriatenessForm'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

export const CryptoAssetDiversityOptions = {
  DIFFERENT_PRODUCTS_AND_AREAS: 'differentProductsAndAreas',
  DIFFERENT_PRODUCTS_IN_ONE_AREA: 'differentProductsInOneArea',
  SINGLE_PRODUCT_TYPE: 'singleProductType',
} as const

type CryptoAssetDiversityOption =
  (typeof CryptoAssetDiversityOptions)[keyof typeof CryptoAssetDiversityOptions]

const cryptoAssetDiversityOptionList = Object.values(CryptoAssetDiversityOptions)
const cryptoAssetDiversityCorrectAnswer: CryptoAssetDiversityOption =
  CryptoAssetDiversityOptions.DIFFERENT_PRODUCTS_AND_AREAS

export const StepCryptoAssetDiversity = () => {
  const {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  } = useFinpromAppropriatenessForm<CryptoAssetDiversityOption>()

  const cryptoAssetDiversityOptions = getQuestionOptions(cryptoAssetDiversityOptionList)

  const handleSubmit = () => {
    const isCorrectAnswer = questionAnswer === cryptoAssetDiversityCorrectAnswer
    onSubmitQuestion(
      isCorrectAnswer,
      FinpromTrackingLabels.APPROPRIATENESS_STEP_CRYPTO_ASSET_DIVERSITY
    )
  }

  return (
    <Container>
      <Radio
        value={questionAnswer}
        options={cryptoAssetDiversityOptions}
        onChange={handleSelectAnswer}
        error={isQuestionError}
        errorText={questionErrorText}
        hasParagraphLayout
      />
      {isErrorContinue && <FinpromSubmitError />}
      <FormSubmitButton
        disabled={isQuestionError}
        loading={isLoadingContinue}
        onClick={handleSubmit}
      />
    </Container>
  )
}
