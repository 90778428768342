import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'

export const RecaptchaTokenError = () => {
  const { t } = useTranslation()

  return (
    <Box aria-label="recaptcha token error">
      {`${t('recaptcha.tokenError')} ${t('requestErrors.comeBackLater')} `}
      <TextAction isWindowReload text={t('requestErrors.refreshPage')} inheritColor />.
    </Box>
  )
}
