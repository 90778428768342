import React, { ReactNode, useCallback, useMemo } from 'react'
import {
  Box,
  Box as Container,
  Box as CheckboxBar,
  Box as DescriptionBox,
  Box as TextInputBox,
  Button,
  CheckBox,
  ErrorText,
  Icon,
} from '@node-space/storybook-components'
import { BorderType } from '@node-space/storybook-components/dist/components/Box/BoxTypes'
import { Description } from 'components/Text'
import clsx from 'clsx'

export type TextInputType = {
  value: string
  placeholder?: string
  onChange: (value: string) => void
}

export type CheckboxAccordionProps = {
  id: number | string
  label: string
  description?: ReactNode
  checked: boolean
  hasError?: boolean
  errorMessage?: string
  isOpen?: boolean
  textInput?: TextInputType
  onCheck: () => void
  onToggle?: () => void
}

export const CheckboxAccordion = ({
  id,
  label,
  description,
  checked,
  hasError,
  errorMessage,
  isOpen,
  textInput,
  onCheck,
  onToggle,
}: CheckboxAccordionProps) => {
  const borderColour = useMemo((): BorderType => {
    if (hasError) return 'error'
    if (checked) return 'primary-500'
    return 'gray'
  }, [hasError, checked])

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      textInput?.onChange(event.target.value)
    },
    [textInput?.onChange]
  )

  return (
    <Box>
      <Container
        borderRadius={4}
        border={borderColour}
        paddingX={12}
        className={clsx('ring-1', {
          'ring-status-error-500': hasError,
          'hover:ring-primary-500': !checked,
          'hover:ring-primary-700': checked,
        })}
      >
        <CheckboxBar flex justifyContent="between" paddingY={12} gapX={16}>
          <Box flexSize="fill">
            <CheckBox
              testid={`accordion-checkbox-${id}`}
              value={checked}
              label={label}
              onChange={onCheck}
            />
          </Box>

          {description && (
            <Button
              noStyling
              iconElement={<Icon name={isOpen ? 'CaretUpIcon' : 'CaretDownIcon'} />}
              onClick={onToggle}
              name={`toggleCheckboxDescription${id}`}
            />
          )}
        </CheckboxBar>

        {description && isOpen && (
          <DescriptionBox paddingY={12} divider="top">
            {typeof description === 'string' ? (
              <Description>{description}</Description>
            ) : (
              description
            )}
          </DescriptionBox>
        )}

        {!!textInput && (
          <TextInputBox divider="top">
            <input
              type="text"
              value={textInput.value}
              placeholder={textInput.placeholder}
              onChange={handleInputChange}
              className="py-3 w-full border-0 outline-0 bg-transparent text-sm"
            />
          </TextInputBox>
        )}
      </Container>

      {errorMessage && <ErrorText text={errorMessage} />}
    </Box>
  )
}
