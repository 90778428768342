import React from 'react'
import { useTranslation } from 'react-i18next'
import Callout from '@node-space/storybook-components/dist/Callout'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { ExternalLinks } from 'constants/ExternalLinks'

export const FinpromSubmitError = () => {
  const { t } = useTranslation()

  return (
    <Callout state="error" testid="finprom-error">
      {`${t('requestError.couldNotProcess')} ${t('requestError.tryLater')} `}
      <TextAction
        text={t('contactSupportLowercase')}
        linkTo={ExternalLinks.contactSupport}
        inheritColor
      />
      .
    </Callout>
  )
}
