import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box as ActiveStep, Box } from '@node-space/storybook-components/dist/Box'
import Loader from '@node-space/storybook-components/dist/Loader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import {
  AccountContactStepKeys,
  AccountContactTypesList,
} from 'components/accountContact/accountContactV2/constants'
import { useAccountContactsData } from 'components/accountContact/accountContactV2/useAccountContactsData'
import { getAccountContactStepStatus } from 'components/accountContact/accountContactV2/utils'
import { Description } from 'components/Text'
import {
  VerificationRouteList,
  VerificationRoutes,
  VerificationRouteStatus,
} from 'constants/verification'
import { VerificationContextProvider } from 'contexts/verificationContext'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { useBusinessVerificationStatusQuery } from 'hooks/queries/useBusinessVerificationStatus'
import { FinpromVerificationStepList } from 'pages/Finprom/constants'
import {
  finpromAppropriatenessTestPreferenceKey,
  finpromRiskWarningPreferenceKey,
  finpromSelfCategorisationPreferenceKey,
} from 'pages/Finprom/finpromPreferenceData'
import { FinpromStep } from 'pages/Finprom/steps/FinpromStep'
import {
  FinpromStepKey,
  FinpromVerificationStep,
  FinpromVerificationStepKey,
} from 'pages/Finprom/types'
import { VerificationRouteKey, VerificationStep as VerificationStepType } from 'types/verification'
import { trackOnboarding } from 'utils/tracker'
import { moveItemInArray } from 'utils/utils'
import { UnsavedChangesModal } from './_components/UnsavedChangesModal'
import { VerificationMenu } from './_components/VerificationMenu'
import { Review } from './Review/Review'
import { VerificationStep } from './VerificationStep'

export interface VerificationStepsProps {
  onCompleteSteps: () => void
}

export const VerificationSteps = ({ onCompleteSteps }: VerificationStepsProps) => {
  const { t } = useTranslation()
  const accountContacts = useAccountContactsData()

  const {
    accountPreferences,
    activeStep: activeFinpromStep,
    showFinpromPhaseTwo,
    isGoBackToBusinessAddress,
    isWatchingBusinessAddress,
    onGoBackToBusinessAddress,
    onLeaveBusinessAddress,
    setActiveStep: setActiveFinpromStep,
  } = useFinpromContext()

  const {
    data: businessVerificationStatus,
    isFetching: isLoadingBusinessVerificationStatus,
    isError: isBusinessVerificationStatusError,
    refetch: getRoutesStatus,
  } = useBusinessVerificationStatusQuery()

  const isLoadingStepsData = useMemo(
    () => isLoadingBusinessVerificationStatus || accountContacts?.isFetching,
    [isLoadingBusinessVerificationStatus, accountContacts?.isFetching]
  )

  const finpromStepIsComplete: Record<FinpromVerificationStepKey, boolean> = {
    riskWarning: !!accountPreferences?.[finpromRiskWarningPreferenceKey]?.isComplete,
    selfCategorisation: !!accountPreferences?.[finpromSelfCategorisationPreferenceKey]?.isComplete,
    appropriatenessTest:
      !!accountPreferences?.[finpromAppropriatenessTestPreferenceKey]?.isComplete,
  }

  const sortedSteps = useMemo(() => {
    {
      if (!accountContacts?.isEnabled) return VerificationRouteList
      return moveItemInArray<VerificationRouteKey>(
        VerificationRouteList,
        VerificationRouteList?.indexOf(VerificationRoutes.ACCOUNT_REPRESENTATIVE),
        VerificationRouteList?.indexOf(VerificationRoutes.BUSINESS_OWNERS)
      )
    }
  }, [accountContacts?.isEnabled])

  const verificationSteps: VerificationStepType[] = useMemo(
    () =>
      sortedSteps?.map((key: VerificationRouteKey) => ({
        key,
        status: businessVerificationStatus?.[key] ?? VerificationRouteStatus.PENDING,
      })),
    [sortedSteps, businessVerificationStatus]
  )

  if (accountContacts?.isEnabled) {
    AccountContactTypesList?.forEach(accountContactType => {
      const stepKey = AccountContactStepKeys?.[accountContactType]
      const stepIndex = verificationSteps?.findIndex(step => step?.key === stepKey)
      if (stepIndex !== -1) {
        const routeStatus = getAccountContactStepStatus(accountContactType, accountContacts?.list)
        verificationSteps[stepIndex].status = routeStatus
      }
    })
  }

  const finpromVerificationSteps: FinpromVerificationStep[] =
    showFinpromPhaseTwo && !isWatchingBusinessAddress
      ? FinpromVerificationStepList?.map((key: FinpromVerificationStepKey) => ({
          key,
          status: !!finpromStepIsComplete?.[key]
            ? VerificationRouteStatus.COMPLETED
            : VerificationRouteStatus.PENDING,
        }))
      : []

  const completedSteps = verificationSteps?.filter(
    (step: VerificationStepType) => step?.status === VerificationRouteStatus.COMPLETED
  )

  const pendingSteps = verificationSteps?.filter(
    (step: VerificationStepType) => step?.status === VerificationRouteStatus.PENDING
  )

  const hasCompletedSteps = !!completedSteps?.length

  const [modalForwardRoute, setModalForwardRoute] = useState<VerificationRouteKey>()
  const [activeStep, setActiveStep] = useState<VerificationRouteKey>()
  const [isInitialActiveStepLoaded, setIsInitialActiveStepLoaded] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useState(false)

  const goToNextPendingStep = () => {
    if (!verificationSteps?.length) return
    if (!pendingSteps?.length) {
      setActiveStep(verificationSteps?.at(-1)?.key)
      return
    }
    setActiveStep(pendingSteps?.[0]?.key)
  }

  const goToNextStep = () => {
    const currentStepIndex = verificationSteps?.findIndex(step => step?.key === activeStep)
    const nextStepKey = verificationSteps?.[currentStepIndex + 1]?.key
    if (nextStepKey) {
      setActiveStep(nextStepKey)
    }
  }

  const checkUnsavedChanges = (targetRoute: VerificationRouteKey) => {
    if (isWatchingBusinessAddress && activeStep === VerificationRoutes.BUSINESS_ADDRESS) {
      onLeaveBusinessAddress()
    }
    const isMultipleAccountContactStep =
      activeStep === VerificationRoutes.BUSINESS_OWNERS ||
      activeStep === VerificationRoutes.BUSINESS_DIRECTORS
    if (accountContacts?.isEnabled && isMultipleAccountContactStep) {
      getRoutesStatus()
    }
    if (unsavedChanges && targetRoute !== activeStep) {
      setModalForwardRoute(targetRoute)
    } else {
      setActiveStep(targetRoute)
    }
  }

  const handleMenuItemClick = (newStep: VerificationRouteKey) => {
    checkUnsavedChanges(newStep)
    if (activeFinpromStep) {
      setActiveFinpromStep(null)
    }
  }

  const handleFinpromMenuItemClick = (newStep: FinpromStepKey) => {
    setActiveFinpromStep(newStep)
  }

  /**
   * This function tracks the continue event in Amplitude,
   * updates setUnsavedChanges and setActiveRoute state and
   * executes getRouteStatus to check Onboarding progress.
   * @param {String} currentStepName is used for the tracking event label.
   * @returns void
   */
  const submitStep = (currentStepName: string) => {
    trackOnboarding(`Continue - ${currentStepName}`)
    setUnsavedChanges(false)
    goToNextStep()
    getRoutesStatus()
  }

  useEffect(() => {
    if (isGoBackToBusinessAddress && activeStep !== VerificationRoutes.BUSINESS_ADDRESS) {
      onGoBackToBusinessAddress()
      setActiveStep(VerificationRoutes.BUSINESS_ADDRESS)
    }
  }, [isGoBackToBusinessAddress, activeStep])

  useEffect(() => {
    setModalForwardRoute(undefined)
  }, [activeStep])

  useEffect(() => {
    if (!isLoadingStepsData && businessVerificationStatus && !isInitialActiveStepLoaded) {
      setIsInitialActiveStepLoaded(true)
      goToNextPendingStep()
    }
  }, [isLoadingStepsData, businessVerificationStatus, isInitialActiveStepLoaded])

  useEffect(() => {
    if (accountContacts?.isEnabled && isInitialActiveStepLoaded) {
      goToNextPendingStep()
    }
  }, [accountContacts?.isEnabled, isInitialActiveStepLoaded])

  return (
    <>
      {isLoadingStepsData && !activeStep ? (
        <Box flex centerChildren>
          <Loader size="large" />
        </Box>
      ) : isBusinessVerificationStatusError ? (
        <Box flex centerChildren>
          <StatusCard status="error" headline={t('requestErrors.pageErrorIntro')} borderless>
            <Description>
              {t('requestErrors.comeBackLater')}{' '}
              <TextAction isWindowReload text={t('requestErrors.refreshPage')} />.
            </Description>
          </StatusCard>
        </Box>
      ) : (
        <VerificationContextProvider
          accountContacts={accountContacts}
          hasCompletedSteps={hasCompletedSteps}
          setActiveRoute={setActiveStep}
          setUnsavedChanges={setUnsavedChanges}
          submitStep={submitStep}
        >
          <Box alignItems="stretch" justifyContent="start" width="full" className="md:flex">
            <VerificationMenu
              activeStep={activeStep}
              steps={verificationSteps}
              finpromSteps={finpromVerificationSteps}
              onClick={handleMenuItemClick}
              onClickFinpromStep={handleFinpromMenuItemClick}
            />

            <ActiveStep
              flexSize="fill"
              paddingX={16}
              paddingY={24}
              className="md:px-12 md:py-12 xl:pl-0 xl:pr-[300px]"
            >
              <Box maxWidth={448} centerBox aria-label="verificationStep">
                {showFinpromPhaseTwo && !!activeFinpromStep ? (
                  <FinpromStep />
                ) : (
                  <Box>
                    {activeStep === VerificationRoutes.REVIEW_AND_FINISH ? (
                      <Review
                        steps={verificationSteps}
                        isLoadingStepsStatus={isLoadingBusinessVerificationStatus}
                        onSubmit={onCompleteSteps}
                      />
                    ) : (
                      <VerificationStep activeStep={activeStep} />
                    )}
                  </Box>
                )}
              </Box>
            </ActiveStep>
          </Box>

          <UnsavedChangesModal
            isOpen={!!modalForwardRoute}
            onStay={() => setModalForwardRoute(undefined)}
            onLeave={() => {
              setUnsavedChanges(false)
              setActiveStep(modalForwardRoute)
            }}
          />
        </VerificationContextProvider>
      )}
    </>
  )
}
