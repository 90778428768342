import React from 'react'
import { Box as Page, Box as Container, Box as Body, Text } from '@node-space/storybook-components'
import { Description } from 'components/Text'
import { LoginFooter } from 'components/footer/LoginFooter'
import { LogoHeader } from 'components/header/LogoHeader'
import { TextAlignType } from 'types/text'

interface LoginPageLayoutProps {
  children: React.ReactNode
  title?: string
  description?: React.ReactNode
  headerRightContent?: React.ReactNode
  hasFooter?: boolean
  narrowView?: boolean
  titleBig?: boolean
}

const LoginPageLayout = ({
  children,
  title,
  description,
  headerRightContent,
  hasFooter,
  narrowView = true,
  titleBig,
}: LoginPageLayoutProps) => {
  return (
    <Page flex direction="col" width="full" minHeight="screen" background="off-white">
      <LogoHeader>{headerRightContent}</LogoHeader>
      <Container flex flexSize="fill" width="full" centerChildren paddingX={16} className="sm:px-8">
        <Body
          flex
          direction="col"
          {...(narrowView && {
            maxWidth: 384,
          })}
          width="full"
          paddingY={32}
          gapY={24}
        >
          {title && (
            <Text
              tag="h1"
              size={titleBig ? '3xl' : 'xl'}
              weight="semibold"
              align={TextAlignType.CENTER}
              testid="dev-signup-header-text"
            >
              {title}
            </Text>
          )}
          {description && <Description align={TextAlignType.CENTER}>{description}</Description>}
          {children}
        </Body>
      </Container>
      {hasFooter && <LoginFooter />}
    </Page>
  )
}

export default LoginPageLayout
