import { TFunction } from 'i18next'
import * as yup from 'yup'
import { applyPasswordTests } from 'utils/password'
import { useYupFields } from 'hooks/forms/useYupFields'

export interface DevXReq {
  firstName: string
  lastName: string
  emailAddress: string
  password: string
  companyName: string
}

export type DevXReqKeys = keyof DevXReq

const devXSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField, getEmailYupField } = useYupFields()

  return yup.object<Partial<Record<DevXReqKeys, yup.AnySchema>>>({
    firstName: getRequiredYupField({ label: t('firstName') }),
    lastName: getRequiredYupField({ label: t('lastName') }),
    emailAddress: getEmailYupField(t('workEmail')),
    password: getRequiredYupField({ label: t('password') }).test({
      name: 'testPassword',
      test: (value, ctx) => {
        const { isValid, message } = applyPasswordTests(value, t)
        return isValid
          ? true
          : ctx.createError({
              message: message,
              path: 'password',
            })
      },
    }),
    companyName: getRequiredYupField({ label: t('companyName') }),
  })
}

export default devXSchema
