import React from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Box as InlineFields } from '@node-space/storybook-components/dist/Box'
import Input from '@node-space/storybook-components/dist/Input'
import { Select, Option as SelectOption } from '@node-space/storybook-components/dist/Select'
import {
  AccountContactActionsLoading,
  AccountContactWrapper,
} from 'components/accountContact/AccountContactWrapper'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useFormSetup } from 'hooks/forms/useFormSetup'
import { BusinessOwnersCorporateSchema } from '../schemas/corporateBusinessOwnersSchema'
import { initialCorporateOwnerFormData, OwnershipTypes } from './constants'
import { CorporateOwnerFormData, CorporateOwnerFormKeys, OwnershipTypeOption } from './types'

export interface CorporateOwnerFormProps {
  corporateOwner: CorporateOwnerFormData
  countryOptions: SelectOption[]
  isEditing: boolean
  actionsLoading?: AccountContactActionsLoading
  onSave: (ownerData: CorporateOwnerFormData) => void
  onDelete: () => void
}

export const CorporateOwnerForm = ({
  corporateOwner,
  countryOptions,
  isEditing,
  actionsLoading,
  onSave,
  onDelete,
}: CorporateOwnerFormProps) => {
  const { t } = useTranslation()

  const ownershipTypeSelectOptions: OwnershipTypeOption[] = [
    { value: OwnershipTypes.DIRECT, label: t('verification.businessOwners.direct') },
    { value: OwnershipTypes.INDIRECT, label: t('verification.businessOwners.indirect') },
  ]

  const defaultValues = corporateOwner ?? initialCorporateOwnerFormData

  const formSchema = BusinessOwnersCorporateSchema(t)
  const { form, formValues, errors, handleSubmit } = useFormSetup<CorporateOwnerFormData>(
    formSchema,
    defaultValues
  )
  const { setInputField, setSelectField } = useFormFields<CorporateOwnerFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: Record<CorporateOwnerFormKeys, any> = {
    name: setInputField({ key: 'name' }),
    countryCode: setSelectField({
      key: 'countryCode',
      placeholder: t('formFields.chooseCountry'),
      options: countryOptions,
    }),
    registrationNumber: setInputField({ key: 'registrationNumber' }),
    ownershipPercentage: setInputField({
      key: 'ownershipPercentage',
      placeholder: '0',
    }),
    ownershipType: setSelectField({ key: 'ownershipType', options: ownershipTypeSelectOptions }),
  }

  const onSubmit: SubmitHandler<typeof formValues> = (formValues: CorporateOwnerFormData) => {
    onSave(formValues)
  }

  return (
    <AccountContactWrapper
      addActionLabel={t('addCorporateShareholder')}
      {...(actionsLoading && { actionsLoading })}
      actionsError={{ isFormValuesError: !!Object.keys(errors)?.length }}
      isEditing={isEditing}
      onSubmit={handleSubmit(onSubmit)}
      onDelete={onDelete}
    >
      <Input {...formFields.name} />
      <Select {...formFields.countryCode} loading={!countryOptions} isSearchable />
      <Input {...formFields.registrationNumber} />
      <InlineFields flex gap={16}>
        <Box flexSize="fill" width="full">
          <Input {...formFields.ownershipPercentage} postLabel="%" inputMode="numeric" />
        </Box>
        <Box flexSize="fill" width="full">
          <Select {...formFields.ownershipType} isSearchable />
        </Box>
      </InlineFields>
    </AccountContactWrapper>
  )
}
