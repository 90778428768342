import { transformIndustries } from 'utils/utils'
import { useIndustriesQuery } from './queries/useIndustriesQuery'

export const useIndustryOptions = (enableSubcategory: boolean) => {
  const { data: industries } = useIndustriesQuery(enableSubcategory)
  const options = industries?.map(industry => ({ label: industry.name, value: `${industry.id}` }))
  const industryOptions = enableSubcategory
    ? transformIndustries(industries, enableSubcategory)
    : options

  return industryOptions ?? []
}

export const useIndustryValues = (enableSubcategory: boolean) => {
  const { data: industries } = useIndustriesQuery(enableSubcategory)
  const values = industries?.map(industry => `${industry.id}`)
  const industryOptionValues = enableSubcategory
    ? transformIndustries(industries, enableSubcategory)?.flatMap(option =>
        option?.options?.map(({ value }) => `${value}`)
      )
    : values

  return industryOptionValues ?? []
}
