import { useState } from 'react'

declare global {
  interface Window {
    grecaptcha: any
  }
}

const RECAPTCHA_API_URL: string = process.env.RECAPTCHA_API_URL
const RECAPTCHA_SITEKEY: string = process.env.RECAPTCHA_SITEKEY_V3
const RECAPTCHA_SRC = `${RECAPTCHA_API_URL}?render=${RECAPTCHA_SITEKEY}`

export const useRecaptcha = (action: string) => {
  const [isLoadingRecaptcha, setIsLoadingRecaptcha] = useState(true)

  const getRecaptchaToken = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      window.grecaptcha?.ready(() => {
        try {
          window.grecaptcha
            .execute(RECAPTCHA_SITEKEY, { action })
            .then((token: string) => resolve(token))
        } catch (error) {
          reject(error)
        }
      })
    })
  }

  const getLoadedRecaptcha = () => document.querySelectorAll(`script[src="${RECAPTCHA_SRC}"]`)

  const removeBadges = () => {
    const badges = document.querySelectorAll('.grecaptcha-badge')
    if (!badges?.length) return
    badges?.forEach(badge => badge.parentElement.remove())
  }

  const loadRecaptcha = () => {
    const loaded = getLoadedRecaptcha()
    if (!!loaded?.length) {
      setIsLoadingRecaptcha(false)
      return
    }
    const script = document.createElement('script')
    script.src = RECAPTCHA_SRC
    document.body.appendChild(script)
    setIsLoadingRecaptcha(false)
  }

  const unloadRecaptcha = () => {
    const loaded = getLoadedRecaptcha()
    if (!loaded?.length) return
    loaded?.forEach(load => load.remove())
    removeBadges()
  }

  return {
    isLoadingRecaptcha,
    loadRecaptcha,
    unloadRecaptcha,
    getRecaptchaToken,
  }
}
