import React from 'react'
import {
  Button,
  Icon,
  Box,
  Box as InfoContainer,
  Box as ActionContainer,
  Text,
} from '@node-space/storybook-components'

interface ItemDisplayProps {
  itemHeader: string
  itemSubheader?: string
  onDelete: () => void
  onEdit: () => void
  disable?: boolean
}

export const ItemDisplay = ({
  itemHeader,
  itemSubheader,
  onDelete,
  onEdit,
  disable,
}: ItemDisplayProps) => {
  return (
    <Box
      flex
      border="gray"
      flexItemsCenter
      flexJustifyBetween
      padding={16}
      borderRadius={4}
      className="bg-gray-0 xs:w-full sm:w-96"
    >
      <InfoContainer>
        <Text size="sm" weight="medium">
          {itemHeader}
        </Text>
        <Text color="grey" size="xs">
          {itemSubheader}
        </Text>
      </InfoContainer>
      <ActionContainer flex gapX={16} paddingL={16}>
        <Button
          onClick={onDelete}
          noStyling
          iconElement={<Icon name="TrashIcon" />}
          disabled={disable}
        />
        <Button
          onClick={onEdit}
          noStyling
          iconElement={<Icon name="EditIcon" />}
          disabled={disable}
        />
      </ActionContainer>
    </Box>
  )
}
