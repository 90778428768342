import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromTrackingLabels } from '../constants'
import {
  AppropriatenessData,
  finpromAppropriatenessTestPreferenceKey,
} from '../finpromPreferenceData'
import { useFinpromPreferenceRequest } from './useFinpromPreferenceRequest'

export const useRetakeAppropriatenessTest = () => {
  const { accountPreferences } = useFinpromContext() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<AppropriatenessData>()

  const onRetakeQuiz = () => {
    const attempts = accountPreferences?.[finpromAppropriatenessTestPreferenceKey]?.attempts
    const updatedAppropriatenessData: AppropriatenessData = { attempts }
    submitPreference(
      finpromAppropriatenessTestPreferenceKey,
      updatedAppropriatenessData,
      `${FinpromTrackingLabels.APPROPRIATENESS_RETAKE_QUIZ} attempt ${attempts?.unsuccessful}`
    )
  }

  return {
    isLoadingRetake: isLoadingContinue,
    isErrorRetake: isErrorContinue,
    onRetakeQuiz,
  }
}
