import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box as Container, Button, Text } from '@node-space/storybook-components'
import { isProduction, isSandbox } from 'utils/environment'
import { Amp } from 'utils/tracker'
import { RedirectUri } from 'constants/General'

interface ExtraSignUpCTAProps {
  url: string
  isExternal?: boolean
  externalUrl?: string
  trackingLabel: string
  preButtonText: string
  buttonText: string
}

export const ExtraSignUpCTA = ({
  url,
  isExternal,
  externalUrl,
  trackingLabel,
  preButtonText,
  buttonText,
}: ExtraSignUpCTAProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const signUpUrl = url || RedirectUri.KYB_SIGNUP_URL

  const goInternal = () => navigate(signUpUrl)
  const goExternal = () =>
    window.open(`${externalUrl ?? process.env.ONBOARDING_SANDBOX_URL}${signUpUrl}`)

  const onClickHandler = () => {
    if (isSandbox) Amp.track(`DevX: SB: Click - ${trackingLabel}`)
    if (isProduction) Amp.track(`DevX: PD: Click - ${trackingLabel}`)
    Amp.track(`ONB - ${trackingLabel}`, {
      category: 'Signup',
      action: 'click',
    })

    isProduction || isExternal ? goExternal() : goInternal()
  }

  return (
    <Container divider="top" paddingT={24}>
      <Text size="sm" color="grey">
        {`${preButtonText} `}
        <Button
          className="text-secondary-500"
          noStyling
          onClick={onClickHandler}
        >{`${buttonText}`}</Button>
      </Text>
    </Container>
  )
}
