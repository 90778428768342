import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderCTA } from 'components/header/HeaderCTA'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { SignUpAgreement } from './components'
import BusinessForm from './components/BusinessForm'
import { useDeleteAuthCookie } from 'hooks/useDeleteAuthCookie'
import { ExtraSignUpCTA } from './components/ExtraSignUpCTA'
import { RedirectUri } from 'constants/General'

export const CreateAccount = () => {
  const { t } = useTranslation()

  useDeleteAuthCookie()

  return (
    <LoginPageLayout
      title={`${t('openAccount')}`}
      headerRightContent={
        <HeaderCTA
          helpText={`${t('alreadySignedUp')}`}
          buttonText={`${t('logIn')}`}
          buttonUrl={RedirectUri.LOGIN}
        />
      }
      hasFooter
    >
      <BusinessForm />
      <SignUpAgreement />
      <ExtraSignUpCTA
        url={RedirectUri.DEVX_SIGNUP_URL}
        trackingLabel="Sign up on Sandbox link"
        preButtonText={t('devAccountCtaMessage')}
        buttonText={t('signUpSandbox')}
      />
    </LoginPageLayout>
  )
}
