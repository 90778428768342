import React from 'react'
import clsx from 'clsx'
import { Box, Box as IconBox } from '@node-space/storybook-components/dist/Box'
import { Text } from '@node-space/storybook-components/dist/Text'
import {
  CSATFeedbackValue,
  CSATFeedbackValueType,
  CSATOptionStatus,
  CSATOptionStatusType,
} from './types'
import { CSATIcon } from './CSATIcon'

type CSATOptionProps = {
  label: string
  value: CSATFeedbackValueType
  status: CSATOptionStatusType
  onClick: () => void
}

export const CSATOption = ({ label, value, status, onClick }: CSATOptionProps) => {
  return (
    <Box tag="button" flex direction="col" centerChildren onClick={onClick} key={value}>
      <IconBox
        className={clsx(
          status === CSATOptionStatus.UNSELECTED
            ? 'text-text-300'
            : {
                'text-status-error-500': value === CSATFeedbackValue.BAD,
                'text-primary-500': value === CSATFeedbackValue.OKAY,
                'text-status-success-500': value === CSATFeedbackValue.GOOD,
              }
        )}
      >
        <CSATIcon name={value} selected={status === CSATOptionStatus.SELECTED} />
      </IconBox>
      <Text
        size="sm"
        weight={status === CSATOptionStatus.SELECTED ? 'bold' : 'normal'}
        className={clsx(
          status === CSATOptionStatus.UNSELECTED
            ? 'text-text-300'
            : {
                'text-status-error-900': value === CSATFeedbackValue.BAD,
                'text-primary-900': value === CSATFeedbackValue.OKAY,
                'text-status-success-900': value === CSATFeedbackValue.GOOD,
              }
        )}
      >
        {label}
      </Text>
    </Box>
  )
}
