import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { AppropriatenessStepKey } from 'pages/Finprom/types'
import { StepCryptoAssetBvnkOffer } from './steps/StepCryptoAssetBvnkOffer'
import { StepCryptoAssetDiversity } from './steps/StepCryptoAssetDiversity'
import { StepCryptoAssetPaymentRisks } from './steps/StepCryptoAssetPaymentRisks'
import { StepCryptoAssetServices } from './steps/StepCryptoAssetServices'
import { StepDueDiligence } from './steps/StepDueDiligence'
import { StepFscsCover } from './steps/StepFscsCover'
import { StepInstructions } from './steps/StepInstructions'
import { StepInvestingInCrypto } from './steps/StepInvestingInCrypto'

export interface AppropriatenessStepProps {
  activeFormStep: AppropriatenessStepKey
}

const stepsMap: Record<AppropriatenessStepKey, () => JSX.Element> = {
  instructions: StepInstructions,
  dueDiligence: StepDueDiligence,
  investingInCrypto: StepInvestingInCrypto,
  cryptoAssetServices: StepCryptoAssetServices,
  cryptoAssetPaymentRisks: StepCryptoAssetPaymentRisks,
  cryptoAssetBvnkOffer: StepCryptoAssetBvnkOffer,
  fscsCover: StepFscsCover,
  cryptoAssetDiversity: StepCryptoAssetDiversity,
}

export const AppropriatenessStep = ({ activeFormStep }: AppropriatenessStepProps) => {
  const { t } = useTranslation()
  const StepForm = stepsMap[activeFormStep]

  return (
    StepForm && (
      <Container>
        <Description>{t(`finprom.appropriateness.${activeFormStep}.introduction`)}</Description>
        <StepForm />
      </Container>
    )
  )
}
