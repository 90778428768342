import { requestApi } from 'api/api'
import { AccountAgreementData, AccountPreferenceData, AccountPreferenceKey } from 'types/accounts'
import { AccountDetails } from 'types/types'
import { VerificationRequestStatus } from 'types/verification'

export const getUserAccounts = async () => {
  return requestApi({
    url: '/account/user-accounts',
    method: 'GET',
  })
}

export const getVerificationRequestStatus = (): Promise<VerificationRequestStatus> => {
  return requestApi({
    url: '/business/account/verification-request-status',
    method: 'GET',
  })
}

export const getAccountAgreements = (): Promise<AccountAgreementData[]> => {
  return requestApi({
    url: '/business/account/applicable-agreement',
    method: 'GET',
  })
}

export const putAccountDetails = (data: AccountDetails): Promise<AccountDetails> => {
  return requestApi({
    url: '/business/account/details',
    method: 'PUT',
    data,
  })
}

export const getAccountPreferences = (): Promise<AccountPreferenceData> => {
  return requestApi({
    url: `/account/preference`,
    method: 'GET',
  })
}

export const putAccountPreference = <T>({
  preferenceKey,
  value,
}: {
  preferenceKey: AccountPreferenceKey
  value: T
}) => {
  return requestApi({
    url: `/account/preference/${preferenceKey}`,
    method: 'PUT',
    data: {
      value,
    },
  })
}
