import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Description } from 'components/Text'
import { RedirectUri } from 'constants/General'
import { FinpromResultModal } from 'pages/Finprom/components/FinpromResultModal'
import { useRetakeSelfCategorisation } from 'pages/Finprom/hooks/useRetakeSelfCategorisation'
import { TextAlignType } from 'types/text'

export const UnsuccessfulModal = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { quizCategoryLabel, isLoadingRetake, isErrorRetake, onRetakeQuiz } =
    useRetakeSelfCategorisation() || {}

  const onExit = () => navigate(RedirectUri.LOGOUT, { replace: true })

  const exitAction: ButtonProps = {
    children: t('exit'),
    destructive: true,
    disabled: isLoadingRetake,
    onClick: onExit,
  }

  const retakeQuizAction: ButtonProps = {
    children: t('finprom.selfCategorisation.retakeQuiz'),
    loading: isLoadingRetake,
    onClick: onRetakeQuiz,
  }

  return (
    <FinpromResultModal
      title={t('unsuccessful')}
      icon={<Icon name="CloudLightningIcon" />}
      actions={[exitAction, retakeQuizAction]}
      showError={isErrorRetake}
    >
      <Description align={TextAlignType.CENTER}>
        {`${t('finprom.selfCategorisation.thankYouForCompleting')} ${t(
          'finprom.selfCategorisation.resultUnsuccessful',
          { category: quizCategoryLabel.toLocaleLowerCase() }
        )}`}
      </Description>
      <Description align={TextAlignType.CENTER}>
        {t('finprom.selfCategorisation.ifYouRetakeQuiz')}
      </Description>
    </FinpromResultModal>
  )
}
