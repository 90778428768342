import { DocumentNameType, PersonDocuments, RequiredDocument } from 'types/verification'
import { AccountContactDocument } from './types'

export const getPersonDocuments = (
  documents: RequiredDocument[],
  personName: string,
  contactId?: number
): AccountContactDocument[] => {
  return documents?.map(document => ({
    contactFullname: personName,
    documentCode: document?.code as DocumentNameType,
    filename: document?.filename,
    ...(contactId && { contactId }),
  }))
}

export const getPersonsDocuments = (persons: PersonDocuments[]): AccountContactDocument[] => {
  const personsDocuments = []
  persons?.forEach(person => {
    const contactFullname = person?.name ?? `${person?.firstName} ${person?.lastName}`
    const personDocuments = getPersonDocuments(person?.documents, contactFullname, person?.id)
    personsDocuments?.push(...personDocuments)
  })
  return personsDocuments
}
