import React, { ReactNode } from 'react'
import { Box } from '@node-space/storybook-components/dist/Box'

export type FormBodyProps = {
  children: ReactNode
  hasDivider?: boolean
}

export const FormBody = ({ children, hasDivider }: FormBodyProps) => {
  return (
    <Box
      flex
      direction="col"
      gapY={24}
      paddingT={24}
      {...(hasDivider && { divider: 'top', className: 'mt-6' })}
    >
      {children}
    </Box>
  )
}
