import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import { Quicklink } from '@node-space/storybook-components/dist/Quicklink'
import Text from '@node-space/storybook-components/dist/Text'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { ExternalLinks } from 'constants/ExternalLinks'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { AppropriatenessStepKeys, FinpromTrackingLabels } from 'pages/Finprom/constants'
import {
  AppropriatenessData,
  finpromAppropriatenessTestPreferenceKey,
} from 'pages/Finprom/finpromPreferenceData'
import { useFinpromPreferenceRequest } from 'pages/Finprom/hooks/useFinpromPreferenceRequest'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

const FCA_HANDBOOK_URL = process.env.FCA_HANDBOOK_URL

export const StepInstructions = () => {
  const { t } = useTranslation()
  const { accountPreferences, setAppropriatenessActiveFormStep } = useFinpromContext()
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<AppropriatenessData>()

  const onContinue = () => {
    const currentAppropriatenessData = accountPreferences?.[finpromAppropriatenessTestPreferenceKey]
    if (!currentAppropriatenessData?.hasReadInstructions) {
      const updatedAppropriatenessData: AppropriatenessData = {
        ...currentAppropriatenessData,
        hasReadInstructions: true,
      }
      submitPreference(
        finpromAppropriatenessTestPreferenceKey,
        updatedAppropriatenessData,
        FinpromTrackingLabels.APPROPRIATENESS_STEP_INSTRUCTIONS
      )
    } else {
      setAppropriatenessActiveFormStep(AppropriatenessStepKeys.DUE_DILIGENCE)
    }
  }

  return (
    <Container>
      <Description>{t('finprom.appropriateness.instructions.yourResponses')}</Description>
      <Description>{t('finprom.appropriateness.instructions.educationalMaterial')}</Description>
      <Box flex direction="col" gap={16}>
        <Quicklink
          text={t('finprom.appropriateness.instructions.riskSummaryLink')}
          to={ExternalLinks.riskSummaryFCA}
        />
        <Quicklink
          text={t('finprom.appropriateness.instructions.mitigatingRisksLink')}
          to={FCA_HANDBOOK_URL}
        />
      </Box>
      {isErrorContinue ? (
        <FinpromSubmitError />
      ) : (
        <Callout state="info">
          <Text tag="span" size="sm" weight="medium" color="text-500">
            {t('finprom.appropriateness.instructions.calloutNote')}
          </Text>{' '}
          <Text tag="span" size="sm" color="text-500">
            {t('finprom.appropriateness.instructions.calloutBody')}
          </Text>
        </Callout>
      )}
      <FormSubmitButton onClick={onContinue} loading={isLoadingContinue} disabled={false} />
    </Container>
  )
}
