import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Description } from 'components/Text'
import { useToastContext } from 'hooks/useToastContext'
import { postBusinessDocuments } from 'services/VerificationService'
import { BusinessDocument, BusinessDocumentsPayload, DocumentUpload } from 'types/verification'
import { cleanBase64String } from 'utils/utils'
import { ItemDisplay } from './ItemDisplay'
import { NotificationCard } from './NotificationCard/NotificationCard'
import { UploadDocumentModal } from './UploadDocumentModal'

export const UploadNotification = () => {
  const { t } = useTranslation()

  const addToast = useToastContext()

  const [documents, setDocuments] = useState<DocumentUpload[]>([])
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [document, setDocument] = useState<DocumentUpload>(null)
  const [documentIndex, setDocumentIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const addDocument = (document: DocumentUpload) => {
    setDocuments([...documents, document])
  }

  const editDocument = (newDocument: DocumentUpload) => {
    const tempDocuments = documents?.map((document, index) => {
      return index === documentIndex ? newDocument : document
    })
    setDocuments(tempDocuments)
  }

  const deleteDocument = (deleteIndex: number) => {
    setDocuments(documents?.filter((document, index) => index !== deleteIndex))
  }

  useEffect(() => {
    if (document) {
      setShowUploadModal(true)
    }
  }, [document])

  const uploadDocuments = async () => {
    setIsLoading(true)

    const accountDocuments: BusinessDocument[] = documents?.map(document => {
      return {
        code: 'additionalDocument',
        file: {
          name: document?.documentType?.trim(),
          base64: cleanBase64String(document?.buffer as string),
        },
      }
    })

    const payloadData: BusinessDocumentsPayload = {
      accountDocuments: accountDocuments,
    }

    try {
      await postBusinessDocuments(payloadData)

      if (documents?.length === 1) {
        addToast({ title: t('verification.documentSubmitted'), state: 'success' })
      }

      if (documents?.length > 1) {
        addToast({ title: t('verification.documentsSubmitted'), state: 'success' })
      }

      setDocuments([])
      setIsError(false)
    } catch (error) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const closeModal = () => {
    setShowUploadModal(false)
    setDocument(null)
  }

  return (
    <>
      <NotificationCard
        title={t('verification.uploadHeading')}
        iconBackground={'gray'}
        iconComponent={<Icon name="UploadIcon" color="grey-08" size="lg" />}
      >
        <Description>{`${t('verification.uploadMessage')}`}</Description>
        {isError && (
          <Callout state="error" message={t('oopsSomethingWentWrong')} showCloseButton={false} />
        )}
        <Box>
          <Button
            onClick={() => setShowUploadModal(true)}
            iconElement={<Icon name="PlusIcon" color="primary-500" />}
            className="mt-1"
            secondary
          >
            {t('verification.uploadDocument')}
          </Button>
        </Box>
        {documents.map((document, index) => {
          return (
            <ItemDisplay
              key={index}
              itemHeader={document?.fileData?.name}
              itemSubheader={document?.documentType}
              onDelete={() => deleteDocument(index)}
              disable={isLoading}
              onEdit={() => {
                setDocument(document)
                setDocumentIndex(index)
              }}
            />
          )
        })}
        {!!documents?.length && (
          <Box>
            <Button onClick={uploadDocuments} loading={isLoading}>
              {t('verification.submitForReview')}
            </Button>
          </Box>
        )}
      </NotificationCard>
      {showUploadModal && (
        <UploadDocumentModal
          onAdd={document => addDocument(document)}
          onEdit={document => editDocument(document)}
          onClose={closeModal}
          document={document}
        />
      )}
    </>
  )
}
