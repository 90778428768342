import { PersonDocuments, RequiredDocument, RequiredDocumentsDetails } from 'types/verification'
import { AccountContactDocumentCategories, AccountContactDocumentCategoryList } from './constants'
import { RequiredAccountContactDocuments } from './types'
import { getPersonDocuments, getPersonsDocuments } from './utils'

export const useMappedDocuments = (
  documentsData: RequiredDocumentsDetails,
  accountRepName: string
) => {
  const accountContactDocuments: RequiredAccountContactDocuments = {}
  AccountContactDocumentCategoryList?.forEach(category => {
    const categoryData = documentsData?.[category]
    if (!categoryData?.length) return
    const categoryDocuments =
      category === AccountContactDocumentCategories.ACCOUNT_REPRESENTATIVE
        ? getPersonDocuments(categoryData as RequiredDocument[], accountRepName)
        : getPersonsDocuments(categoryData as PersonDocuments[])
    accountContactDocuments[category] = categoryDocuments
  })

  return { accountContactDocuments }
}
