import React from 'react'
import { useTranslation } from 'react-i18next'
import Callout from '@node-space/storybook-components/dist/Callout'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { ExternalLinks } from 'constants/ExternalLinks'
import { useAccountContext } from 'hooks/context/useAccountContext'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromStepKeys, FinpromTrackingLabels } from 'pages/Finprom/constants'
import {
  finpromRiskWarningPreferenceKey,
  FinpromStepStatus,
} from 'pages/Finprom/finpromPreferenceData'
import { useFinpromPreferenceRequest } from 'pages/Finprom/hooks/useFinpromPreferenceRequest'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'

const FCA_HANDBOOK_URL = process.env.FCA_HANDBOOK_URL

export const RiskWarning = () => {
  const { t } = useTranslation()
  const { currentAccount } = useAccountContext()
  const { accountPreferences, activeStep, setActiveStep } = useFinpromContext() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<FinpromStepStatus>()

  const onContinue = () => {
    const isRiskWarningComplete = accountPreferences?.[finpromRiskWarningPreferenceKey]?.isComplete
    if (!isRiskWarningComplete) {
      const updatedRiskWarningData: FinpromStepStatus = { isComplete: true }
      submitPreference(
        finpromRiskWarningPreferenceKey,
        updatedRiskWarningData,
        FinpromTrackingLabels.RISK_WARNING
      )
    } else {
      setActiveStep(FinpromStepKeys.SELF_CATEGORISATION)
    }
  }

  return (
    <Container>
      <VerificationHeader header={t('finprom.steps.riskWarning')} />
      {isErrorContinue && <FinpromSubmitError />}
      <Description>
        {`${currentAccount?.name}, ${t('finprom.riskWarning.introduction')} `}
        <TextAction
          text={t('finprom.riskWarning.learnMoreLink')}
          linkTo={ExternalLinks.riskSummaryFCA}
        />
      </Description>
      <Description>{t('finprom.riskWarning.description')}</Description>
      <Description>{t('finprom.riskWarning.helpResources')}</Description>
      <Description>
        <TextAction text={t('finprom.riskWarning.helpResourcesLink')} linkTo={FCA_HANDBOOK_URL} />
      </Description>
      <Callout state="info">{t('finprom.riskWarning.callout')}</Callout>
      <FormSubmitButton
        disabled={activeStep === FinpromStepKeys.INITIAL}
        loading={isLoadingContinue}
        onClick={onContinue}
      />
    </Container>
  )
}
