import * as yup from 'yup'
import { TFunction } from 'i18next'
import { ServiceNeed, ServiceNeedErrorType } from 'types/verification'
import { useYupFields } from 'hooks/forms/useYupFields'

export interface BusinessActivityFormData {
  description: string
  website: string
  expectedMonthlyVolume: string
  serviceNeeds: ServiceNeed[]
}

export type BusinessActivityFormKeys = keyof BusinessActivityFormData

const serviceNeedShape = {
  id: yup.number().required(),
  checked: yup.boolean().required(),
  label: yup.string().required(),
  note: yup.string(),
  optionalLabel: yup.string(),
  optionalValue: yup.string().nullable(),
}

export const businessActivitySchema = (t: TFunction<'translation', undefined>) => {
  const { getYupField, getRequiredYupField, getArrayOfObjectsYupField } = useYupFields()

  return yup.object<Record<BusinessActivityFormKeys, yup.AnySchema>>({
    description: getRequiredYupField({
      label: t('formFields.describeBusinessActivity'),
      requiredErrorMessage: t('formFields.businessActivityRequired'),
    }),
    website: getYupField({ label: t('formFields.businessWebsite') }),
    expectedMonthlyVolume: getRequiredYupField({
      label: t('formFields.estimatedMonthlyVolume'),
      requiredErrorMessage: t('formFields.estimatedMonthlyVolumeRequired'),
    }),
    serviceNeeds: getArrayOfObjectsYupField({ ...serviceNeedShape })
      .test({
        name: 'noService' as ServiceNeedErrorType,
        test: (serviceNeeds, ctx) => {
          const checkedServiceNeeds = serviceNeeds?.filter(serviceNeed => serviceNeed.checked)

          return !checkedServiceNeeds?.length
            ? ctx.createError({
                message: t('formFields.serviceNeedsRequired'),
                path: 'serviceNeeds',
              })
            : true
        },
      })
      .test({
        name: 'noDescription' as ServiceNeedErrorType,
        test: (serviceNeeds, ctx) => {
          const requireDescriptionServiceNeeds = serviceNeeds?.filter(
            serviceNeed =>
              serviceNeed.checked &&
              serviceNeed.optionalLabel?.length > 0 &&
              !serviceNeed.optionalValue?.length
          )

          return requireDescriptionServiceNeeds?.length
            ? ctx.createError({
                message: t('formFields.emptyAnswerError'),
                path: 'serviceNeeds',
              })
            : true
        },
      }),
  })
}
