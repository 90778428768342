import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import { logError } from '@node-space/utils'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { RecaptchaScoreError } from 'components/recaptcha/RecaptchaScoreError'
import { RecaptchaTokenError } from 'components/recaptcha/RecaptchaTokenError'
import { Description } from 'components/Text'
import { useResendEmailConfirmMutation } from 'hooks/mutations/useResendEmailConfirmMutation'
import { useSalesforcePardotFormSubmission } from 'hooks/mutations/useSalesforcePardotFormSubmission'
import { useRecaptchaVersioning } from 'hooks/recaptcha/useRecaptchaVersioning'
import { postLogin, postRegisterAdditional, postRegisterConfirm } from 'services/AuthService'
import { ResendEmailData } from 'types/accounts'
import { PostLoginReq } from 'types/authentication/PostLoginReq'
import { TextAlignType } from 'types/text'
import { AccountTypes } from 'types/types'
import { isSandbox } from 'utils/environment'
import { isRecapthcaLowScoreError } from 'utils/recaptcha'
import { trackOnboarding } from 'utils/tracker'

export default function ConfirmEmail() {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()

  const { recaptchaVersion, isLoadingRecaptcha, getRecaptchaToken, resetRecaptcha } =
    useRecaptchaVersioning('signup')

  const [errorMessage, setErrorMessage] = useState<React.ReactNode>()
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [loadingSendMail, setLoadSendMail] = useState(false)
  const [mailSent, setMailSent] = useState(false)

  const email = location.state?.email || null
  const password = location.state?.password || null
  const accountType = location.state?.accountType || null
  const firstName = location.state?.firstName || null
  const lastName = location.state?.lastName || null
  const companyName = location.state?.companyName || null

  const salesforcePardotPayload = {
    RegisteredName: companyName,
    EmailAddress: email,
    FirstName: firstName,
    LastName: lastName,
  }

  const { mutate } = useSalesforcePardotFormSubmission()
  const { mutate: postResendEmailConfirm } = useResendEmailConfirmMutation()

  useEffect(() => {
    if (accountType === AccountTypes.DEVELOPER && isSandbox) {
      mutate(salesforcePardotPayload, {
        onError: error => {
          console.error('Salesforce Pardot Submission Error', error)
        },
      })
    }
  }, [])

  const isLoading = useMemo(
    () => isLoadingRecaptcha || isLoadingSubmit,
    [isLoadingRecaptcha, isLoadingSubmit]
  )

  const onInputChange = event => {
    if (event.target.validity.valid) {
      setVerificationCode(event.target.value)
    }
  }

  const sendVerificationCode = async () => {
    setIsLoadingSubmit(true)
    setErrorMessage(null)
    try {
      const recaptchaToken: string = await getRecaptchaToken()

      if (!recaptchaToken) {
        setErrorMessage(<RecaptchaTokenError />)
        setIsLoadingSubmit(false)
        return
      }

      const postData: PostLoginReq = {
        emailAddress: email,
        password: password,
        recaptcha: recaptchaToken,
        recaptchaVersion: recaptchaVersion,
      }

      await postRegisterConfirm({ body: verificationCode })

      await postLogin(postData)

      trackOnboarding('Confirmed email', 'verified')

      if (firstName && lastName && accountType) {
        await postRegisterAdditional({
          firstName,
          lastName,
          companyPosition: accountType,
          companyName,
        })
      }

      await queryClient.invalidateQueries({ queryKey: reactQueryKeys.profile() })
      await queryClient.invalidateQueries({ queryKey: reactQueryKeys.verification })

      resetRecaptcha()

      if (accountType === AccountTypes.DEVELOPER) {
        return navigate('/')
      }

      navigate('/verification')
    } catch (error) {
      if (isRecapthcaLowScoreError(error?.data?.message)) {
        setErrorMessage(<RecaptchaScoreError />)
      } else {
        setErrorMessage(t('otp.error'))
      }
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const verificationPattern = /^\d{6}$/

  const testRegExp = () => {
    return new RegExp(verificationPattern).test(verificationCode)
  }

  async function resendMail() {
    setLoadSendMail(true)

    const data: ResendEmailData = { emailAddress: email }

    postResendEmailConfirm(data, {
      onSuccess: () => {
        setMailSent(true)

        trackOnboarding('Resend email verification code')
      },
      onError: error => {
        logError('postResendEmailConfirm', error)
      },
      onSettled: () => {
        setLoadSendMail(false)
      },
    })
  }

  return (
    <LoginPageLayout title={`${t('emailAction.confirm')}`}>
      <Description align={TextAlignType.CENTER}>
        {`${t('emailAction.sentTo')} `}
        <span className="font-bold">{email ? email : 'your email'}</span>{' '}
      </Description>
      <Description align={TextAlignType.CENTER}>{`${t('otp.description', {
        length: '6',
      })}`}</Description>

      {errorMessage && <Callout state="error">{errorMessage}</Callout>}

      <Input
        value={verificationCode}
        pattern="[0-9]+"
        type="text"
        inputMode="numeric"
        placeholder={`${t('otp.enter', { length: '6' })}`}
        onChange={e => onInputChange(e)}
        postLabel={<Icon name="TimerIcon" color="grey-10" />}
        testid="confirmEmail"
      ></Input>

      <Button
        loading={isLoading || loadingSendMail}
        full
        disabled={!testRegExp()}
        type="button"
        iconElement={<Icon name="CaretRightIcon" color="inherit" />}
        iconRight
        onClick={() => {
          if (testRegExp()) {
            sendVerificationCode()
          }
        }}
      >
        {`${t('continue')}`}
      </Button>

      {!loadingSendMail && (
        <Description align={TextAlignType.CENTER}>
          {!mailSent ? `${t('otp.notReceived')} ` : `${t('emailAction.sent')} `}
          <Button onClick={() => resendMail()} noStyling className="text-secondary-500">
            {`${t('emailAction.resend')}`}
          </Button>
        </Description>
      )}
    </LoginPageLayout>
  )
}
