import React, { useEffect } from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Box as GroupFields,
  Box as InlineFields,
} from '@node-space/storybook-components/dist/Box'
import DateOfBirth, {
  DateErrorType,
  DateType,
} from '@node-space/storybook-components/dist/DateOfBirth'
import { InputLabel } from '@node-space/storybook-components/dist/FormElements'
import Input from '@node-space/storybook-components/dist/Input'
import { Select } from '@node-space/storybook-components/dist/Select'
import { IdVerificationMessage } from 'components/IdVerification/IdVerificationMessage'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useFormSetup } from 'hooks/forms/useFormSetup'
import { useDateOfBirthInput } from 'hooks/useDateOfBirthInput'
import { AccountContactSchema } from '../accountContactSchema'
import { AccountContactSummary } from '../AccountContactSummary'
import { AccountContactWrapper } from '../AccountContactWrapper'
import { AccountContactFormKeys, AccountContactLabels } from '../types'
import { PersonDefaultOptions } from './constants'
import { PersonSelector } from './PersonSelector'
import {
  AccountContactActionsData,
  AccountContactData,
  AccountContactFormData,
  onSaveAccountContact,
} from './types'
import { usePersonSelector } from './usePersonSelector'
import { getInitialFormValues, getUpdatedAccountContactData } from './utils'

export interface AccountContactFormProps extends AccountContactActionsData {
  accountContact: AccountContactData
  labels: AccountContactLabels
  hasTopPadding?: boolean
  onSave: onSaveAccountContact
  onDelete: () => void
}

export const AccountContactForm = ({
  currentContactType,
  accountContact,
  personSelectorData,
  countryOptions,
  labels,
  idVerificationMessage,
  isUpdatingDetails,
  loading,
  onSave,
  onDelete,
}: AccountContactFormProps) => {
  const { t } = useTranslation()
  const { selectedData, personSelectorInfo, personSummaryInfo, onPersonChange } = usePersonSelector(
    personSelectorData,
    currentContactType
  )

  const initialAccountContactData =
    !isUpdatingDetails && !!selectedData ? selectedData : accountContact
  const showPersonSummary = !isUpdatingDetails && !!personSummaryInfo
  const showForm = isUpdatingDetails ? true : !personSummaryInfo?.contactDetail

  const {
    dateOfBirthLabels,
    hasDateOfBirthErrors,
    getDateOfBirthFields,
    setDateOfBirthInputValue,
    setDateOfBirthErrors,
    getDateOfBirthErrors,
    getDateOfBirthErrorText,
    formatDateOfBirthData,
    formatDateOfBirthInput,
  } = useDateOfBirthInput()

  const getInitialFormData = (): AccountContactFormData => {
    const initialFormValues = getInitialFormValues(initialAccountContactData)
    return {
      ...initialFormValues,
      dateOfBirth: formatDateOfBirthData(initialFormValues?.dateOfBirth),
    }
  }

  const formSchema = AccountContactSchema(t)
  const { form, formValues, errors, control, handleSubmit } = useFormSetup<AccountContactFormData>(
    formSchema,
    getInitialFormData()
  )
  const { setInputField, setSelectField } = useFormFields<AccountContactFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: Record<AccountContactFormKeys, any> = {
    firstName: setInputField({ key: 'firstName', useLabelAsPlaceholder: true, showLabel: false }),
    lastName: setInputField({ key: 'lastName', useLabelAsPlaceholder: true, showLabel: false }),
    dateOfBirth: {}, // handled as custom control
    address1: setInputField({ key: 'address1', useLabelAsPlaceholder: true, showLabel: false }),
    address2: setInputField({ key: 'address2', useLabelAsPlaceholder: true, showLabel: false }),
    city: setInputField({ key: 'city', useLabelAsPlaceholder: true, showLabel: false }),
    postalCode: setInputField({ key: 'postalCode', useLabelAsPlaceholder: true, showLabel: false }),
    countryCode: setSelectField({
      key: 'countryCode',
      options: countryOptions,
      placeholder: t('formFields.chooseCountry'),
      showLabel: false,
    }),
  }

  const contactFullName = `${formValues?.firstName} ${formValues?.lastName}`

  const handleContinueIdVerificationMessage = () => {
    idVerificationMessage?.onContinue(contactFullName)
  }

  const onSubmit: SubmitHandler<typeof formValues> = (formValues: AccountContactFormData) => {
    const mappedFormValues = {
      ...formValues,
      dateOfBirth: formatDateOfBirthInput(formValues.dateOfBirth),
    }
    const isNewPerson = !isUpdatingDetails && !initialAccountContactData?.person?.reference
    const isAddingExistingPerson =
      !isUpdatingDetails && !!initialAccountContactData?.person?.reference
    const updatedAccountContact = getUpdatedAccountContactData(
      initialAccountContactData,
      mappedFormValues,
      isAddingExistingPerson
    )
    onSave(updatedAccountContact, isNewPerson)
  }

  useEffect(() => {
    if (!isUpdatingDetails) {
      form?.reset(getInitialFormData())
    }
  }, [isUpdatingDetails, selectedData])

  return idVerificationMessage?.showMessage ? (
    <IdVerificationMessage
      contactType={currentContactType}
      {...(personSelectorInfo?.selectedReference !== PersonDefaultOptions.MYSELF && {
        contactFullName,
      })}
      linkUrl={idVerificationMessage?.linkUrl}
      onClose={handleContinueIdVerificationMessage}
      onContinue={handleContinueIdVerificationMessage}
    />
  ) : (
    <AccountContactWrapper
      addActionLabel={labels?.addButton}
      actionsLoading={loading}
      actionsError={{
        isFormValuesError: !!Object.keys(errors)?.length || hasDateOfBirthErrors,
      }}
      isEditing={isUpdatingDetails}
      onSubmit={handleSubmit(onSubmit)}
      onDelete={onDelete}
    >
      {!isUpdatingDetails && (
        <PersonSelector {...personSelectorInfo} onPersonChange={onPersonChange} />
      )}

      {showPersonSummary && (
        <AccountContactSummary
          contactFullName={personSummaryInfo?.contactFullName}
          contactDetail={personSummaryInfo?.contactDetail ?? t('accountContact.typesLabel.none')}
        />
      )}

      {showForm && (
        <>
          {!showPersonSummary && (
            <Box>
              <InputLabel>{t('verification.businessOwners.legalNameOfPerson')}</InputLabel>
              <GroupFields flex direction="col" gapY={8}>
                <Input {...formFields.firstName} />
                <Input {...formFields.lastName} />
              </GroupFields>
            </Box>
          )}
          <Box>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <DateOfBirth
                  date={getDateOfBirthFields(value)}
                  labels={{
                    ...dateOfBirthLabels,
                    errorMessage: getDateOfBirthErrorText(error),
                  }}
                  errors={getDateOfBirthErrors(error)}
                  onChange={(date: DateType) => onChange(setDateOfBirthInputValue(date))}
                  onValidate={(errors: DateErrorType) => setDateOfBirthErrors(errors)}
                />
              )}
            />
          </Box>
          <Box>
            <InputLabel>{t('verification.businessOwners.residentialAddress')}</InputLabel>
            <GroupFields flex direction="col" gapY={8}>
              <Input {...formFields.address1} />
              <Input {...formFields.address2} />
              <InlineFields flex gap={8}>
                <Box flexSize="fill" width="full">
                  <Input {...formFields.city} />
                </Box>
                <Box flexSize="fill" width="full">
                  <Input {...formFields.postalCode} />
                </Box>
              </InlineFields>
              <Select
                {...formFields.countryCode}
                loading={loading?.isFetchingCountryOptions}
                isSearchable
              />
            </GroupFields>
          </Box>
        </>
      )}
    </AccountContactWrapper>
  )
}
