import React from 'react'
import { Box, Button, Icon } from '@node-space/storybook-components'
import Loader from '@node-space/storybook-components/dist/Loader'
import Text from '@node-space/storybook-components/dist/Text'

export const NameTilesContanier = ({ children }) => {
  return (
    <Box flex direction="col" gapY={8}>
      {children}
    </Box>
  )
}

interface NameTileProps {
  label: string
  tileId: string | number
  isLoading?: boolean
  onEdit: () => void
  onDelete: () => void
}

export const NameTile = ({ label, tileId, isLoading, onEdit, onDelete }: NameTileProps) => {
  return (
    <Box
      flex
      justifyContent="between"
      alignItems="center"
      borderRadius={4}
      paddingX={16}
      paddingY={12}
      gapX={16}
      background="gray"
      border="gray"
    >
      <Box flexSize="fill">
        <Text size="sm">{label}</Text>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Button
            onClick={onDelete}
            noStyling
            iconElement={<Icon name="TrashIcon" color="grey-10" />}
            name={`delete-${label}-${tileId}`}
          />
          <Button
            onClick={onEdit}
            noStyling
            iconElement={<Icon name="EditIcon" color="grey-10" />}
            name={`edit-${label}-${tileId}`}
          />
        </>
      )}
    </Box>
  )
}
