import React from 'react'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import { useFinpromAppropriatenessForm } from 'pages/Finprom/hooks/useFinpromAppropriatenessForm'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

export const InvestingInCryptoOptions = {
  LOSE_NONE: 'loseNone',
  LOSE_SOME: 'loseSome',
  LOSE_ALL: 'loseAll',
} as const

type InvestingInCryptoOption =
  (typeof InvestingInCryptoOptions)[keyof typeof InvestingInCryptoOptions]

const investingInCryptoOptionList = Object.values(InvestingInCryptoOptions)
const investingInCryptoCorrectAnswer: InvestingInCryptoOption = InvestingInCryptoOptions.LOSE_ALL

export const StepInvestingInCrypto = () => {
  const {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  } = useFinpromAppropriatenessForm<InvestingInCryptoOption>()

  const investingInCryptoOptions = getQuestionOptions(investingInCryptoOptionList)

  const handleSubmit = () => {
    const isCorrectAnswer = questionAnswer === investingInCryptoCorrectAnswer
    onSubmitQuestion(
      isCorrectAnswer,
      FinpromTrackingLabels.APPROPRIATENESS_STEP_INVESTING_IN_CRYPTO
    )
  }

  return (
    <Container>
      <Radio
        value={questionAnswer}
        options={investingInCryptoOptions}
        onChange={handleSelectAnswer}
        error={isQuestionError}
        errorText={questionErrorText}
        hasParagraphLayout
      />
      {isErrorContinue && <FinpromSubmitError />}
      <FormSubmitButton
        disabled={isQuestionError}
        loading={isLoadingContinue}
        onClick={handleSubmit}
      />
    </Container>
  )
}
