import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQueryClient } from '@tanstack/react-query'
import {
  Box as AgreementLink,
  Box as AgreementLinksContainer,
  Box as CheckboxCalloutContainer,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { CSAT_PARAM } from 'components/CSAT/CSATLaunch'
import { VerificationRoutes, VerificationRouteStatus } from 'constants/verification'
import { useShowVerifiedBannerMutation } from 'hooks/mutations/useShowVerifiedBannerMutation'
import {
  usePostNewAccountAgreementsMutation,
  usePutVerificationMutation,
} from 'hooks/mutations/useVerificationReviewMutation'
import { useAccountAgreementsQuery } from 'hooks/queries/useAccountAgreementsQuery'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { useToastContext } from 'hooks/useToastContext'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { BaseErrorResponse } from 'types/errors'
import { AgreementAcceptancePayload, VerificationStep } from 'types/verification'
import { getAgreement } from 'utils/agreements'
import { trackOnboarding } from 'utils/tracker'
import { arrayListToString } from 'utils/utils'
import { ConfirmCompleteModal } from '../_components/ConfirmCompleteModal'
import { FormBody } from '../_components/FormBody'
import { FormGetError } from '../_components/FormGetError'
import { FormSubmitButton } from '../_components/FormSubmitButton'
import { FormSubmitError } from '../_components/FormSubmitError'

type LinkType = {
  url: string
  label: string
}

interface ReviewProps {
  steps: VerificationStep[]
  isLoadingStepsStatus: boolean
  onSubmit: () => void
}

const AgreementsStepKey = VerificationRoutes.REVIEW_AND_FINISH

export const Review = ({ steps, isLoadingStepsStatus, onSubmit }: ReviewProps) => {
  const { t } = useTranslation()
  const [_, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const addToast = useToastContext()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()

  const agreementsStatus = steps?.find(step => step?.key === AgreementsStepKey)?.status

  const [isAgreementsCompleted, setIsAgreementsCompleted] = useState(
    agreementsStatus === VerificationRouteStatus.COMPLETED
  )
  const [checkboxConfirmAgreements, setCheckboxConfirmAgreements] = useState(isAgreementsCompleted)
  const [checkboxConfirmInformation, setCheckboxConfirmInformation] = useState(false)
  const [showConfirmCompleteModal, setShowConfirmCompleteModal] = useState(false)

  const {
    data: agreementsData,
    isError: isAgreementsGetError,
    isFetching: isFetchingAgreementsData,
  } = useAccountAgreementsQuery()

  const { mutate: postAgreementAcceptance, isPending: isLoadingPostAgreementAcceptance } =
    usePostNewAccountAgreementsMutation()

  const { mutate: putVerificationRequest, isPending: isLoadingPutVerificationRequest } =
    usePutVerificationMutation()

  const { mutate: putShowVerifiedBanner } = useShowVerifiedBannerMutation()

  const pendingSteps = steps?.filter(
    ({ key, status }) => key !== AgreementsStepKey && status === VerificationRouteStatus.PENDING
  )
  const verificationComplete = !pendingSteps?.length

  const agreementIds = []
  const agreementsLinks: LinkType[] = [
    {
      url: process.env.MASTER_SERVICES_AGREEMENT,
      label: t('masterServicesAgreement'),
    },
    {
      url: process.env.PRIVACY_POLICY_URL,
      label: t('privacyPolicy'),
    },
  ]

  if (!!agreementsData) {
    const emiData = getAgreement('EMI', agreementsData)
    if (!!emiData) {
      // insert EMI Agreement as second listed agreement
      const emiLink: LinkType = {
        url: emiData?.url,
        label: t('emiTerms', { country: emiData?.description }),
      }
      agreementsLinks.splice(1, 0, emiLink)
      // insert EMI Agreement id in agreementIds payload
      agreementIds.push(emiData?.id)
    }
  }

  const readAgreementsLabel = arrayListToString(
    [...agreementsLinks]?.map(agreement => `BVNK ${agreement?.label}`),
    t('verification.review.confirmReadLabel')
  )

  const isLoadingData = useMemo(
    () => isFetchingAgreementsData || isLoadingStepsStatus,
    [isFetchingAgreementsData, isLoadingStepsStatus]
  )

  const isLoadingSubmit = useMemo(
    () => isLoadingPostAgreementAcceptance || isLoadingPutVerificationRequest,
    [isLoadingPostAgreementAcceptance, isLoadingPutVerificationRequest]
  )

  const isGetError = useMemo(() => isAgreementsGetError, [isAgreementsGetError])

  const isSubmitDisabled = useMemo(
    () => !verificationComplete || !checkboxConfirmAgreements || !checkboxConfirmInformation,
    [verificationComplete, checkboxConfirmAgreements, checkboxConfirmInformation]
  )

  const onClickDone = async () => {
    addToast({ title: t('verification.kybSubmitted'), state: 'success' })

    await queryClient.invalidateQueries({
      queryKey: [
        reactQueryKeys.businessVerificationStatus(),
        reactQueryKeys.verificationRequestStatus(),
      ],
    })

    onSubmit()

    setSearchParams({ [CSAT_PARAM]: '1' })
  }

  const postLegalConfirmations = () => {
    resetRequestError()
    const agreementsPayload: AgreementAcceptancePayload = {
      masterServicesAgreement: checkboxConfirmAgreements,
      platformAgreement: checkboxConfirmAgreements,
      authorityToAct: checkboxConfirmInformation,
      agreementIds: !!agreementIds.length ? agreementIds : null,
    }
    postAgreementAcceptance(agreementsPayload, {
      onSuccess: () => {
        trackOnboarding('Legal confirmations submitted')
        setIsAgreementsCompleted(true)
        submitVerification()
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('postReviewAgreementAcceptance', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  const submitVerification = () => {
    resetRequestError()
    putVerificationRequest(null, {
      onSuccess: () => {
        trackOnboarding('Submit for review')
        putShowVerifiedBanner(true)
        setShowConfirmCompleteModal(true)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('putReviewVerificationRequest', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  const handleSubmit = () => {
    isAgreementsCompleted ? submitVerification() : postLegalConfirmations()
  }

  return (
    <>
      <VerificationHeader
        header={t('verification.routes.businessAgreements')}
        subHeader={
          verificationComplete
            ? t('verification.review.introduction')
            : t('verification.review.introductionDetailsIncomplete')
        }
      />

      {isLoadingData ? (
        <SkeletonForm name="Review" />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <FormBody>
          {requestError?.show && (
            <FormSubmitError
              errorCode={requestError?.errorCode}
              sentryTraceId={requestError?.sentryTraceId}
            />
          )}

          {verificationComplete && (
            <>
              <AgreementLinksContainer className="-mt-4">
                {agreementsLinks.map(agreement => (
                  <AgreementLink paddingT={8} key={agreement?.label}>
                    <Button onClick={() => window.open(agreement?.url)} noStyling>
                      <Text size="sm" color="secondary-500">
                        {agreement?.label}
                      </Text>
                    </Button>
                  </AgreementLink>
                ))}
              </AgreementLinksContainer>

              <CheckboxCalloutContainer flex direction="col" gapY={16}>
                <CheckBox
                  testid="masterServicesAgreementCheckbox"
                  label={readAgreementsLabel}
                  value={checkboxConfirmAgreements}
                  onChange={() => setCheckboxConfirmAgreements(current => !current)}
                />
                <CheckBox
                  testid="informationAgreementCheckbox"
                  label={t('verification.review.confirmProvidedLabel')}
                  value={checkboxConfirmInformation}
                  onChange={() => setCheckboxConfirmInformation(current => !current)}
                />
                <Callout
                  state="info"
                  message={t('verification.review.calloutNote')}
                  showCloseButton={false}
                />
              </CheckboxCalloutContainer>
            </>
          )}

          <FormSubmitButton
            buttonText={t('verification.submitForReview')}
            loading={isLoadingSubmit}
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          />
        </FormBody>
      )}

      {showConfirmCompleteModal && <ConfirmCompleteModal onClick={onClickDone} />}
    </>
  )
}
