import React from 'react'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import { useFinpromAppropriatenessForm } from 'pages/Finprom/hooks/useFinpromAppropriatenessForm'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

export const CryptoAssetBvnkOfferOptions = {
  ANTI_MONEY_LAUNDERING: 'antiMoneyLaundering',
  AUTOMATICALLY_REGULATED: 'automaticallyRegulated',
  PROFITS_ARE_GUARANTEED: 'profitsAreGuaranteed',
} as const

type CryptoAssetBvnkOfferOption =
  (typeof CryptoAssetBvnkOfferOptions)[keyof typeof CryptoAssetBvnkOfferOptions]

const cryptoAssetBvnkOfferOptionList = Object.values(CryptoAssetBvnkOfferOptions)
const cryptoAssetBvnkOffercorrectAnswer: CryptoAssetBvnkOfferOption =
  CryptoAssetBvnkOfferOptions.ANTI_MONEY_LAUNDERING

export const StepCryptoAssetBvnkOffer = () => {
  const {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  } = useFinpromAppropriatenessForm<CryptoAssetBvnkOfferOption>()

  const cryptoAssetBvnkOfferOptions = getQuestionOptions(cryptoAssetBvnkOfferOptionList)

  const handleSubmit = () => {
    const isCorrectAnswer = questionAnswer === cryptoAssetBvnkOffercorrectAnswer
    onSubmitQuestion(
      isCorrectAnswer,
      FinpromTrackingLabels.APPROPRIATENESS_STEP_CRYPTO_ASSET_BVNK_OFFER
    )
  }

  return (
    <Container>
      <Radio
        value={questionAnswer}
        options={cryptoAssetBvnkOfferOptions}
        onChange={handleSelectAnswer}
        error={isQuestionError}
        errorText={questionErrorText}
        hasParagraphLayout
      />
      {isErrorContinue && <FinpromSubmitError />}
      <FormSubmitButton
        disabled={isQuestionError}
        loading={isLoadingContinue}
        onClick={handleSubmit}
      />
    </Container>
  )
}
