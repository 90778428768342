import React, { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Select } from '@node-space/storybook-components/dist/Select'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { HeadsUp } from 'components/verification/HeadsUp'
import { VerificationTrackingLabels } from 'constants/verification'
import { useAccountContext } from 'hooks/context/useAccountContext'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useGetStartedMutation } from 'hooks/mutations/useGetStartedMutation'
import { useBusinessEntitiesQuery } from 'hooks/queries/useBusinessEntitiesQuery'
import { useGetStartedQuery } from 'hooks/queries/useGetStartedQuery'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { BaseErrorResponse } from 'types/errors'
import { GetStartedPayload } from 'types/verification'
import { trackOnboarding } from 'utils/tracker'
import { FormBody } from '../_components/FormBody'
import { FormGetError } from '../_components/FormGetError'
import { FormSubmitButton } from '../_components/FormSubmitButton'
import { FormSubmitError } from '../_components/FormSubmitError'
import { VerificationHeader } from '../_components/VerificationHeader'
import {
  GetStartedFormData,
  GetStartedFormKeys,
  getStartedSchema,
} from '../schemas/getStartedSchema'

const GetStarted = () => {
  const { t } = useTranslation()

  const { currentAccount } = useAccountContext()
  const { hasCompletedSteps, setUnsavedChanges, submitStep } = useVerificationContext()
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()

  const {
    data: getStartedDetails,
    isFetching: isFetchingGetStartedDetails,
    isError: isGetStartedGetError,
  } = useGetStartedQuery()

  const {
    data: businessEntities,
    isFetching: isFetchingBusinessEntities,
    isError: isBusinessEntitiesGetError,
  } = useBusinessEntitiesQuery()

  const { mutate: putGetStarted, isPending: isLoadingSubmitRequest } = useGetStartedMutation()

  const businessEntityOptions = businessEntities?.map(data => {
    return { value: `${data?.id}`, label: data?.name }
  })

  const isGetError = useMemo(
    () => isBusinessEntitiesGetError || isGetStartedGetError,
    [isBusinessEntitiesGetError, isGetStartedGetError]
  )

  const isLoading = useMemo(
    () => isFetchingBusinessEntities || isFetchingGetStartedDetails,
    [isFetchingBusinessEntities, isFetchingGetStartedDetails]
  )

  useEffect(() => {
    if (!isLoading && !isGetError) {
      trackOnboarding('Page Load - Get started', 'load')
    }
  }, [isLoading, isGetError])

  const formSchema = getStartedSchema(t)

  const form = useForm<GetStartedFormData>({
    defaultValues: {
      businessType: '',
    },
    resolver: yupResolver(formSchema),
  })

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = form

  const formValues = useWatch({ control })

  const { setSelectField } = useFormFields<GetStartedFormKeys>(formSchema, form, formValues)

  const formFields: { [key in GetStartedFormKeys] } = {
    businessType: setSelectField({
      key: 'businessType',
      placeholder: t('formFields.chooseOption'),
      options: businessEntityOptions,
    }),
  }

  useEffect(() => {
    const entityId = getStartedDetails?.entityType?.id
    if (!!entityId) {
      setValue('businessType', `${entityId}`)
      reset({}, { keepValues: true })
    }
  }, [getStartedDetails])

  useEffect(() => {
    setUnsavedChanges(isDirty)
  }, [isDirty])

  const onSubmit: SubmitHandler<typeof formValues> = () => {
    if (!isDirty) {
      submitStep(VerificationTrackingLabels.GET_STARTED)
      return
    }
    resetRequestError()
    const data: GetStartedPayload = {
      id: currentAccount?.id,
      entityTypeId: Number(formValues?.businessType),
    }

    putGetStarted(data, {
      onSuccess: () => {
        submitStep(VerificationTrackingLabels.GET_STARTED)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('putGetStarted', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  return (
    <>
      <VerificationHeader header={t('verification.routes.getStarted')} />
      <HeadsUp />

      {isLoading ? (
        <SkeletonForm name="Get started" rowCount={1} />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormBody hasDivider>
            {requestError?.show && (
              <FormSubmitError
                errorCode={requestError?.errorCode}
                sentryTraceId={requestError?.sentryTraceId}
              />
            )}
            <Select {...formFields.businessType} />

            <FormSubmitButton
              buttonText={
                hasCompletedSteps
                  ? t('verification.continueVerification')
                  : t('verification.letsgo')
              }
              loading={isSubmitting || isLoadingSubmitRequest}
              disabled={!!Object.keys(errors)?.length}
            />
          </FormBody>
        </form>
      )}
    </>
  )
}

export default GetStarted
