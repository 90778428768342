import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { Profile } from 'types/authentication/Profile'

export const useSentryIdentification = (profile: Profile) => {
  const { id, emailAddress: email } = profile || {}

  useEffect(() => {
    if (!Sentry) return

    if (id && email) {
      const user = { id, email }
      Sentry.setUser(user)
    }
  }, [id, email, Sentry])
}
