import { init, track, identify, Types, Identify } from '@amplitude/analytics-browser'

const amplitudeToken = process.env.AMPLITUDE_TOKEN
const isEnabled = !!amplitudeToken
const isDebugEnabled = false

isEnabled &&
  init(amplitudeToken, null, {
    ...(!!isDebugEnabled && { logLevel: Types.LogLevel.Debug }),
  })

const actions = {
  track: (eventInput: string | Types.BaseEvent, eventProperties = {}, eventOptions = {}) =>
    isEnabled && track(eventInput, eventProperties, eventOptions),
  identify: (userInfo = {}) => {
    if (isEnabled) {
      const identifyObj = new Identify()
      identify(identifyObj, userInfo)
    }
  },
}

export const Amplitude = actions
