import React from 'react'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromStepKeys } from '../constants'
import { Appropriateness } from './Appropriateness/Appropriateness'
import { RiskWarning } from './RiskWarning/RiskWarning'
import { SelfCategorisation } from './SelfCategorisation/SelfCategorisation'

export const FinpromStep = () => {
  const { activeStep } = useFinpromContext()

  switch (activeStep) {
    case FinpromStepKeys.INITIAL:
    case FinpromStepKeys.RISK_WARNING:
      return <RiskWarning />
    case FinpromStepKeys.SELF_CATEGORISATION:
      return <SelfCategorisation />
    case FinpromStepKeys.APPROPRIATENESS_TEST:
      return <Appropriateness />
    default:
      return <></>
  }
}
