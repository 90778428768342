import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box as PersonList } from '@node-space/storybook-components/dist/Box'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Description } from 'components/Text'
import { IndividualVerificationStatus, PersonDetails } from 'types/identityVerification'
import {
  IndividualVerificationDetail,
  PendingIdentificationPerson,
} from './IndividualVerificationDetail'
import { NotificationCard } from './NotificationCard/NotificationCard'

export interface IndividualVerificationNotificationProps {
  persons: PersonDetails[]
}

export const IndividualVerificationNotification = ({
  persons,
}: IndividualVerificationNotificationProps) => {
  const { t } = useTranslation()

  const getPendingIdentificationPersons = (): PendingIdentificationPerson[] => {
    const pendingPersons = []
    persons?.forEach(person => {
      const { reference, firstName, lastName, identifications } = person || {}
      if (!identifications || !identifications?.length) return
      const completedIndex = identifications?.findIndex(
        identification => identification?.status === IndividualVerificationStatus.COMPLETE
      )
      if (completedIndex !== -1) return
      const latestPendingIdentification = identifications?.reduce((latest, current) =>
        new Date(latest?.dateCreated) > new Date(current?.dateCreated) ? latest : current
      )
      const pendingPerson: PendingIdentificationPerson = {
        reference,
        personName: `${firstName} ${lastName}`,
        idVerificationUrl: latestPendingIdentification?.url,
      }
      pendingPersons?.push(pendingPerson)
    })
    return pendingPersons
  }

  const pendingIdentificationPersons = getPendingIdentificationPersons()

  return (
    !!pendingIdentificationPersons?.length && (
      <NotificationCard
        title={t('verification.individual.pendingHeading')}
        iconBackground={'warning'}
        iconComponent={<Icon name="WarningCircleIcon" color="status-warning-500" size="lg" />}
      >
        <Description>{t('verification.individual.pendingSubHeading')}</Description>
        <PersonList flex direction="col" gapY={24} paddingT={20}>
          {pendingIdentificationPersons?.map(person => (
            <IndividualVerificationDetail key={person?.reference} {...person} />
          ))}
        </PersonList>
      </NotificationCard>
    )
  )
}
