import { useEffect, useState } from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { useAccountContactsQuery } from 'hooks/queries/useAccountContactsQuery'
import { AccountContactTypesList } from './constants'
import { AccountContactData, AccountContactsContext } from './types'

export const useAccountContactsData = (): AccountContactsContext => {
  const { enableAccountContactsCrud } = useFeatureFlags()

  const [accountContactsList, setAccountContactsList] = useState<AccountContactData[]>([])

  const {
    data: accountContactsData,
    isFetching,
    isError,
    refetch,
  } = useAccountContactsQuery(AccountContactTypesList, !!enableAccountContactsCrud)

  useEffect(() => {
    if (!isFetching) {
      setAccountContactsList(accountContactsData)
    }
  }, [isFetching])

  return {
    isEnabled: !!enableAccountContactsCrud,
    list: accountContactsList,
    isFetching,
    isFetchingError: isError,
    setList: setAccountContactsList,
    refetch,
  }
}
