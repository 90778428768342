import React from 'react'
import { FinpromResult } from 'pages/Finprom/constants'
import { FinpromResultType } from 'pages/Finprom/types'
import { PassedModal } from './result/PassedModal'
import { UnsuccessfulModal } from './result/UnsuccessfulModal'

export interface SelfCategorisationResultModalProps {
  result: FinpromResultType
}

export const SelfCategorisationResultModal = ({ result }: SelfCategorisationResultModalProps) => {
  return result === FinpromResult.PASSED ? <PassedModal /> : <UnsuccessfulModal />
}
