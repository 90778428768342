import React, { ReactNode } from 'react'
import Box, { BoxProps } from '@node-space/storybook-components/dist/Box'

export interface ContainerProps extends BoxProps {
  children: ReactNode
}

export const Container = ({ children, ...boxProps }: ContainerProps) => {
  return (
    <Box flex direction="col" gap={24} {...boxProps}>
      {children}
    </Box>
  )
}
