import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { UploadFile } from '@node-space/storybook-components/dist/UploadFile'
import { dropZoneAcceptedDocuments } from 'utils/documents'
import { MappedDocument } from '../helpers/types'
import { useDocumentInput } from '../helpers/useDocumentInput'

export const DocumentInput = ({
  id,
  title,
  tooltip,
  warning,
  file,
  onChooseFile,
  onRemoveFile,
}: MappedDocument) => {
  const { t } = useTranslation()
  const { uploadErrorMessage, onDrop } = useDocumentInput(onChooseFile, onRemoveFile)

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: dropZoneAcceptedDocuments,
  })

  return (
    <UploadFile
      title={title}
      warning={warning}
      tooltipHtml={tooltip}
      defaultMessage={t('uploadDocument.uploadRequirements')}
      file={file}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      isDragActive={isDragActive}
      isActionRequired
      name={`${id?.code}${id?.contactReference}`}
      removeFile={{
        text: {
          remove: t('uploadDocument.remove'),
          or: t('uploadDocument.or'),
          replace: t('uploadDocument.chooseAnotherFile'),
        },
        onRemoveFile,
      }}
      textList={{
        uploadFile: t('uploadDocument.uploadFile'),
        dropFile: t('uploadDocument.dropFile'),
        dragAndDrop: t('uploadDocument.dragAndDrop'),
        replaceFile: t('uploadDocument.replaceFile'),
        uploadingFile: t('uploadDocument.uploadingFile'),
        fileRequired: t('uploadDocument.fileRequired'),
      }}
      errorMessage={uploadErrorMessage}
    />
  )
}
