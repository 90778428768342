import clone from 'lodash/clone'
import each from 'lodash/each'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import random from 'lodash/random'
import { invalidWorkEmailDomains } from 'constants/Forms'
import { loginUrl, RedirectUri } from 'constants/General'
import { Country, ExcludedCountry } from 'types/types'
import { BusinessDocumentsPayload } from 'types/verification'
import { isLocal, isStaging } from './environment'

export const HALF_SECOND = 500 // 500 ms = 0.5 seconds
export const ONE_DAY = 1000 * 60 * 60 * 24 // 86,400,000 ms = 24 hours
export const TEN_MINUTES = 1000 * 60 * 10 // 600,000 ms = 10 mins

export const FILE_UPLOAD_LIMIT = 10000000
export const FILE_UPLOAD_LIMIT_SMALL = 2000000

export const IS_LOGGING_ENABLED = isLocal || isStaging

export function formatDob(day, month, year) {
  // accepts year XXXX
  // month X or XX
  // day X or XX
  // and formats to YYYY-MM-DD
  // if is able to format, returns result, else returns 'invalid'
  let result = ''
  if (year.length !== 4) {
    return 'invalid'
  }
  const yearString = year + '-'
  let monthString = ''
  switch (month.length) {
    case 1:
      monthString = '0' + month + '-'
      break
    case 2:
      monthString = month + '-'
      break
    default:
      return 'invalid'
  }
  switch (day.length) {
    case 1:
      result = yearString + monthString + '0' + day
      break
    case 2:
      result = yearString + monthString + day
      break
    default:
      return 'invalid'
  }
  return result
}

export const formatString = (input: string, ...args: (string | number)[]) => {
  const replaceArgs = (match, number) => {
    return typeof args[number] != 'undefined' ? args[number] : match
  }
  return input.replace(/{(\d+)}/g, replaceArgs)
}

export const cleanBase64String = (string: string) => {
  const regex = new RegExp(/^data:.+;base64,/)
  const result = string.replace(regex, '')
  return result
}

/**
 * Takes an array of strings and joins it into a comma separated sentance.
 * @returns string
 */
export const arrayListToString = (array: string[], start?: string, end?: string): string => {
  let string = start || ''
  const stop = end || '.'
  array.forEach((item, index) => {
    const isFirst = index === 0
    const isLast = index === array.length - 1
    const prefix = isFirst ? ' ' : isLast ? ' and ' : ', '
    const suffix = isLast ? stop : ''
    const formatted = `${prefix}${item}${suffix}`
    string += formatted
  })
  return string
}

/**
 * Promise timer to pause and help async functions delay
 * @returns Promise<void>
 */
export const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms))

/**
 * Returns url-encoded string from an object
 *
 * @param   {object} obj  Object
 * @return  {string}      String like 'RegisteredName=Adidas&FirstName=Kanye&LastName=West',
 */

export const objectToEncodedString = (obj: Record<string, string | number | boolean>): string => {
  if (!isObject(obj)) {
    return ''
  }

  if (isEmpty(obj)) {
    return ''
  }

  const queryParam = Object.keys(obj)
    .map(key => {
      return `${key}=${encodeURIComponent(obj[key])}`
    })
    .join('&')

  return queryParam
}

/**
 * Promise delay binder
 * @returns Promise<void>
 */
export const delay = (t: number, data: unknown) => {
  return new Promise(resolve => {
    setTimeout(resolve.bind(null, data), t)
  })
}

/**
 * Fake promise to sometimes pass or sometimes fail
 * @returns Promise<unknown>
 */
export const mockAsyncSuccessFail = (n: unknown) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      /* eslint-disable-next-line */
      IS_LOGGING_ENABLED && console.log('[mockAsyncSuccessFail INPUT] : ', n)

      const randomNum = random(1, 2)
      if (randomNum === 1) {
        resolve(n)
      } else {
        reject(n)
      }
    }, Math.random() * 2000)
  })
}

/**
 * Sequentially runs promises from array list, and on failure continues and provides log of success and error list
 * @returns Promise<SuccessList[],ErrorList[]>
 */
export const sequence = async (
  queue: unknown[],
  asyncPromise: (val: unknown) => Promise<unknown>,
  successCallback?: (val?: unknown) => void,
  errorCallback?: (val?: unknown) => void
) => {
  const successList = []
  const errorList = []

  for (const item of queue) {
    try {
      const res = await asyncPromise(item).then(delay.bind(null, 100))
      successList.push(res)

      if (isFunction(successCallback) && res) {
        successCallback(res)
      }
    } catch (error) {
      errorList.push(item)

      if (isFunction(errorCallback)) {
        errorCallback(item)
      }
    }
  }
  return [successList, errorList]
}

/**
 * Takes in an array and an async function, and executes them in squence of the array
 * @returns Promise<void>
 */
export const asyncQueue = async (
  queue: unknown[],
  asyncPromise: (val: unknown) => Promise<unknown>,
  successCallback?: (val?: unknown) => void,
  errorCallback?: (val?: unknown) => void
) => {
  const [successList, errorList] = await sequence(
    queue,
    asyncPromise,
    successCallback,
    errorCallback
  )

  if (!errorList?.length) {
    return Promise.resolve(successList)
  }

  if (!!errorList?.length) {
    const [successListRetry, errorListRetry] = await sequence(
      errorList,
      asyncPromise,
      successCallback,
      errorCallback
    )

    if (!errorListRetry.length) {
      const completeList = [successList, successListRetry]
      /* eslint-disable-next-line */
      IS_LOGGING_ENABLED && console.log('SEQ: Success all uploaded', completeList)
      return Promise.resolve(completeList)
    } else {
      const completeErrorList = [errorListRetry]
      IS_LOGGING_ENABLED &&
        /* eslint-disable-next-line */
        console.log(`SEQ: ${completeErrorList?.length} failed :(`, completeErrorList)
      return Promise.reject(completeErrorList)
    }
  }
}

/**
 * Takes in Document payload object, strips them individual document payloads
 * @returns Promise<BusinessDocumentsPayload[]>
 */
export const documentPayloadToArray = (data: BusinessDocumentsPayload) => {
  const template: BusinessDocumentsPayload = {
    accountDocuments: [],
    representativeDocuments: [],
    corporateOwnersDocuments: {},
    directorsDocuments: {},
    financeDocuments: [],
    individualOwnersDocuments: {},
  }
  const documents: BusinessDocumentsPayload[] = []

  Object.keys(data)?.forEach(keyName => {
    const item = data[keyName]

    if (!isEmpty(item)) {
      if (isArray(item)) {
        each(item, el => {
          const copy = clone(template)
          copy[keyName] = [el]
          documents.push(copy)
        })
      }

      if (isObject(item)) {
        each(item, (val, key) => {
          if (isArray(val) && !isEmpty(val)) {
            each(val, elem => {
              const copy = clone(template)
              copy[keyName] = { [key]: [elem] }
              documents.push(copy)
            })
          }
        })
      }
    }
  })

  return documents
}

/**
 * Filters out countries based on exclusion list.
 * @param countryOptions Array of country options to filter.
 * @param enableCountriesExclusionList Optional array of countries to exclude.
 * @returns Filtered array of countries.
 */
export const filterCountries = (
  countryOptions: Country[],
  enableCountriesExclusionList?: ExcludedCountry[]
) => {
  return countryOptions.filter(
    country =>
      !enableCountriesExclusionList?.some(
        excluded => excluded?.name === country?.label || excluded.code === country?.value
      )
  )
}

/**
 * Transforms an array of industry objects into a new structure with modified keys suitable for use in UI component.
 * @param {Object[]} industries - The array of industry objects to be transformed.
 * @param {boolean} enableSubcategory - A flag indicating whether to use references or IDs for child options.
 * @returns {Object[]} An array of objects with updated keys.
 */
export const transformIndustries = (industries, enableSubcategory) => {
  return industries?.map(industry => {
    const transformedIndustry = {
      label: `${industry?.name}`,
      options: [],
    }

    if (industry?.children) {
      transformedIndustry.options = industry?.children?.map(industrySubCategory => ({
        label: industrySubCategory?.name,
        value: enableSubcategory
          ? `${industrySubCategory?.reference}`
          : `${industrySubCategory?.id}`,
      }))
    }

    return transformedIndustry
  })
}

export const scrollToTop = () => window.scrollTo(0, 0)

export const convertArrayToCommaString = <ArrayType>(array: ArrayType[]): string => {
  if (!array?.length) return ''
  return array?.join()
}

export const convertCommaStringToArray = <ArrayType>(string: string): ArrayType[] => {
  if (!string) return []
  return string?.split(',') as ArrayType[]
}

export const moveItemInArray = <ArrayType>(
  array: ArrayType[],
  fromIndex: number,
  toIndex: number
) => {
  if (!array?.length) return
  if (!fromIndex || fromIndex < 0 || !toIndex || toIndex < 0) return array
  const updatedArray = [...array]
  const itemToBeMoved = updatedArray?.splice(fromIndex, 1)?.[0]
  updatedArray?.splice(toIndex, 0, itemToBeMoved)
  return updatedArray
}

/**
 * Either adds value to array if not present or removes value from array if present
 * @returns {ArrayType[]} An updated array of values
 */
export const toggleArrayValue = <ArrayType>(array: ArrayType[], value: ArrayType): ArrayType[] => {
  if (!array) return []
  if (!value) return array
  const arraySet = new Set(array)
  arraySet?.has(value) ? arraySet?.delete(value) : arraySet.add(value)
  return Array.from(arraySet)
}

/**
 * Checks if the email is valid by ensuring it does not include any excluded domains
 * @param {string} email - The email address to check
 * @returns {boolean} - True if the email is valid, false otherwise
 */
export const isValidWorkEmailDomain = email =>
  Object.values(invalidWorkEmailDomains)?.every(domain => !email?.includes(`@${domain}.`))

/**
 * Redirects the browser to the specified URL.
 * @param {string} url - The URL to redirect to.
 */
const redirectTo = (url: string) => {
  if (url) {
    window.location.href = url
  } else {
    console.error('Invalid URL for redirection.')
  }
}

/**
 * Redirects the browser to the login page of the merchant portal.
 */
export const goToLogin = () => {
  redirectTo(loginUrl)
}

/**
 * Redirects the browser to the dashboard page.
 */
export const redirectToDashboard = () => {
  redirectTo(RedirectUri.DASHBOARD)
}
