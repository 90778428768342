import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { DynamicFieldsSections } from 'constants/dynamicFields'
import { getDynamicFields } from 'services/DynamicFieldsService'

export const useDynamicDocumentsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.dynamicFields(),
    queryFn: () => getDynamicFields([DynamicFieldsSections.DOCUMENTS]),
    select: data => {
      return data?.documents ?? []
    },
  })
}
