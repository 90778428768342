import { useMutation } from '@tanstack/react-query'
import { deleteAccountContact, saveAccountContact } from 'services/AccountContactsService'

export const useSaveAccountContactMutation = () => {
  return useMutation({ mutationFn: saveAccountContact })
}

export const useDeleteAccountContactMutation = () => {
  return useMutation({ mutationFn: deleteAccountContact })
}
