import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import {
  Box as Body,
  Box,
  Button,
  Callout,
  Icon,
  Box as IconCircle,
  Box as IconCol,
  Text,
} from '@node-space/storybook-components'
import { logSentryError } from '@node-space/utils'
import { Description } from 'components/Text'
import { useBusinessDocumentsMutation } from 'hooks/mutations/useBusinessDocumentsMutation'
import { useRequestedDocumentsQuery } from 'hooks/queries/useRequestedDocumentQuery'
import { useToastContext } from 'hooks/useToastContext'
import UploadFile from 'pages/Verification/_components/UploadFile'
import { BusinessDocument, BusinessDocumentsPayload, DocumentUpload } from 'types/verification'
import { cleanBase64String } from 'utils/utils'

export const DocumentRequestNotification = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const addToast = useToastContext()

  const {
    mutate: postBusinessDocuments,
    isError: isErrorPostBusinessDocuments,
    isPending: isLoadingPostBusinessDocuments,
  } = useBusinessDocumentsMutation()

  const [uploadedDocuments, setUploadedDocuments] = useState<DocumentUpload[]>([])

  const { data: requestedDocuments } = useRequestedDocumentsQuery()

  const hasUploadedDocuments = useMemo(
    () => !!uploadedDocuments.filter(doc => !isEmpty(doc))?.length,
    [uploadedDocuments]
  )

  const uploadedDocumentsCount = useMemo(
    () => uploadedDocuments.filter(doc => !isEmpty(doc))?.length,
    [uploadedDocuments]
  )

  useEffect(() => {
    if (requestedDocuments) {
      setUploadedDocuments(new Array(requestedDocuments?.length).fill({}))
    }
  }, [requestedDocuments])

  const addDocument = (newDocument: DocumentUpload, newDocumentIndex: number) => {
    const tempDocuments = uploadedDocuments?.map((document, index) => {
      return index === newDocumentIndex ? newDocument : document
    })
    setUploadedDocuments(tempDocuments)
  }

  const uploadDocuments = async () => {
    const accountDocuments: BusinessDocument[] = uploadedDocuments
      ?.map((document, index) => {
        return isEmpty(document)
          ? null
          : {
              code: 'additionalDocument',
              documentRequestId: requestedDocuments?.[index]?.id,
              file: {
                name: requestedDocuments?.[index]?.name,
                base64: cleanBase64String(document?.buffer as string),
              },
            }
      })
      ?.filter(doc => !isEmpty(doc))

    const payloadData: BusinessDocumentsPayload = {
      accountDocuments: accountDocuments,
    }

    postBusinessDocuments(payloadData, {
      onSuccess: () => {
        if (uploadedDocumentsCount === 1) {
          addToast({ title: t('verification.requestedDocumentSubmitted'), state: 'success' })
        }

        if (uploadedDocumentsCount > 1) {
          addToast({ title: t('verification.requestedDocumentsSubmitted'), state: 'success' })
        }

        setUploadedDocuments([])

        queryClient.invalidateQueries({ queryKey: reactQueryKeys.requestedDocuments() })
      },
      onError: error => {
        logSentryError('postRequestedDocuments', error)
      },
    })
  }

  return (
    <Box
      background="white"
      border="gray"
      borderRadius={8}
      paddingY={24}
      gapX={24}
      paddingX={32}
      className="relative xs:flex"
    >
      <IconCol flex justifyContent="center" className="xs:justify-start">
        <IconCircle flex borderRadius="full" size={48} centerChildren background="warning">
          <Icon name="WarningCircleIcon" color="status-warning-500" size="lg" />
        </IconCircle>
      </IconCol>
      <Body className="max-w-[476px]" flex direction="col" paddingT={12} gapY={12} width="full">
        <Text tag="h2" size="lg" weight="medium">
          {t('verification.actionRequiredAdditionalDocuments')}
        </Text>
        <Description>{`${t('verification.additionalDocumentsDescription')}`}</Description>
        {isErrorPostBusinessDocuments && (
          <Callout state="error" message={t('oopsSomethingWentWrong')} showCloseButton={false} />
        )}
        <Box flex direction="col" gapY={16}>
          {requestedDocuments?.map((document, index) => {
            return (
              <UploadFile
                key={index}
                title={document?.name}
                {...(document?.url && { url: document?.url })}
                isActionRequired
                name="documentUpload"
                file={uploadedDocuments[index]?.fileData?.name && uploadedDocuments[index]}
                setFile={newDocument => addDocument(newDocument, index)}
              />
            )
          })}
          <Button
            className="max-w-[158px]"
            onClick={uploadDocuments}
            disabled={!hasUploadedDocuments}
            loading={isLoadingPostBusinessDocuments}
          >
            {t('verification.submitForReview')}
          </Button>
        </Box>
      </Body>
    </Box>
  )
}
