import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { useFeatureFlags } from '@node-space/hooks'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { AccountContactTypes } from 'components/accountContact/accountContactV2/constants'
import { getAccountContactName } from 'components/accountContact/accountContactV2/utils'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useBusinessDocumentsMutation } from 'hooks/mutations/useBusinessDocumentsMutation'
import { useAccountRepresentativeQuery } from 'hooks/queries/useAccountRepresentativeQuery'
import { useRequiredDocumentsQuery } from 'hooks/queries/useRequiredDocsQuery'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { BaseErrorResponse } from 'types/errors'
import {
  BusinessDocument,
  BusinessDocumentsPayload,
  DocumentCategories,
  DocumentUpload,
  PersonDocumentsPayload,
  RequiredDocument,
} from 'types/verification'
import {
  getDocumentInfo,
  getDocumentUploadPayload,
  getUploadMessages,
  isOwner,
} from 'utils/onboarding'
import {
  AccountContactDocumentCategoryList,
  initialDocumentsPayload,
  payloadCategoryMapping,
} from './constants'
import {
  AccountContactDocumentCategory,
  FileDetails,
  FileValueId,
  UploadDocumentsHelperData,
} from './types'
import { useMappedDocuments } from './useMappedDocuments'

export const useUploadDocumentsHelper = (): UploadDocumentsHelperData => {
  const { t } = useTranslation()

  const { enableQueuedFileUpload } = useFeatureFlags()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { accountContacts, setUnsavedChanges, submitStep } = useVerificationContext()

  const [newFileList, setNewFileList] = useState<FileDetails[]>([])

  const {
    data: requiredDocuments,
    isFetching: isFetchingRequiredDocuments,
    isError: isRequiredDocumentsGetError,
  } = useRequiredDocumentsQuery()

  const {
    data: accountRepresentativeV1,
    isFetching: isLoadingAccountRepresentative,
    isError: isAccountRepresentativeGetError,
  } = useAccountRepresentativeQuery(!accountContacts?.isEnabled)

  const isLoadingContacts = useMemo(
    () =>
      accountContacts?.isEnabled ? accountContacts?.isFetching : isLoadingAccountRepresentative,
    [accountContacts?.isEnabled, accountContacts?.isFetching, isLoadingAccountRepresentative]
  )

  const accountRepresentativeV2 = accountContacts?.list?.find(
    contact => contact?.type === AccountContactTypes.ACCOUNT_REPRESENTATIVE
  )

  const accountRepName = accountContacts?.isEnabled
    ? getAccountContactName(accountRepresentativeV2)
    : `${accountRepresentativeV1?.firstName} ${accountRepresentativeV1?.lastName}`

  const { accountContactDocuments } = useMappedDocuments(requiredDocuments, accountRepName)

  const { mutate: postBusinessDocuments, isPending: isLoadingSubmitRequest } =
    useBusinessDocumentsMutation(!!enableQueuedFileUpload)

  const isLoading = useMemo(
    () => isFetchingRequiredDocuments || isLoadingContacts,
    [isFetchingRequiredDocuments, isLoadingContacts]
  )

  const isGetError = useMemo(
    () =>
      isRequiredDocumentsGetError || isAccountRepresentativeGetError || isEmpty(requiredDocuments),
    [isRequiredDocumentsGetError, isAccountRepresentativeGetError, requiredDocuments]
  )

  const getCategoryFilesCount = (category: DocumentCategories) => {
    const isAccountContactDocuments = AccountContactDocumentCategoryList?.includes(
      category as AccountContactDocumentCategory
    )
    const savedFiles = isAccountContactDocuments ? accountContactDocuments : requiredDocuments
    const savedFilesLength = savedFiles?.[category]?.filter(
      (document: RequiredDocument) => !!document?.filename
    )?.length
    const newFilesLength = newFileList?.filter(file => file?.category === category)?.length
    return {
      uploaded: savedFilesLength + newFilesLength,
      total: savedFiles?.[category]?.length,
    }
  }

  const getCategoryNewFiles = (category: DocumentCategories) => {
    return newFileList?.filter(fileDetails => fileDetails?.category === category)
  }

  const getFileValue = ({ category, documentCode, ownerId }: FileValueId): DocumentUpload => {
    const categoryFiles = getCategoryNewFiles(category)
    const fileDetails = categoryFiles?.find(
      categoryFile =>
        `${categoryFile?.documentCode}${categoryFile?.ownerId ?? ''}` ===
        `${documentCode}${ownerId ?? ''}`
    )
    return fileDetails?.file
  }

  const onChooseFile = (newFile: FileDetails) => {
    const { file, ...newFileDetails } = newFile || {}
    if (!file) return
    const updatedFileList = [...newFileList]
    const fileIndex = newFileList?.findIndex(({ file, ...fileDetails }) => {
      const currentFileId = Object?.values(fileDetails)?.join()
      const newFileId = Object?.values(newFileDetails)?.join()
      return currentFileId === newFileId
    })
    if (fileIndex !== -1) {
      updatedFileList[fileIndex] = newFile
    } else {
      updatedFileList?.push(newFile)
    }
    setNewFileList(updatedFileList)
  }

  const documents = getDocumentInfo(t)

  const uploadMessages = getUploadMessages(t)

  const getCategoryPayload = (categoryNewFiles: FileDetails[]): BusinessDocument[] => {
    return categoryNewFiles?.map(newFile => {
      if (!newFile?.file) return
      return getDocumentUploadPayload(newFile?.documentCode, newFile?.file?.buffer as string)
    })
  }

  const getOwnerCategoryPayload = (categoryNewFiles: FileDetails[]): PersonDocumentsPayload => {
    const ownerCategoryPayload = {}
    categoryNewFiles?.forEach(newFile => {
      if (!newFile?.file) return
      const filePayload = getDocumentUploadPayload(
        newFile?.documentCode,
        newFile?.file?.buffer as string
      )
      const ownerList = ownerCategoryPayload?.[newFile?.ownerId] ?? []
      ownerList.push(filePayload)
      ownerCategoryPayload[newFile?.ownerId] = ownerList
    })
    return ownerCategoryPayload
  }

  const getPayloadData = (): BusinessDocumentsPayload => {
    const updatedPayload = { ...initialDocumentsPayload }
    Object.keys(updatedPayload)?.forEach(payloadCategoryKey => {
      const category = payloadCategoryMapping?.[payloadCategoryKey]
      const categoryNewFiles = getCategoryNewFiles(category)
      if (!categoryNewFiles) return
      const categoryPayload = isOwner(category)
        ? getOwnerCategoryPayload(categoryNewFiles)
        : getCategoryPayload(categoryNewFiles)
      if (categoryPayload) {
        updatedPayload[payloadCategoryKey] = categoryPayload
      }
    })
    return updatedPayload
  }

  const submitData = () => {
    resetRequestError()
    const payload = getPayloadData()
    postBusinessDocuments(payload, {
      onSuccess: async () => {
        submitStep(VerificationTrackingLabels.UPLOAD_DOCUMENTS)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('postUploadDocuments', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  useEffect(() => {
    setUnsavedChanges(!!newFileList?.length)
  }, [newFileList?.length])

  return {
    data: {
      uploadMessages,
      documents,
      requiredDocuments,
      accountContactDocuments,
      isLoading,
      isGetError,
      requestError,
      isLoadingSubmitRequest,
    },
    getCategoryFilesCount,
    getFileValue,
    onChooseFile,
    submitData,
  }
}
