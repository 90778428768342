import React from 'react'
import { capitalize } from '@node-space/utils'
import { DocumentRejectionStatusDetail } from './DocumentRejectionStatusDetail'

interface DocumentRejectionStatusProps {
  statusReasonDetail: string
}

export const DocumentRejectionStatus = ({ statusReasonDetail }: DocumentRejectionStatusProps) => {
  const documentHasMultipleRejectionReasons = statusReasonDetail?.includes('\n')
  const statusReasonList = statusReasonDetail?.split('\n') ?? []
  const reasonDetail = statusReasonList?.[0] || statusReasonDetail

  const additionalReasons = documentHasMultipleRejectionReasons
    ? statusReasonList?.slice(1)?.map((message, index) => ({
        children: capitalize(message?.replace('- ', '')),
        key: index.toString(),
      }))
    : []

  return (
    <DocumentRejectionStatusDetail
      reasonDetail={reasonDetail}
      additionalReasons={additionalReasons}
    />
  )
}
