import React, { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useFeatureFlags } from '@node-space/hooks'
import { Box, Box as DragDropContainer } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import {
  BackgroundType,
  BorderType,
} from '@node-space/storybook-components/dist/components/Box/BoxTypes'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import Loader from '@node-space/storybook-components/dist/Loader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextList } from '@node-space/storybook-components/dist/TextList'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { logError } from '@node-space/utils'
import { Description } from 'components/Text/Description'
import { TextAlignType } from 'types/text'
import { DocumentUpload } from 'types/verification'
import {
  convertDataUrlToArrayBuffer,
  dropZoneAcceptedDocuments,
  getMimeTypeFromArrayBuffer,
} from 'utils/documents'
import { FILE_UPLOAD_LIMIT, FILE_UPLOAD_LIMIT_SMALL } from 'utils/utils'

interface TooltipContent {
  title: string
  description?: string
  bulletList?: string[]
}

interface UploadFileProps {
  title: string
  file: DocumentUpload
  url?: string
  isActionRequired?: boolean
  isRejectedDocument?: boolean
  tooltipContent?: TooltipContent
  showIsRequired?: boolean
  isDownloadingDocument?: boolean
  onDownloadClick?: () => void
  setFile: (fileData: DocumentUpload) => void
  name: string
}

export default function UploadFile({
  title,
  file,
  url,
  isActionRequired,
  isRejectedDocument = false,
  tooltipContent,
  showIsRequired,
  isDownloadingDocument,
  onDownloadClick,
  setFile,
  name,
}: UploadFileProps) {
  const { t } = useTranslation()
  const { enableFileUploadLimit } = useFeatureFlags()
  const [error, setError] = useState<string | undefined>()

  const maxDocumentUpload = 1

  const onDrop = (acceptedFiles, fileRejections: FileRejection[]) => {
    // -- clear state
    setError(null)
    setFile(null)

    // -- error: multiple files are uploaded
    if (fileRejections?.length > maxDocumentUpload) {
      setError(t('uploadDocument.multipleUploadsError'))
      return
    }

    // -- error: file extension is not accepted
    if (!acceptedFiles?.length) {
      setError(t('uploadDocument.uploadRequirementsOnly'))
      return
    }

    acceptedFiles.forEach(acceptedFile => {
      try {
        const fileExtension: string = acceptedFile?.type
        const fileExtensionDisplay: string = fileExtension.split('/')[1].toLocaleUpperCase()

        // -- error: file exceeds max size
        if (!enableFileUploadLimit && acceptedFile?.size > FILE_UPLOAD_LIMIT) {
          setError(t('uploadDocument.smallerFileRequired'))
          return
        }

        // -- error: file exceeds max size
        if (enableFileUploadLimit && acceptedFile?.size > FILE_UPLOAD_LIMIT_SMALL) {
          setError(t('uploadDocument.smallerFileRequiredSmall'))
          return
        }

        // -- read file data and set state
        const reader = new FileReader()

        // -- to handle other events on the reader, use 'reader.onabort' and 'reader.onerror'
        reader.onload = async () => {
          const dataUrl = reader?.result as string

          const arrayBuffer: ArrayBuffer = await convertDataUrlToArrayBuffer(dataUrl)
          const mimeType: string = getMimeTypeFromArrayBuffer(arrayBuffer)

          // -- error: file extension and the mime type is incompatible
          if (!mimeType || mimeType !== fileExtension) {
            setError(t('uploadDocument.mimeTypeError', { extension: fileExtensionDisplay }))
            return
          }

          const fileData = { fileData: acceptedFile, buffer: dataUrl }
          setFile && setFile(fileData)
        }

        reader.readAsDataURL(acceptedFile)
      } catch (error) {
        logError('uploadFileOnDrop', error)
        setError(t('oopsSomethingWentWrong'))
      }
    })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: dropZoneAcceptedDocuments,
  })

  const outerClassName = clsx('h-small cursor-pointer', {
    'border-2 border-dashed': (!file && !error) || isDragActive,
  })

  const getBackgroundColour = (): BackgroundType => {
    if (isDragActive) return 'gray'
    if (error || showIsRequired) return 'error'
    if (file) return 'success'
    return 'white'
  }

  const backgroundColour = getBackgroundColour()

  const getBorderColour = (): BorderType => {
    if (isDragActive) return null
    if (error || showIsRequired) return 'error'
    if (file) return 'success'
    return null
  }

  const borderColour = getBorderColour()

  return (
    <Box {...(isActionRequired && { background: 'gray', padding: 16, borderRadius: 8 })}>
      {(url || isRejectedDocument) && (
        <Box paddingB={16}>
          <Text size="sm" weight="medium" color="black">
            {isRejectedDocument ? t('rejectedDocument') : `${t('downloadAndComplete')} ${title}`}
          </Text>
          {isRejectedDocument ? (
            <Button
              iconElement={
                !isDownloadingDocument ? (
                  <Icon name="DownloadIcon" color="primary-500" size="sm" />
                ) : (
                  <Loader size="small" theme="dark" />
                )
              }
              noStyling
              onClick={onDownloadClick}
            >
              <Text
                className="hover:underline inline-block"
                size="sm"
                align="center"
                color="primary-500"
              >
                {title}
              </Text>
            </Button>
          ) : (
            <a href={url} target="_blank" rel="noreferrer">
              <Box className="inline-block -mb-0.5">
                <Icon name="DownloadIcon" color="primary-500" size="sm" />
              </Box>{' '}
              <Text size="sm" color="primary-500" className="inline-block">
                {title}
              </Text>
            </a>
          )}
        </Box>
      )}

      <Box flex paddingB={4} gapX={4} flexItemsCenter>
        <Text size="sm" weight="medium" color="black">
          {title}
        </Text>
        {!!tooltipContent && (
          <Tooltip
            position="right"
            hasMaxWidth
            bodyContent={
              <>
                <Text size="sm" color="inherit">
                  {tooltipContent?.title}
                </Text>
                {tooltipContent?.bulletList && (
                  <TextList
                    textSize="sm"
                    textColor="inherit"
                    listStyle="bullet"
                    listItems={tooltipContent?.bulletList?.map((item, index) => ({
                      key: `list-item-${index}`,
                      children: item,
                    }))}
                    className="pl-3"
                  />
                )}
              </>
            }
          >
            <Icon name="InfoCircleIcon" />
          </Tooltip>
        )}
      </Box>

      <div {...getRootProps()}>
        <DragDropContainer
          flex
          centerChildren
          direction="col"
          width="full"
          borderRadius={4}
          paddingY={20}
          paddingX={16}
          className={outerClassName}
          {...(backgroundColour && { background: backgroundColour })}
          {...(borderColour && { border: borderColour })}
        >
          <input data-testid="document-upload-zone" name={name} {...getInputProps()} />
          {isDragActive ? (
            <Text size="sm" color="grey" weight="semibold">
              {t('uploadDocument.dropFile')}
            </Text>
          ) : file && !error ? (
            <Box flex direction="col" gapY={4} flexItemsCenter>
              <Text size="sm" color="grey" textStyle="underline">
                {file?.fileData?.name}
              </Text>
              <Description color="primary-500">{t('uploadDocument.replaceFile')}</Description>
            </Box>
          ) : (
            <Box flex direction="col" gapY={4} flexItemsCenter>
              <Box flex gapX={4}>
                <Icon name="UploadIcon" color="primary-500" />
                <Description color="primary-500">{t('uploadDocument.uploadFile')}</Description>
              </Box>
              <Description align={TextAlignType.CENTER}>
                {error
                  ? error
                  : enableFileUploadLimit
                    ? t('uploadDocument.uploadRequirementsSmall')
                    : t('uploadDocument.uploadRequirements')}
              </Description>
            </Box>
          )}
        </DragDropContainer>
      </div>

      {showIsRequired && (
        <Box paddingT={4}>
          <Description color="error">{t('uploadDocument.fileRequired')}</Description>
        </Box>
      )}
    </Box>
  )
}
