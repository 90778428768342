import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import StatusPill from '@node-space/storybook-components/dist/StatusPill'
import { Text } from '@node-space/storybook-components/dist/Text'
import { IdVerificationLink } from 'components/IdVerification/IdVerificationLink'

export type PendingIdentificationPerson = {
  reference: string
  personName: string
  idVerificationUrl: string
}

export const IndividualVerificationDetail = ({
  personName,
  idVerificationUrl,
}: PendingIdentificationPerson) => {
  const { t } = useTranslation()

  return (
    <Box flex direction="col" gapY={8}>
      <Box flex direction="row" justifyContent="between" alignItems="center" gapY={8}>
        <Text size="sm">{personName}</Text>
        <StatusPill
          displayAsDot
          state="warning"
          label={
            <Text size="xs" color="text-900">
              {t('verification.individual.status.pending')}
            </Text>
          }
          fitContent
        />
      </Box>
      <IdVerificationLink url={idVerificationUrl} />
    </Box>
  )
}
