import React, { createContext, useEffect, useMemo, useState } from 'react'
import { finpromCoolOffPreferenceKey } from 'pages/Finprom/finpromPreferenceData'
import { AppropriatenessStepKey, FinpromStepKey } from 'pages/Finprom/types'
import { getFinpromActiveStep, getIsFinpromCountry } from 'pages/Finprom/utils'
import { AccountPreferenceData } from 'types/accounts'

interface FinpromContextShared {
  isEnabled: boolean
  isFetched: boolean
  isPhaseTwo: boolean
  refetchData: () => void
}

interface FinpromContextType extends FinpromContextShared {
  isFinpromCountry: boolean
  showBackToBusinessAddress: boolean
  isGoBackToBusinessAddress: boolean
  isWatchingBusinessAddress: boolean
  accountPreferences: AccountPreferenceData
  showFinpromPhaseTwo: boolean
  activeStep: FinpromStepKey
  appropriatenessActiveFormStep: AppropriatenessStepKey
  setCompanyCountryCode: React.Dispatch<React.SetStateAction<string>>
  setShowBackToBusinessAddress: React.Dispatch<React.SetStateAction<boolean>>
  setIsGoBackToBusinessAddress: React.Dispatch<React.SetStateAction<boolean>>
  setAccountPreferences: React.Dispatch<React.SetStateAction<AccountPreferenceData>>
  setActiveStep: React.Dispatch<React.SetStateAction<FinpromStepKey>>
  setAppropriatenessActiveFormStep: React.Dispatch<React.SetStateAction<AppropriatenessStepKey>>
  setActiveStepByPreferences: () => void
  onGoBackToBusinessAddress: () => void
  onLeaveBusinessAddress: () => void
}

interface FinpromContextProviderProps extends FinpromContextShared {
  children: React.ReactNode
  companyCountryCodeData: string
  accountPreferencesData: AccountPreferenceData
}

export const finpromContextInitialValues: FinpromContextType = {
  isEnabled: false,
  isFetched: false,
  isPhaseTwo: false,
  showFinpromPhaseTwo: false,
  isFinpromCountry: false,
  showBackToBusinessAddress: false,
  isGoBackToBusinessAddress: false,
  isWatchingBusinessAddress: false,
  accountPreferences: {},
  activeStep: null,
  appropriatenessActiveFormStep: null,
  setCompanyCountryCode: () => {},
  setShowBackToBusinessAddress: () => {},
  setIsGoBackToBusinessAddress: () => {},
  setAccountPreferences: () => {},
  setActiveStep: () => {},
  setAppropriatenessActiveFormStep: () => {},
  refetchData: () => {},
  setActiveStepByPreferences: () => {},
  onGoBackToBusinessAddress: () => {},
  onLeaveBusinessAddress: () => {},
}

export const FinpromContext = createContext<FinpromContextType>(finpromContextInitialValues)

export const FinpromContextProvider = ({
  children,
  isEnabled,
  isFetched,
  isPhaseTwo,
  companyCountryCodeData,
  accountPreferencesData,
  refetchData,
}: FinpromContextProviderProps) => {
  const [activeStep, setActiveStep] = useState<FinpromStepKey>()
  const [companyCountryCode, setCompanyCountryCode] = useState<string>()
  const [accountPreferences, setAccountPreferences] = useState<AccountPreferenceData>()
  const [showBackToBusinessAddress, setShowBackToBusinessAddress] = useState(false)
  const [isGoBackToBusinessAddress, setIsGoBackToBusinessAddress] = useState(false)
  const [isWatchingBusinessAddress, setIsWatchingBusinessAddress] = useState(false)
  const [appropriatenessActiveFormStep, setAppropriatenessActiveFormStep] =
    useState<AppropriatenessStepKey>()

  const isFinpromCountry = useMemo(
    () => getIsFinpromCountry(companyCountryCode),
    [companyCountryCode]
  )

  const isPhaseOneAccepted = useMemo(
    () => accountPreferences?.[finpromCoolOffPreferenceKey] === true,
    [accountPreferences?.[finpromCoolOffPreferenceKey]]
  )

  const showFinpromPhaseTwo = useMemo(
    () => isFetched && isEnabled && isFinpromCountry && isPhaseTwo && !isPhaseOneAccepted,
    [isFetched, isEnabled, isFinpromCountry, isPhaseTwo, isPhaseOneAccepted]
  )

  const setActiveStepByPreferences = () => {
    const activeStep = getFinpromActiveStep(accountPreferences)
    setActiveStep(activeStep)
  }

  const onGoBackToBusinessAddress = () => {
    setIsGoBackToBusinessAddress(false)
    setShowBackToBusinessAddress(false)
    setIsWatchingBusinessAddress(true)
  }

  const onLeaveBusinessAddress = () => {
    setIsWatchingBusinessAddress(false)
    setActiveStepByPreferences()
  }

  useEffect(() => {
    setCompanyCountryCode(companyCountryCodeData)
  }, [companyCountryCodeData])

  useEffect(() => {
    setAccountPreferences(accountPreferencesData)
  }, [accountPreferencesData])

  const value: FinpromContextType = {
    isEnabled,
    isFetched,
    isFinpromCountry,
    showBackToBusinessAddress,
    isGoBackToBusinessAddress,
    isWatchingBusinessAddress,
    isPhaseTwo,
    accountPreferences,
    activeStep,
    appropriatenessActiveFormStep,
    showFinpromPhaseTwo,
    setCompanyCountryCode,
    setShowBackToBusinessAddress,
    setIsGoBackToBusinessAddress,
    onGoBackToBusinessAddress,
    onLeaveBusinessAddress,
    setAccountPreferences,
    setActiveStep,
    setAppropriatenessActiveFormStep,
    setActiveStepByPreferences,
    refetchData,
  }

  return <FinpromContext.Provider value={value}>{children}</FinpromContext.Provider>
}
