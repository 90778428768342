import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { Box as CSATOptions } from '@node-space/storybook-components/dist/Box'
import {
  Modal,
  ModalNavBar,
  ModalActions,
  ModalBody,
} from '@node-space/storybook-components/dist/Modal'
import { Text } from '@node-space/storybook-components/dist/Text'
import {
  CSATCustomerType,
  CSATFeedbackRating,
  CSATFeedbackRatingType,
  CSATFeedbackValue,
  CSATOptionStatus,
  CSATOptionType,
} from './types'
import { CSATOption } from './CSATOption'

const CSAT_API_URL = process.env.CSAT_API_URL
const CSAT_API_KEY = process.env.CSAT_API_KEY

export type CSATProps = {
  customer: CSATCustomerType
  onClose: () => void
}

export const CSATModal = ({ customer, onClose }: CSATProps) => {
  const { t } = useTranslation()

  const [rating, setRating] = useState<CSATFeedbackRatingType>()

  const feedbackOptions: CSATOptionType[] = [
    {
      label: t('csat.options.bad'),
      value: CSATFeedbackValue.BAD,
      rating: CSATFeedbackRating.BAD,
    },
    {
      label: t('csat.options.average'),
      value: CSATFeedbackValue.OKAY,
      rating: CSATFeedbackRating.OKAY,
    },
    {
      label: t('csat.options.good'),
      value: CSATFeedbackValue.GOOD,
      rating: CSATFeedbackRating.GOOD,
    },
  ]

  const params = {
    source: `generic_embed`,
    customer_email: `${customer?.emailAddress}`,
    // prepend custom fields with t_ to show on simplesat dashboard
    t_firstname: `${customer?.firstname}`,
    t_lastname: `${customer?.lastname}`,
    t_customer_id: `${customer?.customerID}`,
    t_companyName: `${customer?.companyName}`,
  }
  const paramList2 = new URLSearchParams(params).toString()

  const CSATsurveyUrl = `${CSAT_API_URL}/api/embed/rating/${rating}/${CSAT_API_KEY}/?${paramList2}`

  const onSubmitFeedback = () => {
    window.open(CSATsurveyUrl)
    onClose()
  }

  const sendButton: ButtonProps = {
    children: t('csat.sendFeedback'),
    disabled: !rating,
    onClick: onSubmitFeedback,
  }

  return (
    <Modal visible closeModal={onClose}>
      <ModalNavBar title={t('csat.title')} onClose={onClose} />

      <ModalBody spaceChildren={32}>
        <Text size="sm" color="grey" align="center">
          {t('csat.intro')}
        </Text>

        <CSATOptions flex justifyContent="between" paddingX={20}>
          {feedbackOptions?.map(option => {
            const status = !rating
              ? CSATOptionStatus.DEFAULT
              : rating === option?.rating
              ? CSATOptionStatus.SELECTED
              : CSATOptionStatus.UNSELECTED

            return (
              <CSATOption
                key={option?.value}
                label={option?.label}
                value={option?.value}
                status={status}
                onClick={() => setRating(option?.rating)}
              />
            )
          })}
        </CSATOptions>

        <Text size="sm" color="grey" align="center">
          {t('csat.additionalText')}
        </Text>
      </ModalBody>

      <ModalActions actions={[sendButton]} />
    </Modal>
  )
}
