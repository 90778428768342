import { useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Profile } from 'types/authentication/Profile'

const useLaunchDarkly = (profile: Profile) => {
  const { id: profileId, firstName, lastName, emailAddress: email } = profile || {}

  const LDClient = useLDClient()

  useEffect(() => {
    if (profileId && LDClient) {
      const user = { key: `${profileId}`, firstName, lastName, email }
      LDClient.identify(user)
    }
  }, [profileId, LDClient])

  return { LDClient }
}

export default useLaunchDarkly
