import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getBusinessActivityDetails } from 'services/VerificationService'

export const useBusinessActivityQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.businessActivity(),
    queryFn: getBusinessActivityDetails,
  })
}
