import { useState } from 'react'
import { isEqual } from 'lodash'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { AccountContactShared } from './types'

export const useAccountContacts = <AccountContactType extends AccountContactShared>() => {
  const [initialAccountContacts, setInitialAccountContacts] = useState<AccountContactType[]>([])
  const [accountContacts, setAccountContacts] = useState<AccountContactType[]>([])
  const [selectedAccountContact, setSelectedAccountContact] = useState<AccountContactType>()
  const [isAccountContactsEmptyError, setIsAccountContactsEmptyError] = useState(false)
  const [isEditingAccountContact, setIsEditingAccountContact] = useState(false)
  const [isAccountContactOpen, setIsAccountContactOpen] = useState(false)

  const { data: countryOptions = [] } = useCountriesQuery(false)

  const loadAccountContacts = (loadedAccountContacts: AccountContactType[]) => {
    setInitialAccountContacts(loadedAccountContacts)
    setAccountContacts(loadedAccountContacts)
  }

  const handleAddAccountContact = () => {
    setIsAccountContactOpen(true)
    setIsEditingAccountContact(false)
  }

  const handleCloseAccountContact = () => {
    setIsAccountContactOpen(false)
    setIsEditingAccountContact(false)
    setSelectedAccountContact(null)
  }

  const handleSaveAccountContact = (savedAccountContact: AccountContactType) => {
    isAccountContactsEmptyError && setIsAccountContactsEmptyError(false)
    setAccountContacts(currentList => {
      if (!isEditingAccountContact) {
        return [...currentList, savedAccountContact]
      }
      const updatedList = [...currentList]
      const selectedIndex = updatedList?.findIndex(
        contact => contact?.id === savedAccountContact?.id
      )
      updatedList[selectedIndex] = savedAccountContact
      return updatedList
    })
    handleCloseAccountContact()
  }

  const handleEditAccountContact = selectedContactId => {
    const selectedAccountContact: AccountContactType = accountContacts?.find(
      contact => contact?.id === selectedContactId
    )
    setSelectedAccountContact(selectedAccountContact)
    setIsEditingAccountContact(true)
    setIsAccountContactOpen(true)
  }

  const handleDeleteAccountContact = selectedContactId => {
    const updatedList = accountContacts?.filter(contact => contact?.id !== selectedContactId)
    setAccountContacts(updatedList)
    if (isEditingAccountContact) {
      handleCloseAccountContact()
    }
  }

  const getHasAccountContactsChanged = () => !isEqual(accountContacts, initialAccountContacts)

  const getIsAccountContactsValid = (): boolean => {
    const hasAccountContacts = !!accountContacts?.length
    if (!hasAccountContacts) {
      setIsAccountContactsEmptyError(true)
    }
    return hasAccountContacts
  }

  return {
    accountContacts,
    countryOptions,
    selectedAccountContact,
    isAccountContactOpen,
    isEditingAccountContact,
    isAccountContactsEmptyError,
    loadAccountContacts,
    getHasAccountContactsChanged,
    getIsAccountContactsValid,
    handleAddAccountContact,
    handleSaveAccountContact,
    handleEditAccountContact,
    handleDeleteAccountContact,
    handleCloseAccountContact,
  }
}
