import { useEffect, useState } from 'react'
import {
  AccountContactTypes,
  PersonDefaultOptions,
} from 'components/accountContact/accountContactV2/constants'
import {
  AccountContactData,
  AccountContactPayload,
  AccountContactsContext,
  PersonSelectorData,
} from 'components/accountContact/accountContactV2/types'
import {
  getInitialMyselfOption,
  getIntialSelectedReference,
  getIsOtherAccountContact,
  getIsPersonMyself,
  getPersonSelectorData,
} from 'components/accountContact/accountContactV2/utils'
import { useProfileContext } from 'hooks/context/useProfileContext'
import {
  useDeleteAccountContactMutation,
  useSaveAccountContactMutation,
} from 'hooks/mutations/useAccountContactsMutation'
import { BaseErrorResponse } from 'types/errors'
import { HandleSubmit } from 'types/reactQuery'
import { AccountRepData, AccountRepFormData } from './types'
import { getUpdatedAccountRepData } from './utils'

const accountContactType = AccountContactTypes.ACCOUNT_REPRESENTATIVE

export const useAccountRepCrud = (accountContactsData: AccountContactsContext) => {
  const { profile } = useProfileContext()

  const [personSelectorData, setPersonSelectorData] = useState<PersonSelectorData>()
  const [savedReferenceData, setSavedReferenceData] = useState<string>()
  const [savedAccountRepData, setSavedAccountRepData] = useState<AccountContactData>()
  const [initialSelectedReference, setInitialSelectedReference] = useState<string>()
  const [selectedReference, setSelectedReference] = useState<string>()
  const [selectedAccountRep, setSelectedAccountRep] = useState<AccountContactData>()
  const [myselfData, setMyselfData] = useState<AccountContactData>()
  const [someoneElseData, setSomeoneElseData] = useState<AccountContactData>()

  const { mutate: saveAccountRep, isPending: isSavingAccountRep } = useSaveAccountContactMutation()

  const { mutate: deleteAccountRep, isPending: isDeletingAccountRep } =
    useDeleteAccountContactMutation()

  const loadInitialData = () => {
    const savedAccountRep = accountContactsData?.list?.find(
      contact => contact?.type === accountContactType
    )
    const selectorData = getPersonSelectorData(accountContactsData?.list, accountContactType)
    const initialReference = getIntialSelectedReference({
      otherPersons: selectorData?.personsData,
      profile,
      savedData: savedAccountRep,
    })
    if (savedAccountRep) {
      setSavedReferenceData(savedAccountRep?.reference)
      setSavedAccountRepData(savedAccountRep)
    }
    if (initialReference === PersonDefaultOptions.SOMEONE_ELSE) {
      setSomeoneElseData(savedAccountRep)
    }
    setMyselfData(
      initialReference === PersonDefaultOptions.MYSELF && !!savedAccountRep?.reference
        ? savedAccountRep
        : getInitialMyselfOption(profile)
    )
    setInitialSelectedReference(initialReference)
    setSelectedReference(initialReference)
    const filteredPersonsData = selectorData?.personsData?.filter(
      contact => !getIsPersonMyself(contact?.person, profile)
    )
    setPersonSelectorData({ ...selectorData, personsData: filteredPersonsData })
  }

  const getSelectedData = () => {
    switch (selectedReference) {
      case PersonDefaultOptions.MYSELF:
        return myselfData
      case PersonDefaultOptions.SOMEONE_ELSE:
        return someoneElseData
      default:
        const otherContact = personSelectorData?.personsData?.find(
          contact => contact?.reference === selectedReference
        )
        const otherPersonReference = otherContact?.person?.reference
        if (
          otherPersonReference &&
          otherPersonReference === savedAccountRepData?.person?.reference
        ) {
          otherContact.contactInfo = {
            emailAddress: savedAccountRepData?.contactInfo?.emailAddress,
          }
        }
        return otherContact
    }
  }

  const onSaveAccountRep = ({
    payload: formValues,
    onSuccess,
    onError,
  }: HandleSubmit<AccountRepFormData, AccountContactData>) => {
    const updatedAccountRepData = getUpdatedAccountRepData(selectedAccountRep, formValues)
    const isSameOptionSelected = initialSelectedReference === selectedReference
    const isOtherAccountContactSelected = getIsOtherAccountContact(selectedReference)
    const containsOtherAccountContact =
      getIsOtherAccountContact(initialSelectedReference) || isOtherAccountContactSelected
    const shouldDeleteOldAccountRep =
      !!savedReferenceData && !isSameOptionSelected && containsOtherAccountContact
    if (isOtherAccountContactSelected) {
      updatedAccountRepData.person.reference = selectedAccountRep?.person?.reference
    }
    if (shouldDeleteOldAccountRep) {
      const payload: AccountContactPayload = {
        reference: savedReferenceData,
        type: AccountContactTypes.ACCOUNT_REPRESENTATIVE,
      }
      deleteAccountRep(payload, {
        onSuccess: () => {
          saveAccountRep(updatedAccountRepData, {
            onSuccess,
            onError: (error: BaseErrorResponse) => {
              setSavedReferenceData(null)
              onError(error)
            },
          })
        },
        onError,
      })
      return
    }
    updatedAccountRepData.reference = savedReferenceData
    saveAccountRep(updatedAccountRepData, { onSuccess, onError })
  }

  useEffect(() => {
    if (!accountContactsData?.isFetching) {
      loadInitialData()
    }
  }, [accountContactsData?.isFetching])

  useEffect(() => {
    const updatedAccountRep = getSelectedData()
    setSelectedAccountRep(updatedAccountRep)
  }, [selectedReference])

  const data: AccountRepData = {
    initialReference: initialSelectedReference,
    selectedReference,
    selectedAccountRep,
    personSelectorData,
  }

  return {
    data,
    loading: {
      isLoadingForm: accountContactsData?.isFetching || !initialSelectedReference,
      isSavingAccountRep: isSavingAccountRep || isDeletingAccountRep,
    },
    errors: {
      isFetchingAccountContacts: accountContactsData?.isFetchingError,
    },
    setSelectedReference,
    onSaveAccountRep,
  }
}
