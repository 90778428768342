import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormSubmitError } from 'pages/Verification/_components/FormSubmitError'
import { AccountContactTranslation } from './accountContactV2/constants'
import {
  AccountContactErrorStatus,
  AccountContactErrorType,
  AccountContactType,
} from './accountContactV2/types'

export interface AccountContactActionErrorProps extends AccountContactErrorStatus {
  errorCode?: number
  accountContactType: AccountContactType
}

export const AccountContactActionError = ({
  errorCode,
  errorType,
  sentryTraceId,
  contactFullName,
  accountContactType,
}: AccountContactActionErrorProps) => {
  const { t } = useTranslation()
  const contactType = t(`accountContact.type.${AccountContactTranslation?.[accountContactType]}`)

  const errorMessages: Record<AccountContactErrorType, string> = {
    emptyContacts: t('accountContact.emptyError', { contactType }),
    addContact: t('accountContact.add.error', { contactType, contactFullName }),
    updateContact: t('accountContact.update.error', { contactType, contactFullName }),
    deleteContact: t('accountContact.delete.error', { contactType, contactFullName }),
    createIdVerificationLink: t('accountContact.createIdVerificationLink.error', {
      contactType,
      contactFullName,
    }),
  }

  const errorMessage = errorMessages?.[errorType]

  return (
    <FormSubmitError
      {...(!!sentryTraceId && { sentryTraceId })}
      {...(!!errorCode && { errorCode, fallbackMessage: errorMessage })}
    >
      {!errorCode && errorMessage}
    </FormSubmitError>
  )
}
