import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { ModalActions, ModalBody, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { AccountContactTranslation } from 'components/accountContact/accountContactV2/constants'
import { AccountContactType } from 'components/accountContact/accountContactV2/types'
import { Description } from 'components/Text'
import { IdVerificationLink } from './IdVerificationLink'

export type IdVerificationMessageProps = {
  contactType: AccountContactType
  contactFullName?: string
  linkUrl: string
  onClose: () => void
  onContinue: () => void
}

export const IdVerificationMessage = ({
  contactType,
  contactFullName,
  linkUrl,
  onClose,
  onContinue,
}: IdVerificationMessageProps) => {
  const { t } = useTranslation()

  const message = {
    title: t('accountContact.createIdVerificationLink.title.myself'),
    requirements: t(
      `accountContact.createIdVerificationLink.requirements.${AccountContactTranslation?.[contactType]}`
    ),
    instructions: t('accountContact.createIdVerificationLink.instructions.myself'),
    closingLine: t('accountContact.createIdVerificationLink.convenientTime'),
  }

  if (!!contactFullName) {
    message.title = t('accountContact.createIdVerificationLink.title.anotherPerson', {
      contactFullName,
    })
    message.instructions = t('accountContact.createIdVerificationLink.instructions.anotherPerson')
  }

  const handleContinue = () => {
    !contactFullName && window.open(linkUrl)
    onContinue()
  }

  const actions: ButtonProps[] = [
    {
      children: t('continue'),
      onClick: handleContinue,
    },
  ]

  return (
    <>
      <ModalNavBar title={message?.title} onClose={onClose} />
      <ModalBody spaceChildren={24}>
        <Description>{`${message?.requirements} ${message?.instructions}`}</Description>
        <Description>{message?.closingLine}</Description>
        {!!contactFullName && <IdVerificationLink url={linkUrl} />}
      </ModalBody>
      <ModalActions actions={actions} />
    </>
  )
}
