import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@node-space/hooks'
import { getBusinessVerificationStatus } from 'services/VerificationService'
import { isTestEnvironment } from 'utils/environment'
import { ONE_DAY } from 'utils/utils'

export const useBusinessVerificationStatusQuery = (isEnabled = true) => {
  const { isAuthed } = useAuth()

  return useQuery({
    queryKey: reactQueryKeys.businessVerificationStatus(),
    queryFn: getBusinessVerificationStatus,
    staleTime: ONE_DAY,
    select: data => {
      const businessVerificationStatus = data?.status
      return businessVerificationStatus
    },
    enabled: isTestEnvironment || (isAuthed && isEnabled),
  })
}
