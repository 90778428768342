import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Box as GroupFields,
  Box as InlineFields,
} from '@node-space/storybook-components/dist/Box'
import {
  DateErrorType,
  DateOfBirth,
  DateType,
} from '@node-space/storybook-components/dist/DateOfBirth'
import { InputLabel } from '@node-space/storybook-components/dist/FormElements'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Modal } from '@node-space/storybook-components/dist/Modal'
import { Select } from '@node-space/storybook-components/dist/Select'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { AccountContactActionError } from 'components/accountContact/AccountContactActionError'
import { AccountContactSummary } from 'components/accountContact/AccountContactSummary'
import {
  AccountContactErrorTypes,
  AccountContactTypes,
  PersonDefaultOptions,
} from 'components/accountContact/accountContactV2/constants'
import { PersonSelector } from 'components/accountContact/accountContactV2/PersonSelector'
import {
  AccountContactData,
  AccountContactErrorType,
  AccountContactPayload,
} from 'components/accountContact/accountContactV2/types'
import {
  getAccountContactName,
  getPersonDisplayTypes,
} from 'components/accountContact/accountContactV2/utils'
import { IdVerificationMessage } from 'components/IdVerification/IdVerificationMessage'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useFormSetup } from 'hooks/forms/useFormSetup'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useDateOfBirthInput } from 'hooks/useDateOfBirthInput'
import { useIdVerification } from 'hooks/useIdVerification'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { FormBody } from 'pages/Verification/_components/FormBody'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { BaseErrorResponse } from 'types/errors'
import { HandleSubmit } from 'types/reactQuery'
import { AccountRepSchema } from './AccountRepSchema'
import { initialAccountRepFormData } from './constants'
import { AccountRepData, AccountRepFormData, AccountRepFormKeys } from './types'
import { getInitialAccountRepFormData } from './utils'

const accountContactType = AccountContactTypes.ACCOUNT_REPRESENTATIVE

type HandleSaveAccountRep = HandleSubmit<AccountRepFormData, AccountContactData>

type AccountRepFormProps = AccountRepData & {
  isSavingAccountRep: boolean
  onAccountRepChange: (updatedReference: string) => void
  onSaveAccountRep: (saveAccountRepArgs: HandleSaveAccountRep) => void
}

export const AccountRepresentativeForm = ({
  initialReference,
  selectedReference,
  selectedAccountRep,
  personSelectorData,
  isSavingAccountRep,
  onAccountRepChange,
  onSaveAccountRep,
}: AccountRepFormProps) => {
  const { t } = useTranslation()
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const {
    setUnsavedChanges,
    submitStep,
    accountContacts: { refetch: refetchAccountContacts } = {},
  } = useVerificationContext()
  const { isIdVerificationEnabled, idVerificationMessage, createIdVerificationLink } =
    useIdVerification()
  const { data: countryOptions = [], isLoading: isLoadingCountries } = useCountriesQuery(false)

  const [idVerificationLink, setIdVerificationLink] = useState<string>()

  const isMyselfSelected = selectedReference === PersonDefaultOptions.MYSELF
  const isSomeoneElseSelected = selectedReference === PersonDefaultOptions.SOMEONE_ELSE
  const isOtherContactSelected = !isMyselfSelected && !isSomeoneElseSelected

  const {
    dateOfBirthLabels,
    hasDateOfBirthErrors,
    getDateOfBirthFields,
    setDateOfBirthInputValue,
    setDateOfBirthErrors,
    getDateOfBirthErrors,
    getDateOfBirthErrorText,
    formatDateOfBirthData,
    formatDateOfBirthInput,
  } = useDateOfBirthInput()

  const getInitialFormData = (): AccountRepFormData => {
    const initialFormData = !!selectedAccountRep
      ? getInitialAccountRepFormData(selectedAccountRep)
      : initialAccountRepFormData
    return {
      ...initialFormData,
      dateOfBirth: formatDateOfBirthData(initialFormData?.dateOfBirth),
    }
  }

  const defaultValues = getInitialFormData()

  const formSchema = AccountRepSchema(t)
  const { form, formValues, errors, isDirty, control, handleSubmit } =
    useFormSetup<AccountRepFormData>(formSchema, defaultValues)

  const { setInputField, setSelectField, setEmailField } = useFormFields<AccountRepFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: Record<AccountRepFormKeys, any> = {
    emailAddress: setEmailField({ key: 'emailAddress' }),
    firstName: setInputField({ key: 'firstName', useLabelAsPlaceholder: true, showLabel: false }),
    lastName: setInputField({ key: 'lastName', useLabelAsPlaceholder: true, showLabel: false }),
    dateOfBirth: {}, // handled as custom control
    address1: setInputField({ key: 'address1', useLabelAsPlaceholder: true, showLabel: false }),
    address2: setInputField({ key: 'address2', useLabelAsPlaceholder: true, showLabel: false }),
    city: setInputField({ key: 'city', useLabelAsPlaceholder: true, showLabel: false }),
    postalCode: setInputField({ key: 'postalCode', useLabelAsPlaceholder: true, showLabel: false }),
    countryCode: setSelectField({
      key: 'countryCode',
      options: countryOptions,
      placeholder: t('formFields.chooseCountry'),
      showLabel: false,
    }),
  }

  const errorMessage = requestError?.show ? (
    <AccountContactActionError
      errorCode={requestError?.errorCode}
      errorType={requestError?.errorType as AccountContactErrorType}
      accountContactType={accountContactType}
      contactFullName={`${formValues?.firstName} ${formValues?.lastName}`}
      sentryTraceId={requestError?.sentryTraceId}
    />
  ) : null

  const handleSubmitError = (errorType: AccountContactErrorType, error: BaseErrorResponse) => {
    const sentryTraceId = logSentryErrorAndGetTraceId(`${errorType} - ${accountContactType}`, error)
    setRequestError({
      errorCode: error?.status,
      errorType,
      sentryTraceId,
      show: true,
    })
  }

  const continueToNextStep = () => {
    refetchAccountContacts()
    submitStep(VerificationTrackingLabels.ACCOUNT_REPRESENTATIVE)
  }

  const onCreateIdVerification = (reference: string) => {
    const createIdVerificationLinkArgs: HandleSubmit<AccountContactPayload, string> = {
      payload: {
        reference,
        type: accountContactType,
      },
      onSuccess: idVerificationLinkResponse => {
        setIdVerificationLink(idVerificationLinkResponse)
        idVerificationMessage?.setShowMessage(true)
      },
      onError: error => {
        handleSubmitError(AccountContactErrorTypes.CREATE_ID_VERIFICATION_LINK, error)
      },
    }
    createIdVerificationLink?.onCreateLink(createIdVerificationLinkArgs)
  }

  const onSubmit: SubmitHandler<typeof formValues> = (formValues: AccountRepFormData) => {
    if (!isDirty && initialReference === selectedReference) {
      continueToNextStep()
      return
    }
    resetRequestError()
    const saveAccountRepArgs: HandleSaveAccountRep = {
      payload: {
        ...formValues,
        dateOfBirth: formatDateOfBirthInput(formValues.dateOfBirth),
      },
      onSuccess: accountRepData => {
        const isExistingPerson = !!selectedAccountRep?.person?.reference
        if (isIdVerificationEnabled && !isExistingPerson) {
          onCreateIdVerification(accountRepData?.reference)
          return
        }
        continueToNextStep()
      },
      onError: error => {
        handleSubmitError(AccountContactErrorTypes.ADD_CONTACT, error)
      },
    }
    onSaveAccountRep(saveAccountRepArgs)
  }

  useEffect(() => {
    form?.reset(getInitialFormData())
  }, [selectedAccountRep])

  useEffect(() => {
    setUnsavedChanges(isDirty)
  }, [isDirty])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBody>
        {!!errorMessage && errorMessage}
        <PersonSelector
          otherPersons={personSelectorData?.personsData}
          selectedReference={selectedReference}
          onPersonChange={onAccountRepChange}
        />

        {!isSomeoneElseSelected && (
          <AccountContactSummary
            contactFullName={getAccountContactName(selectedAccountRep)}
            contactDetail={
              isMyselfSelected
                ? selectedAccountRep?.contactInfo?.emailAddress
                : getPersonDisplayTypes(
                    personSelectorData?.personTypes,
                    selectedAccountRep?.person?.reference,
                    t
                  )
            }
          />
        )}

        {!isMyselfSelected && <Input {...formFields?.emailAddress} disabled={isMyselfSelected} />}

        {isSomeoneElseSelected && (
          <Box>
            <InputLabel>{t('verification.businessDirectors.legalNameOfPerson')}</InputLabel>
            <GroupFields flex direction="col" gapY={8}>
              <Input {...formFields?.firstName} />
              <Input {...formFields?.lastName} />
            </GroupFields>
          </Box>
        )}

        {!isOtherContactSelected && (
          <>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <DateOfBirth
                  date={getDateOfBirthFields(value)}
                  labels={{
                    ...dateOfBirthLabels,
                    errorMessage: getDateOfBirthErrorText(error),
                  }}
                  errors={getDateOfBirthErrors(error)}
                  onChange={(date: DateType) => onChange(setDateOfBirthInputValue(date))}
                  onValidate={(errors: DateErrorType) => setDateOfBirthErrors(errors)}
                />
              )}
            />
            <Box>
              <InputLabel>{t('verification.businessDirectors.residentialAddress')}</InputLabel>
              <GroupFields flex direction="col" gapY={8}>
                <Input {...formFields?.address1} />
                <Input {...formFields?.address2} />
                <InlineFields flex gap={8}>
                  <Box flexSize="fill" width="full">
                    <Input {...formFields?.city} />
                  </Box>
                  <Box flexSize="fill" width="full">
                    <Input {...formFields?.postalCode} />
                  </Box>
                </InlineFields>
                <Select {...formFields?.countryCode} loading={isLoadingCountries} isSearchable />
              </GroupFields>
            </Box>
          </>
        )}

        <FormSubmitButton
          loading={isSavingAccountRep}
          disabled={!!Object.keys(errors)?.length || hasDateOfBirthErrors}
        />
      </FormBody>
      {idVerificationMessage?.showMessage && (
        <Modal visible closeModal={continueToNextStep}>
          <IdVerificationMessage
            contactType={accountContactType}
            {...(!isMyselfSelected && {
              contactFullName: `${formValues?.firstName} ${formValues?.lastName}`,
            })}
            linkUrl={idVerificationLink}
            onClose={continueToNextStep}
            onContinue={continueToNextStep}
          />
        </Modal>
      )}
    </form>
  )
}
