import { AccountContactTypes } from 'components/accountContact/accountContactV2/constants'
import { AccountContactData } from 'components/accountContact/accountContactV2/types'
import {
  getInitialFormValues,
  getUpdatedAccountContactData,
} from 'components/accountContact/accountContactV2/utils'
import { AccountRepFormData } from './types'

export const getInitialAccountRepFormData = (
  accountContactData: AccountContactData
): AccountRepFormData => {
  const sharedFormData = getInitialFormValues(accountContactData)
  return {
    ...sharedFormData,
    emailAddress: accountContactData?.contactInfo?.emailAddress ?? '',
  }
}

export const getUpdatedAccountRepData = (
  accountContactData: AccountContactData,
  formValues: AccountRepFormData
): AccountContactData => {
  const sharedData = getUpdatedAccountContactData(accountContactData, formValues, false)
  const updatedData: AccountContactData = {
    type: AccountContactTypes.ACCOUNT_REPRESENTATIVE,
    person: sharedData?.person,
    contactInfo: {
      emailAddress: formValues?.emailAddress,
    },
  }
  updatedData.person.reference = null
  return updatedData
}
