import React from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { Box, Box as FileUploadsContainer } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Collapsible } from '@node-space/storybook-components/dist/Collapsible'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { FormBody } from 'pages/Verification/_components/FormBody'
import { FormGetError } from 'pages/Verification/_components/FormGetError'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { FormSubmitError } from 'pages/Verification/_components/FormSubmitError'
import UploadFile from 'pages/Verification/_components/UploadFile'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { DocumentCategories } from 'types/verification'
import { formatString } from 'utils/utils'
import { CategoryDocuments } from './CategoryDocuments'
import { AccountContactDocumentCategoryList } from './constants'
import { AccountContactDocumentCategory } from './types'
import { useUploadDocumentsHelper } from './useUploadDocuments'

export const UploadDocuments = () => {
  const { t } = useTranslation()

  const {
    data: {
      uploadMessages,
      documents,
      requiredDocuments,
      accountContactDocuments,
      isLoading,
      isGetError,
      requestError,
      isLoadingSubmitRequest,
    } = {},
    getCategoryFilesCount,
    getFileValue,
    onChooseFile,
    submitData,
  } = useUploadDocumentsHelper()

  return (
    <>
      <VerificationHeader header={t('verification.routes.businessDocuments')} />

      {isLoading ? (
        <SkeletonForm name="Upload documents" />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <FormBody>
          {requestError?.show && (
            <FormSubmitError
              errorCode={requestError?.errorCode}
              sentryTraceId={requestError?.sentryTraceId}
            />
          )}

          <FileUploadsContainer>
            {Object.keys(omit(requiredDocuments, 'id'))
              ?.filter(
                category =>
                  !AccountContactDocumentCategoryList?.includes(
                    category as AccountContactDocumentCategory
                  )
              )
              ?.map((category: DocumentCategories, categoryKeyIndex) => {
                const categoryFiles = requiredDocuments?.[category]
                const categoryFilesCount = getCategoryFilesCount(category)
                return (
                  <Box key={category}>
                    {!isEmpty(categoryFiles) && (
                      <Collapsible
                        key={categoryKeyIndex}
                        testid={`${category}-upload`}
                        title={t(`uploadDocuments.labels.${category}`)}
                        rightLabel={formatString(
                          t('uploadsCountStatus'),
                          categoryFilesCount?.uploaded,
                          categoryFilesCount?.total
                        )}
                      >
                        <Box flex direction="col" gapY={16}>
                          {categoryFiles?.map((categoryObject, categoryObjectIndex) => {
                            const documentInfo = documents?.[category]?.find(
                              documentFields => documentFields?.name === categoryObject?.code
                            )

                            const documentCode = categoryObject?.code

                            return (
                              <Box flex direction="col" gapY={16} key={categoryObjectIndex}>
                                {!categoryObject?.filename ? (
                                  <Box>
                                    <UploadFile
                                      file={getFileValue({ category, documentCode })}
                                      setFile={file =>
                                        onChooseFile({ category, file, documentCode })
                                      }
                                      {...documentInfo}
                                    />
                                    {!!documentInfo?.permanentWarning && (
                                      <Box paddingT={8}>
                                        <Callout
                                          state="warning"
                                          message={documentInfo?.permanentWarning}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                ) : (
                                  <Callout
                                    state="success"
                                    message={uploadMessages?.[category]?.[documentCode]}
                                  />
                                )}
                              </Box>
                            )
                          })}
                        </Box>
                      </Collapsible>
                    )}
                  </Box>
                )
              })}
            {Object.keys(accountContactDocuments)?.map(
              (category: AccountContactDocumentCategory) => (
                <CategoryDocuments
                  key={category}
                  category={category}
                  documentList={accountContactDocuments?.[category]}
                  fileCount={getCategoryFilesCount(category)}
                  getFileValue={getFileValue}
                  onChooseFile={onChooseFile}
                />
              )
            )}
          </FileUploadsContainer>

          <FormSubmitButton
            loading={isLoadingSubmitRequest}
            disabled={false}
            onClick={submitData}
          />
        </FormBody>
      )}
    </>
  )
}
