import {
  DocumentCategories,
  DocumentNameType,
  Documents,
  PartialUploadMessageList,
} from 'types/verification'
import { cleanBase64String } from 'utils/utils'

export const documentNames = {
  certificateOfIncorporation: 'Certificate of Incorporation',
  registeredBusinessAddress: 'Proof of Business Address',
  shareholderCertificate: 'Shareholder Certificate',
  shareholderStructureChart: 'Shareholder Structure Chart',
  shareholderRegister: 'Shareholder Register',
  sourceOfFunds: 'Proof of Source of Funds',
  proofOfId: 'Proof of ID',
  proofOfAddress: 'Proof of Address',
  operatingLicense: 'Operating Licence',
  memorandumArticlesOfAssociation: 'Memorandum & Articles of Association',
  registerOfDirectors: 'Register of Directors',
  amlPolicy: 'AML Policy',
}

export const owners: Partial<DocumentCategories>[] = [
  'corporateOwners',
  'directors',
  'individualOwners',
]

export const isOwner = (categoryKey: DocumentCategories): boolean => {
  return owners?.includes(categoryKey)
}

export const getDocumentInfo = t => {
  const documents: Partial<Documents> = {
    account: [
      {
        name: 'certificateOfIncorporation',
        title: t('uploadDocuments.titles.yourCertificateOfIncorporation'),
        tooltipContent: {
          title: t('uploadDocsBusinessCertificateTooltip'),
        },
      },
      {
        name: 'proofOfAddress',
        title: t('uploadDocuments.titles.registeredBusinessAddress'),
        permanentWarning: t('uploadDocuments.permanentWarnings.registeredBusinessAddress'),
      },
      {
        name: 'shareholderCertificate',
        title: t('uploadDocuments.titles.shareholderCertificate'),
        tooltipContent: {
          title: t('uploadDocsShareholderCertificateTooltip'),
        },
        permanentWarning: t('uploadDocuments.permanentWarnings.shareholderCertificate'),
      },
      {
        name: 'shareholderStructureChart',
        title: t('uploadDocuments.titles.shareholderStructureChart'),
        tooltipContent: {
          title: t('uploadDocsShareholderStructureChartTooltip'),
        },
        permanentWarning: t('uploadDocuments.permanentWarnings.shareholderStructureChart'),
      },
      {
        name: 'operatingLicense',
        title: t('uploadDocuments.titles.operatingLicense'),
        tooltipContent: {
          title: t('uploadDocsOperatingLicenseTooltip'),
        },
      },
      {
        name: 'memorandumArticlesOfAssociation',
        title: t('uploadDocuments.titles.memorandumArticlesOfAssociation'),
        tooltipContent: {
          title: t('uploadDocsMemorandumArticlesOfAssociationTooltip'),
        },
      },
      {
        name: 'registerOfDirectors',
        title: t('uploadDocuments.titles.registerOfDirectors'),
        tooltipContent: {
          title: t('uploadRegisterOfDirectorsTooltip'),
        },
        permanentWarning: t('uploadDocuments.permanentWarnings.registerOfDirectors'),
      },
      {
        name: 'amlPolicy',
        title: t('uploadDocuments.titles.amlPolicy'),
        tooltipContent: {
          title: t('uploadAmlPolicyTooltip'),
        },
        permanentWarning: t('uploadDocuments.permanentWarnings.amlPolicy'),
      },
    ],
    finance: [
      {
        name: 'sourceOfFunds',
        title: t('uploadDocuments.titles.sourceOfFunds'),
        tooltipContent: {
          title: t('sourceOfFundsInfoBannerContentDescription'),
          bulletList: [
            t('sourceOfFundsInfoBannerContentLine1'),
            t('sourceOfFundsInfoBannerContentLine2'),
            t('sourceOfFundsInfoBannerContentLine3'),
            t('sourceOfFundsInfoBannerContentLine4'),
          ],
        },
      },
    ],
  }

  return documents
}

export const getUploadMessages = t => {
  const uploadMessages: Partial<PartialUploadMessageList> = {
    account: {
      certificateOfIncorporation: t('uploadDocuments.uploaded.certificateOfIncorporation'),
      proofOfAddress: t('uploadDocuments.uploaded.registeredBusinessAddress'),
      shareholderCertificate: t('uploadDocuments.uploaded.shareholderCertificate'),
      shareholderStructureChart: t('uploadDocuments.uploaded.shareholderStructureChart'),
      operatingLicense: t('uploadDocuments.uploaded.operatingLicense'),
      memorandumArticlesOfAssociation: t(
        'uploadDocuments.uploaded.memorandumArticlesOfAssociation'
      ),
      registerOfDirectors: t('uploadDocuments.uploaded.registerOfDirectors'),
      amlPolicy: t('uploadDocuments.uploaded.amlPolicy'),
    },
    finance: {
      sourceOfFunds: t('uploadDocuments.uploaded.sourceOfFunds'),
    },
  }

  return uploadMessages
}

export const getDocumentUploadPayload = (fileName: DocumentNameType, fileBuffer: string) => {
  return {
    code: fileName,
    file: {
      name: documentNames?.[fileName],
      base64: cleanBase64String(fileBuffer as string),
    },
  }
}
