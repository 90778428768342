import { AccountAgreementData } from 'types/accounts'

/**
 * Takes a string and an array of AccountAgreementData objects and returns the agreement object
 * which name value matches the nameQuery value passed in.
 * @returns AccountAgreementData
 */
export const getAgreement = (
  nameQuery: string,
  agreements: AccountAgreementData[]
): AccountAgreementData => {
  const agreementIndex = agreements?.findIndex(agreement => agreement?.name.includes(nameQuery))
  if (agreementIndex === -1) return
  const agreement = agreements?.[agreementIndex]
  return agreement
}

/**
 * Takes an AccountAgreementData object and checks if the agreement description value
 * indicating the country is in the string array for unavailable countries.
 * A true value is returned (is available) if the description value is not in the array.
 * @returns boolean
 */
export const isCountryEmiAvailable = (emiAgreement: AccountAgreementData): boolean => {
  const unavailableCountries = ['Malta']
  const isCountryUnavailable = unavailableCountries.includes(emiAgreement?.description)
  return !isCountryUnavailable
}
