import { Accept } from 'react-dropzone'

type AcceptedDocumentType = {
  type: string
  extensions: string[]
  signatures: string[]
}

export const acceptedDocuments: AcceptedDocumentType[] = [
  {
    type: 'image/png',
    extensions: ['.png'],
    signatures: ['89504E47'],
  },
  {
    type: 'image/jpeg',
    extensions: ['.jpg', '.jpeg'],
    signatures: ['FFD8FFE1', 'FFD8FFE0'],
  },
  {
    type: 'application/pdf',
    extensions: ['.pdf'],
    signatures: ['25504446'],
  },
]

export const dropZoneAcceptedDocuments: Accept = acceptedDocuments.reduce(
  (documents, currentDocument) => {
    documents[currentDocument.type] = currentDocument.extensions
    return documents
  },
  {}
)

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = atob(base64)
  const bytes = new Uint8Array(binaryString?.length)
  for (let i = 0; i < binaryString?.length; i++) {
    bytes[i] = binaryString?.charCodeAt(i)
  }
  return bytes?.buffer
}

export const convertDataUrlToArrayBuffer = async (dataUrl: string): Promise<ArrayBuffer> => {
  try {
    const base64Data: string = dataUrl?.split(',')?.[1]
    const arrayBuffer = base64ToArrayBuffer(base64Data)

    return arrayBuffer
  } catch (error) {
    console.error('Error: convertDataUrlToArrayBuffer', error)
    return undefined
  }
}

export const getMimeTypeFromArrayBuffer = (arrayBuffer: ArrayBuffer): string | null => {
  const uint8arr = new Uint8Array(arrayBuffer)
  const len = 4

  if (uint8arr?.length >= len) {
    const signatureArr = new Array(len)
    for (let i = 0; i < len; i++) {
      signatureArr[i] = uint8arr?.[i]?.toString(16)
    }
    const signature: string = signatureArr?.join('')?.toUpperCase()

    const documentIndex: number = acceptedDocuments?.findIndex(document =>
      document?.signatures?.includes(signature)
    )

    if (documentIndex === -1) return null
    return acceptedDocuments?.[documentIndex]?.type
  }

  return null
}

/**
 * Returns file with valid MIME type PDF
 * @returns File
 */
export const generateValidMimePDF = (fileName: string) => {
  return new File([new Uint8Array([37, 80, 68, 70])], fileName, {
    type: 'application/pdf',
  })
}
