import { useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { DocumentUpload } from '@node-space/storybook-components/dist/UploadFile'
import { logSentryError } from '@node-space/utils'
import { convertDataUrlToArrayBuffer, getMimeTypeFromArrayBuffer } from 'utils/documents'
import { FILE_UPLOAD_LIMIT } from 'utils/utils'

export const MAX_DOCUMENT_UPLOAD = 1

export const useDocumentInput = (
  onChooseFile: (fileData: DocumentUpload) => void,
  onRemoveFile: () => void
) => {
  const { t } = useTranslation()

  const [uploadErrorMessage, setUploadErrorMessage] = useState('')

  const uploadErrorMessages = {
    multipleUploads: t('uploadDocument.multipleUploadsError'),
    fileNotAccepted: t('uploadDocument.uploadRequirementsOnly'),
    fileTooBig: t('uploadDocument.smallerFileRequired'),
    fallback: t('uploadDocument.errorFallback'),
  }

  const handleUploadError = (errorMessage: string) => {
    onRemoveFile()
    setUploadErrorMessage(errorMessage)
  }

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setUploadErrorMessage(null)
    if (fileRejections?.length > MAX_DOCUMENT_UPLOAD) {
      handleUploadError(uploadErrorMessages.multipleUploads)
      return
    }
    if (!acceptedFiles?.length) {
      handleUploadError(uploadErrorMessages.fileNotAccepted)
      return
    }
    acceptedFiles.forEach(acceptedFile => {
      try {
        const fileExtension: string = acceptedFile?.type
        const fileExtensionDisplay: string = fileExtension.split('/')[1].toLocaleUpperCase()
        if (acceptedFile?.size > FILE_UPLOAD_LIMIT) {
          handleUploadError(uploadErrorMessages.fileTooBig)
          return
        }
        const reader = new FileReader()
        reader.onload = async () => {
          const dataUrl = reader?.result as string
          const arrayBuffer: ArrayBuffer = await convertDataUrlToArrayBuffer(dataUrl)
          const mimeType: string = getMimeTypeFromArrayBuffer(arrayBuffer)
          if (!mimeType || mimeType !== fileExtension) {
            handleUploadError(
              t('uploadDocument.mimeTypeError', { extension: fileExtensionDisplay })
            )
            return
          }
          const fileData = { fileData: acceptedFile, buffer: dataUrl }
          onChooseFile(fileData)
        }
        reader.readAsDataURL(acceptedFile)
      } catch (error) {
        logSentryError('uploadFileOnDrop', error)
        handleUploadError(uploadErrorMessages.fallback)
      }
    })
  }

  return { uploadErrorMessage, onDrop }
}
