import React from 'react'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromPhaseOne } from './PhaseOne/FinpromPhaseOne'
import { FinpromPhaseTwo } from './PhaseTwo/FinpromPhaseTwo'

export const Finprom = () => {
  const { isFinpromCountry, isPhaseTwo } = useFinpromContext()

  if (!isFinpromCountry) return <></>

  return isPhaseTwo ? <FinpromPhaseTwo /> : <FinpromPhaseOne />
}
