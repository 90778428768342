import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getAccountAgreements } from 'services/AccountService'

export const useAccountAgreementsQuery = (isEnabled = true) => {
  return useQuery({
    queryKey: reactQueryKeys.accountAgreements(),
    queryFn: getAccountAgreements,
    enabled: isEnabled,
  })
}
