import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { AccountContactSummaryProps } from '../AccountContactSummary'
import { PersonDefaultOptions } from './constants'
import {
  AccountContactData,
  AccountContactType,
  PersonSelectorData,
  PersonSelectorInfo,
} from './types'
import {
  getAccountContactName,
  getInitialMyselfOption,
  getIntialSelectedReference,
  getIsPersonMyself,
  getPersonDisplayTypes,
} from './utils'

export const usePersonSelector = (
  personSelectorData: PersonSelectorData,
  currentContactType: AccountContactType
) => {
  const { t } = useTranslation()
  const { profile } = useProfileContext()

  const [selectedReference, setSelectedReference] = useState<string>()
  const [selectedData, setSelectedData] = useState<AccountContactData>()
  const [myselfData, setMyselfData] = useState<AccountContactData>()
  const [showMyselfOption, setShowMyselfOption] = useState(true)
  const [otherPersons, setOtherPersons] = useState<AccountContactData[]>()

  const personSelectorInfo: PersonSelectorInfo = {
    selectedReference,
    otherPersons,
    showMyselfOption,
  }

  const personSummaryInfo: AccountContactSummaryProps =
    selectedReference !== PersonDefaultOptions.SOMEONE_ELSE
      ? {
          contactFullName: getAccountContactName(selectedData),
          contactDetail: getPersonDisplayTypes(
            personSelectorData?.personTypes,
            selectedData?.person?.reference,
            t
          ),
        }
      : null

  const getSelectedData = () => {
    switch (selectedReference) {
      case PersonDefaultOptions.MYSELF:
        return myselfData
      case PersonDefaultOptions.SOMEONE_ELSE:
        return null
      default:
        return personSelectorData?.personsData?.find(
          contact => contact?.reference === selectedReference
        )
    }
  }

  const loadInitialData = () => {
    const savedMyselfData = personSelectorData?.personsData?.find(({ person }) =>
      getIsPersonMyself(person, profile)
    )
    setMyselfData(savedMyselfData ?? getInitialMyselfOption(profile))
    let isMyselfAlreadyAdded = false
    const otherPersonsData = personSelectorData?.personsData?.filter(contact => {
      const personRef = contact?.person?.reference
      const personContactTypes = personSelectorData?.personTypes?.[personRef]
      const isCurrentContactType = personContactTypes?.includes(currentContactType)
      const isMyself = !!savedMyselfData && personRef === savedMyselfData?.person?.reference
      if (isMyself && isCurrentContactType) {
        isMyselfAlreadyAdded = true
      }
      return !isMyself && !isCurrentContactType
    })
    const initialReference = getIntialSelectedReference({
      otherPersons: otherPersonsData,
      profile,
      showMyselfOption: !isMyselfAlreadyAdded,
    })
    setShowMyselfOption(!isMyselfAlreadyAdded)
    setSelectedReference(initialReference)
    setOtherPersons(otherPersonsData)
  }

  const onPersonChange = (updatedReference: string) => {
    setSelectedReference(updatedReference)
  }

  useEffect(() => {
    loadInitialData()
  }, [])

  useEffect(() => {
    const updatedPersonData = getSelectedData()
    setSelectedData(updatedPersonData)
  }, [selectedReference])

  return {
    selectedData,
    personSelectorInfo,
    personSummaryInfo,
    onPersonChange,
  }
}
