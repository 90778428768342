import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getSourceOfFunds } from 'services/VerificationService'

export const useSourceOfFundsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.sourceOfFunds(),
    queryFn: getSourceOfFunds,
  })
}
