import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { Description } from 'components/Text'
import { FormGetError } from 'pages/Verification/_components/FormGetError'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { CategoryDocuments } from './components/CategoryDocuments'
import { DocumentCategoryKey } from './helpers/types'
import { useUploadDocuments } from './helpers/useUploadDocuments'

export const UploadDocuments = () => {
  const { t } = useTranslation()
  const {
    isFetchingRequiredDocuments,
    isErrorFetchingRequiredDocuments,
    requiredDocuments,
    requiredCategories,
    uploadedDocuments,
    documentActions,
  } = useUploadDocuments()

  return (
    <>
      <VerificationHeader header={t('verification.routes.businessDocuments')} />
      {isFetchingRequiredDocuments ? (
        <SkeletonForm name="Upload documents" />
      ) : isErrorFetchingRequiredDocuments ? (
        <FormGetError />
      ) : (
        <Box paddingT={24}>
          {!!requiredCategories?.length ? (
            requiredCategories?.map((category: DocumentCategoryKey) => (
              <CategoryDocuments
                key={category}
                category={category}
                requiredDocuments={requiredDocuments?.[category]}
                uploadedDocuments={uploadedDocuments?.filter(
                  ({ documentId }) => documentId?.category === category
                )}
                documentActions={documentActions}
              />
            ))
          ) : (
            <Description>{t('uploadDocuments.requiredDocumentsEmpty')}</Description>
          )}
        </Box>
      )}
    </>
  )
}
