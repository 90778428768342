import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { YesNoValues } from '../constants'

export type SophisticatedFormData = {
  isCertificateReceived: string
  authorisedFirm: string
  isRiskAccepted: boolean
}

export type SophisticatedFormKeys = keyof SophisticatedFormData

export const SophisticatedSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField, getConditionalYupField, getRequiredCheckboxYupField } =
    useYupFields()

  return yup.object<Partial<Record<SophisticatedFormKeys, yup.AnySchema>>>({
    isCertificateReceived: getRequiredYupField({
      requiredErrorMessage: t('emptySelect'),
      label: '',
    }),
    authorisedFirm: getConditionalYupField<SophisticatedFormKeys>({
      fieldKey: 'isCertificateReceived',
      fieldValue: YesNoValues.YES,
      label: t('finprom.selfCategorisation.sophisticatedForm.questionAFirmLabel'),
    }),
    isRiskAccepted: getRequiredCheckboxYupField(
      t('finprom.selfCategorisation.sophisticatedForm.acceptCheckboxLabel')
    ),
  })
}
