import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { YesNoValues } from '../constants'

export type HighNetWorthFormData = {
  isAnnualIncomeMore: string
  annualIncomeValue: string
  isNetAssetsMore: string
  netAssetsValue: string
  isRiskAccepted: boolean
}

export type HighNetWorthFormKeys = keyof HighNetWorthFormData

export const HighNetWorthSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField, getConditionalYupField, getRequiredCheckboxYupField } =
    useYupFields()

  return yup.object<Partial<Record<HighNetWorthFormKeys, yup.AnySchema>>>({
    isAnnualIncomeMore: getRequiredYupField({
      label: t('finprom.selfCategorisation.highNetWorthForm.questionALabel'),
      requiredErrorMessage: t('emptySelect'),
    }),
    annualIncomeValue: getConditionalYupField<HighNetWorthFormKeys>({
      fieldKey: 'isAnnualIncomeMore',
      fieldValue: YesNoValues.YES,
      label: t('finprom.selfCategorisation.highNetWorthForm.questionAIncomeLabel'),
    }),
    isNetAssetsMore: getRequiredYupField({
      label: t('finprom.selfCategorisation.highNetWorthForm.questionBLabel'),
      requiredErrorMessage: t('emptySelect'),
    }),
    netAssetsValue: getConditionalYupField<HighNetWorthFormKeys>({
      fieldKey: 'isNetAssetsMore',
      fieldValue: YesNoValues.YES,
      label: t('finprom.selfCategorisation.highNetWorthForm.questionBAssetsLabel'),
    }),
    isRiskAccepted: getRequiredCheckboxYupField(
      t('finprom.selfCategorisation.highNetWorthForm.acceptCheckboxLabel')
    ),
  })
}
