import React, { useEffect } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFeatureFlags } from '@node-space/hooks'
import { Box as FormFields, Box as TooltipLabel } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { Option, Select } from '@node-space/storybook-components/dist/Select'
import { SelectGroup } from '@node-space/storybook-components/dist/SelectGroup'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useBusinessDetailsMutation } from 'hooks/mutations/useBusinessDetailsMutation'
import { useBusinessDetailsQuery } from 'hooks/queries/useBusinessDetailsQuery'
import { useIndustryOptions } from 'hooks/useIndustryOptions'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { BaseErrorResponse } from 'types/errors'
import { BusinessDetailsPayload } from 'types/verification'
import { FormBody } from '../_components/FormBody'
import { FormGetError } from '../_components/FormGetError'
import { FormSubmitButton } from '../_components/FormSubmitButton'
import { FormSubmitError } from '../_components/FormSubmitError'
import {
  BusinessDetailsFormData,
  BusinessDetailsFormKeys,
  BusinessDetailsSchema,
} from '../schemas/businessDetailsSchema'
import { BusinessDetailsHeader } from './BusinessDetailsHeader'

export const BusinessDetails = () => {
  const { t } = useTranslation()
  const { enableSubIndustry } = useFeatureFlags()

  const industryOptions = useIndustryOptions(enableSubIndustry)

  const isOperationLicenseRequiredOptions: Option[] = [
    { value: 'yes', label: t('yes') },
    { value: 'no', label: t('no') },
  ]

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { setUnsavedChanges, submitStep } = useVerificationContext()

  const {
    data: businessDetails,
    isFetching: isFetchingBusinessDetails,
    isError: isGetError,
  } = useBusinessDetailsQuery()

  const { mutate: putBusinessDetails, isPending: isLoadingSubmitRequest } =
    useBusinessDetailsMutation()

  const defaultValues: BusinessDetailsFormData = {
    legalEntityName: '',
    legalEntityRegistrationNumber: '',
    incomeTaxNumber: '',
    industryId: '',
    isOperationLicenseRequired: '',
    operatingLicenseType: '',
    operatingLicenseIssuer: '',
  }

  const formSchema = BusinessDetailsSchema(t, enableSubIndustry)

  const form = useForm<BusinessDetailsFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    setValue,
    reset,
    setError,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = form

  const formValues = useWatch({ control })

  const { setInputField, setSelectField, setRadioField } = useFormFields<BusinessDetailsFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: { [key in BusinessDetailsFormKeys] } = {
    legalEntityName: setInputField({ key: 'legalEntityName' }),
    legalEntityRegistrationNumber: setInputField({ key: 'legalEntityRegistrationNumber' }),
    incomeTaxNumber: setInputField({ key: 'incomeTaxNumber' }),
    industryId: setSelectField({
      key: 'industryId',
      placeholder: t('verification.chooseIndustry'),
      options: industryOptions,
    }),
    isOperationLicenseRequired: setRadioField({
      key: 'isOperationLicenseRequired',
      options: isOperationLicenseRequiredOptions,
      onChangeAdditional: value => {
        if (value === 'no') {
          clearErrors('operatingLicenseType')
          clearErrors('operatingLicenseIssuer')
        }
      },
    }),
    operatingLicenseType: setInputField({ key: 'operatingLicenseType' }),
    operatingLicenseIssuer: setInputField({ key: 'operatingLicenseIssuer' }),
  }

  useEffect(() => {
    const keys: BusinessDetailsFormKeys[] = [
      'legalEntityName',
      'legalEntityRegistrationNumber',
      'incomeTaxNumber',
      'industryId',
      'operatingLicenseType',
      'operatingLicenseIssuer',
    ]
    keys.forEach(key => {
      if (businessDetails?.[key]) {
        let value = businessDetails?.[key]?.toLocaleString()
        if (enableSubIndustry && key === 'industryId') {
          value = businessDetails?.['industryReference']?.toString()
        }
        setValue(key, value)
      }
    })
    if (typeof businessDetails?.isOperationLicenseRequired === 'boolean') {
      setValue(
        'isOperationLicenseRequired',
        !!businessDetails?.isOperationLicenseRequired ? 'yes' : 'no'
      )
    }
    reset({}, { keepValues: true })
  }, [businessDetails, enableSubIndustry])

  useEffect(() => {
    setUnsavedChanges(isDirty)
  }, [isDirty])

  const getIsHandledError = error => {
    let isHandledError = false
    if (error?.status === 409) {
      setError(
        'legalEntityName',
        { type: 'focus', message: t('verification.legalEntityNameError') },
        { shouldFocus: true }
      )
      isHandledError = true
    }
    return isHandledError
  }

  const onSubmit: SubmitHandler<typeof formValues> = ({
    legalEntityName,
    legalEntityRegistrationNumber,
    incomeTaxNumber,
    industryId,
    isOperationLicenseRequired,
    operatingLicenseType,
    operatingLicenseIssuer,
  }: BusinessDetailsFormData) => {
    if (!isDirty) {
      submitStep(VerificationTrackingLabels.BUSINESS_DETAILS)
      return
    }
    resetRequestError()

    const data: BusinessDetailsPayload = {
      legalEntityName,
      legalEntityRegistrationNumber,
      incomeTaxNumber: incomeTaxNumber ?? '',
      ...(!enableSubIndustry
        ? { industryId: parseInt(industryId) }
        : { industryReference: industryId }),
      isOperationLicenseRequired: isOperationLicenseRequired === 'yes',
      operatingLicenseType: operatingLicenseType ?? '',
      operatingLicenseIssuer: operatingLicenseIssuer ?? '',
    }

    putBusinessDetails(data, {
      onSuccess: () => {
        submitStep(VerificationTrackingLabels.BUSINESS_DETAILS)
      },
      onError: (error: BaseErrorResponse) => {
        if (getIsHandledError(error)) return
        const sentryTraceId = logSentryErrorAndGetTraceId('putBusinessDetails', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  const filterOption = ({ label, value }, input) => {
    const search = input?.toLowerCase()
    const OptionLabel = label?.toLowerCase()

    if (OptionLabel?.includes(search)) return true

    const groupOptions = industryOptions?.filter(group =>
      group?.label?.toLowerCase()?.includes(search)
    )

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        const option = groupOption?.options?.find(opt => opt?.value === value)
        if (option) {
          return true
        }
      }
    }
    return false
  }

  return (
    <>
      <BusinessDetailsHeader />
      {isFetchingBusinessDetails ? (
        <SkeletonForm name="Business details" />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <FormBody>
            {requestError?.show && (
              <FormSubmitError
                errorCode={requestError?.errorCode}
                sentryTraceId={requestError?.sentryTraceId}
              />
            )}

            <FormFields flex direction="col" gapY={24}>
              <Input
                {...formFields.legalEntityName}
                label={
                  <TooltipLabel flex alignItems="center" gapX={4}>
                    <span>{formFields.legalEntityName.label}</span>
                    <Tooltip
                      id="tooltip-legal-entity-name"
                      position="right"
                      hasMaxWidth
                      bodyContent={`${t('verification.legalEntityNameNote')}`}
                    >
                      <Icon name="InfoCircleIcon" size="sm" />
                    </Tooltip>
                  </TooltipLabel>
                }
                bottomLeftLabel={t('verification.legalEntityNameHelp')}
              />
              <Input
                {...formFields.legalEntityRegistrationNumber}
                bottomLeftLabel={t('verification.registrationNumberHelp')}
              />
              <Input
                {...formFields.incomeTaxNumber}
                label={
                  <>
                    {formFields.incomeTaxNumber.label}
                    <Text tag="span" color="disabled" size="sm" className="pl-1">
                      {t('ifApplicable')}
                    </Text>
                  </>
                }
              />
              {!enableSubIndustry ? (
                <Select
                  {...formFields.industryId}
                  isSearchable
                  iconLeft={<Icon name="SearchIcon" />}
                />
              ) : (
                <SelectGroup
                  {...formFields.industryId}
                  isSearchable
                  iconLeft={<Icon name="SearchIcon" />}
                  customFilter={filterOption}
                />
              )}

              <Radio {...formFields.isOperationLicenseRequired} horizontal borderless={false} />

              {formValues.isOperationLicenseRequired === 'yes' && (
                <>
                  <Input {...formFields.operatingLicenseType}></Input>
                  <Input {...formFields.operatingLicenseIssuer}></Input>
                </>
              )}
            </FormFields>

            <FormSubmitButton
              loading={isSubmitting || isLoadingSubmitRequest}
              disabled={!!Object.keys(errors)?.length}
            />
          </FormBody>
        </form>
      )}
    </>
  )
}
