import { useMemo } from 'react'
import { isBoolean } from 'lodash'
import { useFeatureFlags } from '@node-space/hooks'

export interface FinpromFeature {
  isFetchedFeatureFlags: boolean
  isEnabled: boolean
  isPhaseTwo: boolean
}

export const useFinpromFeature = (): FinpromFeature => {
  const { enableUkInfoBanner, enableFinpromQuestionnaires } = useFeatureFlags()

  const isFetchedFeatureFlags = useMemo(
    () => isBoolean(enableUkInfoBanner) && isBoolean(enableFinpromQuestionnaires),
    [enableUkInfoBanner, enableFinpromQuestionnaires]
  )

  return {
    isFetchedFeatureFlags,
    isEnabled: !!enableUkInfoBanner,
    isPhaseTwo: !!enableFinpromQuestionnaires,
  }
}
