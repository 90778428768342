export const DocumentCategories = {
  BUSINESS_INFORMATION: 'businessInformation',
  FINANCIAL_DOCUMENTS: 'financialDocuments',
  INDIVIDUALS: 'individuals',
  CORPORATE_OWNERS: 'corporateOwners',
  INDUSTRY_DOCUMENTS: 'industryDocuments',
} as const
export const DocumentCategoryList = Object.values(DocumentCategories)

export const BusinessInformationDocumentCodes = {
  CERTIFICATE_OF_INCORPORATION: 'certificateOfIncorporation',
  SHAREHOLDER_CERTIFICATE: 'shareholderCertificate',
  SHAREHOLDER_STRUCTURE_CHART: 'shareholderStructureChart',
  OPERATING_LICENSE: 'operatingLicense',
  PROOF_OF_ADDRESS: 'proofOfAddress',
  MEMORANDUM_ARTICLES_OF_ASSOCIATION: 'memorandumArticlesOfAssociation',
  REGISTER_OF_DIRECTORS: 'registerOfDirectors',
  AML_POLICY: 'amlPolicy',
} as const
export const BusinessInformationDocumentCodeList = Object.values(BusinessInformationDocumentCodes)

export const FinancialDocumentCodes = {
  SOURCE_OF_FUNDS: 'sourceOfFunds',
} as const
export const FinancialDocumentCodeList = Object.values(FinancialDocumentCodes)

export const IndividualDocumentCodes = {
  PROOF_OF_ID: 'proofOfId',
  PROOF_OF_ADDRESS: 'proofOfAddress',
} as const
export const IndividualDocumentCodeList = Object.values(IndividualDocumentCodes)

export const CorporateOwnerDocumentCodes = {
  CERTIFICATE_OF_INCORPORATION: 'certificateOfIncorporation',
  SHAREHOLDER_REGISTER: 'shareholderRegister',
} as const
export const CorporateOwnerDocumentCodeList = Object.values(CorporateOwnerDocumentCodes)

export const AllDocumentCodes = {
  ...BusinessInformationDocumentCodes,
  ...FinancialDocumentCodes,
  ...IndividualDocumentCodes,
  ...CorporateOwnerDocumentCodes,
}
