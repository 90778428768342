import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useToastContext } from 'hooks/useToastContext'
import { postBusinessDocuments, postBusinessDocumentsQueue } from 'services/VerificationService'
import { BusinessDocumentsPayload } from 'types/verification'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'

export const useBusinessDocumentsMutation = (isQueued = false) => {
  const { t } = useTranslation()
  const addToast = useToastContext()
  const queryClient = useQueryClient()

  const successToast = () =>
    addToast({ title: t('uploadDocument.fileUploadSuccess'), state: 'success', delay: 5 })
  const errorToast = () =>
    addToast({ title: t('uploadDocument.fileUploadError'), state: 'error', delay: 5 })

  return useMutation({
    ...(!isQueued && {
      mutationFn: postBusinessDocuments,
    }),
    ...(isQueued && {
      mutationFn: (data: BusinessDocumentsPayload) =>
        postBusinessDocumentsQueue(data, successToast, errorToast),
      onError: async () => {
        await queryClient.invalidateQueries({
          queryKey: reactQueryKeys.requiredDocuments(),
        })
      },
    }),
  })
}
