import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  Box,
  Box as DropdownContainer,
  Box as ListContainer,
  Box as MenuDivider,
  Box as MenuDropdown,
  Box as MenuList,
  Box as MenuPanel,
} from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { StepState, VerificationRouteStatus } from 'constants/verification'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromVerificationStep, FinpromVerificationStepKey } from 'pages/Finprom/types'
import { MenuItemType, VerificationRouteKey, VerificationStep } from 'types/verification'
import { getStepState } from 'utils/verification'
import { RouteLink } from './RouteLink'

interface VerificationMenuProps {
  activeStep: VerificationRouteKey
  steps: VerificationStep[]
  finpromSteps: FinpromVerificationStep[]
  onClick: (newStep: VerificationRouteKey) => void
  onClickFinpromStep: (newStep: FinpromVerificationStepKey) => void
}

const MENU_ID = 'verificationMenu'

export const VerificationMenu = ({
  activeStep,
  steps,
  finpromSteps,
  onClick,
  onClickFinpromStep,
}: VerificationMenuProps) => {
  const { t } = useTranslation()
  const { activeStep: finpromActiveStep } = useFinpromContext()

  const [isOpen, setIsOpen] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => setHasScrolled(window.pageYOffset > 0)
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [isOpen])

  useEffect(() => {
    const handleClose = event => {
      const isMenu = !!event?.target?.closest(`#${MENU_ID}`)
      if (isOpen && !isMenu) setIsOpen(false)
    }
    document.addEventListener('click', handleClose)

    return () => {
      document.removeEventListener('click', handleClose)
    }
  }, [isOpen])

  const menuPanelClass = clsx(
    'fixed md:relative z-10 md:top-0 md:w-[300px] md:border-solid md:border-gray-03 md:border-r',
    hasScrolled && 'shadow-menu md:shadow-none transition-shadow duration-500'
  )

  const dropdownContainerClass = clsx('h-header md:hidden sm:px-6')

  const listContainerClass = clsx(
    'absolute md:static z-10 top-[65px] sm:px-6 md:px-0',
    !isOpen && 'hidden md:flex'
  )

  const menuListClass = clsx(
    'md:px-6 md:py-12 md:bg-transparent md:rounded-none shadow-menu md:shadow-none max-h-verificationMenuMobile md:max-h-none overflow-auto'
  )

  const hasFinpromSteps = useMemo(() => !!finpromSteps?.length, [finpromSteps?.length])

  const hasIncompleteFinpromSteps = useMemo(
    () =>
      !!finpromSteps?.filter(({ status }) => status !== VerificationRouteStatus.COMPLETED)?.length,
    [finpromSteps]
  )

  const activeMenuItemKey = useMemo(
    () => finpromActiveStep ?? activeStep,
    [finpromActiveStep, activeStep]
  )

  const menuItems: MenuItemType[] = steps?.map((step: VerificationStep) => ({
    key: step?.key,
    title: t(`verification.routes.${step?.key}`),
    state: getStepState(step, activeMenuItemKey),
    onClick: () => onClick(step?.key),
  }))

  const finpromMenuItems: MenuItemType[] = finpromSteps?.map((step: FinpromVerificationStep) => ({
    key: step?.key,
    title: t(`finprom.steps.${step?.key}`),
    state: getStepState(step, activeMenuItemKey),
    onClick: () => onClickFinpromStep(step?.key),
  }))

  const activeMenuItem: MenuItemType = [...finpromMenuItems, ...menuItems]?.find(
    (step: MenuItemType) => step?.state === StepState?.IN_PROGRESS
  )

  return (
    <>
      <MenuPanel background="off-white" width="full" className={menuPanelClass} id={MENU_ID}>
        <DropdownContainer
          flex
          width="full"
          alignItems="center"
          paddingX={16}
          divider="bottom"
          className={dropdownContainerClass}
        >
          <MenuDropdown
            tag="button"
            background="white"
            borderRadius={4}
            border={isOpen ? 'primary' : 'gray'}
            width="full"
            paddingX={16}
            height={40}
            flex
            justifyContent="between"
            alignItems="center"
            onClick={() => setIsOpen(current => !current)}
            aria-label="verificationMenuDropdown"
          >
            <Text tag="span" size="sm">
              {activeMenuItem?.title}
            </Text>
            <Box className="pointer-events-none">
              <Icon name={isOpen ? 'CaretUpIcon' : 'CaretDownIcon'} />
            </Box>
          </MenuDropdown>
        </DropdownContainer>
        <ListContainer
          flex
          width="full"
          paddingX={16}
          aria-label="verificationMenuList"
          className={listContainerClass}
        >
          <MenuList
            flex
            width="full"
            direction="col"
            background="white"
            borderRadius={8}
            padding={20}
            gapY={16}
            className={menuListClass}
          >
            {hasFinpromSteps && (
              <>
                {finpromMenuItems?.map(({ key, title, state, onClick }, stepIndex) => {
                  const previousStepStatus = finpromSteps?.[stepIndex - 1]?.status
                  const isDisabled =
                    state === StepState.UNTOUCHED &&
                    previousStepStatus !== VerificationRouteStatus.COMPLETED
                  return (
                    <RouteLink
                      key={key}
                      title={title}
                      state={state}
                      disabled={isDisabled}
                      onClick={() => {
                        onClick()
                        setIsOpen(false)
                      }}
                    />
                  )
                })}
                <MenuDivider paddingX={32} paddingY={8}>
                  <Box divider="top" />
                </MenuDivider>
              </>
            )}

            {menuItems?.map(({ key, title, state, onClick }) => (
              <RouteLink
                key={key}
                title={title}
                state={state}
                disabled={hasIncompleteFinpromSteps}
                onClick={() => {
                  onClick()
                  setIsOpen(false)
                }}
              />
            ))}
          </MenuList>
        </ListContainer>
      </MenuPanel>
      <Box className="h-header" />
    </>
  )
}
