import React, { useEffect, useState } from 'react'
import Box from '@node-space/storybook-components/dist/Box'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import { ErrorText } from '@node-space/storybook-components/dist/FormElements'
import { Container } from 'components/Container'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import { useFinpromAppropriatenessForm } from 'pages/Finprom/hooks/useFinpromAppropriatenessForm'
import { AppropriatenessQuestionOption } from 'pages/Finprom/types'
import { getIsAnswersCheckedCorrect } from 'pages/Finprom/utils'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { convertArrayToCommaString, convertCommaStringToArray, toggleArrayValue } from 'utils/utils'

export const CryptoAssetServicesOptions = {
  NOT_BACKED: 'notBacked',
  NOT_UNIVERSALLY_ACCEPTED: 'notUniversallyAccepted',
  NOT_PROTECTED: 'notProtected',
  LIKE_SHARES_INVESTMENT: 'likeSharesInvestment',
} as const

type CryptoAssetServicesOption =
  (typeof CryptoAssetServicesOptions)[keyof typeof CryptoAssetServicesOptions]

const cryptoAssetServicesOptionList = Object.values(CryptoAssetServicesOptions)
const cryptoAssetServicesCorrectAnswers: CryptoAssetServicesOption[] = [
  CryptoAssetServicesOptions.NOT_BACKED,
  CryptoAssetServicesOptions.NOT_UNIVERSALLY_ACCEPTED,
  CryptoAssetServicesOptions.NOT_PROTECTED,
]

export const StepCryptoAssetServices = () => {
  const {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  } = useFinpromAppropriatenessForm()

  const [checkedAnswers, setCheckedAnswers] = useState<CryptoAssetServicesOption[]>(
    convertCommaStringToArray<CryptoAssetServicesOption>(questionAnswer)
  )

  const cryptoAssetServicesOptions = getQuestionOptions(cryptoAssetServicesOptionList)

  const handleOptionChange = (option: CryptoAssetServicesOption) => {
    setCheckedAnswers(currentOptions => toggleArrayValue(currentOptions, option))
  }

  const handleSubmit = () => {
    const isCorrectAnswer = getIsAnswersCheckedCorrect(
      cryptoAssetServicesCorrectAnswers,
      checkedAnswers
    )
    onSubmitQuestion(
      isCorrectAnswer,
      FinpromTrackingLabels.APPROPRIATENESS_STEP_CRYPTO_ASSET_SERVICES
    )
  }

  useEffect(() => {
    const answerFormatted = convertArrayToCommaString(checkedAnswers)
    handleSelectAnswer(answerFormatted)
  }, [checkedAnswers])

  return (
    <Container>
      {cryptoAssetServicesOptions?.map(
        ({ value, secondaryLabel }: AppropriatenessQuestionOption<CryptoAssetServicesOption>) => (
          <Box key={value} border="gray" borderRadius={4} padding={12}>
            <CheckBox
              label={secondaryLabel}
              value={checkedAnswers?.includes(value)}
              onChange={() => handleOptionChange(value)}
              labelColour="light"
            />
          </Box>
        )
      )}
      {isQuestionError && <ErrorText text={questionErrorText} />}
      {isErrorContinue && <FinpromSubmitError />}
      <FormSubmitButton
        disabled={isQuestionError}
        loading={isLoadingContinue}
        onClick={handleSubmit}
      />
    </Container>
  )
}
