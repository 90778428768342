export const VerificationRequestStatus = {
  NOT_SUBMITTED: 'businessVerificationNotSubmitted',
  PENDING: 'businessVerificationPending',
  VERIFIED: 'businessVerified',
} as const

export const VerificationRoutes = {
  GET_STARTED: 'getStarted',
  BUSINESS_DETAILS: 'businessDetails',
  SOURCE_OF_FUNDS: 'sourceOfFunds',
  BUSINESS_ACTIVITY: 'businessActivity',
  BUSINESS_ADDRESS: 'businessAddress',
  BUSINESS_OWNERS: 'businessOwners',
  BUSINESS_DIRECTORS: 'businessDirectors',
  ACCOUNT_REPRESENTATIVE: 'accountRepresentative',
  UPLOAD_DOCUMENTS: 'businessDocuments',
  REVIEW_AND_FINISH: 'businessAgreements',
} as const

export const VerificationTrackingLabels = {
  GET_STARTED: 'Get started',
  BUSINESS_DETAILS: 'Business details',
  SOURCE_OF_FUNDS: 'Source of funds',
  BUSINESS_ACTIVITY: 'Business activity',
  BUSINESS_ADDRESS: 'Business address',
  BUSINESS_OWNERS: 'Business owners',
  BUSINESS_DIRECTORS: 'Business directors',
  ACCOUNT_REPRESENTATIVE: 'Account representative',
  UPLOAD_DOCUMENTS: 'Upload document',
  REVIEW_AND_FINISH: 'Review and finish',
} as const

export const VerificationRouteList = Object.values(VerificationRoutes)

export const VerificationRouteStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
} as const

export const StepState = {
  UNTOUCHED: 'untouched',
  IN_PROGRESS: 'in progress',
  COMPLETED: 'completed',
} as const
