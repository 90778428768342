import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getEntityTypes } from 'services/VerificationService'
import { ONE_DAY } from 'utils/utils'

export const useBusinessEntitiesQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.businessEntities(),
    queryFn: getEntityTypes,
    staleTime: ONE_DAY,
  })
}
