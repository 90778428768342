import React from 'react'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import { useFinpromAppropriatenessForm } from 'pages/Finprom/hooks/useFinpromAppropriatenessForm'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

export const DueDiligenceOptions = {
  NONE: 'none',
  BASIC: 'basic',
  DONE_BY_FCA: 'doneByFca',
} as const

type DueDiligenceOption = (typeof DueDiligenceOptions)[keyof typeof DueDiligenceOptions]

const dueDiligenceOptionList = Object.values(DueDiligenceOptions)
const dueDiligenceCorrectAnswer: DueDiligenceOption = DueDiligenceOptions.BASIC

export const StepDueDiligence = () => {
  const {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  } = useFinpromAppropriatenessForm<DueDiligenceOption>()

  const dueDiligenceOptions = getQuestionOptions(dueDiligenceOptionList)

  const handleSubmit = () => {
    const isCorrectAnswer = questionAnswer === dueDiligenceCorrectAnswer
    onSubmitQuestion(isCorrectAnswer, FinpromTrackingLabels.APPROPRIATENESS_STEP_DUE_DILIGENCE)
  }

  return (
    <Container>
      <Radio
        value={questionAnswer}
        options={dueDiligenceOptions}
        onChange={handleSelectAnswer}
        error={isQuestionError}
        errorText={questionErrorText}
        hasParagraphLayout
      />
      {isErrorContinue && <FinpromSubmitError />}
      <FormSubmitButton
        disabled={isQuestionError}
        loading={isLoadingContinue}
        onClick={handleSubmit}
      />
    </Container>
  )
}
