import React from 'react'
import { CSATIconType } from '../CSATIcon'

export const IconGoodSelected = ({ size }: CSATIconType) => {
  return (
    <svg
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28.5C22.6274 28.5 28 23.1274 28 16.5C28 9.87258 22.6274 4.5 16 4.5C9.37258 4.5 4 9.87258 4 16.5C4 23.1274 9.37258 28.5 16 28.5Z"
        fill="#E2F9F5"
        stroke="#3BDABA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 15.25C12.1904 15.25 12.75 14.6904 12.75 14C12.75 13.3096 12.1904 12.75 11.5 12.75C10.8096 12.75 10.25 13.3096 10.25 14C10.25 14.6904 10.8096 15.25 11.5 15.25Z"
        fill="#3BDABA"
      />
      <path
        d="M20.5 15.25C21.1904 15.25 21.75 14.6904 21.75 14C21.75 13.3096 21.1904 12.75 20.5 12.75C19.8096 12.75 19.25 13.3096 19.25 14C19.25 14.6904 19.8096 15.25 20.5 15.25Z"
        fill="#3BDABA"
      />
      <path
        d="M21.2008 19.5C20.6721 20.4107 19.9136 21.1667 19.001 21.6922C18.0884 22.2176 17.0538 22.4942 16.0008 22.4942C14.9477 22.4942 13.9131 22.2176 13.0006 21.6922C12.088 21.1667 11.3294 20.4107 10.8008 19.5"
        stroke="#3BDABA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
