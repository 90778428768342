import React from 'react'
import { SelfCategorisationStepKeys } from 'pages/Finprom/constants'
import { SelfCategorisationCategory, SelfCategorisationStepKey } from 'pages/Finprom/types'
import { StepConfirmCategory } from './steps/StepConfirmCategory'
import { StepSelectCategory } from './steps/StepSelectCategory'

export interface SelfCategorisationStepProps {
  activeStep: SelfCategorisationStepKey
  selectedCategory: SelfCategorisationCategory
  setSelectedCategory: React.Dispatch<React.SetStateAction<SelfCategorisationCategory>>
  onContinue: () => void
}

export const SelfCategorisationStep = ({
  activeStep,
  selectedCategory,
  setSelectedCategory,
  onContinue,
}: SelfCategorisationStepProps) => {
  switch (activeStep) {
    case SelfCategorisationStepKeys.SELECT_CATEGORY:
      return (
        <StepSelectCategory
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          onContinue={onContinue}
        />
      )
    default:
      return <StepConfirmCategory selectedCategory={selectedCategory} />
  }
}
