import React from 'react'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { finpromAppropriatenessTestPreferenceKey } from 'pages/Finprom/finpromPreferenceData'
import { AppropriatenessResultPage } from './AppropriatenessResultPage'
import { AppropriatenessSteps } from './AppropriatenessSteps'

export const Appropriateness = () => {
  const { accountPreferences } = useFinpromContext() || {}
  const appropriatenessData = accountPreferences?.[finpromAppropriatenessTestPreferenceKey]
  const showResultPage = !!appropriatenessData?.isComplete

  return showResultPage ? <AppropriatenessResultPage /> : <AppropriatenessSteps />
}
