import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import Button from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Description } from 'components/Text'
import { NotificationCard } from './NotificationCard/NotificationCard'

export const PendingNotification = () => {
  const { t } = useTranslation()

  return (
    <NotificationCard
      title={t('verification.pendingHeading')}
      iconBackground={'info'}
      iconComponent={<Icon name="ClockCounterClockwiseIcon" color="secondary-500" size="lg" />}
    >
      <Description>{t('verification.pendingSubHeading1')}</Description>
      <Description>{t('verification.pendingSubHeading2')}</Description>
      <Box>
        <Button
          onClick={() => window.open(process.env.SUPPORT_URL)}
          iconElement={<Icon name="ExternalLinkIcon" color="primary-500" />}
          className="mt-1"
          secondary
        >
          {t('contactSupport')}
        </Button>
      </Box>
    </NotificationCard>
  )
}
