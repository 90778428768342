import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { useSourceOfFundsQuery } from 'hooks/queries/useSourceOfFundsQuery'
import { FormGetError } from '../_components/FormGetError'
import { VerificationHeader } from '../_components/VerificationHeader'
import { SourceOfFundsFormData } from '../schemas/sourceOfFundsSchema'
import { SourceOfFundsForm } from './SourceOfFundsForm'

export const SourceOfFunds = () => {
  const { t } = useTranslation()

  const sourceOfFundsDefaultData: SourceOfFundsFormData = {
    currencyType: '',
    sourceOfFundsDescription: '',
    cryptoAddress: '',
  }

  const {
    data: loadedSourceOfFundsData,
    isFetching: isFetchingSourceOfFunds,
    isError: isGetSourceOfFundsError,
  } = useSourceOfFundsQuery()

  const [sourceOfFundsData, setSourceOfFundsData] =
    useState<SourceOfFundsFormData>(sourceOfFundsDefaultData)
  const [isFormPopulated, setIsFormPopulated] = useState<boolean>(false)

  const isLoadingForm = useMemo(
    () => isFetchingSourceOfFunds || !isFormPopulated,
    [isFetchingSourceOfFunds, isFormPopulated]
  )

  useEffect(() => {
    if (!isFetchingSourceOfFunds) {
      setSourceOfFundsData({
        currencyType:
          !loadedSourceOfFundsData?.currencyType || loadedSourceOfFundsData?.currencyType === 0
            ? null
            : `${loadedSourceOfFundsData?.currencyType}`,
        sourceOfFundsDescription: loadedSourceOfFundsData?.sourceOfFundsDescription ?? '',
        cryptoAddress: loadedSourceOfFundsData?.cryptoAddress ?? '',
      })
      setIsFormPopulated(true)
    }
  }, [isFetchingSourceOfFunds, loadedSourceOfFundsData])

  return (
    <div>
      <VerificationHeader
        header={t('sourceOfFunds')}
        subHeader={t('verification.sourceOfFunds.introduction')}
      />
      {isLoadingForm ? (
        <SkeletonForm name="Source of funds" />
      ) : isGetSourceOfFundsError ? (
        <FormGetError />
      ) : (
        <SourceOfFundsForm defaultValues={sourceOfFundsData} />
      )}
    </div>
  )
}

export default SourceOfFunds
