import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Description } from 'components/Text'
import { DescriptionProps } from 'components/Text/Description'
import { SupportEmail } from 'constants/General'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromResultModal } from 'pages/Finprom/components/FinpromResultModal'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import {
  AppropriatenessData,
  finpromAppropriatenessTestPreferenceKey,
} from 'pages/Finprom/finpromPreferenceData'
import { useFinpromPreferenceRequest } from 'pages/Finprom/hooks/useFinpromPreferenceRequest'
import { TextAlignType } from 'types/text'
import { useRedirect } from 'utils/redirects'

export const PassedModal = () => {
  const { t } = useTranslation()
  const { onLogout } = useRedirect()
  const { accountPreferences } = useFinpromContext() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<AppropriatenessData>()

  const alignProp: Omit<DescriptionProps, 'children'> = { align: TextAlignType.CENTER }

  const onContinue = () => {
    const updatedAppropriatenessData: AppropriatenessData = {
      ...accountPreferences?.[finpromAppropriatenessTestPreferenceKey],
      isComplete: true,
    }
    submitPreference(
      finpromAppropriatenessTestPreferenceKey,
      updatedAppropriatenessData,
      FinpromTrackingLabels.APPROPRIATENESS_STEPS_RESULT_PASSED
    )
  }

  const exitAction: ButtonProps = {
    children: t('exit'),
    destructive: true,
    disabled: isLoadingContinue,
    onClick: onLogout,
  }

  const continueAction: ButtonProps = {
    children: t('continue'),
    loading: isLoadingContinue,
    onClick: onContinue,
  }

  return (
    <FinpromResultModal
      title={t('passed')}
      icon={<Icon name="ClapIcon" color="status-success-500" />}
      actions={[exitAction, continueAction]}
      showError={isErrorContinue}
    >
      <Description {...alignProp}>{t('finprom.appropriateness.passed.intro')}</Description>
      <Description {...alignProp}>{t('finprom.appropriateness.passed.mayContinue')}</Description>
      <Description {...alignProp}>
        {t('finprom.appropriateness.passed.haveFurtherQuestions')}
        <TextAction text={SupportEmail.MERCHANTS} linkTo={`mailto:${SupportEmail.MERCHANTS}`} />.
      </Description>
    </FinpromResultModal>
  )
}
