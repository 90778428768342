import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import {
  Box as Body,
  Box,
  Box as IconCircle,
  Box as IconCol,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { logSentryError } from '@node-space/utils'
import { Description } from 'components/Text'
import { useBusinessDocumentsMutation } from 'hooks/mutations/useBusinessDocumentsMutation'
import { useRejectedDocumentsQuery } from 'hooks/queries/useRejectedDocumentQuery'
import { useRejectedDocumentUrlQuery } from 'hooks/queries/useRejectedDocumentUrlQuery'
import { useToastContext } from 'hooks/useToastContext'
import UploadFile from 'pages/Verification/_components/UploadFile'
import { BusinessDocumentsPayload, DocumentUpload, RejectedDocument } from 'types/verification'
import { Amp } from 'utils/tracker'
import { cleanBase64String } from 'utils/utils'
import { DocumentRejectionStatus } from './DocumentRejectionStatus'

export const DocumentRejectedNotification = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const addToast = useToastContext()

  const {
    mutate: postBusinessDocuments,
    isError: isErrorPostBusinessDocuments,
    isPending: isLoadingPostBusinessDocuments,
  } = useBusinessDocumentsMutation()

  const [uploadedDocuments, setUploadedDocuments] = useState<DocumentUpload[]>([])
  const [documentId, setDocumentId] = useState<number>(null)

  const { data: rejectedDocumentsData } = useRejectedDocumentsQuery()

  const { data: rejectedDocumentUrl, isFetching: isFetchingDocumentUrl } =
    useRejectedDocumentUrlQuery(documentId)

  const hasUploadedDocuments = useMemo(
    () => !!uploadedDocuments.filter(doc => !isEmpty(doc))?.length,
    [uploadedDocuments]
  )

  const uploadedDocumentsCount = useMemo(
    () => uploadedDocuments.filter(doc => !isEmpty(doc))?.length,
    [uploadedDocuments]
  )

  useEffect(() => {
    if (rejectedDocumentsData) {
      setUploadedDocuments(new Array(rejectedDocumentsData?.length).fill({}))
    }
  }, [rejectedDocumentsData])

  useEffect(() => {
    if (rejectedDocumentUrl && !isFetchingDocumentUrl) {
      window.open(rejectedDocumentUrl, '_blank')
    }
  }, [rejectedDocumentUrl, isFetchingDocumentUrl])

  const addDocument = (newDocument: DocumentUpload, newDocumentIndex: number) => {
    const tempDocuments = uploadedDocuments?.map((document, index) => {
      return index === newDocumentIndex ? newDocument : document
    })
    setUploadedDocuments(tempDocuments)
  }

  const uploadDocuments = async () => {
    const rejectedDocuments: RejectedDocument[] = uploadedDocuments
      ?.map((document, index) => {
        return isEmpty(document)
          ? null
          : {
              id: rejectedDocumentsData?.[index]?.id,
              file: {
                name: rejectedDocumentsData?.[index]?.fileName,
                base64: cleanBase64String(document?.buffer as string),
              },
            }
      })
      ?.filter(doc => !isEmpty(doc))

    const payloadData: BusinessDocumentsPayload = {
      rejectedDocuments,
    }

    postBusinessDocuments(payloadData, {
      onSuccess: () => {
        Amp.track('Pending Verification - Rejected Document Resubmission', {
          category: 'Rejected Documents',
          action: 'submit',
          documentCount: uploadedDocumentsCount,
        })

        if (uploadedDocumentsCount === 1) {
          addToast({ title: t('verification.rejectedDocumentSubmitted'), state: 'success' })
        }

        if (uploadedDocumentsCount > 1) {
          addToast({ title: t('verification.rejectedDocumentsSubmitted'), state: 'success' })
        }

        setUploadedDocuments([])

        queryClient.invalidateQueries({ queryKey: reactQueryKeys.rejectedDocuments() })
      },
      onError: error => {
        logSentryError('postRejectedDocuments', error)
      },
    })
  }

  const onDownloadClick = (documentUrlId: number) => {
    setDocumentId(documentUrlId)
    queryClient.invalidateQueries({ queryKey: reactQueryKeys.rejectedDocumentUrl(documentUrlId) })
  }

  return (
    <Box
      background="white"
      border="gray"
      borderRadius={8}
      paddingY={24}
      gapX={24}
      paddingX={32}
      className="relative xs:flex"
    >
      <IconCol flex justifyContent="center" className="xs:justify-start">
        <IconCircle flex borderRadius="full" size={48} centerChildren background="warning">
          <Icon name="WarningCircleIcon" color="status-warning-500" size="lg" />
        </IconCircle>
      </IconCol>
      <Body className="max-w-[476px]" flex direction="col" paddingT={12} gapY={12} width="full">
        <Text tag="h2" size="lg" weight="medium">
          {t('verification.actionRequiredRejectedDocuments')}
        </Text>
        <Box>
          <Description>{t('verification.rejectedDocumentsDescription1')}</Description>
          <Description>{t('verification.rejectedDocumentsDescription2')}</Description>
        </Box>
        {isErrorPostBusinessDocuments && (
          <Callout state="error" message={t('oopsSomethingWentWrong')} showCloseButton={false} />
        )}
        <Box flex direction="col" gapY={16}>
          {rejectedDocumentsData?.map((document, index) => {
            return (
              <Box flex direction="col" gapY={16} key={index}>
                <UploadFile
                  title={document?.fileName}
                  isActionRequired
                  isRejectedDocument
                  name="documentUpload"
                  onDownloadClick={() => onDownloadClick(document?.id)}
                  isDownloadingDocument={isFetchingDocumentUrl}
                  file={uploadedDocuments[index]?.fileData?.name && uploadedDocuments[index]}
                  setFile={newDocument => addDocument(newDocument, index)}
                />
                {document?.statusReasonDetail && (
                  <DocumentRejectionStatus statusReasonDetail={document?.statusReasonDetail} />
                )}
              </Box>
            )
          })}
          <Button
            className="max-w-[158px]"
            onClick={uploadDocuments}
            disabled={!hasUploadedDocuments}
            loading={isLoadingPostBusinessDocuments}
          >
            {t('verification.submitForReview')}
          </Button>
        </Box>
      </Body>
    </Box>
  )
}
