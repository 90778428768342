import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Modal, { ModalActions, ModalBody } from '@node-space/storybook-components/dist/Modal'
import { Headline } from 'components/Text'
import { RedirectUri } from 'constants/General'
import { TextAlignType } from 'types/text'
import { FinpromSubmitError } from './FinpromSubmitError'

export interface ResultModalProps {
  icon: ReactNode
  title: string
  children: ReactNode
  actions: ButtonProps[]
  showError: boolean
}

export const FinpromResultModal = ({
  icon,
  title,
  children,
  actions,
  showError,
}: ResultModalProps) => {
  const navigate = useNavigate()
  const handleClose = () => navigate(RedirectUri.LOGOUT, { replace: true })

  return (
    <Modal visible closeModal={handleClose}>
      <ModalBody>
        <Box flex centerChildren>
          {icon}
        </Box>
        <Headline align={TextAlignType.CENTER}>{title}</Headline>
        {children}
        {showError && <FinpromSubmitError />}
      </ModalBody>
      <ModalActions actions={actions} alignActions="center" />
    </Modal>
  )
}
