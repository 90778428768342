import React from 'react'
import { useNavigate } from 'react-router-dom'
import AbsoluteLayout from 'components/layout/AbsoluteLayout'
import { Button, ReturnIcon } from '@node-space/storybook-components'

const NotFound = () => {
  const navigate = useNavigate()
  return (
    <AbsoluteLayout>
      <div className="w-full h-screen flex flex-col sm:mx-0 mx-4">
        <div className="flex justify-center w-full h-full">
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold mt-8 md:mt-16 text-center">404 - Not Found!</h1>
            <div>
              <Button
                full
                iconElement={<ReturnIcon />}
                className="mt-6"
                onClick={() => navigate('/')}
              >
                Go Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AbsoluteLayout>
  )
}

export default NotFound
