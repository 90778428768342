import { requestApi } from 'api/api'
import { Country } from 'types/authentication/Profile'

export const getCountries = (canEnable = true, pagination: boolean): Promise<Country[]> => {
  const queryString = canEnable ? 'enabled=false&' : ''
  return requestApi({
    url: `/country?${queryString}pagination=${pagination}`,
    method: 'GET',
    authenticated: false,
  })
}
