import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getRequestedDocuments } from 'services/VerificationService'
import { QueryMetaType } from 'types/reactQuery'
import { TEN_MINUTES } from 'utils/utils'

export const useRequestedDocumentsQuery = (isEnabled = true) => {
  const meta: QueryMetaType = {
    errorAdditionalData: {
      isEnabled,
      refetchInterval: TEN_MINUTES,
    },
  }

  return useQuery({
    queryKey: reactQueryKeys.requestedDocuments(),
    queryFn: getRequestedDocuments,
    staleTime: TEN_MINUTES,
    enabled: isEnabled,
    meta,
  })
}
