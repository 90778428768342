import React, { createContext, Dispatch, ReactNode, SetStateAction } from 'react'
import { AccountContactsContext } from 'components/accountContact/accountContactV2/types'
import { VerificationRouteKey } from 'types/verification'

export interface VerificationRouteProps {
  accountContacts?: AccountContactsContext
  hasCompletedSteps: boolean
  setActiveRoute: Dispatch<SetStateAction<VerificationRouteKey>>
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>
  submitStep: (currentStepName: string) => void
}

export interface VerificationContextProviderProps extends VerificationRouteProps {
  children: ReactNode
}

export const VerificationContext = createContext<VerificationRouteProps>({
  accountContacts: {
    isEnabled: false,
    isFetching: false,
    isFetchingError: false,
    list: [],
    setList: () => null,
    refetch: () => null,
  },
  hasCompletedSteps: false,
  setActiveRoute: () => null,
  setUnsavedChanges: () => null,
  submitStep: () => null,
})

export const VerificationContextProvider = ({
  children,
  accountContacts,
  hasCompletedSteps,
  setActiveRoute,
  setUnsavedChanges,
  submitStep,
}: VerificationContextProviderProps) => {
  return (
    <VerificationContext.Provider
      value={{
        hasCompletedSteps,
        accountContacts,
        setActiveRoute,
        setUnsavedChanges,
        submitStep,
      }}
    >
      {children}
    </VerificationContext.Provider>
  )
}
