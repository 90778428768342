import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { validEmailRegex } from 'constants/Forms'
import { isProduction } from 'utils/environment'
import { isValidWorkEmailDomain } from 'utils/utils'

export type YupFieldType = {
  label: string
}

export type RequiredYupFieldType = YupFieldType & {
  requiredErrorMessage?: string
}

export type ConditionalYupFieldType<T> = RequiredYupFieldType & {
  fieldKey: T
  fieldValue: string | ((value: string) => boolean)
}

export const useYupFields = () => {
  const { t } = useTranslation()

  /**
   * This function gets the default required error message utilising the field label.
   * - Pattern: {{label}} is required.
   * - Example: "Legal entity name is required."
   * @param {String} label The label of the field.
   * @returns string
   */
  const getRequiredErrorText = (label: string): string => t('fieldRequired', { field: label })

  const yupField: yup.StringSchema = yup.string().trim()

  /**
   * This function is used for a field that is optional
   * @param {String} label The label of the field.
   * @returns yup schema
   */
  const getYupField = ({ label }: YupFieldType): yup.StringSchema => {
    return yupField.label(label)
  }

  /**
   * This function is used for a field that is required
   * @param {String} label The label of the field.
   * @param {String} requiredErrorMessage An optional error message for when
   * the default pattern of "{{label}} is required." doesn't fit.
   * @returns yup schema
   */
  const getRequiredYupField = ({
    label,
    requiredErrorMessage,
  }: RequiredYupFieldType): yup.StringSchema => {
    return yupField
      .label(label)
      .required(requiredErrorMessage ? requiredErrorMessage : getRequiredErrorText(label))
  }

  /**
   * This function is used for a field that is conditionally required based off a related field value.
   * @param {String} label The field label.
   * @param {String} requiredErrorMessage An optional error message
   * for when the default pattern of "{{label}} is required." doesn't fit.
   * @param {T} fieldKey Key of the related field that conditionally makes the field required.
   * @param {String} fieldValue Value(s) of the related field that conditionally makes the field required.
   * - If only 1 value is accepted pass the string value.
   * - If the value can be more than one option use a function that takes the current value and matches it against accepted options,
   * for example: currentValue => currentValue === optionOne || currentValue === optionTwo.
   * @template T The form keys Type used for typing fieldKey value.
   * @returns yup schema
   */
  const getConditionalYupField = <T>({
    label,
    requiredErrorMessage,
    fieldKey,
    fieldValue,
  }: ConditionalYupFieldType<T>): yup.StringSchema => {
    return getYupField({ label }).when(fieldKey as string, {
      is: fieldValue,
      then: getRequiredYupField({ label, requiredErrorMessage }),
    })
  }

  /**
   * This function is used for a field contains an array of objects.
   * @param {Object} shape An object of keys that each have a specific Yup Schema.
   * @returns yup schema
   */
  const getArrayOfObjectsYupField = (shape: {}): yup.AnySchema => {
    return yup.array().of(yup.object().shape(shape))
  }

  /**
   * This function is used for a checkbox that should be true
   * @returns yup schema
   */
  const getRequiredCheckboxYupField = (
    label: string,
    requiredErrorMessage?: string
  ): yup.BooleanSchema => {
    return yup
      .bool()
      .oneOf([true], requiredErrorMessage ?? t('requiredToProceed'))
      .label(label)
  }

  /**
   * This function is used for an email field
   * @returns yup schema
   */
  const getEmailYupField = (label: string): yup.StringSchema => {
    return getRequiredYupField({ label }).matches(validEmailRegex, t('invalidEmail'))
  }

  /**
   * This function is used for an work email field
   * @returns yup schema
   */
  const getWorkEmailYupField = (label: string): yup.StringSchema => {
    const baseSchema = getEmailYupField(label)

    return isProduction
      ? baseSchema.test({
          name: 'isValidWorkEmailDomain',
          message: t('invalidEmailDomain'),
          test: value => (!value ? true : isValidWorkEmailDomain(value?.toLowerCase())),
        })
      : baseSchema
  }

  return {
    yupField,
    getYupField,
    getRequiredYupField,
    getConditionalYupField,
    getArrayOfObjectsYupField,
    getRequiredCheckboxYupField,
    getEmailYupField,
    getWorkEmailYupField,
  }
}
