import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getBusinessDetails } from 'services/VerificationService'

export const useBusinessDetailsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.businessDetails(),
    queryFn: getBusinessDetails,
  })
}
