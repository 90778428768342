import React, { useEffect, useMemo } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { usePageViewTracker } from '@node-space/hooks'
import '@node-space/storybook-components/dist/index.css'

import track from 'utils/tracker'
import { isLocal, isProduction, isStaging } from 'utils/environment'
import { performAuthRedirect } from 'utils/auth/utils'
import { RedirectUri } from 'constants/General'
import useLaunchDarkly from 'hooks/useLaunchDarkly'
import { usePardotTracking } from 'utils/pardot/usePardotTracking'
import { useRefreshSessionQuery } from 'hooks/useRefreshSessionQuery'
import { useSentryIdentification } from 'hooks/useSentryIdentification'
import { useRequestsOnLogin } from 'hooks/useRequestsOnLogin'

//   Keep Page components A-Z
import { AppProviders } from 'components/app/AppProviders'
import AccountError from 'pages/AccountError'
import ConfirmEmail from 'pages/Signup/CreateAccount/ConfirmEmail/ConfirmEmail'
import CreateAccount from 'pages/Signup'
import CreateAccountDeveloper from 'pages/Signup/CreateAccount/CreateAccountDeveloper'
import ErrorContent from 'components/ErrorContent'
import { LoginDeveloper } from 'pages/Login/LoginDeveloper'
import { LoginUser } from 'pages/Login/LoginUser'
import Logout from './components/Logout'
import NotAvailable from 'pages/NotAvailable'
import NotFound from 'pages/NotFound'
import PageLayout from './components/layout/PageLayout'
import { Verification } from 'pages/Verification/Verification'
import Verify from 'pages/Verify/Verify'

const App = () => {
  const { t } = useTranslation()

  const {
    profile,
    currentAccount,
    isDeveloperAccount,
    isVerifiedBusinessAccount,
    isLoadingRequestsOnLogin,
    isLoadingProfile,
    isLoadingUserAccounts,
    verificationRequestStatus,
  } = useRequestsOnLogin()

  const profileContext = useMemo(
    () => ({ data: profile, isLoading: isLoadingProfile }),
    [profile, isLoadingProfile]
  )

  const accountContext = useMemo(
    () => ({
      data: { currentAccount },
      isLoading: isLoadingUserAccounts,
    }),
    [currentAccount, isLoadingUserAccounts]
  )

  const hasMerchantRole = profile?.roles?.includes('ROLE_MERCHANT') || profile?.viewOnly

  useSentryIdentification(profile)
  usePardotTracking()
  usePageViewTracker()
  useRefreshSessionQuery()
  useLaunchDarkly(profile)

  useEffect(() => {
    if (profile?.loggedIn) {
      // Hotjar Tracking start
      track.Hotjar.identify(profile?.id, {
        first_name: profile?.firstName,
        last_name: profile?.lastName,
        id: profile?.id,
        country: profile?.country?.name,
        default_currency: profile?.currencyCode,
        twoFactorEnabled: profile?.twoFactorEnabled,
        registrationAccountType: profile?.options?.registrationAccountType,
      })
      track.Hotjar.event('app_start')
      track.Hotjar.event('user_logged_in')

      // Google Analytics Tracking start
      track.GA.initialize()
      track.GA.event({
        category: 'Login',
        action: `A user with ID: ${profile?.id} logged in`,
      })

      // Amplitude Tracking start
      track.Amp.identify({
        user_id: `${process.env.ENV}_bvnk_${profile?.id}`?.padStart(5, '0'),
      })
      track.Amp.track('App start: Logged in', {
        category: 'Application',
        action: 'load',
      })
    }
  }, [profile])

  const getValidationRedirect = () => {
    if (isVerifiedBusinessAccount || (isDeveloperAccount && !isProduction)) {
      performAuthRedirect(process.env.MERCHANT_PORTAL_URL)
      return
    }

    if (!isVerifiedBusinessAccount) {
      return <Navigate replace to="/verification" />
    }
  }

  return (
    <>
      <Sentry.ErrorBoundary
        fallback={({ componentStack }) => <ErrorContent errorInfo={componentStack} />}
      >
        <AppProviders profile={profileContext} account={accountContext}>
          <Routes>
            <Route
              key="verification"
              element={
                <PageLayout
                  title={t('verification.verification')}
                  isTitleEnabled={true}
                  noStyling
                  hasMerchantRole={hasMerchantRole}
                  canRoute={!isVerifiedBusinessAccount && !isDeveloperAccount}
                  loading={isLoadingRequestsOnLogin}
                />
              }
            >
              <Route
                path={'/verification'}
                element={<Verification verificationRequestStatus={verificationRequestStatus} />}
              />
            </Route>
            <Route key="create-account" path={'/create-account/*'} element={<CreateAccount />} />
            <Route key="confirm-email" path={'/create-account/1'} element={<ConfirmEmail />} />
            {!isProduction && (
              <Route
                key="create-account"
                path={'/create-dev-account'}
                element={<CreateAccountDeveloper />}
              />
            )}
            <Route path="/not-available" element={<NotAvailable />} />
            <Route
              path="/login"
              element={isLocal || isStaging ? <LoginDeveloper /> : <LoginUser />}
            />
            <Route path="/logout" element={<Logout />} />
            <Route
              element={
                <PageLayout
                  title={t('home')}
                  hasMerchantRole={hasMerchantRole}
                  loading={isLoadingProfile || isLoadingUserAccounts}
                />
              }
            >
              <Route path="/" element={getValidationRedirect()} />
            </Route>
            <Route path="/verify" element={<Verify />} />
            <Route path={RedirectUri.ACCOUNT_ERROR} element={<AccountError />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppProviders>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default App
