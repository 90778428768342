import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import Loader from '@node-space/storybook-components/dist/Loader'
import Text from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromStepKeys, FinpromTrackingLabels } from 'pages/Finprom/constants'
import {
  finpromSelfCategorisationPreferenceKey,
  SelfCategorisationData,
} from 'pages/Finprom/finpromPreferenceData'
import { useFinpromPreferenceRequest } from 'pages/Finprom/hooks/useFinpromPreferenceRequest'
import { SelfCategorisationCategory } from 'pages/Finprom/types'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'

export interface SelfCategorisationResultPageProps {
  category: SelfCategorisationCategory
}

export const SelfCategorisationResultPage = ({ category }: SelfCategorisationResultPageProps) => {
  const { t } = useTranslation()
  const { accountPreferences, setActiveStep } = useFinpromContext() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<SelfCategorisationData>()

  const onContinue = () => {
    setActiveStep(FinpromStepKeys.APPROPRIATENESS_TEST)
  }

  const onUpdateCategorisation = () => {
    const currentAttempts = accountPreferences?.[finpromSelfCategorisationPreferenceKey]?.attempts
    const updatedSelfCaregorisationData: SelfCategorisationData = {
      attempts: currentAttempts,
    }
    submitPreference(
      finpromSelfCategorisationPreferenceKey,
      updatedSelfCaregorisationData,
      FinpromTrackingLabels.SELF_CATEGORISATION_UPDATE
    )
  }

  return (
    <Container>
      <VerificationHeader header={t('finprom.selfCategorisation.result.title')} />
      <Box>
        <Text size="sm" weight="medium">
          {t(`finprom.selfCategorisation.category.${category}.label`)}
        </Text>
        <Description>
          {t(`finprom.selfCategorisation.category.${category}.description`)}
        </Description>
      </Box>
      {isErrorContinue ? (
        <FinpromSubmitError />
      ) : (
        <Callout state="info">
          <Box paddingB={12}>{t('finprom.selfCategorisation.result.callout')}</Box>
          {isLoadingContinue ? (
            <Box flex gap={8}>
              <Loader />
              <Text size="sm" color="secondary-500">
                {t('finprom.selfCategorisation.result.updatingCategorisation')}
              </Text>
            </Box>
          ) : (
            <TextAction
              text={t('finprom.selfCategorisation.result.updateCategorisation')}
              onClick={onUpdateCategorisation}
              className="text-secondary-500 hover:text-secondary-700"
            />
          )}
        </Callout>
      )}
      <FormSubmitButton onClick={onContinue} disabled={false} loading={false} />
    </Container>
  )
}
