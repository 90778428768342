import React, { useEffect } from 'react'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromStepKeys } from '../constants'
import { InitialModal } from './modals/InitialModal'

export const FinpromPhaseTwo = () => {
  const { accountPreferences, activeStep, setActiveStepByPreferences } = useFinpromContext()

  useEffect(() => {
    setActiveStepByPreferences()
  }, [accountPreferences])

  switch (activeStep) {
    case FinpromStepKeys.INITIAL:
      return <InitialModal />
    default:
      return <></>
  }
}
