import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { AccountRepOption, AccountRepType } from '../AccountRepresentative/useAccountRep'

export interface AccountRepFormData {
  accountRepType: AccountRepType
  accountRep: string
  emailAddress: string
  firstName: string
  lastName: string
  dateOfBirth: string
  address1: string
  address2: string
  city: string
  postalCode: string
  countryCode: string
  id?: string
}

export type AccountRepFormKeys = keyof AccountRepFormData

export const AccountRepSchema = (t: TFunction<'translation', undefined>) => {
  const { getYupField, getRequiredYupField, getEmailYupField, getConditionalYupField } =
    useYupFields()

  return yup.object<Partial<Record<AccountRepFormKeys, yup.AnySchema>>>({
    accountRepType: getRequiredYupField({
      label: t('accountRepresentative.selectAccountRepresentative'),
    }),
    accountRep: getConditionalYupField<AccountRepFormKeys>({
      fieldKey: 'accountRepType',
      fieldValue: type => type === AccountRepOption.DIRECTOR || type === AccountRepOption.OWNER,
      label: t('accountRepresentative.header'),
    }),
    emailAddress: getEmailYupField(t('formFields.emailAddress')),
    firstName: getRequiredYupField({ label: t('formFields.firstName') }),
    lastName: getRequiredYupField({ label: t('formFields.lastName') }),
    dateOfBirth: getRequiredYupField({ label: t('formFields.dateOfBirth') }),
    address1: getRequiredYupField({ label: t('formFields.addressLine1') }),
    address2: getYupField({ label: t('formFields.addressLine2') }),
    city: getRequiredYupField({ label: t('formFields.city') }),
    postalCode: getRequiredYupField({ label: t('formFields.postalCode') }),
    countryCode: getRequiredYupField({
      label: t('formFields.chooseCountry'),
      requiredErrorMessage: t('formFields.countryIsRequired'),
    }),
  })
}
