import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getRejectedDocumentUrl } from 'services/VerificationService'
import { QueryMetaType } from 'types/reactQuery'

export const useRejectedDocumentUrlQuery = (documentId: number) => {
  const meta: QueryMetaType = {
    errorAdditionalData: { documentId },
  }

  return useQuery({
    queryKey: reactQueryKeys.rejectedDocumentUrl(documentId),
    queryFn: () => getRejectedDocumentUrl(documentId),
    enabled: !!documentId,
    meta,
  })
}
