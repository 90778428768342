import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { YesNoValues } from '../constants'

export type RestrictedFormData = {
  isPercentageInvestedLess: string
  percentageInvested: string
  isPercentageIntendedToInvestLess: string
  percentageIntendedToInvest: string
  isRiskAccepted: boolean
}

export type RestrictedFormKeys = keyof RestrictedFormData

export const RestrictedSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField, getConditionalYupField, getRequiredCheckboxYupField } =
    useYupFields()

  return yup.object<Partial<Record<RestrictedFormKeys, yup.AnySchema>>>({
    isPercentageInvestedLess: getRequiredYupField({
      label: t('finprom.selfCategorisation.restrictedForm.questionALabel'),
      requiredErrorMessage: t('emptySelect'),
    }),
    percentageInvested: getConditionalYupField<RestrictedFormKeys>({
      fieldKey: 'isPercentageInvestedLess',
      fieldValue: YesNoValues.YES,
      label: t('finprom.selfCategorisation.restrictedForm.questionAPercentageLabel'),
    }),
    isPercentageIntendedToInvestLess: getRequiredYupField({
      label: t('finprom.selfCategorisation.restrictedForm.questionBLabel'),
      requiredErrorMessage: t('emptySelect'),
    }),
    percentageIntendedToInvest: getConditionalYupField<RestrictedFormKeys>({
      fieldKey: 'isPercentageIntendedToInvestLess',
      fieldValue: YesNoValues.YES,
      label: t('finprom.selfCategorisation.restrictedForm.questionBPercentageLabel'),
    }),
    isRiskAccepted: getRequiredCheckboxYupField(
      t('finprom.selfCategorisation.restrictedForm.acceptCheckboxLabel')
    ),
  })
}
