import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TextColor } from '@node-space/storybook-components/dist/Text'

export const LoginFooter = () => {
  const { t } = useTranslation()

  return (
    <>
      <Text size="xs" color={TextColor.GREY06} align="center" className="pb-4">
        {`${t('recaptcha.protected')}`}
        <br />© 2017 - {new Date().getFullYear()} BVNK. {`${t('allRightsReserved')}`}
      </Text>
    </>
  )
}
