import { requestApi } from 'api/api'
import capitalize from 'lodash/capitalize'
import { ResendEmailData } from 'types/accounts'
import { MerchantRes } from 'types/authentication/MerchantRes'
import { PostLoginReq } from 'types/authentication/PostLoginReq'
import { PostRegisterAdditionalReq } from 'types/authentication/PostRegisterAdditionalReq'
import {
  PostRegisterConfirmRequest,
  PostRegisterUserReq,
} from 'types/authentication/PostRegisterUserReq'
import { Profile } from 'types/authentication/Profile'
import { AuthToken } from 'types/types'
import { getAuthCookie, setAuthCookies } from 'utils/auth/utils'
import { isTestEnvironment } from 'utils/environment'
import { putAccountDetails } from './AccountService'

export const postLogin = async (postData: PostLoginReq) => {
  const data = await requestApi({
    url: '/login',
    method: 'POST',
    authenticated: false,
    data: postData,
  })

  if (!isTestEnvironment) {
    setAuthCookies(data as AuthToken)
  }
  return data
}

export const postResendEmailConfirm = (data: ResendEmailData): Promise<void> => {
  return requestApi({
    url: '/user/register/resend',
    method: 'POST',
    authenticated: false,
    data,
  })
}

export const postRegisterUser = (data: PostRegisterUserReq) => {
  return requestApi({
    url: '/user/register',
    method: 'POST',
    authenticated: false,
    data,
  })
}

export const postRegisterConfirm = (data: PostRegisterConfirmRequest) => {
  return requestApi({
    url: '/user/register/confirm',
    method: 'POST',
    authenticated: false,
    data,
  })
}

export const postRegisterAdditional = async (
  postData: PostRegisterAdditionalReq
): Promise<void> => {
  await requestApi({
    url: '/business/account/user-details',
    method: 'PUT',
    data: {
      firstName: postData?.firstName,
      lastName: postData?.lastName,
      companyPosition: capitalize(postData?.companyPosition),
    },
  })

  await putAccountDetails({
    tradingName: postData?.companyName,
  })
}

export const getProfile = async (): Promise<Profile> => {
  const data: Profile = await requestApi({
    url: '/user/profile',
    method: 'GET',
  })
  const merchant = getAuthCookie() as MerchantRes
  const roles = merchant?.roles || []
  return {
    loggedIn: true,
    viewOnly: data?.roleGroups?.includes('Viewer'),
    ...data,
    roles,
  }
}

export const getReissueToken = (): Promise<AuthToken> => {
  return requestApi({
    url: '/login/reissue',
    method: 'POST',
  })
}

export const getReissueTokenParams = ({
  access_token,
  secret,
}: {
  access_token?: string
  secret?: string
} = {}): Promise<AuthToken> => {
  return requestApi({
    url: '/login/reissue',
    method: 'POST',
    access_token,
    secret,
  })
}
