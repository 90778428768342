import React from 'react'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { TextColor } from '@node-space/storybook-components/dist/Text'
import { TextList } from '@node-space/storybook-components/dist/TextList'
import { Description } from 'components/Text'
import { useTranslation } from 'react-i18next'

export const SourceOfFundsDocumentsCallout = () => {
  const { t } = useTranslation()

  const documentsInfoListItems = [
    {
      children: t('verification.sourceOfFunds.documentsInformation.documentInfo1'),
      key: '1',
    },
    {
      children: t('verification.sourceOfFunds.documentsInformation.documentInfo2'),
      key: '2',
    },
    {
      children: t('verification.sourceOfFunds.documentsInformation.documentInfo3'),
      key: '3',
    },
    {
      children: t('verification.sourceOfFunds.documentsInformation.documentInfo4'),
      key: '4',
    },
  ]

  return (
    <Callout state="info" showCloseButton={false}>
      <Description>{t('verification.sourceOfFunds.documentsInformation.introduction')}</Description>
      <Box paddingL={16} paddingT={8}>
        <TextList
          textColor={TextColor.GREY}
          listItems={documentsInfoListItems}
          listStyle="bullet"
          tag="ul"
        />
      </Box>
    </Callout>
  )
}
