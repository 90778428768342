import React, { useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import {
  Box as FieldGroup,
  Box as FormBody,
  Box as FormFields,
  Box as SplitFields,
  Box as TooltipLabel,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { InputLabel } from '@node-space/storybook-components/dist/FormElements'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useDetectCountryQuery } from 'hooks/queries/useDetectCountryQuery'
import { useRecaptchaVersioning } from 'hooks/recaptcha/useRecaptchaVersioning'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { FormSubmitError } from 'pages/Verification/_components/FormSubmitError'
import { postRegisterUser } from 'services/AuthService'
import { PostRegisterUserReq } from 'types/authentication/PostRegisterUserReq'
import { BaseErrorResponse } from 'types/errors'
import { AccountTypes } from 'types/types'
import { isSandbox } from 'utils/environment'
import { Amp, GA } from 'utils/tracker'
import { useCreateAccountErrors } from '../hooks/useCreateAccountErrors'
import devXSchema, { DevXReq, DevXReqKeys } from '../schemas/devXSchema'
import { CreateAccountError } from '../types'
import { CreateAccountErrorMessage } from './CreateAccountErrorMessage'
import { SignUpAgreement } from './SignUpAgreement'

const DevXForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { recaptchaVersion, isLoadingRecaptcha, getRecaptchaToken, resetRecaptcha } =
    useRecaptchaVersioning('signup')

  const { data: country } = useDetectCountryQuery()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const isLoading = useMemo(
    () => isLoadingRecaptcha || isLoadingSubmit,
    [isLoadingRecaptcha, isLoadingSubmit]
  )

  useEffect(() => {
    if (isSandbox) Amp.track('DevX: SB: Action - Developer form visited')
  }, [])

  const defaultValues: DevXReq = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    companyName: '',
  }

  const formSchema = devXSchema(t)
  const form = useForm<DevXReq>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = form

  const { handleErrors } = useCreateAccountErrors(setError)

  const formValues = useWatch({ control })

  const { setInputField, setEmailField } = useFormFields<DevXReqKeys>(formSchema, form, formValues)

  const formFields = {
    firstName: setInputField({ key: 'firstName', placeholder: t('firstName'), showLabel: false }),
    lastName: setInputField({ key: 'lastName', placeholder: t('lastName'), showLabel: false }),
    emailAddress: setEmailField({ key: 'emailAddress' }),
    password: setInputField({ key: 'password', type: 'password', placeholder: t('enterPassword') }),
    companyName: setInputField({ key: 'companyName', placeholder: t('companyNamePlaceholder') }),
  }

  const createNewDeveloper = async ({
    firstName,
    lastName,
    emailAddress,
    password,
    companyName,
  }) => {
    setIsLoadingSubmit(true)
    resetRequestError()
    try {
      const recaptchaToken: string = await getRecaptchaToken()

      if (!recaptchaToken) {
        setRequestError({
          handledMessage: (
            <CreateAccountErrorMessage errorType={CreateAccountError.RECAPTCHA_TOKEN} />
          ),
          show: true,
        })
        return
      }

      const countryCode = country?.code
      const selectedCountry = countryCode ?? 'GB'

      const postData: PostRegisterUserReq = {
        emailAddress,
        password,
        countryCode: selectedCountry,
        accountType: AccountTypes.DEVELOPER,
        recaptcha: recaptchaToken,
        recaptchaVersion: recaptchaVersion,
      }

      await postRegisterUser(postData)

      if (isSandbox) {
        // Amplitude - New DevX Action tracking
        Amp.track('DevX: SB: Action - Developer new registration')

        // GA - New Registration
        GA.event({
          category: 'Registration',
          action: `A Developer user successfully registered`,
        })
      }

      resetRecaptcha()

      navigate('/create-account/1', {
        state: {
          email: emailAddress,
          password,
          accountType: AccountTypes.DEVELOPER,
          firstName,
          lastName,
          companyName,
        },
      })
    } catch (errors) {
      const requestError = handleErrors('postRegisterDevXAccount', errors as BaseErrorResponse)
      if (!!requestError) {
        setRequestError(requestError)
      }
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onSubmit: SubmitHandler<typeof formValues> = formValues => {
    const mappedData = formValues as DevXReq
    createNewDeveloper(mappedData)
  }

  return (
    <>
      {requestError?.show && (
        <FormSubmitError
          errorCode={requestError?.errorCode}
          sentryTraceId={requestError?.sentryTraceId}
          fallbackMessage={t('createAccountError.default')}
        >
          {requestError?.handledMessage}
        </FormSubmitError>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody flex direction="col" gapY={24}>
          <FormFields flex direction="col" gapY={20}>
            <FieldGroup>
              <InputLabel>{`${t('name')}`}</InputLabel>
              <SplitFields flex gapX={8}>
                <Input {...formFields.firstName} testid="dev-signup-firstName" />
                <Input {...formFields.lastName} testid="dev-signup-lastName" />
              </SplitFields>
            </FieldGroup>

            <Input
              {...formFields.emailAddress}
              testid="dev-signup-emailAddress"
              label={
                <TooltipLabel flex alignItems="center" gapX={4}>
                  <span>{`${t('workEmail')}`}</span>
                  <Tooltip
                    id="tooltip-work-email"
                    position="right"
                    bodyContent={`${t('workEmailInputInfo')}`}
                  >
                    <Icon name="InfoCircleIcon" size="sm" />
                  </Tooltip>
                </TooltipLabel>
              }
            />

            <Input {...formFields.password} testid="dev-signup-password" />

            <Input {...formFields.companyName} testid="dev-signup-companyName" />
          </FormFields>

          <Button type="submit" full loading={isLoading} disabled={!isEmpty(errors)} testid="dev-signup-submit-btn">
            {`${t('signUp')}`}
          </Button>
          <SignUpAgreement />
        </FormBody>
      </form>
    </>
  )
}

export default DevXForm
