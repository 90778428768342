import { useTranslation } from 'react-i18next'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromTrackingLabels } from '../constants'
import {
  finpromSelfCategorisationPreferenceKey,
  SelfCategorisationData,
} from '../finpromPreferenceData'
import { useFinpromPreferenceRequest } from './useFinpromPreferenceRequest'

export const useRetakeSelfCategorisation = () => {
  const { t } = useTranslation()
  const { accountPreferences } = useFinpromContext() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<SelfCategorisationData>()

  const quizCategory = accountPreferences?.[finpromSelfCategorisationPreferenceKey]?.category
  const quizCategoryLabel = t(`finprom.selfCategorisation.category.${quizCategory}.label`)

  const onRetakeQuiz = () => {
    const attempts = accountPreferences?.[finpromSelfCategorisationPreferenceKey]?.attempts
    const updatedSelfCategorisationData: SelfCategorisationData = { attempts }
    submitPreference(
      finpromSelfCategorisationPreferenceKey,
      updatedSelfCategorisationData,
      FinpromTrackingLabels.SELF_CATEGORISATION_RETAKE_QUIZ
    )
  }

  return {
    quizCategoryLabel,
    isLoadingRetake: isLoadingContinue,
    isErrorRetake: isErrorContinue,
    onRetakeQuiz,
  }
}
