import React from 'react'
import { Box } from '@node-space/storybook-components/dist/Box'
import { AnimatedIconLoader } from './loaders/AnimatedIconLoader'
import { Logo } from './logos/Logo'

const AppLoader = () => {
  return (
    <Box flex direction="col" centerChildren width="full" minHeight="screen" gap={20}>
      <AnimatedIconLoader />
      <Logo />
    </Box>
  )
}

export default AppLoader
