import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { WorkspaceMenu } from '@node-space/storybook-components/dist/WorkspaceMenu'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useAccountContext } from 'hooks/context/useAccountContext'
import { isProduction } from 'utils/environment'

export const UserMenu = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { profile: { firstName, lastName } = {} } = useProfileContext()
  const { currentAccount, isLoading: isLoadingAcounts } = useAccountContext()

  const logout = () => {
    navigate('/logout')
  }

  const profileName = !!firstName && !!lastName && `${firstName} ${lastName}`

  const menuListItems = [
    {
      icon: <Icon name="SupportIcon" />,
      text: t('helpMenuSupportAndHelp'),
      hyperlink: process.env.SUPPORT_URL,
    },
    {
      icon: <Icon name="LogOutIcon" />,
      text: t('logOut'),
      onClick: () => logout(),
    },
  ]

  !isProduction &&
    menuListItems.unshift({
      icon: <Icon name="ExternalLinkIcon" />,
      text: t('developerDocs'),
      hyperlink: process.env.DEVELOPER_DOCS,
    })

  return (
    <Box className="relative inline-flex">
      <WorkspaceMenu
        workspaceAccounts={{
          accountName: profileName,
          accounts: [currentAccount],
          selectedAccount: currentAccount,
          setSelectedAccount: () => null,
        }}
        menuListItems={menuListItems}
        loading={isLoadingAcounts}
        testid="workspaceMenu"
      />
    </Box>
  )
}
