import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { AccountContactActionError } from 'components/accountContact/AccountContactActionError'
import {
  AccountContactErrorTypes,
  AccountContactTypes,
} from 'components/accountContact/accountContactV2/constants'
import { AccountContactData } from 'components/accountContact/accountContactV2/types'
import { useAccountContactActions } from 'components/accountContact/accountContactV2/useAccountContactActions'
import { getAccountContactName } from 'components/accountContact/accountContactV2/utils'
import { Description } from 'components/Text'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useIdVerification } from 'hooks/useIdVerification'
import { FormBody } from 'pages/Verification/_components/FormBody'
import { FormGetError } from 'pages/Verification/_components/FormGetError'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { NameTile, NameTilesContanier } from 'pages/Verification/_components/NameTile'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { BusinessDirectorModal } from './BusinessDirectorModal'

const accountContactType = AccountContactTypes.BUSINESS_DIRECTOR

export const BusinessDirectors = () => {
  const { t } = useTranslation()
  const { isIdVerificationEnabled } = useIdVerification()

  const { accountContacts, submitStep } = useVerificationContext()

  const {
    contactList: businessDirectors,
    actionsData,
    selectedAccountContact,
    deletingAccountContacts,
    isDetailsOpen,
    errors,
    getIsAccountContactsValid,
    onAddDetails,
    onUpdateDetails,
    onSave,
    onDelete,
    onCloseDetails,
  } = useAccountContactActions(accountContactType, accountContacts)

  const handleContinue = () => {
    const isValid = getIsAccountContactsValid()
    if (!isValid) return
    submitStep(VerificationTrackingLabels.BUSINESS_DIRECTORS)
  }

  return (
    <>
      <VerificationHeader header={t('verification.routes.businessDirectors')}>
        <Description>{t('verification.businessDirectors.subHeader')}</Description>
        {isIdVerificationEnabled && (
          <Description>{t('verification.businessDirectors.descriptionIdVerification')}</Description>
        )}
      </VerificationHeader>
      {accountContacts?.isFetching ? (
        <SkeletonForm name="Business directors" rowCount={1} />
      ) : accountContacts?.isFetchingError ? (
        <FormGetError />
      ) : (
        <FormBody>
          {!!errors?.actionErrorStatus?.errorType && !isDetailsOpen && (
            <AccountContactActionError
              {...errors?.actionErrorStatus}
              accountContactType={accountContactType}
            />
          )}
          {!!businessDirectors?.length && (
            <Box flex direction="col" gap={8}>
              <NameTilesContanier>
                {businessDirectors?.map(({ reference, ...contact }: AccountContactData) => {
                  const contactFullName = getAccountContactName(contact)
                  return (
                    <NameTile
                      key={reference}
                      tileId={reference}
                      label={contactFullName}
                      isLoading={deletingAccountContacts?.includes(reference)}
                      onEdit={() => onUpdateDetails(reference)}
                      onDelete={() => onDelete({ reference, contactFullName })}
                    />
                  )
                })}
              </NameTilesContanier>
              {isIdVerificationEnabled && (
                <Description>
                  {t('accountContact.createIdVerificationLink.linkProvidedLater')}
                </Description>
              )}
            </Box>
          )}
          <Button
            testid="addDirector"
            onClick={onAddDetails}
            secondary
            iconElement={<Icon name="PlusIcon" color="primary-500" />}
            className="w-fit"
          >
            {t('verification.businessDirectors.addDirector')}
          </Button>

          <FormSubmitButton
            disabled={
              errors?.actionErrorStatus?.errorType === AccountContactErrorTypes.EMPTY_CONTACTS
            }
            onClick={handleContinue}
          />
        </FormBody>
      )}
      {isDetailsOpen && (
        <BusinessDirectorModal
          initialData={selectedAccountContact}
          actionsData={actionsData}
          actionErrorStatus={errors?.actionErrorStatus}
          onSave={onSave}
          onDelete={onDelete}
          onClose={onCloseDetails}
        />
      )}
    </>
  )
}

export default BusinessDirectors
