import { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import { useDynamicDocumentsQuery } from 'hooks/queries/useDynamicFieldsQuery'
import { DocumentCategoryList } from './constants'
import { initialRequiredDocuments } from './data'
import {
  DocumentCategoryKey,
  RemoveDocumentAction,
  RequiredDocuments,
  UploadDocumentAction,
  UploadedDocument,
} from './types'
import { getDocumentCategory, getUploadedIndex } from './utils'

export const useUploadDocuments = () => {
  const [requiredDocuments, setRequiredDocuments] =
    useState<RequiredDocuments>(initialRequiredDocuments)
  const [requiredCategories, setRequiredCategories] = useState<DocumentCategoryKey[]>([])
  const [uploadedDocuments, setUploadedDocuments] = useState<UploadedDocument[]>([])

  const {
    data: requiredDocumentsData,
    isFetching: isFetchingRequiredDocuments,
    error: isErrorFetchingRequiredDocuments,
  } = useDynamicDocumentsQuery()

  const loadRequiredDocuments = () => {
    const updatedRequiredDocuments = cloneDeep(initialRequiredDocuments)
    requiredDocumentsData?.forEach(document => {
      const category = getDocumentCategory(document)
      if (category) {
        updatedRequiredDocuments?.[category]?.push(document)
      }
    })
    const updatedRequiredCategories = DocumentCategoryList?.filter(
      category => !!updatedRequiredDocuments?.[category]?.length
    )
    setRequiredDocuments(updatedRequiredDocuments)
    setRequiredCategories(updatedRequiredCategories)
  }

  const onUploadDocument: UploadDocumentAction = uploadedDocument => {
    setUploadedDocuments(currentList => {
      const updatedList = [...currentList]
      const existingUploadedIndex = getUploadedIndex(updatedList, uploadedDocument?.documentId)
      if (existingUploadedIndex === -1) {
        updatedList?.push(uploadedDocument)
      } else {
        updatedList[existingUploadedIndex].file = uploadedDocument?.file
      }
      return updatedList
    })
  }

  const onRemoveDocument: RemoveDocumentAction = removedDocumentId => {
    setUploadedDocuments(currentList => {
      const updatedList = [...currentList]
      const existingUploadedIndex = getUploadedIndex(updatedList, removedDocumentId)
      if (existingUploadedIndex !== -1) {
        updatedList?.splice(existingUploadedIndex, 1)
      }
      return updatedList
    })
  }

  useEffect(() => {
    if (!isFetchingRequiredDocuments && requiredDocumentsData) {
      loadRequiredDocuments()
    }
  }, [isFetchingRequiredDocuments, requiredDocumentsData])

  return {
    isFetchingRequiredDocuments,
    isErrorFetchingRequiredDocuments,
    requiredDocuments,
    requiredCategories,
    uploadedDocuments,
    documentActions: {
      onUploadDocument,
      onRemoveDocument,
    },
  }
}
