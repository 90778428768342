import { useQuery } from '@tanstack/react-query'
import { reactQueryKeys } from '../../reactQueryKeys/reactQueryKeys'
import { detectCountry } from './../../services/VerificationService'

export const useDetectCountryQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.country(),
    queryFn: detectCountry,
  })
}
