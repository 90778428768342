export const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const invalidWorkEmailDomains = {
  GMAIL: 'gmail',
  YAHOO: 'yahoo',
  HOTMAIL: 'hotmail',
  OUTLOOK: 'outlook',
  ICLOUD: 'icloud',
  AOL: 'aol',
  YMAIL: 'ymail',
} as const
