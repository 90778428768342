import { initialAccountContactFormData } from 'components/accountContact/constants'
import { CorporateOwnerFormData } from './types'

export const OwnerTypes = {
  INDIVIDUAL: 'individual',
  CORPORATE: 'corporate',
} as const

export const OwnershipTypes = {
  DIRECT: 'direct',
  INDIRECT: 'indirect',
} as const

export const initialCorporateOwnerFormData: CorporateOwnerFormData = {
  name: '',
  countryCode: '',
  registrationNumber: '',
  ownershipPercentage: '',
  ownershipType: OwnershipTypes.DIRECT,
}

export const emptyBusinessOwnerDetails = {
  ...initialAccountContactFormData,
  ...initialCorporateOwnerFormData,
}
