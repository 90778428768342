import dayjs from 'dayjs'
import { Timer } from 'constants/General'

/**
 * Compares the query date with the current date and checks if the query hours has expired
 * @param queryDate date you want to query
 * @param queryHours optional hours expired you want to query - 24 if unset
 * @returns boolean
 */
export const hasHoursExpired = (queryDate: Date, queryHours = 24) => {
  const queryDateConverted = dayjs(queryDate)
  const currentDate = dayjs()
  const hoursDifference = currentDate.diff(queryDateConverted, 'hours')
  const isHoursExpired = hoursDifference >= queryHours
  return isHoursExpired
}

/**
 * Gets the time left in minutes from current date to query date
 * @param queryDate date you want to query
 * @param addHours optional hours to add to query date
 * @returns {Number} time left in minutes
 */
export const getRemainingMinutes = (queryDate: Date, addHours?: number): number => {
  if (!dayjs(queryDate)?.isValid()) return
  const queryDateConverted = dayjs(queryDate)
  const compareDate = addHours ? queryDateConverted?.add(addHours, 'hour') : queryDateConverted
  const currentDate = dayjs()
  return compareDate?.diff(currentDate, 'minute')
}

/**
 * Returns the time left in minutes as a formatted countdown timer
 * @param remainingMinutes time left in minutes
 * @returns {String} time left in hh:mm
 */
export const getRemainingMinutesFormatted = (remainingMinutes: number): string => {
  if (remainingMinutes <= 0) return '00:00'
  const timeLeftHours = String(Math.floor(remainingMinutes / Timer.ONE_MINUTE))?.padStart(2, '0')
  const timeLeftMin = String(remainingMinutes % Timer.ONE_MINUTE)?.padStart(2, '0')
  return `${timeLeftHours}:${timeLeftMin}`
}
