export const LogoColor = {
  DARK: 'black',
  LIGHT: 'white',
} as const

export type LogoColorType = (typeof LogoColor)[keyof typeof LogoColor]

export interface ErrorAPI {
  errorList: Error[]
}

export interface Error {
  parameter: string
  code: string
  message: string
}

export interface Country {
  label: string
  value: string
}

export interface ExcludedCountry {
  name: string
  code: string
}

export type FeatureFlags = FeatureFlagsGeneric & {
  enableAccountContactsCrud: boolean
  enableBanner: EnableBanner
  enableDynamicDocuments: boolean
  enableFinpromQuestionnaires: boolean
  enableIdentityVerification: boolean
  enableRequestedDocuments: boolean
  enableRejectedDocuments: boolean
  enableSubIndustry: boolean
  enableCountriesExclusionList: ExcludedCountry[]
}

export interface FeatureFlagsGeneric {
  [key: string]: boolean | undefined | unknown
}

export interface Options {
  transaction: string
  address: string
  explorer: string
  confirmations: number
}

export interface WorkspaceListItemId {
  id: string
  name: string
}

export interface ErrorData {
  code: string
  message: string
}

export interface ProfileCountry {
  name: string
}
export interface Profile {
  id?: string
  key?: string
  firstName?: string
  lastName?: string
  emailAddress?: string
  roles?: string[]
  twoFactorEnabled?: boolean
  country?: ProfileCountry
  language?: string
  currencyCode?: string
  loggedIn?: boolean
  viewOnly?: boolean
}

type EnableBanner = {
  enabled: boolean
  state: 'critical'
  message: string
  buttonText: string
  buttonLink: string
}

export interface AuthToken {
  access_token?: string
  username?: string
  secret?: string
  impersonate?: boolean
  roles?: string[]
  length?: number
  token_type?: 'Hawk'
}

export const AccountTypes = {
  BUSINESS: 'business',
  DEVELOPER: 'developer',
} as const

export type AccountTypes = (typeof AccountTypes)[keyof typeof AccountTypes]

export interface RequestedDocument {
  id: number
  name: string
  url?: string
}

export interface RejectedDocument {
  id: number
  code: string
  fileName: string
  url?: string
  statusReason: string
  statusReasonDetail?: string
}

export interface AccountDetails {
  tradingName: string
}
