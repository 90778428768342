import { customHeaders } from 'api/api'
import { SalesForce } from 'constants/General'
import { objectToEncodedString } from 'utils/utils'

interface PostPardotDevXDto {
  RegisteredName: string
  EmailAddress: string
  FirstName: string
  LastName: string
}

export const postPardotService = async ({
  RegisteredName,
  EmailAddress,
  FirstName,
  LastName,
}: PostPardotDevXDto) => {
  // Must be exact for Salesforce Pardot API to accept
  const sanitizedData = objectToEncodedString({
    RegisteredName: RegisteredName || 'n/a',
    EmailAddress: EmailAddress || 'n/a',
    FirstName: FirstName || 'n/a',
    LastName: LastName || 'n/a',
  })

  return fetch(SalesForce.PARDOT_DEVX_ENDPOINT, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...customHeaders },

    body: sanitizedData,
    mode: 'no-cors' as RequestMode,
  })
}
