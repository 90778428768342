import React, { useCallback, useMemo } from 'react'
import { ServiceNeed, ServiceNeedErrorType } from 'types/verification'
import { CheckboxAccordion } from 'components/_storybook/CheckboxAccordion'

export type ProductErrorType = {
  type: ServiceNeedErrorType
  message: string
}

export interface ServiceItemParams {
  product: ServiceNeed
  error?: ProductErrorType
  isDescriptionOpen: boolean
  onCheckProduct: (id: number, value: boolean) => void
  onOptionalValueChange: (id: number, value: string) => void
  onToggleDescription: (id: number) => void
}

export const ServiceItem = ({
  product,
  error,
  isDescriptionOpen,
  onCheckProduct,
  onOptionalValueChange,
  onToggleDescription,
}: ServiceItemParams) => {
  const { id, label, note, checked, optionalLabel, optionalValue } = product

  const handleOptionalValueChange = useCallback(
    value => {
      onOptionalValueChange(id, value)
    },
    [onOptionalValueChange, id]
  )

  const handleCheckProduct = useCallback(() => {
    onCheckProduct(id, !checked)
  }, [onCheckProduct, id, checked])

  const handleToggleDescription = useCallback(() => {
    onToggleDescription(id)
  }, [onToggleDescription, id])

  const hasEmptyTextInputError = useMemo(
    () => !!optionalLabel && error?.type === 'noDescription',
    [optionalLabel, error.type]
  )

  return (
    <CheckboxAccordion
      id={id}
      label={label}
      description={note}
      checked={checked}
      hasError={hasEmptyTextInputError || error?.type === 'noService'}
      errorMessage={hasEmptyTextInputError ? error?.message : ''}
      isOpen={isDescriptionOpen}
      onCheck={handleCheckProduct}
      onToggle={handleToggleDescription}
      {...(optionalLabel &&
        checked && {
          textInput: {
            value: optionalValue,
            placeholder: optionalLabel,
            onChange: handleOptionalValueChange,
          },
        })}
    />
  )
}
