export const AccountContactTypes = {
  ACCOUNT_REPRESENTATIVE: 'ACCOUNT_REPRESENTATIVE',
  BUSINESS_DIRECTOR: 'BUSINESS_DIRECTOR',
  BUSINESS_OWNER: 'BUSINESS_OWNER',
} as const

export const AccountContactTypesList = Object.values(AccountContactTypes)

export const AccountContactTranslation = {
  ACCOUNT_REPRESENTATIVE: 'accountRepresentative',
  BUSINESS_DIRECTOR: 'businessDirector',
  BUSINESS_OWNER: 'businessOwner',
} as const

export const AccountContactStepKeys = {
  ACCOUNT_REPRESENTATIVE: 'accountRepresentative',
  BUSINESS_DIRECTOR: 'businessDirectors',
  BUSINESS_OWNER: 'businessOwners',
} as const

export const AccountContactErrorTypes = {
  EMPTY_CONTACTS: 'emptyContacts',
  ADD_CONTACT: 'addContact',
  UPDATE_CONTACT: 'updateContact',
  DELETE_CONTACT: 'deleteContact',
  CREATE_ID_VERIFICATION_LINK: 'createIdVerificationLink',
} as const

export const AccountContactOwnershipTypes = {
  DIRECT: 'DIRECT',
  INDIRECT: 'INDIRECT',
} as const

export const PersonDefaultOptions = {
  MYSELF: 'myself',
  SOMEONE_ELSE: 'someoneElse',
}

export const PersonDefaultOptionsList = Object.values(PersonDefaultOptions)
