import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { wait } from '@node-space/utils'
import { RedirectUri } from 'constants/General'
import { deleteAuthCookie } from 'utils/auth/utils'
import { HALF_SECOND } from 'utils/utils'

const Logout = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const resetCookiesSession = async () => {
    deleteAuthCookie()

    return Promise.resolve()
  }

  const resetReactQuery = async () => {
    await queryClient.clear()
  }

  const startCleanup = async () => {
    await wait(HALF_SECOND)
    await resetCookiesSession()
    await resetReactQuery()

    navigate(RedirectUri.KYB_SIGNUP_URL)
  }

  useEffect(() => {
    startCleanup()
  }, [])

  return <></>
}

export default Logout
