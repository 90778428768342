import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { getDirectorsData } from 'services/VerificationService'

export const useBusinessDirectorsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.businessDirectors(),
    queryFn: getDirectorsData,
    placeholderData: keepPreviousData,
  })
}
