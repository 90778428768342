import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@node-space/storybook-components/dist/Select'
import { PersonDefaultOptions } from './constants'
import { PersonSelectorInfo } from './types'
import { getOtherPersonOptions } from './utils'

export interface PersonSelectorProps extends PersonSelectorInfo {
  onPersonChange: (updatedReference: string) => void
}

export const PersonSelector = ({
  selectedReference,
  otherPersons,
  showMyselfOption = true,
  onPersonChange,
}: PersonSelectorProps) => {
  const { t } = useTranslation()

  const otherPersonOptions = getOtherPersonOptions(otherPersons)
  const personOptions = [
    ...(showMyselfOption ? [{ value: PersonDefaultOptions.MYSELF, label: t('myself') }] : []),
    {
      value: PersonDefaultOptions.SOMEONE_ELSE,
      label: t('someoneElse'),
    },
    ...(otherPersonOptions ?? []),
  ]

  return <Select value={selectedReference} options={personOptions} onChange={onPersonChange} />
}
