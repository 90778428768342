import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { AccountContactActionError } from 'components/accountContact/AccountContactActionError'
import { AccountContactForm } from 'components/accountContact/accountContactV2/AccountContactForm'
import { AccountContactTypes } from 'components/accountContact/accountContactV2/constants'
import { AccountContactProps } from 'components/accountContact/accountContactV2/types'
import {
  getAccountContactName,
  getInitialCompanyFormValues,
  getUpdatedAccountContactCompanyData,
} from 'components/accountContact/accountContactV2/utils'
import { AccountContactLabels } from 'components/accountContact/types'
import { Description } from 'components/Text'
import { OwnerTypes } from '../constants'
import { CorporateOwnerForm } from '../CorporateOwnerForm'
import { CorporateOwnerFormData, OwnerType, OwnerTypeOption } from '../types'

export const BusinessOwnerModal = ({
  initialData,
  actionsData,
  actionErrorStatus,
  onSave,
  onDelete,
  onClose,
}: AccountContactProps) => {
  const { t } = useTranslation()
  const { countryOptions, isUpdatingDetails, idVerificationMessage, loading } = actionsData || {}
  const [ownerType, setOwnerType] = useState<OwnerType>(
    !!initialData?.company ? OwnerTypes.CORPORATE : OwnerTypes.INDIVIDUAL
  )

  const businessOwnerLabels: AccountContactLabels = {
    addButton: t('verification.businessOwners.addIndividualOwner'),
  }

  const ownerTypeRadioOptions: OwnerTypeOption[] = [
    { value: OwnerTypes.INDIVIDUAL, label: t('verification.businessOwners.individual') },
    { value: OwnerTypes.CORPORATE, label: t('verification.businessOwners.corporate') },
  ]

  const isIndividual = ownerType === OwnerTypes.INDIVIDUAL

  const handleOwnerTypeChange = (value: OwnerType) => {
    setOwnerType(value)
  }

  const handleSaveCompany = (formValues: CorporateOwnerFormData) => {
    const updatedAccountContact = getUpdatedAccountContactCompanyData(initialData, formValues)
    onSave(updatedAccountContact)
  }

  const handleDelete = () => {
    onDelete({
      reference: initialData?.reference,
      contactFullName: getAccountContactName(initialData),
    })
  }

  return (
    <Modal visible closeModal={onClose} allowOverFlow>
      {!idVerificationMessage?.showMessage && (
        <ModalNavBar title={t('verification.businessOwners.addOwner')} onClose={onClose} />
      )}
      <ModalBody
        spaceChildren={24}
        hasBottomPadding={false}
        hasTopPadding={!idVerificationMessage?.showMessage}
      >
        {!!actionErrorStatus?.errorType && (
          <AccountContactActionError
            {...actionErrorStatus}
            accountContactType={AccountContactTypes.BUSINESS_OWNER}
          />
        )}
        {!idVerificationMessage?.showMessage && (
          <>
            {!isUpdatingDetails && (
              <Radio
                label={t('verification.businessOwners.typeLabel')}
                value={ownerType}
                options={ownerTypeRadioOptions}
                onChange={handleOwnerTypeChange}
                horizontal
                borderless={false}
              />
            )}
            <Description>
              {isIndividual
                ? t('verification.businessOwners.individualDescription')
                : t('verification.businessOwners.corporateDescription')}
            </Description>
          </>
        )}
      </ModalBody>
      {isIndividual ? (
        <AccountContactForm
          {...actionsData}
          accountContact={initialData}
          labels={businessOwnerLabels}
          hasTopPadding={false}
          onSave={onSave}
          onDelete={handleDelete}
        />
      ) : (
        <CorporateOwnerForm
          corporateOwner={getInitialCompanyFormValues(initialData)}
          countryOptions={countryOptions}
          isEditing={isUpdatingDetails}
          actionsLoading={loading}
          onSave={handleSaveCompany}
          onDelete={handleDelete}
        />
      )}
    </Modal>
  )
}
