import React from 'react'
import { createRoot } from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import App from './App'
import './index.scss'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { isProduction, isSandbox } from 'utils/environment'
import { onQueryError } from 'utils/reactQuery/onQueryError'
import { onQuerySuccess } from 'utils/reactQuery/onQuerySuccess'

const IS_SENTRY_ENABLED = isProduction || isSandbox
const IS_SENTRY_REPLAY_ENABLED = isProduction

Sentry.init({
  enabled: !!IS_SENTRY_ENABLED,
  normalizeDepth: 10,
  environment: process.env.ENV,
  dsn: process.env.SENTRY_DSN,
  ...(!!IS_SENTRY_REPLAY_ENABLED && {
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
  }),
  ignoreErrors: ['NetworkError', 'Network Error'],
  denyUrls: [/events\.launchdarkly\.com/i, /app\.launchdarkly\.com/i, /.*launchdarkly\.com/i],
  beforeSend: (event: Sentry.Event) => {
    const ignoreErrorCodes = ['ERR_NETWORK']
    const errorCode = event?.extra?.['Error code'] as string
    if (!!errorCode && ignoreErrorCodes?.includes(errorCode)) {
      return null
    }
    return event
  },
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnWindowFocus: false,
      retry: false, // Retires are disabled by default. Can be overriden individually
    },
  },
  queryCache: new QueryCache({
    onError: onQueryError,
    onSuccess: onQuerySuccess,
  }),
})

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fi'],
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: `/assets/locales/{{lng}}/translation.json?v=${process.env.HASH}`,
    },
    react: {
      useSuspense: false,
    },
    nsSeparator: false,
    keySeparator: '.',
  })

const SentryWithApp = Sentry.withProfiler(App)

// If the MSW_API_MOCK env is `enabled` start msw service worker.
// Used for mocking payloads in the application.
if (process.env.MSW_API_MOCK === 'enabled') {
  const { mswWorker } = require('./msw/worker')
  mswWorker.start({
    onUnhandledRequest: 'bypass',
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <LDProvider
          clientSideID={process.env.LAUNCH_DARKLY_ID}
          options={{
            sendEvents: false, // Dont send internal LD events back home to LD
            diagnosticOptOut: true, // Dont send diagnostics info
          }}
        >
          <SentryWithApp />
        </LDProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
)
