import React, { useMemo, useState } from 'react'
import { Box as Container, Box as Page } from '@node-space/storybook-components/dist/Box'
import { CSATLaunch } from 'components/CSAT/CSATLaunch'
import { LogoHeader } from 'components/header/LogoHeader'
import { UserMenu } from 'components/header/UserMenu'
import { VerificationRequestStatus } from 'constants/verification'
import { FinpromContextProvider } from 'contexts/finpromContext'
import { Finprom } from 'pages/Finprom/Finprom'
import { useFinpromData } from 'pages/Finprom/hooks/useFinpromData'
import { useFinpromFeature } from 'pages/Finprom/hooks/useFinpromFeature'
import { VerificationRequestStatus as VerificationRequestStatusType } from 'types/verification'
import { PendingVerificationView } from './PendingVerificationView'
import { VerificationSteps } from './VerificationSteps'

interface VerificationProps {
  verificationRequestStatus: VerificationRequestStatusType
}

export const Verification = ({ verificationRequestStatus }: VerificationProps) => {
  const { isFetchedFeatureFlags, isEnabled, isPhaseTwo } = useFinpromFeature()
  const { isFetchedFinpromData, companyCountryCode, accountPreferences, refetchFinpromData } =
    useFinpromData(isFetchedFeatureFlags && isEnabled)

  const [isPendingView, setIsPendingView] = useState<boolean>(
    verificationRequestStatus === VerificationRequestStatus.PENDING
  )

  const isFetchedFinprom = useMemo(
    () => isFetchedFeatureFlags && isFetchedFinpromData,
    [isFetchedFeatureFlags, isFetchedFinpromData]
  )

  return (
    <FinpromContextProvider
      isFetched={isFetchedFinprom}
      isEnabled={isEnabled}
      isPhaseTwo={isPhaseTwo}
      companyCountryCodeData={companyCountryCode}
      accountPreferencesData={accountPreferences}
      refetchData={refetchFinpromData}
    >
      <Page background="off-white">
        <Container flex direction="col" width="full" minHeight="screen">
          <LogoHeader isMobileSticky>
            <UserMenu />
          </LogoHeader>

          <Container flex flexSize="fill" justifyContent="center" className="relative">
            {isPendingView ? (
              <PendingVerificationView />
            ) : (
              <VerificationSteps onCompleteSteps={() => setIsPendingView(true)} />
            )}
            <CSATLaunch />
          </Container>
        </Container>

        {isFetchedFinprom && isEnabled && <Finprom />}
      </Page>
    </FinpromContextProvider>
  )
}
