import React from 'react'
import { useTranslation } from 'react-i18next'
import Text from '@node-space/storybook-components/dist/Text'
import { RecaptchaScoreError } from 'components/recaptcha/RecaptchaScoreError'
import { RecaptchaTokenError } from 'components/recaptcha/RecaptchaTokenError'
import { CreateAccountError, CreateAccountErrorType } from '../types'

export interface CreateAccountErrorMessageProps {
  errorType: CreateAccountErrorType
}

export const CreateAccountErrorMessage = ({ errorType }: CreateAccountErrorMessageProps) => {
  const { t } = useTranslation()

  switch (errorType) {
    case CreateAccountError.RECAPTCHA_TOKEN:
      return <RecaptchaTokenError />
    case CreateAccountError.RECAPTCHA_LOW_SCORE:
      return <RecaptchaScoreError />
    case CreateAccountError.UNAUTHORIZED:
      return (
        <Text color="inherit" size="sm">
          {t('createAccountError.error401')}
        </Text>
      )
    default:
      return null
  }
}
