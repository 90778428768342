import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import { SkeletonCard } from '@node-space/storybook-components/dist/SkeletonLoader'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Description } from 'components/Text/Description'
import { DocumentRejectedNotification } from 'components/verification/DocumentRejectedNotification'
import { DocumentRequestNotification } from 'components/verification/DocumentRequestNotification'
import { IndividualVerificationNotification } from 'components/verification/IndividualVerificationNotification'
import { PendingNotification } from 'components/verification/PendingNotification'
import { UploadNotification } from 'components/verification/UploadNotification'
import { useAccountsPersonsQuery } from 'hooks/queries/useAccountsPersonsQuery'
import { useRejectedDocumentsQuery } from 'hooks/queries/useRejectedDocumentQuery'
import { useRequestedDocumentsQuery } from 'hooks/queries/useRequestedDocumentQuery'
import { useIdVerification } from 'hooks/useIdVerification'

export const PendingVerificationView = () => {
  const { t } = useTranslation()
  const { enableRequestedDocuments, enableRejectedDocuments } = useFeatureFlags()

  const { isIdVerificationEnabled } = useIdVerification()

  const {
    data: requestedDocuments = [],
    isFetching: isFetchingRequestedDocuments,
    isError: isErrorRequestedDocuments,
  } = useRequestedDocumentsQuery(enableRequestedDocuments)

  const {
    data: rejectedDocuments = [],
    isFetching: isFetchingRejectedDocuments,
    isError: isErrorRejectedDocuments,
  } = useRejectedDocumentsQuery(enableRejectedDocuments)

  const {
    data: persons,
    isFetching: isFetchingPersons,
    isError: isErrorFetchingPersons,
  } = useAccountsPersonsQuery(isIdVerificationEnabled)

  const showDefaultPendingView = !requestedDocuments?.length && !rejectedDocuments?.length
  const isLoading = isFetchingRequestedDocuments || isFetchingRejectedDocuments || isFetchingPersons
  const isError = isErrorRequestedDocuments || isErrorRejectedDocuments || isErrorFetchingPersons

  return (
    <Box
      flex
      flexSize="fill"
      className="lg:px-0 max-w-[900px]"
      direction="col"
      gapY={16}
      paddingY={24}
      paddingX={20}
    >
      {isLoading && <SkeletonCard name="Pending verification" rowCount={2} />}

      {isError && !isLoading && (
        <StatusCard status="error" headline={t('requestErrors.pageErrorIntro')} borderless>
          <Description>
            {t('requestErrors.comeBackLater')}{' '}
            <TextAction isWindowReload text={t('requestErrors.refreshPage')} />.
          </Description>
        </StatusCard>
      )}

      {!isError && !isLoading && (
        <>
          {!!rejectedDocuments?.length && enableRejectedDocuments && (
            <DocumentRejectedNotification />
          )}
          {!!requestedDocuments?.length && <DocumentRequestNotification />}

          {showDefaultPendingView && (
            <>
              <PendingNotification />
              {isIdVerificationEnabled && !!persons?.length && (
                <IndividualVerificationNotification persons={persons} />
              )}
              <UploadNotification />
            </>
          )}
        </>
      )}
    </Box>
  )
}
