import { useMutation } from '@tanstack/react-query'
import { putOwnersData } from 'services/VerificationService'
import { BusinessOwnerSubmitData } from 'types/verification'

export const submitOwnersData = async ({ owners }: BusinessOwnerSubmitData) => {
  return putOwnersData(owners)
}

export const useBusinessOwnersMutation = () => {
  return useMutation({ mutationFn: submitOwnersData })
}
