import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { isValidPercentage } from 'utils/forms'
import { CorporateOwnerFormKeys } from '../BusinessOwners/types'

export const BusinessOwnersCorporateSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField } = useYupFields()

  return yup.object<Partial<Record<CorporateOwnerFormKeys, yup.AnySchema>>>({
    name: getRequiredYupField({
      label: t('verification.businessOwners.registeredBusinessName'),
    }),
    countryCode: getRequiredYupField({
      label: t('verification.businessOwners.countryOfRegistration'),
      requiredErrorMessage: t('formFields.countryIsRequired'),
    }),
    registrationNumber: getRequiredYupField({
      label: t('verification.businessOwners.businessRegistrationNumber'),
    }),
    ownershipPercentage: getRequiredYupField({
      label: t('verification.businessOwners.percentageOwnership'),
      requiredErrorMessage: t('formFields.validPercentageError'),
    }).test({
      name: 'testPercentage',
      test: (value, ctx) =>
        !value ||
        !!isValidPercentage(value) ||
        ctx.createError({
          message: t('formFields.validPercentageError'),
          path: 'ownershipPercentage',
        }),
    }),
    ownershipType: getRequiredYupField({
      label: t('verification.businessOwners.typeOfOwnership'),
    }),
  })
}
