import * as yup from 'yup'
import { TFunction } from 'i18next'
import { useYupFields } from 'hooks/forms/useYupFields'

export type GetStartedFormData = {
  businessType: string
}

export type GetStartedFormKeys = keyof GetStartedFormData

export const getStartedSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField } = useYupFields()

  return yup.object<Record<GetStartedFormKeys, yup.StringSchema>>({
    businessType: getRequiredYupField({
      label: t('formFields.typeOfBusiness'),
      requiredErrorMessage: t('formFields.requiredEntity'),
    }),
  })
}
