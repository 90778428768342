import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Description } from 'components/Text'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromResultModal } from 'pages/Finprom/components/FinpromResultModal'
import { FinpromStepKeys, FinpromTrackingLabels } from 'pages/Finprom/constants'
import {
  finpromSelfCategorisationPreferenceKey,
  SelfCategorisationData,
} from 'pages/Finprom/finpromPreferenceData'
import { useFinpromPreferenceRequest } from 'pages/Finprom/hooks/useFinpromPreferenceRequest'
import { useRetakeSelfCategorisation } from 'pages/Finprom/hooks/useRetakeSelfCategorisation'
import { TextAlignType } from 'types/text'

export const PassedModal = () => {
  const { t } = useTranslation()
  const { accountPreferences, setActiveStep } = useFinpromContext() || {}
  const { quizCategoryLabel, isLoadingRetake, isErrorRetake, onRetakeQuiz } =
    useRetakeSelfCategorisation() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<SelfCategorisationData>()

  const onContinue = () => {
    const currentSelfCaregorisationData =
      accountPreferences?.[finpromSelfCategorisationPreferenceKey]
    if (!currentSelfCaregorisationData?.isComplete) {
      const updatedSelfCaregorisationData: SelfCategorisationData = {
        ...currentSelfCaregorisationData,
        isComplete: true,
      }
      submitPreference(
        finpromSelfCategorisationPreferenceKey,
        updatedSelfCaregorisationData,
        FinpromTrackingLabels.SELF_CATEGORISATION_COMPLETED
      )
    } else {
      setActiveStep(FinpromStepKeys.APPROPRIATENESS_TEST)
    }
  }

  const retakeQuizAction: ButtonProps = {
    children: t('finprom.selfCategorisation.noRetakeQuiz'),
    secondary: true,
    loading: isLoadingRetake,
    disabled: isLoadingContinue,
    onClick: onRetakeQuiz,
  }

  const continueAction: ButtonProps = {
    children: t('yes'),
    loading: isLoadingContinue,
    disabled: isLoadingRetake,
    onClick: onContinue,
  }

  return (
    <FinpromResultModal
      title={`${t('result')}: ${quizCategoryLabel}`}
      icon={<Icon name={'CheckBadgeIcon'} />}
      actions={[retakeQuizAction, continueAction]}
      showError={isErrorRetake || isErrorContinue}
    >
      <Description align={TextAlignType.CENTER}>
        {`${t('finprom.selfCategorisation.thankYouForCompleting')} ${t(
          'finprom.selfCategorisation.resultUnsuccessful',
          { category: quizCategoryLabel?.toLocaleLowerCase() }
        )}`}
      </Description>
      <Description align={TextAlignType.CENTER}>
        {t('finprom.selfCategorisation.correctCategory')}
      </Description>
    </FinpromResultModal>
  )
}
