import React, { ReactNode } from 'react'
import { AccountContextProvider, AccountData } from 'contexts/accountContext'
import { OfflineContextProvider } from 'contexts/offlineContext'
import { ProfileContextProvider } from 'contexts/profileContext'
import { ToastContextProvider } from 'contexts/toastContext'
import { Profile } from 'types/authentication/Profile'

type DataProviderType<T> = {
  data: T
  isLoading: boolean
}

export interface AppProvidersProps {
  children: ReactNode
  account: DataProviderType<AccountData>
  profile: DataProviderType<Profile>
}

export const AppProviders = ({ children, profile, account }: AppProvidersProps) => {
  return (
    <OfflineContextProvider>
      <AccountContextProvider account={account?.data} isLoading={account?.isLoading}>
        <ProfileContextProvider profile={profile?.data} isLoading={profile?.isLoading}>
          <ToastContextProvider>{children}</ToastContextProvider>
        </ProfileContextProvider>
      </AccountContextProvider>
    </OfflineContextProvider>
  )
}
