import { useState } from 'react'

export type MappedErrorMessageState = {
  errorCode?: number
  errorType?: string
  handledMessage?: React.ReactNode
  sentryTraceId?: string
  show: boolean
}

export const useMappedErrorMessage = () => {
  const initialRequestError: MappedErrorMessageState = {
    show: false,
  }

  const [requestError, setRequestError] = useState<MappedErrorMessageState>(initialRequestError)

  const resetRequestError = () => setRequestError(initialRequestError)

  return { initialRequestError, requestError, setRequestError, resetRequestError }
}
