import { logSentryError } from '@node-space/utils'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { useAccountPreferenceMutation } from 'hooks/mutations/useAccountPreferenceMutation'
import { AccountPreferenceKey } from 'types/accounts'
import { trackOnboarding } from 'utils/tracker'

export const useFinpromPreferenceRequest = <T>() => {
  const { setAccountPreferences } = useFinpromContext()

  const {
    mutate: putFinpromPreference,
    isPending: isLoadingContinue,
    isError: isErrorContinue,
  } = useAccountPreferenceMutation<T>()

  const submitPreference = (preferenceKey: AccountPreferenceKey, value: T, stepLabel: string) => {
    putFinpromPreference(
      { preferenceKey, value },
      {
        onSuccess: () => {
          trackOnboarding(`Click - Finprom ${stepLabel} - Continue button`)
          setAccountPreferences(prev => ({
            ...prev,
            [preferenceKey]: value,
          }))
        },
        onError: error => {
          logSentryError(`Error: putFinpromPreference ${stepLabel}`, error)
        },
      }
    )
  }

  return { isLoadingContinue, isErrorContinue, submitPreference }
}
