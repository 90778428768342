import { useTranslation } from 'react-i18next'
import { Option as SelectOptionType } from '@node-space/storybook-components/dist/Select'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { AccountRepresentative } from 'types/verification'
import { BusinessDirector } from '../BusinessDirectors/types'
import { BusinessOwnerRequestData as BusinessOwnerData } from '../BusinessOwners/types'
import { AccountRepFormData } from '../schemas/accountRepSchema'

export const AccountRepOption = {
  CURRENT_REP: 'Myself',
  OWNER: 'A business owner',
  DIRECTOR: 'A business director',
  OTHER_REP: 'Someone else',
} as const

export type AccountRepType = (typeof AccountRepOption)[keyof typeof AccountRepOption]

type AccountRepOptionType = SelectOptionType & {
  value: AccountRepType
}

export const useAccountRep = () => {
  const { t } = useTranslation()

  const { profile } = useProfileContext()
  const { data: countryOptions = [], isLoading: isLoadingCountries } = useCountriesQuery(false)

  const accountRepOptions: AccountRepOptionType[] = [
    { value: AccountRepOption.CURRENT_REP, label: t('accountRepresentative.myself') },
    { value: AccountRepOption.DIRECTOR, label: t('accountRepresentative.businessDirector') },
    { value: AccountRepOption.OWNER, label: t('accountRepresentative.businessOwner') },
    { value: AccountRepOption.OTHER_REP, label: t('accountRepresentative.someoneElse') },
  ]

  const getDirectorOptions = (accountReps: AccountRepFormData[]): SelectOptionType[] => {
    return accountReps
      ?.filter(rep => rep?.accountRepType === AccountRepOption.DIRECTOR && !!rep?.firstName)
      ?.map((director: AccountRepFormData) => ({
        value: `${director?.id}`,
        label: `${director?.firstName} ${director?.lastName}`,
      }))
  }

  const getOwnerOptions = (accountReps: AccountRepFormData[]): SelectOptionType[] => {
    return accountReps
      ?.filter(rep => rep?.accountRepType === AccountRepOption.OWNER && !!rep?.firstName)
      ?.map(owner => ({
        value: `${owner?.id}`,
        label: `${owner?.firstName} ${owner?.lastName}`,
      }))
  }

  const getAccountRepFields = (data): AccountRepFormData => {
    return {
      accountRepType: data?.accountRepType ?? AccountRepOption.CURRENT_REP,
      accountRep: data?.accountRep ?? '',
      emailAddress: data?.emailAddress ?? '',
      firstName: data?.firstName ?? '',
      lastName: data?.lastName ?? '',
      dateOfBirth: data?.dateOfBirth ?? '',
      address1: data?.address1 ?? '',
      address2: data?.address2 ?? '',
      city: data?.city ?? '',
      postalCode: data?.postalCode ?? '',
      countryCode: data?.countryCode ?? '',
      id: data?.id ?? null,
    }
  }

  const getMyselfRep = (): AccountRepFormData =>
    getAccountRepFields({
      accountRepType: AccountRepOption.CURRENT_REP,
      firstName: profile?.firstName ?? '',
      lastName: profile?.lastName ?? '',
      emailAddress: profile?.emailAddress ?? '',
      id: profile?.id ?? '',
    })

  const getDirectorsRep = (directors: BusinessDirector[]): AccountRepFormData[] => {
    if (!directors?.length) {
      return [getAccountRepFields({ accountRepType: AccountRepOption.DIRECTOR })]
    }

    return directors?.map((director: BusinessDirector) => {
      const { id, ...rest } = director
      return getAccountRepFields({
        accountRepType: AccountRepOption.DIRECTOR,
        id: `${id}`,
        ...rest,
      })
    })
  }

  const getOwnersRep = (owners: BusinessOwnerData[]): AccountRepFormData[] => {
    const emptyOwnersRep = getAccountRepFields({ accountRepType: AccountRepOption.OWNER })
    if (!owners?.length) return [emptyOwnersRep]

    const individualOwners = owners?.filter(owner => !!owner?.firstName)
    if (!individualOwners?.length) return [emptyOwnersRep]

    return individualOwners?.map((owner: BusinessOwnerData) => {
      const { id, ...rest } = owner
      return getAccountRepFields({
        accountRepType: AccountRepOption.OWNER,
        id: `${id}`,
        ...rest,
      })
    })
  }

  const getSomeOneElseRep = (): AccountRepFormData => {
    return getAccountRepFields({
      accountRepType: AccountRepOption.OTHER_REP,
    })
  }

  const getAccountRepOptions = (
    loadedData: AccountRepresentative,
    directors: BusinessDirector[],
    owners: BusinessOwnerData[]
  ): {
    options: AccountRepFormData[]
    selectedOption: AccountRepFormData
  } => {
    const myselfRep: AccountRepFormData = getMyselfRep()
    const directorsRep: AccountRepFormData[] = getDirectorsRep(directors)
    const ownersRep: AccountRepFormData[] = getOwnersRep(owners)
    const someOneElseRep: AccountRepFormData = getSomeOneElseRep()

    if (!loadedData?.emailAddress) {
      return {
        options: [myselfRep, ...directorsRep, ...ownersRep, someOneElseRep],
        selectedOption: myselfRep,
      }
    }

    const { accountRepType, id, ...rest } = loadedData

    if (loadedData?.emailAddress === myselfRep?.emailAddress) {
      const updatedMyselfRep: AccountRepFormData = {
        ...myselfRep,
        ...rest,
        id: `${id}`,
      }
      return {
        options: [updatedMyselfRep, ...directorsRep, ...ownersRep, someOneElseRep],
        selectedOption: updatedMyselfRep,
      }
    }

    const directorIndex = directorsRep?.findIndex(
      director =>
        loadedData?.firstName === director?.firstName &&
        loadedData?.lastName === director?.lastName &&
        loadedData?.dateOfBirth === director?.dateOfBirth
    )
    const ownerIndex = ownersRep?.findIndex(
      owner =>
        loadedData?.firstName === owner?.firstName &&
        loadedData?.lastName === owner?.lastName &&
        loadedData?.dateOfBirth === owner?.dateOfBirth
    )

    if (directorIndex > -1) {
      const updatedDirectorRep: AccountRepFormData = {
        ...directorsRep?.[directorIndex],
        ...rest,
        accountRep: `${id}`,
        id: `${id}`,
      }
      directorsRep[directorIndex] = updatedDirectorRep
      return {
        options: [myselfRep, ...directorsRep, ...ownersRep, someOneElseRep],
        selectedOption: updatedDirectorRep,
      }
    }

    if (ownerIndex > -1) {
      const updatedOwnerRep: AccountRepFormData = {
        ...ownersRep?.[ownerIndex],
        ...rest,
        accountRep: `${id}`,
        id: `${id}`,
      }
      ownersRep[ownerIndex] = updatedOwnerRep
      return {
        options: [myselfRep, ...directorsRep, ...ownersRep, someOneElseRep],
        selectedOption: updatedOwnerRep,
      }
    }

    const updatedSomeOneElseRep: AccountRepFormData = { ...someOneElseRep, ...rest, id: `${id}` }
    return {
      options: [myselfRep, ...directorsRep, ...ownersRep, updatedSomeOneElseRep],
      selectedOption: updatedSomeOneElseRep,
    }
  }

  return {
    accountRepOptions,
    countryOptions,
    isLoadingCountries,
    getAccountRepFields,
    getDirectorOptions,
    getOwnerOptions,
    getAccountRepOptions,
  }
}
