import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromResult, FinpromTrackingLabels } from '../constants'
import {
  FinpromAttempts,
  finpromSelfCategorisationPreferenceKey,
  SelfCategorisationAnswers,
  SelfCategorisationData,
} from '../finpromPreferenceData'
import { FinpromResultType } from '../types'
import { getTotalAttempts, getUnsuccessfulAttempts } from '../utils'
import { useFinpromPreferenceRequest } from './useFinpromPreferenceRequest'

export const useSubmitSelfCategorisation = <AnswersType extends SelfCategorisationAnswers>() => {
  const { accountPreferences } = useFinpromContext() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<SelfCategorisationData>()

  const savedAnswers = accountPreferences?.[finpromSelfCategorisationPreferenceKey]
    ?.answers as AnswersType

  const onSubmitCategory = (categoryAnswers: AnswersType, isSuccessResult: boolean) => {
    const currentData = accountPreferences?.[finpromSelfCategorisationPreferenceKey]
    const categotyResultValue: FinpromResultType = isSuccessResult
      ? FinpromResult.PASSED
      : FinpromResult.UNSUCCESSFUL
    const attempts: FinpromAttempts = {
      total: getTotalAttempts(currentData?.attempts?.total),
      unsuccessful: getUnsuccessfulAttempts(currentData?.attempts?.unsuccessful, isSuccessResult),
    }
    const updatedSelfCategorisationData: SelfCategorisationData = {
      ...currentData,
      answers: categoryAnswers,
      result: categotyResultValue,
      attempts,
    }
    submitPreference(
      finpromSelfCategorisationPreferenceKey,
      updatedSelfCategorisationData,
      FinpromTrackingLabels.SELF_CATEGORISATION_CONFIRM_CATEGORY
    )
  }

  return { savedAnswers, isLoadingContinue, isErrorContinue, onSubmitCategory }
}
