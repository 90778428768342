import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import { logSentryError } from '@node-space/utils'
import { RedirectUri } from 'constants/General'
import { useAccountContext } from 'hooks/context/useAccountContext'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { useAccountPreferenceMutation } from 'hooks/mutations/useAccountPreferenceMutation'
import { trackOnboarding } from 'utils/tracker'
import { finpromCoolOffPreferenceKey, type FinpromCoolOffStatus } from '../finpromPreferenceData'
import { FinpromStepKey } from '../types'

export const useFinrpomPhaseOne = (activeStep: FinpromStepKey) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentAccount } = useAccountContext()
  const { setAccountPreferences } = useFinpromContext()

  const {
    mutate: putFinpromStatus,
    isPending: isLoadingContinue,
    isError: isErrorContinue,
  } = useAccountPreferenceMutation<FinpromCoolOffStatus>()

  const trackUkRiskModalEvent = (isContinue: boolean) => {
    const buttonLabel = isContinue ? 'Continue' : 'Exit'
    trackOnboarding(`Click - UK Risk ${activeStep} modal - ${buttonLabel} button`)
  }

  const onExit = () => {
    trackUkRiskModalEvent(false)
    navigate(RedirectUri.LOGOUT, { replace: true })
  }

  const submitFinpromStatus = (value: FinpromCoolOffStatus) => {
    putFinpromStatus(
      { preferenceKey: finpromCoolOffPreferenceKey, value },
      {
        onSuccess: () => {
          trackUkRiskModalEvent(true)
          setAccountPreferences(prev => ({
            ...prev,
            [finpromCoolOffPreferenceKey]: value,
          }))
        },
        onError: error => {
          logSentryError(`Error: putContinue ${activeStep} Finprom Modal`, error)
        },
      }
    )
  }

  const companyNameData = currentAccount?.name
  const companyName = !!companyNameData ? `${companyNameData}, ` : ''

  const exitAction: ButtonProps = {
    children: t('exit'),
    destructive: true,
    disabled: isLoadingContinue,
    onClick: onExit,
  }

  return {
    companyName,
    exitAction,
    isLoadingContinue,
    isErrorContinue,
    onExit,
    submitFinpromStatus,
  }
}
