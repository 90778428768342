import { requestApi } from 'api/api'
import { DynamicFieldsData, DynamicFieldsSection } from 'types/dynamicFields'

export const getDynamicFields = (sections: DynamicFieldsSection[]): Promise<DynamicFieldsData> => {
  return requestApi({
    url: '/accounts-management/v1/dynamic-fields',
    method: 'GET',
    params: {
      section: sections?.join(),
    },
  })
}
