import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getBusinessAddress } from 'services/VerificationService'

export const useBusinessAddressQuery = (isEnabled = true) => {
  return useQuery({
    queryKey: reactQueryKeys.businessAddress(),
    queryFn: getBusinessAddress,
    enabled: isEnabled,
  })
}
