import React, { createContext } from 'react'
import { UserAccount } from 'types/accounts'

export interface AccountData {
  currentAccount: UserAccount
}

interface AccountContextType extends AccountData {
  isLoading: boolean
}

interface AccountContextProviderProps {
  account: AccountData
  isLoading: boolean
  children: React.ReactNode
}

export const AccountContext = createContext<AccountContextType>({
  currentAccount: {
    id: null,
    name: '',
    isCurrent: true,
  },
  isLoading: false,
})

export const AccountContextProvider = ({
  account,
  isLoading,
  children,
}: AccountContextProviderProps) => {
  const { currentAccount } = account || {}

  const value = {
    currentAccount,
    isLoading,
  }

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
}
