import React from 'react'
import { Button } from '@node-space/storybook-components'
import { Logo } from './logos/Logo'

interface ErrorContentProps {
  errorInfo: string
}

const ErrorContent: React.FC<ErrorContentProps> = ({ errorInfo }) => {
  return (
    <div className="flex justify-center h-screen items-center">
      <div className="max-w-lg">
        <div>
          <div className="pb-3">
            <Logo />
          </div>
          <p className="text-lg">Unfortunately an error has occurred.</p>
        </div>
        <div className="pb-3">
          <details>
            <summary>Click for error details</summary>
            {errorInfo}
          </details>
        </div>
        <p className="pb-3">Please contact support</p>
        <div className="flex flex-col justify-center mt-3 items-center gap-3">
          <Button
            secondary
            onClick={() => {
              window.location.reload()
            }}
          >
            Reload
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorContent
