import { useEffect, useMemo, useState } from 'react'
import { Timer } from 'constants/General'
import { getRemainingMinutes, getRemainingMinutesFormatted } from 'utils/date'

let timerInterval

export const useCountdownTimer = (endDate: Date, addHours?: number) => {
  const [minutesLeft, setMinutesLeft] = useState<number>()

  const hasTimeLeft = useMemo(() => minutesLeft > 0, [minutesLeft])
  const timeLeftFormatted = useMemo(() => getRemainingMinutesFormatted(minutesLeft), [minutesLeft])

  const startCountdownTimer = () => {
    const minutesLeftInitial = getRemainingMinutes(endDate, addHours)
    setMinutesLeft(minutesLeftInitial)
    timerInterval = setInterval(() => {
      setMinutesLeft(currentMinutes => {
        const updatedMinutesLeft = currentMinutes - 1
        if (updatedMinutesLeft > 0) return updatedMinutesLeft
        clearInterval(timerInterval)
        return 0
      })
    }, Timer.ONE_MINUTE_IN_SECONDS)
  }

  const stopCountdownTimer = () => {
    timerInterval && clearInterval(timerInterval)
  }

  useEffect(() => {
    startCountdownTimer()
    return () => {
      stopCountdownTimer()
    }
  }, [])

  return { hasTimeLeft, timeLeftFormatted }
}
