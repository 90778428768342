import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getAccountPreferences } from 'services/AccountService'
import { ONE_DAY } from 'utils/utils'

export const useAccountPreferencesQuery = (isEnabled = true) => {
  return useQuery({
    queryKey: reactQueryKeys.accountPreferences(),
    queryFn: getAccountPreferences,
    staleTime: ONE_DAY,
    enabled: isEnabled,
  })
}
