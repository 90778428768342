import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Icon from '@node-space/storybook-components/dist/Icon'
import { Description } from 'components/Text'
import { DescriptionProps } from 'components/Text/Description'
import { FinpromResultModal } from 'pages/Finprom/components/FinpromResultModal'
import { TextAlignType } from 'types/text'
import { useRedirect } from 'utils/redirects'

export const UnsuccessfulModalThirdAttempt = () => {
  const { t } = useTranslation()
  const { onLogout } = useRedirect()

  const alignProp: Omit<DescriptionProps, 'children'> = { align: TextAlignType.CENTER }

  const exitAction: ButtonProps = {
    children: t('exit'),
    destructive: true,
    onClick: onLogout,
  }

  return (
    <FinpromResultModal
      title={t('unsuccessful')}
      icon={<Icon name="CloudLightningIcon" />}
      actions={[exitAction]}
      showError={false}
    >
      <Box>
        <Description {...alignProp}>
          {t('finprom.appropriateness.unsuccessful.introLineOne')}
        </Description>
        <Description {...alignProp}>
          {t('finprom.appropriateness.unsuccessful.notPassedThirdAttempt')}
        </Description>
      </Box>
      <Description {...alignProp}>
        {t('finprom.appropriateness.unsuccessful.reviewAndReachOut')}
      </Description>
    </FinpromResultModal>
  )
}
