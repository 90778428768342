import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Box, { Box as Container } from '@node-space/storybook-components/dist/Box'
import { StatusCard } from '@node-space/storybook-components/dist/StatusCard'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { LogoHeader } from 'components/header/LogoHeader'
import { ExternalLinks } from 'constants/ExternalLinks'
import { goToLogin, redirectToDashboard } from 'utils/utils'

const AccountError = () => {
  const { t } = useTranslation()
  const { state: error } = useLocation()
  const [sentryTraceId, setSentryTraceId] = useState(null)

  useEffect(() => {
    if (error) {
      const traceId = logSentryErrorAndGetTraceId('Account Error: ', error)
      setSentryTraceId(traceId)
    }
  }, [error])

  return (
    <Container height="screen" width="full" flex direction="col">
      <LogoHeader />
      <Box flex height="full" centerBox centerChildren maxWidth={896}>
        <StatusCard
          status="error"
          headline={t('requestErrors.errorHeading')}
          description={
            <>
              {` ${t('requestErrors.weApologiseForTheIncovenience')}`}
              {` ${t('requestErrors.pleaseTryTo')} `}
              <TextAction text={t('requestErrors.refreshPage')} onClick={redirectToDashboard} />
              {` ${t('requestErrors.or')} `}
              <TextAction text={t('requestErrors.logIn')} onClick={goToLogin} />
              {`. `}
              {` ${t('requestErrors.ifTheProblemPersists')} `}
              {` ${t('requestErrors.please')} `}
              <TextAction
                text={t('requestErrors.contactSupport')}
                linkTo={ExternalLinks.contactSupport}
              />
              {` ${t('requestErrors.forAssistance')}`}
            </>
          }
          borderless
        >
          {sentryTraceId && (
            <Text size="xs" color="grey">
              {t('requestErrors.traceId')}: {sentryTraceId}
            </Text>
          )}
        </StatusCard>
      </Box>
    </Container>
  )
}

export default AccountError
