import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { useAccountContacts } from 'components/accountContact/useAccountContacts'
import { isTempId } from 'components/accountContact/utils'
import { Description } from 'components/Text'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useBusinessDirectorsMutation } from 'hooks/mutations/useBusinessDirectorsMutation'
import { useBusinessDirectorsQuery } from 'hooks/queries/useBusinessDirectorsQuery'
import { useIdVerification } from 'hooks/useIdVerification'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { FormBody } from 'pages/Verification/_components/FormBody'
import { FormGetError } from 'pages/Verification/_components/FormGetError'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { FormSubmitError } from 'pages/Verification/_components/FormSubmitError'
import { NameTile, NameTilesContanier } from 'pages/Verification/_components/NameTile'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { BaseErrorResponse } from 'types/errors'
import { BusinessDirector } from '../types'
import { BusinessDirectorModal } from './BusinessDirectorModal'

export const BusinessDirectors = () => {
  const { t } = useTranslation()
  const { isIdVerificationEnabled } = useIdVerification()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { setUnsavedChanges, submitStep } = useVerificationContext()

  const {
    accountContacts,
    selectedAccountContact,
    countryOptions,
    isAccountContactOpen,
    isEditingAccountContact,
    isAccountContactsEmptyError,
    loadAccountContacts,
    getHasAccountContactsChanged,
    getIsAccountContactsValid,
    handleAddAccountContact,
    handleSaveAccountContact,
    handleEditAccountContact,
    handleDeleteAccountContact,
    handleCloseAccountContact,
  } = useAccountContacts<BusinessDirector>()

  const {
    data: businessDirectorsData,
    isFetching: isFetchingBusinessDirectors,
    isError: isGetError,
  } = useBusinessDirectorsQuery()

  const { mutate: putBusinessDirectors, isPending: isLoadingSubmitRequest } =
    useBusinessDirectorsMutation()

  const loadBusinessDirectors = () => {
    const savedDirectors: BusinessDirector[] = businessDirectorsData?.map(director => ({
      id: director?.id,
      firstName: director?.firstName ?? '',
      lastName: director?.lastName ?? '',
      dateOfBirth: director.dateOfBirth ?? '',
      address1: director?.address1 ?? '',
      address2: director?.address2 ?? '',
      city: director?.city ?? '',
      postalCode: director?.postalCode ?? '',
      countryCode: director?.countryCode ?? '',
    }))
    loadAccountContacts(savedDirectors)
  }

  const handleContinue = () => {
    resetRequestError()

    const isValid = getIsAccountContactsValid()
    if (!isValid) {
      return
    }

    const data: BusinessDirector[] = accountContacts?.map(({ id, ...directorData }) => ({
      ...(!isTempId(id) && { id: Number(id) }),
      ...directorData,
    }))
    putBusinessDirectors(data, {
      onSuccess: () => {
        submitStep(VerificationTrackingLabels.BUSINESS_DIRECTORS)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('putBusinessDirectors', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  useEffect(() => {
    if (!isFetchingBusinessDirectors && !!businessDirectorsData?.length) {
      loadBusinessDirectors()
    }
  }, [isFetchingBusinessDirectors])

  useEffect(() => {
    const isChanged = getHasAccountContactsChanged()
    setUnsavedChanges(isChanged)
  }, [accountContacts])

  return (
    <>
      <VerificationHeader header={t('verification.routes.businessDirectors')}>
        <Description>{t('verification.businessDirectors.subHeader')}</Description>
        {isIdVerificationEnabled && (
          <Description>{t('verification.businessDirectors.descriptionIdVerification')}</Description>
        )}
      </VerificationHeader>
      {isFetchingBusinessDirectors ? (
        <SkeletonForm name="Business directors" rowCount={1} />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <FormBody>
          {requestError?.show && (
            <FormSubmitError
              errorCode={requestError?.errorCode}
              sentryTraceId={requestError?.sentryTraceId}
            />
          )}

          {!!accountContacts?.length && (
            <NameTilesContanier>
              {accountContacts?.map((director, index) => (
                <NameTile
                  key={`${director.firstName}-${index}`}
                  tileId={index}
                  label={`${director.firstName} ${director.lastName}`}
                  onEdit={() => handleEditAccountContact(director?.id)}
                  onDelete={() => handleDeleteAccountContact(director?.id)}
                />
              ))}
            </NameTilesContanier>
          )}

          {isAccountContactsEmptyError && (
            <Callout
              message={t('accountRepresentative.noAddedDirectors')}
              state="error"
              showCloseButton={false}
            />
          )}

          <Button
            testid="addDirector"
            onClick={handleAddAccountContact}
            secondary
            iconElement={<Icon name="PlusIcon" color="primary-500" />}
            className="w-fit"
          >
            {t('verification.businessDirectors.addDirector')}
          </Button>

          <FormSubmitButton
            loading={isLoadingSubmitRequest}
            disabled={isAccountContactsEmptyError}
            onClick={handleContinue}
          />
        </FormBody>
      )}
      {isAccountContactOpen && (
        <BusinessDirectorModal
          initialData={selectedAccountContact}
          isEditing={isEditingAccountContact}
          countryOptions={countryOptions}
          onSave={handleSaveAccountContact}
          onDelete={handleDeleteAccountContact}
          onClose={handleCloseAccountContact}
        />
      )}
    </>
  )
}

export default BusinessDirectors
