import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { CopyButton } from '@node-space/storybook-components/dist/CopyButton'
import { Text } from '@node-space/storybook-components/dist/Text'

interface IdVerificationLinkProps {
  url: string
}

export const IdVerificationLink = ({ url }: IdVerificationLinkProps) => {
  const { t } = useTranslation()

  return (
    <Box
      flex
      border="gray"
      width="full"
      borderRadius={4}
      padding={12}
      justifyContent="between"
      alignItems="center"
    >
      <Text size="sm" className="select-none line-clamp-1" color="text-300">
        {url}
      </Text>
      <Box>
        <CopyButton confirmationMessage={t('copied')} copyText={url} animated />
      </Box>
    </Box>
  )
}
