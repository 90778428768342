export const CSATFeedbackValue = {
  BAD: 'Bad',
  OKAY: 'Okay',
  GOOD: 'Good',
} as const
export type CSATFeedbackValueType = (typeof CSATFeedbackValue)[keyof typeof CSATFeedbackValue]

export const CSATFeedbackRating = {
  BAD: 2,
  OKAY: 3,
  GOOD: 4,
} as const
export type CSATFeedbackRatingType = (typeof CSATFeedbackRating)[keyof typeof CSATFeedbackRating]

export const CSATOptionStatus = {
  DEFAULT: 'default',
  SELECTED: 'selected',
  UNSELECTED: 'unselected',
} as const
export type CSATOptionStatusType = (typeof CSATOptionStatus)[keyof typeof CSATOptionStatus]

export type CSATOptionType = {
  label: string
  value: CSATFeedbackValueType
  rating: CSATFeedbackRatingType
}

export type CSATCustomerType = {
  emailAddress: string
  firstname: string
  lastname: string
  companyName: string
  customerID: string
}
