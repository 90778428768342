import React from 'react'
import { useTranslation } from 'react-i18next'
import Text, { TextProps } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { ExternalLinks } from 'constants/ExternalLinks'

export interface MappedErrorMessageProps {
  errorCode?: number
  fallbackMessage?: React.ReactNode
}

export const MappedErrorMessage = ({ errorCode, fallbackMessage }: MappedErrorMessageProps) => {
  const { t } = useTranslation()

  const errorTextProps: TextProps = {
    color: 'inherit',
    size: 'sm',
  }

  const textAction = {
    contactSupport: (
      <TextAction
        text={t('contactSupportLowercase')}
        linkTo={ExternalLinks.contactSupport}
        inheritColor
      />
    ),
    expiredSession: (
      <TextAction text={t('requestErrors.expiredLoginSession')} isWindowReload inheritColor />
    ),
    reload: <TextAction text={t('requestErrors.refreshPage')} isWindowReload inheritColor />,
  }

  const tryLaterOrContactSupport = (
    <Text tag="span" {...errorTextProps}>
      {t('requestErrors.tryAgainLater')} {textAction.contactSupport}.
    </Text>
  )

  if (errorCode >= 500) {
    return (
      <Text {...errorTextProps}>
        {t('requestErrors.error5xx')} {tryLaterOrContactSupport}
      </Text>
    )
  }

  switch (errorCode) {
    case 401:
      return (
        <Text {...errorTextProps}>
          {t('requestErrors.error401')} {textAction.expiredSession}.
        </Text>
      )
    case 403:
      return (
        <Text {...errorTextProps}>
          {t('requestErrors.error403')} {textAction.contactSupport}.
        </Text>
      )
    case 429:
      return (
        <Text {...errorTextProps}>
          {t('requestErrors.error429')} {tryLaterOrContactSupport}
        </Text>
      )

    default:
      return (
        <Text {...errorTextProps}>
          {fallbackMessage ?? t('requestErrors.errorOccured')}
          {` ${t('requestErrors.please')} `}
          {textAction.reload}
          {` ${t('requestErrors.or')} `}
          {textAction.contactSupport}.
        </Text>
      )
  }
}
