import { useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { FieldError } from 'react-hook-form'
import {
  DateKeyType,
  DateType,
  DateOfBirthLabels,
  DateErrorType,
} from '@node-space/storybook-components/dist/components/DateOfBirth'
import { formatDob } from '@node-space/utils'

export const useDateOfBirthInput = () => {
  const { t } = useTranslation()

  const dateOfBirthLabels: DateOfBirthLabels = {
    dateOfBirth: t('formFields.dateOfBirth'),
    day: t('formFields.day'),
    month: t('formFields.month'),
    year: t('formFields.year'),
    errorMessage: '',
  }

  const initialValue: DateType = {
    day: '',
    month: '',
    year: '',
  }

  const initialErrors: DateErrorType = {
    day: false,
    month: false,
    year: false,
  }

  const dateOfBirthErrorsAll: DateErrorType = {
    day: true,
    month: true,
    year: true,
  }

  const [dateOfBirthErrors, setDateOfBirthErrors] = useState<DateErrorType>(initialErrors)

  const hasDateOfBirthErrors: boolean = Object.values(dateOfBirthErrors)?.indexOf(true) !== -1

  const getDateOfBirthFields = (value: string): DateType => {
    const values = value?.split(',')
    const dateOfBirth = { ...initialValue }
    Object.keys(dateOfBirth).forEach((key, index) => {
      dateOfBirth[key] = values?.[index] ?? ''
    })
    return dateOfBirth
  }

  const setDateOfBirthInputValue = (date: DateType): string => {
    return Object.values(date).join()
  }

  const getDateOfBirthErrors = (error: FieldError): DateErrorType => {
    return error?.type === 'required' ? dateOfBirthErrorsAll : dateOfBirthErrors
  }

  const getDateOfBirthErrorText = (error: FieldError): string => {
    if (!!error?.message) {
      return error.message
    }

    const fieldLabels: DateType = {
      day: t('formFields.dayLowercase'),
      month: t('formFields.monthLowercase'),
      year: t('formFields.yearLowercase'),
    }

    const errorFields: string[] = Object.keys(dateOfBirthErrors)
      ?.filter((key: DateKeyType) => dateOfBirthErrors[key])
      ?.map((key: DateKeyType) => fieldLabels[key])

    if (!errorFields?.length) return ''

    let message = `${t('formFields.enterValidValue')} `
    if (errorFields?.length === 1) {
      message += `${errorFields.join()}.`
    } else {
      const lastIndex = errorFields?.length - 1
      message += `${errorFields?.slice(0, lastIndex).join(', ')} ${t('and')} ${
        errorFields[lastIndex]
      }.`
    }

    return message
  }

  const formatDateOfBirthData = (value: string): string => {
    if (!value) return ''
    return dayjs(value).format('DD,MM,YYYY')
  }

  const formatDateOfBirthInput = (value: string): string => {
    if (!value) return ''
    const dateValues = value?.split(',')
    const formattedDate = formatDob(dateValues[0], dateValues[1], dateValues[2])
    return formattedDate === 'invalid' ? '' : formattedDate
  }

  return {
    dateOfBirthLabels,
    hasDateOfBirthErrors,
    getDateOfBirthFields,
    setDateOfBirthInputValue,
    setDateOfBirthErrors,
    getDateOfBirthErrors,
    getDateOfBirthErrorText,
    formatDateOfBirthData,
    formatDateOfBirthInput,
  }
}
