import { useState } from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { AccountContactPayload } from 'components/accountContact/accountContactV2/types'
import { BaseErrorResponse } from 'types/errors'
import { HandleSubmit } from 'types/reactQuery'
import { useCreateIdVerificationLinkMutation } from './mutations/useIdVerificationMutation'

export type IdVerificationMessageInfo = {
  linkUrl: string
  showMessage: boolean
  setShowMessage: React.Dispatch<React.SetStateAction<boolean>>
}

export const useIdVerification = () => {
  const { enableIdentityVerification } = useFeatureFlags()

  const [showMessage, setShowMessage] = useState(false)
  const [linkUrl, setLinkUrl] = useState('')

  const { mutate: createLink, isPending } = useCreateIdVerificationLinkMutation()

  const onCreateLink = ({
    payload,
    onSuccess,
    onError,
  }: HandleSubmit<AccountContactPayload, string>) => {
    createLink(payload, {
      onSuccess: idVerificationLink => {
        setLinkUrl(idVerificationLink)
        setShowMessage(true)
        onSuccess?.(idVerificationLink)
      },
      onError: (error: BaseErrorResponse) => {
        onError(error)
      },
    })
  }

  const idVerificationMessage: IdVerificationMessageInfo = {
    linkUrl,
    showMessage,
    setShowMessage,
  }

  return {
    isIdVerificationEnabled: !!enableIdentityVerification,
    createIdVerificationLink: {
      isPending,
      onCreateLink,
    },
    idVerificationMessage,
  }
}
