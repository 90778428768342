import { AccountContactFormData } from 'components/accountContact/types'
import {
  StepState,
  VerificationRequestStatus,
  VerificationRoutes,
  VerificationRouteStatus,
} from 'constants/verification'
import { FinpromVerificationStepKey } from 'pages/Finprom/types'
import { AccountRepType } from 'pages/Verification/AccountRepresentative/useAccountRep'
import { BusinessDirector } from 'pages/Verification/BusinessDirectors/types'
import { BusinessOwnerRequestData } from 'pages/Verification/BusinessOwners/types'
import { Profile } from 'types/authentication/Profile'

export type VerificationRequestStatus =
  (typeof VerificationRequestStatus)[keyof typeof VerificationRequestStatus]

export type VerificationRouteKey = (typeof VerificationRoutes)[keyof typeof VerificationRoutes]

export type VerificationRouteStatus =
  (typeof VerificationRouteStatus)[keyof typeof VerificationRouteStatus]

export type VerificationRoutesStatus = Record<VerificationRouteKey, VerificationRouteStatus>

export type VerificationStep = {
  key: VerificationRouteKey
  status: VerificationRouteStatus
}

export type StepState = (typeof StepState)[keyof typeof StepState]

export type MenuItemType = {
  key: VerificationRouteKey | FinpromVerificationStepKey
  title: string
  state: StepState
  onClick: () => void
}

export interface AccountRepresentative extends AccountContactFormData {
  id?: number
  emailAddress: string
  accountRep?: string
  accountRepType?: AccountRepType
}

export interface BusinessDirectorOptions extends BusinessDirector {
  value: string
  label: string
}

export interface DateOfBirth {
  day: string
  month: string
  year: string
}

export interface ErrorList {
  emailAddress: {
    status: boolean
    message: string
  }
  firstName: {
    status: boolean
    message: string
  }
  lastName: {
    status: boolean
    message: string
  }
  dateOfBirth: {
    status: boolean
    message: string
  }
  addressLineOne: {
    status: boolean
    message: string
  }
  city: {
    status: boolean
    message: string
  }
  postalCode: {
    status: boolean
    message: string
  }
  country: {
    status: boolean
    message: string
  }
}

export type AccountRepDataType = BusinessDirectorOptions[] | BusinessOwnerRequestData[]
export type AccountRepresentativeType =
  | BusinessDirector
  | BusinessOwnerRequestData
  | AccountRepresentative
  | Profile

export interface BusinessEntity {
  id: number
  name: string
  supported: boolean
}

export interface EntityType {
  id: number
  name: string
  supported: boolean
}

export interface GetStartedDetails {
  id: number
  entityTypeId?: number
  entityType?: EntityType
}

export type GetStartedPayload = {
  id: number
  entityTypeId: number
}

export interface Industries {
  id: number
  name: string
}

// Supported Currency Types - NB This maps to backend values!
export const AcceptedCurrencies = {
  FIAT: 1,
  CRYPTO: 2,
} as const
export type AcceptedCurrenciesType = (typeof AcceptedCurrencies)[keyof typeof AcceptedCurrencies]

export interface SourceOfFunds {
  id?: number
  currencyType: number
  sourceOfFundsDescription?: string
  cryptoAddress?: string
}

export type BusinessDetailsPayload = {
  legalEntityName: string
  legalEntityRegistrationNumber: string
  tradingName?: string
  incomeTaxNumber: string
  industryId?: number
  industryReference?: string
  isOperationLicenseRequired: boolean
  operatingLicenseType: string
  operatingLicenseIssuer: string
}

export interface BusinessAddressDetails {
  address1: string
  address2: string
  city: string
  countryCode: string
  id: number
  postalCode: string
}

export interface BusinessAddressFormFields {
  address1: string
  address2: string
  city: string
  postalCode: string
  countryCode: string
}

export interface MonthlyVolume {
  id: number
  max: number
  min: number
  name: string
}

export type ServiceNeedErrorType = 'noService' | 'noDescription'

export interface ServiceNeed {
  id: number
  checked: boolean
  label: string
  note: string
  optionalLabel?: string
  optionalValue?: string
}

export interface BusinessActivityDetails {
  id: number
  businessActivity: string
  website: string
  accountServiceNeeds: ServiceNeed[]
  monthlyExpectedVolumeId: number
  monthlyExpectedVolumeName: string
}

export interface BusinessVerificationStatus extends VerificationRoutesStatus {
  id?: number
}

export interface BusinessVerificationStatusData {
  id?: number
  status: BusinessVerificationStatus
}

export type ServiceNeedsPayload = {
  id: number
  checked: boolean
  optionalValue?: string
}

export type BusinessActivityPayload = {
  id: number
  businessActivity: string
  website: string
  monthlyExpectedVolumeId: number
  accountServiceNeeds: ServiceNeedsPayload[]
}

export interface AgreementAcceptancePayload {
  masterServicesAgreement: boolean
  platformAgreement: boolean
  authorityToAct: boolean
  agreementIds?: number[]
}

export interface BusinessDocument {
  code: string
  file: File
}

export interface RejectedDocument {
  id: number
  file: File
}

export interface File {
  name: string
  base64: string
}

export interface PersonDocumentsPayload {
  [key: number]: BusinessDocument[]
}

export interface BusinessDocumentsPayload {
  accountDocuments?: BusinessDocument[]
  financeDocuments?: BusinessDocument[]
  representativeDocuments?: BusinessDocument[]
  corporateOwnersDocuments?: PersonDocumentsPayload
  directorsDocuments?: PersonDocumentsPayload
  individualOwnersDocuments?: PersonDocumentsPayload
  rejectedDocuments?: RejectedDocument[]
}

export interface BusinessDocumentResponse {
  id: number
  code: string
  documentRequestId?: number
  file?: File
  legalEntityId?: number
}

export interface PersonDocumentsResponse {
  [key: number]: BusinessDocumentResponse[]
}

export interface BusinessDocumentsResponse {
  accountDocuments?: BusinessDocumentResponse[]
  financeDocuments?: BusinessDocumentResponse[]
  representativeDocuments?: BusinessDocumentResponse[]
  corporateOwnersDocuments?: PersonDocumentsResponse
  directorsDocuments?: PersonDocumentsResponse
  individualOwnersDocuments?: PersonDocumentsResponse
}

export interface DocumentUpload {
  id?: number
  buffer: string | ArrayBuffer
  documentType?: string
  fileData: {
    path: string
    lastModified: number
    lastModifiedDate: Date
    name: string
    size: number
    type: string
    webkitRelativePath: string
  }
}

export interface RequiredDocumentsDetails {
  account?: RequiredDocument[]
  accountRepresentative?: RequiredDocument[]
  corporateOwners?: PersonDocuments[]
  directors?: PersonDocuments[]
  finance?: RequiredDocument[]
  individualOwners?: PersonDocuments[]
}

export interface PersonDocuments {
  documents: RequiredDocument[]
  firstName?: string
  id: number
  lastName?: string
  name?: string
}

export interface RequiredDocument {
  code: string
  filename?: string
}

type DocumentTitle = string | ((person: PersonDocuments) => string)

type TooltipContent = {
  title: string
  bulletList?: string[]
}

export type DocumentFields = {
  name: DocumentNameType
  title: string
  tooltipContent?: TooltipContent
  permanentWarning?: string
}

export type UploadedFile = {
  name?: DocumentNameType
  ownerId?: number
  file?: DocumentUpload
}

export type PartialDocumentList = Partial<Record<DocumentNameType, UploadedFile>>
type PartialMessages = Partial<Record<DocumentNameType, DocumentTitle>>

export type DocumentCategories =
  | 'account'
  | 'finance'
  | 'corporateOwners'
  | 'individualOwners'
  | 'directors'
  | 'accountRepresentative'

type DocumentsAccount =
  | 'certificateOfIncorporation'
  | 'shareholderCertificate'
  | 'shareholderStructureChart'
  | 'operatingLicense'
  | 'proofOfAddress'
  | 'memorandumArticlesOfAssociation'
  | 'registerOfDirectors'
  | 'amlPolicy'

type DocumentsFinancial = 'sourceOfFunds'
type DocumentsCorporateOwners = 'certificateOfIncorporation' | 'shareholderRegister'
type DocumentsPerson = 'proofOfId' | 'proofOfAddress'

export type DocumentNameType =
  | DocumentsAccount
  | DocumentsFinancial
  | DocumentsCorporateOwners
  | DocumentsPerson

export type PartialCategoryList = Record<DocumentCategories, PartialDocumentList>
export type PartialUploadMessageList = Record<DocumentCategories, PartialMessages>
export type Documents = Record<DocumentCategories, DocumentFields[]>

export type CountList = Record<DocumentCategories, number>

export interface PreferenceData {
  id?: number
  name: string
  value: boolean
}
