import { useEffect } from 'react'
import { deleteAuthCookie, getAuthCookie } from 'utils/auth/utils'

/**
 * Deletes authentication cookie if it exists
 */
export const useDeleteAuthCookie = () => {
  useEffect(() => {
    const cookie = getAuthCookie()
    if (cookie) {
      deleteAuthCookie()
    }
  }, [])
}
