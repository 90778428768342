export const reactQueryKeys = {
  accountAgreements: () => ['accountAgreements'],
  accountContacts: () => ['accountContacts'],
  accountPreferences: () => ['accountPreferences'],
  accountRepresentative: () => ['accountRepresentative'] as const,
  businessActivity: () => ['businessActivity'],
  businessAddress: () => ['businessAddress'],
  businessDetails: () => ['businessDetails'],
  businessDirectors: () => ['businessDirectors'],
  businessEntities: () => ['businessEntities'],
  businessOwners: () => ['businessOwners'],
  businessVerificationStatus: () =>
    [...reactQueryKeys.verification, 'businessVerificationStatus'] as const,
  country: () => ['detectCountry'],
  countries: (canEnable: boolean, pagination: boolean) => ['countries', canEnable, pagination],
  dynamicFields: () => ['dynamicFields'],
  fiatCurrencies: () => ['fiatCurrencies'],
  getStarted: () => ['getStarted'],
  industries: () => ['industries'],
  monthlyVolumes: () => ['monthlyVolumes'],
  profile: () => ['profile'],
  reissueToken: () => ['reissueToken'],
  rejectedDocuments: () => ['rejectedDocuments'] as const,
  rejectedDocumentUrl: (documentId: number) => ['rejectedDocuments', documentId] as const,
  requestedDocuments: () => ['requestedDocuments'] as const,
  requiredDocuments: () => ['requiredDocuments'] as const,
  serviceNeeds: () => ['serviceNeeds'],
  sourceOfFunds: () => ['sourceOfFunds'],
  userAccounts: () => ['userAccounts'],
  verification: ['verification'] as const,
  verificationProcessStatus: () => ['verificationProcessStatus'],
  verificationRequestStatus: () =>
    [...reactQueryKeys.verification, 'verificationRequestStatus'] as const,
  getAccountPersons: () => ['accountPersons'],
}
