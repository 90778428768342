import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import isString from 'lodash/isString'
import { getReissueTokenParams } from 'services/AuthService'
import { QueryMetaType } from 'types/reactQuery'
import { AuthToken } from 'types/types'
import { setAuthCookies } from 'utils/auth/utils'

export const useRefreshTokenQuery = (accessToken: string, secret: string) => {
  const location = useLocation()

  const shouldRun = useMemo(() => isString(accessToken) && isString(secret), [accessToken, secret])

  const meta: QueryMetaType = {
    errorAdditionalData: { location },
    onSuccess(freshToken: AuthToken) {
      setAuthCookies({ ...freshToken })
    },
  }

  return useQuery({
    queryKey: reactQueryKeys.reissueToken(),
    queryFn: () => getReissueTokenParams({ access_token: accessToken, secret }),
    enabled: shouldRun,
    meta,
  })
}
