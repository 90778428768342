import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getIndustries } from 'services/VerificationService'

export const useIndustriesQuery = (enableSubcategory: boolean) => {
  return useQuery({
    queryKey: reactQueryKeys.industries(),
    queryFn: () => getIndustries(enableSubcategory),
  })
}
