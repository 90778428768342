import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stepper } from '@node-space/storybook-components/dist/Stepper'
import { Container } from 'components/Container'
import { SelfCategorisationStepKeys, selfCategorisationStepList } from 'pages/Finprom/constants'
import { SelfCategorisationData } from 'pages/Finprom/finpromPreferenceData'
import { SelfCategorisationCategory, SelfCategorisationStepKey } from 'pages/Finprom/types'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { scrollToTop } from 'utils/utils'
import { SelfCategorisationResultModal } from './SelfCategorisationResultModal'
import { SelfCategorisationStep } from './SelfCategorisationStep'

export const SelfCategorisationSteps = ({ category, answers, result }: SelfCategorisationData) => {
  const { t } = useTranslation()

  const [activeStep, setActiveStep] = useState<SelfCategorisationStepKey>(
    !!category
      ? SelfCategorisationStepKeys?.CONFIRM_CATEGORY
      : SelfCategorisationStepKeys?.SELECT_CATEGORY
  )
  const [selectedCategory, setSelectedCategory] = useState<SelfCategorisationCategory>(category)

  const onContinueToConfirmCategory = () => {
    setActiveStep(SelfCategorisationStepKeys.CONFIRM_CATEGORY)
  }

  const onRetakeCategoryQuestions = () => {
    setSelectedCategory(null)
    setActiveStep(SelfCategorisationStepKeys.SELECT_CATEGORY)
  }

  useEffect(() => scrollToTop(), [activeStep])

  useEffect(() => {
    if (category && !answers) {
      onContinueToConfirmCategory()
    }
    if (!category) {
      onRetakeCategoryQuestions()
    }
  }, [category, answers])

  return (
    <Container>
      <VerificationHeader header={t('finprom.steps.selfCategorisation')} />
      <Stepper<SelfCategorisationStepKey>
        stepKeys={selfCategorisationStepList}
        activeStepKey={activeStep}
        onBack={stepKey => setActiveStep(stepKey)}
      />
      <SelfCategorisationStep
        activeStep={activeStep}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        onContinue={onContinueToConfirmCategory}
      />
      {!!result && <SelfCategorisationResultModal result={result} />}
    </Container>
  )
}
