import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getRequiredDocuments } from 'services/VerificationService'

export const useRequiredDocumentsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.requiredDocuments(),
    queryFn: getRequiredDocuments,
    refetchOnWindowFocus: false,
    staleTime: 0,
    gcTime: 0,
  })
}
