import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonProps } from '@node-space/storybook-components/dist/components/Button'
import { ModalActions, ModalBody } from '@node-space/storybook-components/dist/Modal'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Description } from 'components/Text'
import { ExternalLinks } from 'constants/ExternalLinks'
import { FinpromStepKeys } from 'pages/Finprom/constants'
import { FinpromSubmitError } from '../../components/FinpromSubmitError'
import { useFinrpomPhaseOne } from '../useFinrpomPhaseOne'

export const CoolOffModal = () => {
  const { t } = useTranslation()
  const { companyName, exitAction, isLoadingContinue, isErrorContinue, submitFinpromStatus } =
    useFinrpomPhaseOne(FinpromStepKeys.COOL_OFF)

  const onContinue = () => {
    submitFinpromStatus(true)
  }

  const continueAction: ButtonProps = {
    children: t('continue'),
    loading: isLoadingContinue,
    onClick: onContinue,
  }

  return (
    <>
      <ModalBody>
        {isErrorContinue && <FinpromSubmitError />}

        <Description>
          {`${companyName}${
            !!companyName
              ? t('ukRiskModal.postCoolOffIntroLowercase')
              : t('ukRiskModal.postCoolOffIntro')
          }`}
        </Description>

        <Description>
          {t('ukRiskModal.needHelp')}{' '}
          <TextAction text={t('contactSupportLowercase')} linkTo={ExternalLinks.contactSupport} />.
        </Description>
      </ModalBody>
      <ModalActions actions={[exitAction, continueAction]} />
    </>
  )
}
