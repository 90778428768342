import React, { useEffect } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Option as RadioOption } from '@node-space/storybook-components/dist/components/Radio/Radio'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { TextArea } from '@node-space/storybook-components/dist/TextArea'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useSourceOfFundsMutation } from 'hooks/mutations/useSourceOfFundsMutation'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { BaseErrorResponse } from 'types/errors'
import { AcceptedCurrencies, SourceOfFunds } from 'types/verification'
import { FormBody } from '../_components/FormBody'
import { FormSubmitButton } from '../_components/FormSubmitButton'
import { FormSubmitError } from '../_components/FormSubmitError'
import {
  SourceOfFundsFormData,
  SourceOfFundsFormKeys,
  SourceOfFundsSchema,
} from '../schemas/sourceOfFundsSchema'
import { SourceOfFundsDocumentsCallout } from './SourceOfFundsDocumentsCallout'

export type SourceOfFundsFormProps = {
  defaultValues: SourceOfFundsFormData
}

export const SourceOfFundsForm = ({ defaultValues }: SourceOfFundsFormProps) => {
  const { t } = useTranslation()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { setUnsavedChanges, submitStep } = useVerificationContext()

  const { mutate: putSourceOfFunds, isPending: isLoadingSubmitRequest } = useSourceOfFundsMutation()

  const currencyTypeOptions: RadioOption[] = [
    {
      label: t('currenciesFiat'),
      value: `${AcceptedCurrencies.FIAT}`,
    },
    { label: t('currenciesCrypto'), value: `${AcceptedCurrencies.CRYPTO}` },
  ]

  const formSchema = SourceOfFundsSchema(t)
  const form = useForm<SourceOfFundsFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    clearErrors,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = form

  const formValues = useWatch({ control })

  const { setBaseField, setRadioField, setInputField } = useFormFields<SourceOfFundsFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: { [key in SourceOfFundsFormKeys] } = {
    currencyType: setRadioField({
      key: 'currencyType',
      options: currencyTypeOptions,
      onChangeAdditional: value => {
        if (value === `${AcceptedCurrencies.FIAT}`) {
          clearErrors('cryptoAddress')
        }
      },
    }),
    sourceOfFundsDescription: setBaseField({
      key: 'sourceOfFundsDescription',
      showLabel: true,
    }),
    cryptoAddress: setInputField({
      key: 'cryptoAddress',
      placeholder: t('verification.sourceOfFunds.cryptoAddressPlaceholder'),
    }),
  }

  const onSubmit: SubmitHandler<typeof formValues> = (formValues: SourceOfFundsFormData) => {
    if (!isDirty) {
      submitStep(VerificationTrackingLabels.SOURCE_OF_FUNDS)
      return
    }
    resetRequestError()

    const updatedSourceOfFundsData: SourceOfFunds = {
      currencyType: Number(formValues?.currencyType),
      sourceOfFundsDescription: formValues?.sourceOfFundsDescription,
      cryptoAddress: formValues?.cryptoAddress,
    }

    putSourceOfFunds(updatedSourceOfFundsData, {
      onSuccess: () => {
        submitStep(VerificationTrackingLabels.SOURCE_OF_FUNDS)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('putSourceOfFunds', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  useEffect(() => {
    setUnsavedChanges(isDirty)
  }, [isDirty])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBody>
        {requestError?.show && (
          <FormSubmitError
            errorCode={requestError?.errorCode}
            sentryTraceId={requestError?.sentryTraceId}
          />
        )}

        <Radio {...formFields?.currencyType} horizontal borderless={false} />

        <TextArea {...formFields?.sourceOfFundsDescription} />

        {formValues?.currencyType === `${AcceptedCurrencies.CRYPTO}` && (
          <Input {...formFields?.cryptoAddress} />
        )}

        <SourceOfFundsDocumentsCallout />

        <FormSubmitButton
          loading={isSubmitting || isLoadingSubmitRequest}
          disabled={!!Object.keys(errors)?.length}
        />
      </FormBody>
    </form>
  )
}
