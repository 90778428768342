import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import Box, { Box as Divider, Box as IntroBox } from '@node-space/storybook-components/dist/Box'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import { Option as RadioOption } from '@node-space/storybook-components/dist/components/Radio/Radio'
import { ErrorText, InputLabel } from '@node-space/storybook-components/dist/FormElements'
import Input from '@node-space/storybook-components/dist/Input'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { useFormFields } from 'hooks/forms/useFormFields'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { YesNoValues } from 'pages/Finprom/constants'
import { useSubmitSelfCategorisation } from 'pages/Finprom/hooks/useSubmitSelfCategorisation'
import {
  SophisticatedFormData,
  SophisticatedFormKeys,
  SophisticatedSchema,
} from 'pages/Finprom/schemas/sophisticatedSchema'
import { getSophisticatedFormResult } from 'pages/Finprom/utils'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

export const SophisticatedRadioValues = {
  ...YesNoValues,
  DOES_NOT_APPLY: 'doesNotApply',
} as const
type SophisticatedRadioValue =
  (typeof SophisticatedRadioValues)[keyof typeof SophisticatedRadioValues]

type SophisticatedRadioOption = RadioOption & {
  value: SophisticatedRadioValue
}

export const SophisticatedInvestorForm = () => {
  const { t } = useTranslation()
  const { savedAnswers, isLoadingContinue, isErrorContinue, onSubmitCategory } =
    useSubmitSelfCategorisation<SophisticatedFormData>() || {}

  const defaultValues: SophisticatedFormData = {
    isCertificateReceived: savedAnswers?.isCertificateReceived,
    authorisedFirm: savedAnswers?.authorisedFirm ?? '',
    isRiskAccepted: !!savedAnswers?.isRiskAccepted,
  }

  const optionsA: SophisticatedRadioOption[] = [
    {
      value: SophisticatedRadioValues.YES,
      secondaryLabel: t('yes'),
    },
    {
      value: SophisticatedRadioValues.NO,
      secondaryLabel: t('no'),
    },
    {
      value: SophisticatedRadioValues.DOES_NOT_APPLY,
      secondaryLabel: t('doesNotApplyToMe'),
    },
  ]

  const formSchema = SophisticatedSchema(t)
  const form = useForm<SophisticatedFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = form

  const formValues = useWatch({ control })

  const { setRadioField, setInputField, setCheckboxField } = useFormFields<SophisticatedFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: Record<SophisticatedFormKeys, any> = {
    isCertificateReceived: setRadioField({
      key: 'isCertificateReceived',
      options: optionsA,
      onChangeAdditional: value => {
        if (value !== YesNoValues.YES) {
          clearErrors('authorisedFirm')
        }
      },
    }),
    authorisedFirm: setInputField({
      key: 'authorisedFirm',
      placeholder: t('finprom.selfCategorisation.sophisticatedForm.questionAFirmPlaceholder'),
    }),
    isRiskAccepted: setCheckboxField({ key: 'isRiskAccepted' }),
  }

  const onSubmit = () => {
    const sophisticatedInvestorAnswers = formValues as SophisticatedFormData
    const isSophisticatedInvestor = getSophisticatedFormResult(sophisticatedInvestorAnswers)
    onSubmitCategory(sophisticatedInvestorAnswers, isSophisticatedInvestor)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        {isErrorContinue && <FinpromSubmitError />}
        <IntroBox>
          <InputLabel>
            {t('finprom.selfCategorisation.sophisticatedForm.statementTitle')}
          </InputLabel>
          <Description>
            {t('finprom.selfCategorisation.sophisticatedForm.statementParagraph')}
          </Description>
        </IntroBox>
        <Radio {...formFields.isCertificateReceived} hasParagraphLayout />
        {formValues?.isCertificateReceived === SophisticatedRadioValues.YES && (
          <>
            <Description>
              {t('finprom.selfCategorisation.sophisticatedForm.questionAFirmIntro')}
            </Description>
            <Input {...formFields.authorisedFirm} />
          </>
        )}
        <Divider divider="bottom" />
        <Box>
          <CheckBox {...formFields.isRiskAccepted} labelColour="light" />
          {errors?.isRiskAccepted && <ErrorText text={errors?.isRiskAccepted?.message} />}
        </Box>
        <FormSubmitButton disabled={!!Object.keys(errors)?.length} loading={isLoadingContinue} />
      </Container>
    </form>
  )
}
