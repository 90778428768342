import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getAccountRepresentative } from 'services/VerificationService'

export const useAccountRepresentativeQuery = (isEnabled = true) => {
  return useQuery({
    queryKey: reactQueryKeys.accountRepresentative(),
    queryFn: getAccountRepresentative,
    enabled: isEnabled,
  })
}
