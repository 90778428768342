import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalActions, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import Text from '@node-space/storybook-components/dist/Text'

export interface UnsavedChangesModalProps {
  isOpen: boolean
  onStay: () => void
  onLeave: () => void
}

export const UnsavedChangesModal = ({ isOpen, onStay, onLeave }: UnsavedChangesModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal visible={isOpen} closeModal={onStay}>
      <ModalNavBar title={t('unsavedChanges')} onClose={onStay} />

      <Text size="sm" color="grey" className="p-6">
        {t('unsavedChangesDescription')}
      </Text>

      <ModalActions
        actions={[
          {
            children: t('unsavedChangesStayOnPage'),
            secondary: true,
            onClick: onStay,
          },
          {
            children: t('unsavedChangesLeave'),
            destructive: true,
            onClick: onLeave,
          },
        ]}
      />
    </Modal>
  )
}
