import React from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { BusinessDirectors as BusinessDirectorsV1 } from './BusinessDirectorsV1/BusinessDirectors'
import { BusinessDirectors as BusinessDirectorsV2 } from './BusinessDirectorsV2/BusinessDirectors'

export const BusinessDirectors = () => {
  const { enableAccountContactsCrud } = useFeatureFlags()

  return !!enableAccountContactsCrud ? <BusinessDirectorsV2 /> : <BusinessDirectorsV1 />
}
