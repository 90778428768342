import { useTranslation } from 'react-i18next'
import { DocumentUpload } from '@node-space/storybook-components/dist/UploadFile'
import { DynamicDocumentData } from 'types/dynamicFields'
import { DocumentCategories } from './constants'
import { getDocumentTooltip } from './documentTooltips'
import { documentWarnings } from './documentWarnings'
import {
  DocumentActionId,
  DocumentActions,
  DocumentCategoryKey,
  DocumentCode,
  MappedDocument,
  UploadedDocument,
} from './types'
import { getDocumentTitleCode, getUploadedFile } from './utils'

export const useCategoryDocuments = (
  category: DocumentCategoryKey,
  requiredDocuments: DynamicDocumentData[],
  uploadedDocuments: UploadedDocument[],
  documentActions: DocumentActions
) => {
  const { t } = useTranslation()

  const documentCount = {
    uploaded:
      requiredDocuments?.filter(document => document?.provided)?.length + uploadedDocuments?.length,
    total: requiredDocuments?.length,
  }

  const mappedDocuments: MappedDocument[] = !!requiredDocuments?.length
    ? requiredDocuments
        ?.map(document => {
          const code = document?.code as DocumentCode
          const contactName = document?.value?.contact?.name
          const contactReference = document?.value?.contact?.reference ?? ''
          const isIndividual = category === DocumentCategories.INDIVIDUALS
          const documentId: DocumentActionId = { category, code, contactReference }
          const titleCode = getDocumentTitleCode(category, code)
          const warning = documentWarnings?.includes(code)
            ? t(`uploadDocuments.documentWarning.${code}`)
            : ''
          return {
            id: documentId,
            title: `${contactName ? `${contactName} ` : ''}${t(`uploadDocuments.documentName.${titleCode}`)}`,
            tooltip: getDocumentTooltip(t, code),
            ...(!!warning && !isIndividual && { warning }),
            file: getUploadedFile(uploadedDocuments, document),
            provided: document?.provided,
            onChooseFile: (file: DocumentUpload) => {
              documentActions?.onUploadDocument({ documentId, file })
            },
            onRemoveFile: () => {
              documentActions?.onRemoveDocument(documentId)
            },
          }
        })
        ?.sort((documentA, documentB) => {
          if (documentA?.provided === documentB?.provided) {
            return documentA?.title.localeCompare(documentB?.title)
          }
          return documentA?.provided && !documentB?.provided ? 1 : -1
        })
    : []

  return { documentCount, mappedDocuments }
}
