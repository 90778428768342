import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { AppropriatenessStepList, FinpromResult } from '../constants'
import {
  AppropriatenessData,
  AppropriatenessQuestion,
  AppropriatenessQuestionsData,
  finpromAppropriatenessTestPreferenceKey,
} from '../finpromPreferenceData'
import {
  AppropriatenessQuestionOption,
  AppropriatenessResult,
  AppropriatenessStepKey,
} from '../types'
import { getAppropriatenessFormsResult, getTotalAttempts, getUnsuccessfulAttempts } from '../utils'
import { useFinpromPreferenceRequest } from './useFinpromPreferenceRequest'

export const useFinpromAppropriatenessForm = <OptionsType extends string>() => {
  const { t } = useTranslation()
  const { accountPreferences, appropriatenessActiveFormStep, setAppropriatenessActiveFormStep } =
    useFinpromContext()
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<AppropriatenessData>()

  const currentData: AppropriatenessData =
    accountPreferences?.[finpromAppropriatenessTestPreferenceKey]
  const savedAnswer = currentData?.questions?.[appropriatenessActiveFormStep]?.answer as OptionsType

  const [questionAnswer, setQuestionAnswer] = useState<OptionsType>(savedAnswer)
  const [isQuestionError, setIsQuestionError] = useState(false)

  const questionErrorText = t('emptySelect')

  const getQuestionOptions = (
    optionKeyList: OptionsType[]
  ): AppropriatenessQuestionOption<OptionsType>[] => {
    return optionKeyList?.map((optionKey: OptionsType) => ({
      value: optionKey,
      secondaryLabel: t(
        `finprom.appropriateness.${appropriatenessActiveFormStep}.options.${optionKey}`
      ),
    }))
  }

  const handleSelectAnswer = (value: OptionsType) => {
    setQuestionAnswer(value)
    if (isQuestionError) {
      setIsQuestionError(false)
    }
  }

  const getNextStep = (): AppropriatenessStepKey => {
    const currentStepIndex = AppropriatenessStepList?.indexOf(appropriatenessActiveFormStep)
    return AppropriatenessStepList?.[currentStepIndex + 1]
  }

  const onSubmitQuestion = (isCorrectAnswer: boolean, trackingLabel: string) => {
    if (!questionAnswer) {
      setIsQuestionError(true)
      return
    }
    const nextStep = getNextStep()
    if (nextStep && questionAnswer === savedAnswer) {
      setAppropriatenessActiveFormStep(nextStep)
      return
    }
    const activeQuestionData: AppropriatenessQuestion = {
      answer: questionAnswer,
      isCorrect: isCorrectAnswer,
    }
    const updatedQuestionsData: AppropriatenessQuestionsData = {
      ...currentData?.questions,
      [appropriatenessActiveFormStep]: activeQuestionData,
    }
    const updatedData: AppropriatenessData = {
      ...currentData,
      questions: updatedQuestionsData,
    }
    if (!nextStep) {
      const lastStepData: AppropriatenessResult =
        getAppropriatenessFormsResult(updatedQuestionsData)
      const currentAttempts = currentData?.attempts?.total
      const isSuccessfulAttempt = lastStepData?.result === FinpromResult.PASSED
      updatedData.dateCompleted = new Date()
      updatedData.correctQuestionsTotal = lastStepData?.correctQuestionsTotal
      updatedData.result = lastStepData?.result
      updatedData.attempts = {
        total: getTotalAttempts(currentAttempts),
        unsuccessful: getUnsuccessfulAttempts(currentAttempts, isSuccessfulAttempt),
      }
    }
    submitPreference(finpromAppropriatenessTestPreferenceKey, updatedData, trackingLabel)
  }

  return {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  }
}
