import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TOKEN_PARAM, getBase64DecodeToString, getDecodedToken, setAuthCookies } from '../utils'

const useAuthRedirect = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get(TOKEN_PARAM)

  useEffect(() => {
    if (token) {
      const tokenString = getBase64DecodeToString(token)
      setSearchParams('')

      if (tokenString) {
        const [access_token, secret] = getDecodedToken(tokenString)
        if (access_token && secret) {
          try {
            setAuthCookies({ access_token, secret })
            navigate('/')
          } catch (error) {
            console.error('Error: Auth Cookie setting', error)
          }
        }
      }
    }
  }, [token])

  return token
}

export default useAuthRedirect
