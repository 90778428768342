export interface Address {
  address1: string
  address2: string
  city: string
  postalCode: string
  countryCode: string
  country?: string
}

export interface Identification {
  reference: string
  url: string
  status: IndividualVerificationStatus
  dateCreated: string
  lastUpdated: string
}

export interface PersonDetails {
  reference?: string
  firstName: string
  lastName: string
  dateOfBirth?: string
  address?: Address
  identifications?: Identification[]
}

export const IndividualVerificationStatus = {
  COMPLETE: 'COMPLETE',
  PENDING: 'PENDING',
} as const

export type IndividualVerificationStatus =
  (typeof IndividualVerificationStatus)[keyof typeof IndividualVerificationStatus]
