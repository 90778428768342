import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'

export const FormGetError = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleRefresh = () => window.location.reload()
  const handleLogIn = () => navigate('/login')

  return (
    <Box paddingT={24} aria-label="get error callout">
      <Callout state="error" showCloseButton={false}>
        {t('requestErrors.getErrorIntro')}{' '}
        <Box tag="button" className="hover:underline" onClick={handleRefresh}>
          {t('requestErrors.refreshPage')}
        </Box>
        {` ${t('requestErrors.or')} `}
        <Box tag="button" className="hover:underline" onClick={handleLogIn}>
          {t('requestErrors.logIn')}
        </Box>
        .
      </Callout>
    </Box>
  )
}
