import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { Option } from '@node-space/storybook-components/dist/Select'
import { getCountries } from 'services/ReferenceService'
import { ONE_DAY } from 'utils/utils'

export const useCountriesQuery = (canEnable = true, pagination = false) => {
  return useQuery({
    queryKey: reactQueryKeys.countries(canEnable, pagination),
    queryFn: () => getCountries(canEnable, pagination),
    staleTime: ONE_DAY,
    select: response => {
      const options: Option[] = response?.map(country => ({
        label: country?.name,
        value: country?.code,
      }))
      return options
    },
  })
}
