import { requestApi } from 'api/api'
import { BusinessDirector } from 'pages/Verification/BusinessDirectors/types'
import {
  BusinessOwnerRequestData,
  BusinessOwnerSubmitData,
} from 'pages/Verification/BusinessOwners/types'
import { Country } from 'types/authentication/Profile'
import { RejectedDocument, RequestedDocument } from 'types/types'
import {
  AccountRepresentative,
  AgreementAcceptancePayload,
  BusinessActivityDetails,
  BusinessActivityPayload,
  BusinessAddressDetails,
  BusinessAddressFormFields,
  BusinessDetailsPayload,
  BusinessDocumentsPayload,
  BusinessDocumentsResponse,
  BusinessEntity,
  BusinessVerificationStatusData,
  GetStartedDetails,
  GetStartedPayload,
  Industries,
  MonthlyVolume,
  PreferenceData,
  RequiredDocumentsDetails,
  ServiceNeed,
  SourceOfFunds,
} from 'types/verification'
import { asyncQueue, documentPayloadToArray } from 'utils/utils'

export const detectCountry = async () => {
  return await requestApi({
    url: '/country/detect',
    method: 'GET',
    authenticated: false,
  })
}

// Get Started

export const getEntityTypes = (): Promise<BusinessEntity[]> => {
  return requestApi({
    url: '/business/account/entity-type',
    method: 'GET',
    authenticated: false,
  })
}

export const getGetStartedDetails = (): Promise<GetStartedDetails> => {
  return requestApi({
    url: '/business/account/get-started',
    method: 'GET',
  })
}

export const putGetStarted = (data: GetStartedPayload): Promise<void> => {
  return requestApi({
    url: '/business/account/get-started',
    method: 'PUT',
    data,
  })
}

// Business Details

export const getIndustries = (enableSubcategory: boolean): Promise<Industries[]> => {
  return requestApi({
    url: enableSubcategory ? '/accounts-management/v1/industries' : '/business/account/industry',
    method: 'GET',
  })
}

export const getBusinessDetails = (): Promise<BusinessDetailsPayload> => {
  return requestApi({
    url: '/v2/business/account/business-details',
    method: 'GET',
  })
}

export const putBusinessDetails = (data: BusinessDetailsPayload): Promise<void> => {
  return requestApi({
    url: '/v2/business/account/business-details',
    method: 'PUT',
    data,
  })
}

// Source of funds

export const getSourceOfFunds = (): Promise<SourceOfFunds> => {
  return requestApi({
    url: '/business/account/source-of-funds',
    method: 'GET',
  })
}

export const putSourceOfFunds = (data: SourceOfFunds): Promise<SourceOfFunds> => {
  return requestApi({
    url: '/business/account/source-of-funds',
    method: 'PUT',
    data,
  })
}

// Business activity

export const getBusinessActivityDetails = (): Promise<BusinessActivityDetails> => {
  return requestApi({
    url: '/business/account/business-activity',
    method: 'GET',
  })
}

export const putBusinessActivity = (data: BusinessActivityPayload): Promise<void> => {
  return requestApi({
    url: '/business/account/business-activity',
    method: 'PUT',
    data,
  })
}

export const getAllMonthlyVolumes = (): Promise<MonthlyVolume[]> => {
  return requestApi({
    url: '/v1/business/monthly-volumes',
    method: 'GET',
  })
}

export const getAllServicesNeeds = (): Promise<ServiceNeed[]> => {
  return requestApi({
    url: '/business/account/service-needs',
    method: 'GET',
  })
}

// Business address

export const getBusinessAddress = (): Promise<BusinessAddressDetails> => {
  return requestApi({
    url: '/business/account/business-address',
    method: 'GET',
  })
}

export const putBusinessAddress = (
  data: BusinessAddressFormFields
): Promise<BusinessAddressDetails> => {
  return requestApi({
    url: '/business/account/business-address',
    method: 'PUT',
    data,
  })
}

export const getCountries = (pagination = false): Promise<Country[]> => {
  return requestApi({
    url: '/country',
    params: {
      pagination,
    },
    method: 'GET',
    authenticated: false,
  })
}

// Business owners

export const getOwnersData = (): Promise<BusinessOwnerRequestData[]> => {
  return requestApi({
    url: '/business/account/business-owners',
    method: 'GET',
  })
}

export const putOwnersData = (
  data: BusinessOwnerSubmitData[]
): Promise<BusinessOwnerSubmitData[]> => {
  return requestApi({
    url: '/business/account/business-owners',
    method: 'PUT',
    data,
  })
}

// Business directors

export const getDirectorsData = (): Promise<BusinessDirector[]> => {
  return requestApi({
    url: '/business/account/business-directors',
    method: 'GET',
  })
}

export const putBusinessDirectors = (data: BusinessDirector[]): Promise<BusinessDirector[]> => {
  return requestApi({
    url: '/business/account/business-directors',
    method: 'PUT',
    data,
  })
}

// Account representative

export const getAccountRepresentative = (): Promise<AccountRepresentative> => {
  return requestApi({
    url: '/business/account/account-representative',
    method: 'GET',
  })
}

export const putAccountRepresentative = (
  data: AccountRepresentative
): Promise<AccountRepresentative> => {
  return requestApi({
    url: '/business/account/account-representative',
    method: 'PUT',
    data,
  })
}

// Upload documents

export const getRequiredDocuments = (): Promise<RequiredDocumentsDetails> => {
  return requestApi({
    url: '/business/account/required-documents',
    method: 'GET',
  })
}

export const postBusinessDocuments = (
  data: BusinessDocumentsPayload
): Promise<BusinessDocumentsResponse> => {
  return requestApi({
    url: '/business/account/business-documents',
    method: 'POST',
    data,
    timeout: 300000, // 5 minutes
  })
}

export const postBusinessDocumentsQueue = (
  data: BusinessDocumentsPayload,
  successToast?: () => void,
  errorToast?: () => void
): Promise<BusinessDocumentsResponse[]> => {
  const queueList = documentPayloadToArray(data)
  return asyncQueue(
    queueList,
    postBusinessDocuments,
    () => successToast && successToast(),
    () => errorToast && errorToast()
  )
}

// Review and finish

export const getBusinessVerificationStatus = (): Promise<BusinessVerificationStatusData> => {
  return requestApi({
    url: '/v2/business/account/business-verification-status',
    method: 'GET',
  })
}

export const putVerificationRequest = (): Promise<boolean> => {
  return requestApi({
    url: '/business/account/submit-verification-request',
    method: 'PUT',
    data: {},
  })
}

export const putShowVerifiedBanner = (value: boolean): Promise<PreferenceData> => {
  return requestApi({
    url: '/account/preference/showVerifiedBanner',
    method: 'PUT',
    data: {
      value,
    },
  })
}

export const postAgreementAcceptance = (data: AgreementAcceptancePayload): Promise<void> => {
  return requestApi({
    url: '/business/account/agreement-acceptance',
    method: 'POST',
    data,
  })
}

// Pending view

export const getRequestedDocuments = (): Promise<RequestedDocument[]> => {
  return requestApi({
    url: '/business/account/requested-documents',
    method: 'GET',
  })
}

export const getRejectedDocuments = (): Promise<RejectedDocument[]> => {
  return requestApi({
    url: '/accounts-management/v1/accounts/documents',
    method: 'GET',
    params: {
      status: 'DECLINED',
    },
  })
}

export const getRejectedDocumentUrl = (documentId: number): Promise<string> => {
  return requestApi({
    url: `/accounts-management/v1/accounts/documents/${documentId}/url`,
    method: 'GET',
  })
}
