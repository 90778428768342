import React from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { UploadDocuments as UploadDocumentsV1 } from './UploadDocumentsV1/UploadDocuments'
import { UploadDocuments as UploadDocumentsV2 } from './UploadDocumentsV2/UploadDocuments'

export const UploadDocuments = () => {
  const { enableDynamicDocuments } = useFeatureFlags()

  return !!enableDynamicDocuments ? <UploadDocumentsV2 /> : <UploadDocumentsV1 />
}
