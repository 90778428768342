import { Toast } from '@node-space/storybook-components'
import React, { useCallback, useEffect, useState, createContext } from 'react'

export interface ToastObject {
  title: string
  state: 'info' | 'warning' | 'error' | 'success'
  delay?: number
}
type ContextType = (toast: ToastObject) => void

const ToastContext = createContext<ContextType>(() => null)

export default ToastContext

export const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState([])
  const delay = toasts?.[0]?.delay || 8

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => setToasts(toasts => toasts.slice(1)), delay * 1000)
      return () => clearTimeout(timer)
    }
  }, [toasts])

  const addToast = useCallback(
    (toast: ToastObject) => {
      setToasts(toasts => [...toasts, toast])
    },
    [setToasts]
  )

  const dismissToast = (index: number) => {
    const newToasts = toasts.filter(t => t !== toasts[index])
    setToasts(newToasts)
  }

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className="fixed flex flex-col right-0 pl-4 md:items-end top-2.5 pr-4 z-30 overflow-hidden">
        {toasts.map((toast, index) => (
          <div
            className="animate-fade-in-down bg-white toast-notification rounded mb-1"
            key={`toast-object-${index}`}
          >
            <Toast
              title={toast.title}
              state={toast.state}
              onClickClose={() => dismissToast(index)}
            />
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  )
}
