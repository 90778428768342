import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import cloneDeep from 'lodash/cloneDeep'
import {
  Box as DescriptionContainer,
  Box as ExpectedVolumeContainer,
  Box as ServiceNeedsContainer,
} from '@node-space/storybook-components/dist/Box'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Option, Select } from '@node-space/storybook-components/dist/Select'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextArea } from '@node-space/storybook-components/dist/TextArea'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { CheckboxAccordion } from 'components/_storybook/CheckboxAccordion'
import { Description } from 'components/Text'
import { VerificationTrackingLabels } from 'constants/verification'
import { useAccountContext } from 'hooks/context/useAccountContext'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useBusinessActivityMutation } from 'hooks/mutations/useBusinessActivityMutation'
import { useBusinessActivityQuery } from 'hooks/queries/useBusinessActivityQuery'
import { useMonthlyVolumesQuery } from 'hooks/queries/useMonthlyVolumesQuery'
import { useServiceNeedsQuery } from 'hooks/queries/useServiceNeedsQuery'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { BaseErrorResponse } from 'types/errors'
import {
  BusinessActivityPayload,
  ServiceNeed,
  ServiceNeedErrorType,
  ServiceNeedsPayload,
} from 'types/verification'
import { FormBody } from '../_components/FormBody'
import { FormGetError } from '../_components/FormGetError'
import { FormSubmitButton } from '../_components/FormSubmitButton'
import { FormSubmitError } from '../_components/FormSubmitError'
import { VerificationHeader } from '../_components/VerificationHeader'
import {
  BusinessActivityFormData,
  BusinessActivityFormKeys,
  businessActivitySchema,
} from '../schemas/businessActivitySchema'
import { ProductErrorType, ServiceItem } from './ServiceItem'

const BusinessActivity = () => {
  const { t } = useTranslation()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { currentAccount } = useAccountContext()
  const { setUnsavedChanges, submitStep } = useVerificationContext()

  const {
    data: serviceNeeds,
    isFetching: isFetchingServiceNeeds,
    isError: isServiceNeedsGetError,
  } = useServiceNeedsQuery()

  const {
    data: monthlyVolumes,
    isFetching: isFetchingMonthlyVolumes,
    isError: isMonthlyVolumesGetError,
  } = useMonthlyVolumesQuery()

  const {
    data: businessActivityDetails,
    isFetching: isFetchingBusinessActivityDetails,
    isError: isBusinessActivityGetError,
  } = useBusinessActivityQuery()

  const { mutate: putBusinessActivity, isPending: isLoadingSubmitRequest } =
    useBusinessActivityMutation()

  const [tempServiceNeeds, setTempServiceNeeds] = useState<ServiceNeed[]>([])
  const [isAllProductsChecked, setIsAllProductsChecked] = useState(false)
  const [openProductDescripton, setOpenProductDescripton] = useState<number>(null)

  const monthlyVolumesOptions: Option[] = monthlyVolumes?.map(data => ({
    value: data.name,
    label: data.name,
  }))

  const isLoading = useMemo(
    () => isFetchingServiceNeeds || isFetchingMonthlyVolumes || isFetchingBusinessActivityDetails,
    [isFetchingServiceNeeds, isFetchingMonthlyVolumes, isFetchingBusinessActivityDetails]
  )

  const isGetError = useMemo(
    () => isServiceNeedsGetError || isMonthlyVolumesGetError || isBusinessActivityGetError,
    [isServiceNeedsGetError, isMonthlyVolumesGetError, isBusinessActivityGetError]
  )

  const formSchema = businessActivitySchema(t)

  const form = useForm<BusinessActivityFormData>({
    defaultValues: {
      description: '',
      website: '',
      expectedMonthlyVolume: '',
      serviceNeeds: [],
    },
    resolver: yupResolver(formSchema),
  })

  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = form

  const formValues = useWatch({ control })

  const { getLabel, setInputField, setTextAreaField, setSelectField } =
    useFormFields<BusinessActivityFormKeys>(formSchema, form, formValues)

  const formFields: { [key in BusinessActivityFormKeys] } = {
    description: setTextAreaField({ key: 'description' }),
    website: setInputField({
      key: 'website',
      showLabel: false,
      placeholder: 'https://example.com',
    }),
    expectedMonthlyVolume: setSelectField({
      key: 'expectedMonthlyVolume',
      options: monthlyVolumesOptions,
      placeholder: t('formFields.chooseOption'),
    }),
    serviceNeeds: setInputField({ key: 'serviceNeeds' }),
  }

  useEffect(() => {
    setUnsavedChanges(isDirty)
  }, [isDirty])

  const updateProducts = (updatedProducts: ServiceNeed[]) => {
    setTempServiceNeeds(updatedProducts)
    setValue('serviceNeeds', updatedProducts, {
      shouldDirty: true,
      shouldValidate: true,
    })
  }

  const updateIsAllChecked = () => {
    if (!tempServiceNeeds.length) return
    const checkedLength = tempServiceNeeds?.filter(
      product => !product.optionalLabel && product.checked
    )?.length
    const totalCheckedCount = tempServiceNeeds?.length - 1
    const isCheckedMatch = checkedLength === totalCheckedCount
    if (isAllProductsChecked && !isCheckedMatch) {
      setIsAllProductsChecked(false)
    }
    if (!isAllProductsChecked && isCheckedMatch) {
      setIsAllProductsChecked(true)
    }
  }

  const checkAllProducts = (value: boolean) => {
    const updatedProducts = cloneDeep(tempServiceNeeds)
    updatedProducts.forEach(product => {
      if (!product.optionalLabel) {
        product.checked = value
      }
    })
    updateProducts(updatedProducts)
  }

  const handleCheckAllProducts = useCallback(() => {
    setIsAllProductsChecked(current => {
      const isAllChecked = !current
      checkAllProducts(isAllChecked)
      return isAllChecked
    })
  }, [checkAllProducts])

  const handleCheckProduct = useCallback(
    (id: number, value: boolean) => {
      const updatedProducts = cloneDeep(tempServiceNeeds)
      const productIndex = updatedProducts.findIndex(product => product.id === id)
      updatedProducts[productIndex].checked = value
      updateProducts(updatedProducts)
    },
    [tempServiceNeeds, updateProducts]
  )

  const handleOptionalValueChange = useCallback(
    (id: number, value: string) => {
      const updatedProducts = cloneDeep(tempServiceNeeds)
      const objIndex = updatedProducts.findIndex(obj => obj.id === id)
      updatedProducts[objIndex].optionalValue = value
      updateProducts(updatedProducts)
    },
    [tempServiceNeeds, updateProducts]
  )

  useEffect(() => {
    if (serviceNeeds) {
      setTempServiceNeeds(cloneDeep(serviceNeeds))
    }
  }, [serviceNeeds])

  useEffect(() => {
    //No business activity details saved yet
    if (!businessActivityDetails?.id && serviceNeeds) {
      form.reset()
      setTempServiceNeeds(cloneDeep(serviceNeeds))
      setValue('serviceNeeds', cloneDeep(serviceNeeds))
    }

    if (businessActivityDetails?.businessActivity) {
      setValue('description', businessActivityDetails?.businessActivity)
    }

    if (businessActivityDetails?.website) {
      setValue('website', businessActivityDetails?.website)
    }

    if (businessActivityDetails?.monthlyExpectedVolumeName) {
      setValue('expectedMonthlyVolume', businessActivityDetails?.monthlyExpectedVolumeName)
    }

    if (businessActivityDetails?.accountServiceNeeds) {
      setTempServiceNeeds(businessActivityDetails?.accountServiceNeeds)
      setValue('serviceNeeds', businessActivityDetails?.accountServiceNeeds)
    }
    reset({}, { keepValues: true })
  }, [businessActivityDetails, serviceNeeds])

  useEffect(() => {
    updateIsAllChecked()
  }, [tempServiceNeeds])

  const onSubmit: SubmitHandler<typeof formValues> = () => {
    if (!isDirty) {
      submitStep(VerificationTrackingLabels.BUSINESS_ACTIVITY)
      return
    }
    resetRequestError()

    const selectedMonthlyVolume = monthlyVolumes?.find(
      monthlyVolume => monthlyVolume.name === formValues.expectedMonthlyVolume
    )

    const payloadServiceNeeds: ServiceNeedsPayload[] = tempServiceNeeds.map(item => {
      return { id: item.id, checked: item.checked, optionalValue: item.optionalValue }
    })

    const data: BusinessActivityPayload = {
      id: currentAccount?.id,
      businessActivity: formValues.description,
      website: formValues.website,
      monthlyExpectedVolumeId: selectedMonthlyVolume?.id,
      accountServiceNeeds: payloadServiceNeeds,
    }

    putBusinessActivity(data, {
      onSuccess: () => {
        submitStep(VerificationTrackingLabels.BUSINESS_ACTIVITY)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('putBusinessActivity', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  const productError: ProductErrorType = {
    type: errors?.['serviceNeeds']?.type as ServiceNeedErrorType,
    message: errors?.['serviceNeeds']?.message,
  }

  return (
    <>
      <VerificationHeader
        header={t('verification.routes.businessActivity')}
        subHeader={t('verification.businessActivitySubHeader')}
      />
      {isLoading ? (
        <SkeletonForm name="Business activity" />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormBody>
            {requestError?.show && (
              <FormSubmitError
                errorCode={requestError?.errorCode}
                sentryTraceId={requestError?.sentryTraceId}
              />
            )}

            <DescriptionContainer flex direction="col" gapY={4}>
              <TextArea {...formFields.description} testid="business-activity-description" />
              {!errors['description'] && (
                <Description>{t('formFields.businessActivityDescription')}</Description>
              )}
            </DescriptionContainer>

            <Input
              {...formFields.website}
              label={
                <>
                  {getLabel('website')}
                  <Text tag="span" size="sm" color="grey" className="pl-1">
                    {t('optional')}
                  </Text>
                </>
              }
              testid="business-activity-website"
            />

            <ExpectedVolumeContainer flex direction="col" gapY={16}>
              <Text tag="h2" size="lg" weight="medium">
                {t('verification.expectedMonthlyVolumes')}
              </Text>
              <Select {...formFields.expectedMonthlyVolume} />
            </ExpectedVolumeContainer>

            <ServiceNeedsContainer flex direction="col" gapY={16}>
              <Text tag="h2" size="lg" weight="medium">
                {t('verification.bvnkProductsRequired')}
              </Text>
              <CheckboxAccordion
                id="service-item-all"
                label={t('formFields.all')}
                checked={isAllProductsChecked}
                onCheck={handleCheckAllProducts}
                hasError={productError?.type === 'noService'}
              />
              {tempServiceNeeds?.map(product => {
                return (
                  <ServiceItem
                    key={product.id}
                    product={product}
                    isDescriptionOpen={openProductDescripton === product.id}
                    error={{
                      type: productError?.type as ServiceNeedErrorType,
                      message: productError?.message,
                    }}
                    onCheckProduct={handleCheckProduct}
                    onOptionalValueChange={handleOptionalValueChange}
                    onToggleDescription={(id: number) => {
                      setOpenProductDescripton(current => (current !== id ? id : null))
                    }}
                  />
                )
              })}
              {productError?.type === 'noService' && (
                <Description color="error">{productError?.message}</Description>
              )}
            </ServiceNeedsContainer>

            <FormSubmitButton
              loading={isSubmitting || isLoadingSubmitRequest}
              disabled={!!Object.keys(errors)?.length}
            />
          </FormBody>
        </form>
      )}
    </>
  )
}

export default BusinessActivity
