import React, { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Box as GroupFields,
  Box as InlineFields,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import {
  DateErrorType,
  DateOfBirth,
  DateType,
} from '@node-space/storybook-components/dist/DateOfBirth'
import { InputLabel } from '@node-space/storybook-components/dist/FormElements'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Select, Option as SelectOptionType } from '@node-space/storybook-components/dist/Select'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useAccountRepresentativeMutation } from 'hooks/mutations/useAccountRepresentativeMutation'
import { useDateOfBirthInput } from 'hooks/useDateOfBirthInput'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { FormBody } from 'pages/Verification/_components/FormBody'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { FormSubmitError } from 'pages/Verification/_components/FormSubmitError'
import {
  AccountRepFormData,
  AccountRepFormKeys,
  AccountRepSchema,
} from 'pages/Verification/schemas/accountRepSchema'
import { BaseErrorResponse } from 'types/errors'
import { AccountRepresentative } from 'types/verification'
import { AccountRepOption, AccountRepType, useAccountRep } from '../useAccountRep'

type AccountRepFormProps = {
  loadedAccountRepId?: number
  directorOptions: SelectOptionType[]
  ownerOptions: SelectOptionType[]
  selectedAccountRepData: AccountRepFormData
  onAccountRepTypeChange: (type: AccountRepType) => void
  onAccountRepChange: (type: string) => void
}

export const AccountRepresentativeForm = ({
  loadedAccountRepId,
  directorOptions,
  ownerOptions,
  selectedAccountRepData,
  onAccountRepTypeChange,
  onAccountRepChange,
}: AccountRepFormProps) => {
  const { t } = useTranslation()
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { setUnsavedChanges, setActiveRoute, submitStep } = useVerificationContext()
  const { accountRepOptions, countryOptions, isLoadingCountries } = useAccountRep()

  const {
    dateOfBirthLabels,
    hasDateOfBirthErrors,
    getDateOfBirthFields,
    setDateOfBirthInputValue,
    setDateOfBirthErrors,
    getDateOfBirthErrors,
    getDateOfBirthErrorText,
    formatDateOfBirthData,
    formatDateOfBirthInput,
  } = useDateOfBirthInput()

  const { mutate: putAccountRepresentative, isPending: isLoadingSubmitRequest } =
    useAccountRepresentativeMutation()

  const [addAccountRepError, setAddAccountRepError] = useState(false)

  const defaultValues: AccountRepFormData = {
    ...selectedAccountRepData,
    dateOfBirth: formatDateOfBirthData(selectedAccountRepData?.dateOfBirth),
  }

  const formSchema = AccountRepSchema(t)
  const form = useForm<AccountRepFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = form

  const formValues = useWatch({ control })

  const { setInputField, setSelectField, setEmailField } = useFormFields<AccountRepFormKeys>(
    formSchema,
    form,
    formValues
  )

  const selectedAccountRep: AccountRepType = formValues?.accountRepType

  const activeAccountRepOptions = useMemo(() => {
    setAddAccountRepError(false)
    let options = []
    if (
      selectedAccountRep === AccountRepOption.CURRENT_REP ||
      selectedAccountRep === AccountRepOption.OTHER_REP
    ) {
      return options
    }

    options = selectedAccountRep === AccountRepOption.OWNER ? ownerOptions : directorOptions
    if (!options?.length) {
      setAddAccountRepError(true)
    }
    return options
  }, [selectedAccountRep, ownerOptions, directorOptions])

  const formFields: { [key in AccountRepFormKeys] } = {
    accountRepType: setSelectField({
      key: 'accountRepType',
      options: accountRepOptions,
      onChangeAdditional: (value: AccountRepType) => {
        onAccountRepTypeChange(value)
        setDateOfBirthErrors({ day: false, month: false, year: false })
      },
    }),
    accountRep: setSelectField({
      key: 'accountRep',
      options: activeAccountRepOptions,
      placeholder:
        formValues?.accountRepType === AccountRepOption.DIRECTOR
          ? t('accountRepresentative.selectDirector')
          : t('accountRepresentative.selectOwner'),
      showLabel: false,
      onChangeAdditional: value => onAccountRepChange(value),
    }),
    emailAddress: setEmailField({ key: 'emailAddress' }),
    firstName: setInputField({ key: 'firstName', useLabelAsPlaceholder: true, showLabel: false }),
    lastName: setInputField({ key: 'lastName', useLabelAsPlaceholder: true, showLabel: false }),
    dateOfBirth: {},
    address1: setInputField({ key: 'address1', useLabelAsPlaceholder: true, showLabel: false }),
    address2: setInputField({ key: 'address2', useLabelAsPlaceholder: true, showLabel: false }),
    city: setInputField({ key: 'city', useLabelAsPlaceholder: true, showLabel: false }),
    postalCode: setInputField({ key: 'postalCode', useLabelAsPlaceholder: true, showLabel: false }),
    countryCode: setSelectField({
      key: 'countryCode',
      options: countryOptions,
      placeholder: t('formFields.chooseCountry'),
      showLabel: false,
    }),
    id: {},
  }

  const isAccountRepMyself: boolean = selectedAccountRep === AccountRepOption.CURRENT_REP

  const showNewPersonFields: boolean =
    isAccountRepMyself || selectedAccountRep === AccountRepOption.OTHER_REP

  const onSubmit: SubmitHandler<typeof formValues> = (formValues: AccountRepFormData) => {
    resetRequestError()
    const { id, accountRepType, accountRep, ...rest } = formValues

    const updatedAccountRepData: AccountRepresentative = {
      ...rest,
      ...(loadedAccountRepId && { id: loadedAccountRepId }),
      dateOfBirth: formatDateOfBirthInput(formValues?.dateOfBirth),
    }

    putAccountRepresentative(updatedAccountRepData, {
      onSuccess: () => {
        submitStep(VerificationTrackingLabels.ACCOUNT_REPRESENTATIVE)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('putAccountRepresentative', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  useEffect(() => {
    if (!!selectedAccountRepData) {
      reset({
        ...selectedAccountRepData,
        dateOfBirth: formatDateOfBirthData(selectedAccountRepData?.dateOfBirth),
      })
    }
  }, [selectedAccountRepData])

  useEffect(() => {
    setUnsavedChanges(isDirty)
  }, [isDirty])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBody>
        {requestError?.show && (
          <FormSubmitError
            errorCode={requestError?.errorCode}
            sentryTraceId={requestError?.sentryTraceId}
          />
        )}

        <GroupFields flex direction="col" gapY={8}>
          <Select
            {...formFields.accountRepType}
            error={!!errors?.accountRepType || addAccountRepError}
            errorText={
              errors?.accountRepType?.message ||
              (addAccountRepError && formValues?.accountRepType === AccountRepOption.OWNER)
                ? `${t('accountRepresentative.noAddedOwners')}`
                : `${t('accountRepresentative.noAddedDirectors')}`
            }
          />

          {!showNewPersonFields &&
            (addAccountRepError ? (
              <Button
                onClick={() =>
                  selectedAccountRep === AccountRepOption.OWNER
                    ? setActiveRoute('businessOwners')
                    : setActiveRoute('businessDirectors')
                }
                iconElement={<Icon name="PlusIcon" color="primary-500" />}
                secondary
                className="mt-5"
              >
                {selectedAccountRep === AccountRepOption.OWNER
                  ? t('verification.businessOwners.addOwner')
                  : t('verification.businessDirectors.addDirector')}
              </Button>
            ) : (
              <Select {...formFields?.accountRep} />
            ))}
        </GroupFields>

        {!addAccountRepError && (
          <Input {...formFields?.emailAddress} disabled={isAccountRepMyself} />
        )}

        {showNewPersonFields && (
          <>
            <Box>
              <InputLabel>{t('verification.businessDirectors.legalNameOfPerson')}</InputLabel>
              <GroupFields flex direction="col" gapY={8}>
                <Input
                  {...formFields?.firstName}
                  disabled={isAccountRepMyself && !!defaultValues?.firstName}
                />
                <Input
                  {...formFields?.lastName}
                  disabled={isAccountRepMyself && !!defaultValues?.lastName}
                />
              </GroupFields>
            </Box>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <DateOfBirth
                  date={getDateOfBirthFields(value)}
                  labels={{
                    ...dateOfBirthLabels,
                    errorMessage: getDateOfBirthErrorText(error),
                  }}
                  errors={getDateOfBirthErrors(error)}
                  onChange={(date: DateType) => onChange(setDateOfBirthInputValue(date))}
                  onValidate={(errors: DateErrorType) => setDateOfBirthErrors(errors)}
                />
              )}
            />
            <Box>
              <InputLabel>{t('verification.businessDirectors.residentialAddress')}</InputLabel>
              <GroupFields flex direction="col" gapY={8}>
                <Input {...formFields?.address1} />
                <Input {...formFields?.address2} />
                <InlineFields flex gap={8}>
                  <Box flexSize="fill" width="full">
                    <Input {...formFields?.city} />
                  </Box>
                  <Box flexSize="fill" width="full">
                    <Input {...formFields?.postalCode} />
                  </Box>
                </InlineFields>
                <Select {...formFields?.countryCode} loading={isLoadingCountries} isSearchable />
              </GroupFields>
            </Box>
          </>
        )}

        <FormSubmitButton
          loading={isSubmitting || isLoadingSubmitRequest}
          disabled={!!Object.keys(errors)?.length || hasDateOfBirthErrors || addAccountRepError}
        />
      </FormBody>
    </form>
  )
}
