import React from 'react'
import clsx from 'clsx'
import { Box, Box as IconBox } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Icon, IconColor, IconName } from '@node-space/storybook-components/dist/Icon'
import { StepState } from 'constants/verification'
import { StepState as StepStateType } from 'types/verification'

interface RouteLinkProps {
  title: string
  state?: StepStateType
  disabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const RouteLink = ({ title, state, disabled, onClick }: RouteLinkProps) => {
  const iconName: IconName = state === StepState.IN_PROGRESS ? 'ArrowRightIcon' : 'CheckIcon'

  const iconColour: IconColor = disabled
    ? 'inherit'
    : state === StepState.COMPLETED
      ? 'status-success-500'
      : 'secondary-500'

  const textColour = clsx(
    `text-sm`,
    !disabled && {
      'text-gray-08 hover:text-secondary-500': state !== StepState.IN_PROGRESS,
      'text-secondary-500': state === StepState.IN_PROGRESS,
    }
  )

  const linkIcon = (
    <IconBox width={24} aria-label={`${state} icon`}>
      {state !== StepState.UNTOUCHED && <Icon name={iconName} size="lg" color={iconColour} />}
    </IconBox>
  )

  return (
    <Box flex className="w-small">
      <Button
        name={title}
        iconElement={linkIcon}
        noStyling
        type="button"
        disabled={disabled}
        onClick={onClick}
      >
        <span className={textColour}>{title}</span>
      </Button>
    </Box>
  )
}
