import React from 'react'
import Box from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'
import { Description } from 'components/Text'

export interface AccountContactSummaryProps {
  contactFullName: string
  contactDetail: string
}

export const AccountContactSummary = ({
  contactFullName,
  contactDetail,
}: AccountContactSummaryProps) => {
  return (
    <Box border="gray" paddingX={16} paddingY={12} borderRadius={4} background="gray">
      <Text size="sm" weight="medium">
        {contactFullName}
      </Text>
      <Description>{contactDetail}</Description>
    </Box>
  )
}
