import { RestApi } from '@node-space/rest-api'
import General, { CookieTime, RedirectUri } from 'constants/General'
import toString from 'lodash/toString'
import { AuthToken } from 'types/types'

export const TOKEN_PARAM = 'token'
export const ENCODING_TYPE = 'base64'
export const ENCODING_UTF = 'utf8'

/**
 * Checks if a string is valid Base64 encoded string
 * @returns boolean
 * @param str string
 */
export const isBase64 = (str: string): boolean => {
  if (!str || !str?.trim()) return false
  try {
    return btoa(atob(str)) === str
  } catch (err) {
    return false
  }
}

/**
 * Gets authentication cookie
 * @returns AuthToken
 */
export const getAuthCookie = (): AuthToken => {
  return RestApi.getCookie(General.COOKIE_NAME)
}

/**
 * Sets authentication cookies
 * @returns void
 */
export const setAuthCookies = (authToken: AuthToken): void => {
  RestApi.setCookie(General.COOKIE_NAME, { ...authToken }, CookieTime.ONE_HOUR)
  RestApi.setCookie(General.COOKIE_PERMANENT, '1', CookieTime.ONE_DAY)
}

/**
 * Deletes authentication cookie
 * @returns void
 */
export const deleteAuthCookie = (): void => {
  RestApi.deleteCookie(General.COOKIE_NAME)
}

/**
 * Gets access_token and secret from token
 * @returns string
 * @param token string
 */
export const getDecodedToken = (token: string): [string, string] => {
  const incoming = token?.split(',')
  const accessToken = incoming?.[0]?.split('=')
  const secret = incoming?.[1]?.split('=')
  return [accessToken?.[1], secret?.[1]]
}

/**
 * Decodes base64 token to string
 * @returns string
 * @param token string
 */
export const getBase64DecodeToString = (token: string): string => {
  if (!isBase64(token)) return
  const decodedString = Buffer.from(token, ENCODING_TYPE)
  return toString(decodedString)
}

/**
 * Encodes token to base64 string
 * @returns string
 * @param token string
 */
export const setBase64EncodeToString = (token: string): string => {
  return Buffer.from(token, ENCODING_UTF)?.toString(ENCODING_TYPE)
}

/**
 * Gets token for redirect
 * @returns string
 */
export const getRedirectToken = (): string => {
  const cookie = getAuthCookie()
  const token = `access_token=${cookie?.access_token},secret=${cookie?.secret}`
  const encodedString = setBase64EncodeToString(token)
  return encodedString
}

/**
 * Redirects to given base url with access token
 * @returns void
 * @param baseUrl string
 */
export const performAuthRedirect = (baseUrl: string): void => {
  const token = getRedirectToken()
  window.location.href = `${baseUrl}${RedirectUri.EXT_VERIFY_URL}?${TOKEN_PARAM}=${token}`
}

/**
 * Clean window.location route logout
 * @returns {void}
 */
export const forceLogout = (): void => {
  window.location.href = RedirectUri.LOGOUT
}
