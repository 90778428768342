import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import AppLoader from 'components/AppLoader'
import {
  forceLogout,
  getBase64DecodeToString,
  getDecodedToken,
  TOKEN_PARAM,
} from 'utils/auth/utils'
import { useRefreshTokenQuery } from 'hooks/useRefreshTokenQuery'
import { RedirectUri } from 'constants/General'

export default function Verify() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get(TOKEN_PARAM)

  const [accessToken, setAccessToken] = useState<string>()
  const [secret, setSecret] = useState<string>()

  const { data: refreshToken, isFetched } = useRefreshTokenQuery(accessToken, secret)

  useEffect(() => {
    if (!isString(token) && isEmpty(token)) return

    const tokenString = getBase64DecodeToString(token)
    if (!tokenString) {
      setSearchParams('')
      return forceLogout()
    }

    const [accessToken, secret] = getDecodedToken(tokenString)
    if (accessToken && secret) {
      setAccessToken(accessToken)
      setSecret(secret)
    }
  }, [token])

  useEffect(() => {
    if (!isFetched) return

    setSearchParams('')
    setAccessToken('')
    setSecret('')

    if (!refreshToken) {
      return forceLogout()
    }
    return navigate(RedirectUri.DASHBOARD)
  }, [refreshToken, isFetched])

  return <AppLoader />
}
