import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { isBoolean } from 'lodash'
import Modal from '@node-space/storybook-components/dist/Modal'
import { RedirectUri } from 'constants/General'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { hasHoursExpired } from 'utils/date'
import { finpromCoolOffPreferenceKey, type FinpromCoolOffStatus } from '../finpromPreferenceData'
import { CoolOffModal } from './modals/CoolOffModal'
import { InitialModal } from './modals/InitialModal'

export const FinpromPhaseOne = () => {
  const navigate = useNavigate()
  const { accountPreferences } = useFinpromContext()

  const onCloseModal = () => navigate(RedirectUri.LOGOUT, { replace: true })

  const activeStep = useMemo(() => {
    const stepStatus: FinpromCoolOffStatus = accountPreferences?.[finpromCoolOffPreferenceKey]
    if (!stepStatus) return <InitialModal />
    const isValidDate = !isBoolean(stepStatus) && dayjs(stepStatus).isValid()
    if (isValidDate) {
      const isCoolOffOver = hasHoursExpired(stepStatus)
      if (isCoolOffOver) return <CoolOffModal />
    }
  }, [accountPreferences])

  return !!activeStep ? (
    <Modal visible closeModal={onCloseModal}>
      {activeStep}
    </Modal>
  ) : (
    <></>
  )
}
