import React from 'react'
import { VerificationRouteKey } from 'types/verification'
import AccountRepresentative from './AccountRepresentative'
import BusinessActivity from './BusinessActivity/BusinessActivity'
import { BusinessAddress } from './BusinessAddress/BusinessAddress'
import { BusinessDetails } from './BusinessDetails/BusinessDetails'
import BusinessDirectors from './BusinessDirectors'
import BusinessOwners from './BusinessOwners'
import GetStarted from './GetStarted/GetStarted'
import SourceOfFunds from './SourceOfFunds'
import { UploadDocuments } from './UploadDocuments/UploadDocuments'

type Steps = Record<VerificationRouteKey, () => JSX.Element>

const stepsMap: Steps = {
  getStarted: GetStarted,
  businessDetails: BusinessDetails,
  sourceOfFunds: SourceOfFunds,
  businessActivity: BusinessActivity,
  businessAddress: BusinessAddress,
  businessOwners: BusinessOwners,
  businessDirectors: BusinessDirectors,
  accountRepresentative: AccountRepresentative,
  businessDocuments: UploadDocuments,
  businessAgreements: null,
}

export interface VerificationStepProps {
  activeStep: VerificationRouteKey
}

export const VerificationStep = ({ activeStep }: VerificationStepProps) => {
  const StepComponent = stepsMap[activeStep]

  return StepComponent ? <StepComponent /> : <></>
}
