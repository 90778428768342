import { StepState, VerificationRouteStatus } from 'constants/verification'
import { FinpromStepKey, FinpromVerificationStep } from 'pages/Finprom/types'
import {
  StepState as StepStateType,
  VerificationRouteKey,
  VerificationStep,
} from 'types/verification'

/**
 * Returns either "untouched" | "in progress" | "completed" step state
 * to determine the verification menu item display
 * @param {VerificationStep} step
 * @param {VerificationRouteKey} activeStep
 * @returns {StepStateType}
 */
export const getStepState = (
  step: VerificationStep | FinpromVerificationStep,
  activeStep: VerificationRouteKey | FinpromStepKey
): StepStateType => {
  if (activeStep === step?.key) return StepState.IN_PROGRESS
  if (step?.status === VerificationRouteStatus.COMPLETED) return StepState.COMPLETED
  return StepState.UNTOUCHED
}
