import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@node-space/hooks'
import { HeaderCTA } from 'components/header/HeaderCTA'
import LoginPageLayout from 'components/layout/LoginPageLayout'
import { RedirectUri } from 'constants/General'
import { useDeleteAuthCookie } from 'hooks/useDeleteAuthCookie'
import { isSandbox } from 'utils/environment'
import { ExtraSignUpCTA } from './components'
import DevXForm from './components/DevXForm'

const CreateAccountDeveloper = () => {
  const { t } = useTranslation()
  const { enableFooterSignup } = useFeatureFlags()

  useDeleteAuthCookie()

  return (
    <LoginPageLayout
      title={t('openDevXAccount')}
      description={t('signUpSandboxDescription')}
      headerRightContent={
        <HeaderCTA
          helpText={t('alreadySignedUp')}
          buttonText={t('logIn')}
          buttonUrl={RedirectUri.LOGIN}
        />
      }
      hasFooter
    >
      <DevXForm />
      {enableFooterSignup && (
        <ExtraSignUpCTA
          url={RedirectUri.KYB_SIGNUP_URL}
          isExternal={isSandbox}
          externalUrl={process.env.PROD_URL}
          trackingLabel="Click on production link"
          preButtonText={t('kybAccountCtaMessage')}
          buttonText={t('signUpProduction')}
        />
      )}
    </LoginPageLayout>
  )
}

export default CreateAccountDeveloper
