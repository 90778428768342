import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import Collapsible from '@node-space/storybook-components/dist/Collapsible'
import UploadFile from 'pages/Verification/_components/UploadFile'
import { DocumentUpload } from 'types/verification'
import { formatString } from 'utils/utils'
import {
  AccountContactDocument,
  AccountContactDocumentCategory,
  FileCount,
  FileDetails,
  FileValueId,
} from './types'

export interface CategoryDocumentsProps {
  category: AccountContactDocumentCategory
  documentList: AccountContactDocument[]
  fileCount: FileCount
  getFileValue: (fileValueId: FileValueId) => DocumentUpload
  onChooseFile: (fileDetails: FileDetails) => void
}

export const CategoryDocuments = ({
  category,
  documentList,
  fileCount,
  getFileValue,
  onChooseFile,
}: CategoryDocumentsProps) => {
  const { t } = useTranslation()

  return (
    <Collapsible
      testid={`${category}-upload`}
      title={t(`uploadDocuments.labels.${category}`)}
      rightLabel={formatString(t('uploadsCountStatus'), fileCount?.uploaded, fileCount?.total)}
    >
      <Box flex direction="col" gapY={16}>
        {documentList?.map(({ documentCode, contactFullname, filename, contactId: ownerId }) => {
          return (
            <Box key={`${documentCode}${ownerId ?? ''}`}>
              {filename ? (
                <Callout
                  state="success"
                  message={`${contactFullname} ${t(`uploadDocuments.uploaded.${documentCode}`)}`}
                />
              ) : (
                <UploadFile
                  name={documentCode}
                  title={`${t(`uploadDocuments.titles.${documentCode}`)} ${contactFullname}`}
                  tooltipContent={{ title: t(`uploadDocuments.tooltip.${documentCode}`) }}
                  file={getFileValue({ category, documentCode, ownerId })}
                  setFile={file =>
                    onChooseFile({
                      category,
                      documentCode,
                      file,
                      ...(ownerId && { ownerId }),
                    })
                  }
                />
              )}
            </Box>
          )
        })}
      </Box>
    </Collapsible>
  )
}
