import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { RedirectUri } from 'constants/General'
import { VerificationRequestStatus } from 'constants/verification'
import { AccountTypes } from 'types/types'
import { useProfileQuery } from './queries/useProfileQuery'
import { useUserAccountsQuery } from './queries/useUserAccountsQuery'
import { useVerificationRequestStatusQuery } from './queries/useVerificationRequestStatusQuery'

export const useRequestsOnLogin = () => {
  const navigate = useNavigate()
  const {
    data: profile,
    isFetching: isLoadingProfile,
    isError: isErrorProfile,
    error: errorProfile,
  } = useProfileQuery()

  const {
    data: userAccounts,
    isFetching: isLoadingUserAccounts,
    isError: isErrorUserAccount,
    error: errorUserAccounts,
  } = useUserAccountsQuery()

  const {
    data: verificationRequestStatus,
    isFetching: isLoadingVerificationRequestStatus,
    isError: isErrorVerificationRequestStatus,
    error: errorVerificationRequestStatus,
  } = useVerificationRequestStatusQuery()

  const isLoading = isLoadingProfile && isLoadingUserAccounts && isLoadingVerificationRequestStatus
  const isError = isErrorProfile || isErrorUserAccount || isErrorVerificationRequestStatus
  const error = errorProfile || errorUserAccounts || errorVerificationRequestStatus

  useEffect(() => {
    if (!isLoading && isError) {
      navigate(RedirectUri.ACCOUNT_ERROR, {
        state: error,
      })
    }
  }, [isLoading, isError, error])

  const currentAccount = useMemo(
    () => userAccounts?.find(account => account?.isCurrent),
    [userAccounts]
  )

  const isDeveloperAccount: boolean =
    profile?.options?.registrationAccountType === AccountTypes.DEVELOPER

  const isVerifiedBusinessAccount = useMemo(
    () => verificationRequestStatus === VerificationRequestStatus.VERIFIED,
    [verificationRequestStatus]
  )

  const isLoadingRequestsOnLogin = useMemo(
    () => isLoadingProfile || isLoadingUserAccounts || isLoadingVerificationRequestStatus,
    [isLoadingProfile, isLoadingUserAccounts, isLoadingVerificationRequestStatus]
  )

  return {
    profile,
    currentAccount,
    isDeveloperAccount,
    isVerifiedBusinessAccount,
    verificationRequestStatus,
    isLoadingRequestsOnLogin,
    isLoadingProfile,
    isLoadingUserAccounts,
  }
}
