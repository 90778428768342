import { useMutation } from '@tanstack/react-query'
import { postAgreementAcceptance, putVerificationRequest } from 'services/VerificationService'

export const usePostNewAccountAgreementsMutation = () => {
  return useMutation({ mutationFn: postAgreementAcceptance })
}

export const usePutVerificationMutation = () => {
  return useMutation({ mutationFn: putVerificationRequest })
}
