import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useFeatureFlags } from '@node-space/hooks'
import {
  Box,
  Box as Container,
  Box as LogoWrapper,
} from '@node-space/storybook-components/dist/Box'
import { AppBanners, AppBannerType } from 'components/app/AppBanners'
import { FlagEnabledBanner } from 'components/banners/FlagEnabledBanner'
import { Logo } from 'components/logos/Logo'

export interface LogoHeaderProps {
  children?: React.ReactNode
  isMobileSticky?: boolean
}

export const LogoHeader = ({ children, isMobileSticky }: LogoHeaderProps) => {
  const { enableBanner } = useFeatureFlags()
  const [headerHeight, setHeaderHeight] = useState(0)
  const ghostSpaceRef = useRef(null)

  const onResize = useCallback(() => {
    if (!!ghostSpaceRef?.current) setHeaderHeight(ghostSpaceRef?.current?.clientHeight)
  }, [])

  const appBanners: AppBannerType[] = []

  if (enableBanner?.enabled) {
    appBanners.push(<FlagEnabledBanner {...enableBanner} />)
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    onResize()

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <>
      <Box className={clsx('top-0 z-30', isMobileSticky ? 'hidden md:block md:sticky' : 'sticky')}>
        <AppBanners banners={appBanners} />
      </Box>

      <Container
        width="full"
        className={clsx({ 'fixed md:static md:relative z-20': isMobileSticky })}
      >
        <div ref={ghostSpaceRef}>
          {isMobileSticky && (
            <Box className="md:hidden">
              <AppBanners banners={appBanners} />
            </Box>
          )}

          <Box
            flex
            justifyContent="between"
            paddingX={16}
            paddingT={12}
            {...(isMobileSticky && {
              background: 'off-white',
              divider: 'bottom',
              paddingB: 12,
              alignItems: 'center',
            })}
            className={clsx('sm:pt-4 sm:px-8', { 'sm:pb-4': isMobileSticky })}
          >
            <LogoWrapper paddingT={8} {...(isMobileSticky && { paddingB: 8 })}>
              <Logo />
            </LogoWrapper>

            {children}
          </Box>
        </div>
      </Container>

      {isMobileSticky && <Box className="md:hidden" style={{ height: `${headerHeight}px` }} />}
    </>
  )
}
