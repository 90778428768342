import React from 'react'
import { Outlet, RouteProps, Navigate } from 'react-router-dom'
import { useDocumentTitle } from '@mantine/hooks'
import { Box as Page } from '@node-space/storybook-components'
import AppLoader from '../AppLoader'
import { useAuth } from '@node-space/hooks'

type PageLayoutProps = {
  title?: string
  isTitleEnabled?: boolean
  hasMerchantRole?: boolean
  loading?: boolean
  canRoute?: boolean
  noStyling?: boolean
} & RouteProps

const PageLayout = ({ title, loading, canRoute, noStyling }: PageLayoutProps) => {
  const { isAuthed } = useAuth()

  // Document titles
  const siteTitle = title ? `${title} | ${process.env.SITE_TITLE}` : `${process.env.SITE_TITLE}`
  useDocumentTitle(siteTitle)

  // anything else that is passed in can be access with ...rest operator

  if (loading) {
    return <AppLoader />
  }

  if (!isAuthed) {
    return <Navigate to="/logout" />
  }

  if (canRoute === false) return <Navigate to="/" />

  if (noStyling) {
    return <Outlet />
  }

  return (
    <div className="h-full min-h-screen">
      <div className="flex overflow-hidden">
        <Page
          flex
          flexCol
          className="flex-1 relative overflow-y-auto overflow-x-hidden bg-grayscale-01"
        >
          <main>
            <div className="sm:p-6 lg:px-8 lg:pb-8 lg:pt-6 p-4 w-full max-w-9xl mx-auto">
              <Outlet />
            </div>
          </main>
        </Page>
      </div>
    </div>
  )
}

export default PageLayout
