import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import Text from '@node-space/storybook-components/dist/Text'
import { MappedErrorMessage, MappedErrorMessageProps } from 'components/errors/MappedErrorMessage'

export interface FormSubmitErrorProps extends MappedErrorMessageProps {
  children?: ReactNode
  sentryTraceId?: string
}

export const FormSubmitError = ({
  children,
  errorCode,
  fallbackMessage,
  sentryTraceId,
}: FormSubmitErrorProps) => {
  const { t } = useTranslation()

  const errorMessage = children || (
    <MappedErrorMessage
      errorCode={errorCode}
      fallbackMessage={fallbackMessage ?? t('requestErrors.submitError')}
    />
  )

  return (
    <Callout state="error" showCloseButton={false} testid="form-error-callout">
      <Box aria-label="Request error message">{errorMessage}</Box>
      {!!sentryTraceId && (
        <Text size="xs" className="pt-2" color="inherit">
          {t('requestErrors.traceId')}: {sentryTraceId}
        </Text>
      )}
    </Callout>
  )
}
