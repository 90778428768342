import React from 'react'
import { BvnkLogo } from './BvnkLogo'
import { LogoColor, LogoColorType } from 'types/types'

interface LogoProps {
  logoColor?: LogoColorType
}

export const Logo = ({ logoColor = LogoColor.DARK }: LogoProps) => {
  return <BvnkLogo color={logoColor} />
}
