import React, { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useFeatureFlags } from '@node-space/hooks'
import { Box } from '@node-space/storybook-components/dist/Box'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Select } from '@node-space/storybook-components/dist/Select'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { VerificationTrackingLabels } from 'constants/verification'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useBusinessAddressMutation } from 'hooks/mutations/useBusinessAddressMutation'
import { useBusinessAddressQuery } from 'hooks/queries/useBusinessAddressQuery'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { getIsFinpromCountry } from 'pages/Finprom/utils'
import { BaseErrorResponse } from 'types/errors'
import { BusinessAddressDetails, BusinessAddressFormFields } from 'types/verification'
import { filterCountries } from 'utils/utils'
import { FormBody } from '../_components/FormBody'
import { FormGetError } from '../_components/FormGetError'
import { FormSubmitButton } from '../_components/FormSubmitButton'
import { FormSubmitError } from '../_components/FormSubmitError'
import { VerificationHeader } from '../_components/VerificationHeader'
import { BusinessAddressFormKeys, businessAddressSchema } from '../schemas/businessAddressSchema'

export const BusinessAddress = () => {
  const { t } = useTranslation()
  const { enableSignupUk, enableCountriesExclusionList } = useFeatureFlags()
  const {
    isWatchingBusinessAddress,
    isEnabled: isFinpromEnabled,
    refetchData: refetchFinpromData,
    setShowBackToBusinessAddress,
    onLeaveBusinessAddress,
  } = useFinpromContext()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { setUnsavedChanges, submitStep } = useVerificationContext()

  const {
    data: countryOptions = [],
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
  } = useCountriesQuery(false)

  const filteredCountriesList = filterCountries(countryOptions, enableCountriesExclusionList)

  const filteredCountryOptions = enableSignupUk
    ? filteredCountriesList
    : filteredCountriesList?.filter(country => country?.value !== 'GB')

  const {
    data: businessAddress,
    isFetching: isFetchingBusinessAddress,
    isError: isErrorBusinessAddress,
  } = useBusinessAddressQuery()

  const { mutate: putBusinessAddress, isPending: isLoadingSubmitRequest } =
    useBusinessAddressMutation()

  const formSchema = businessAddressSchema(t)

  const form = useForm<BusinessAddressFormFields>({
    defaultValues: {
      address1: '',
      address2: '',
      city: '',
      postalCode: '',
      countryCode: '',
    },
    resolver: yupResolver(formSchema),
  })
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty, isSubmitting },
  } = form

  const formValues = useWatch({ control })

  const isLoading = useMemo(
    () => isLoadingCountries || isFetchingBusinessAddress,
    [isLoadingCountries, isFetchingBusinessAddress]
  )

  const isGetError = useMemo(
    () => isErrorCountries || isErrorBusinessAddress,
    [isErrorCountries, isErrorBusinessAddress]
  )

  useEffect(() => {
    if (businessAddress) {
      populateFormValues(businessAddress)
    }
  }, [businessAddress])

  useEffect(() => {
    setUnsavedChanges(isDirty)
  }, [isDirty])

  const onSubmit: SubmitHandler<typeof formValues> = (formValues: BusinessAddressFormFields) => {
    if (!isDirty) {
      submitStep(VerificationTrackingLabels.BUSINESS_ADDRESS)
      return
    }
    resetRequestError()

    putBusinessAddress(formValues, {
      onSuccess: () => {
        if (isFinpromEnabled) {
          if (
            !isWatchingBusinessAddress &&
            businessAddress?.countryCode !== formValues?.countryCode
          ) {
            setShowBackToBusinessAddress(true)
          }
          if (isWatchingBusinessAddress && getIsFinpromCountry(formValues?.countryCode)) {
            onLeaveBusinessAddress()
          }
          refetchFinpromData()
        }
        submitStep(VerificationTrackingLabels.BUSINESS_ADDRESS)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('putBusinessAddress', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  const populateFormValues = (existingFormData: BusinessAddressDetails) => {
    const sanitizedAddressData: BusinessAddressFormFields = {
      address1: existingFormData.address1 || '',
      address2: existingFormData.address2 || '',
      city: existingFormData.city || '',
      postalCode: existingFormData.postalCode || '',
      countryCode: existingFormData?.countryCode || '',
    }
    if (!enableSignupUk && sanitizedAddressData?.countryCode === 'GB') {
      sanitizedAddressData.countryCode = ''
    }
    reset(sanitizedAddressData)
  }

  const { setInputField, setSelectField } = useFormFields<BusinessAddressFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields = {
    address1: setInputField({ key: 'address1', useLabelAsPlaceholder: true, showLabel: false }),
    address2: setInputField({ key: 'address2', useLabelAsPlaceholder: true, showLabel: false }),
    city: setInputField({ key: 'city', useLabelAsPlaceholder: true, showLabel: false }),
    postalCode: setInputField({ key: 'postalCode', useLabelAsPlaceholder: true, showLabel: false }),
    countryCode: setSelectField({
      key: 'countryCode',
      options: filteredCountryOptions,
      showLabel: false,
    }),
  }

  return (
    <>
      <VerificationHeader header={t('businessAddress')} />
      {isLoading ? (
        <SkeletonForm name="Business address" />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormBody>
            {requestError?.show && (
              <FormSubmitError
                errorCode={requestError?.errorCode}
                sentryTraceId={requestError?.sentryTraceId}
              />
            )}
            <Box flex direction="col" gapY={8}>
              <Input label={t('formFields.registeredBusinessAddress')} {...formFields.address1} />
              <Input {...formFields.address2} />
              <Box flex gapX={8}>
                <Input {...formFields.city} />
                <Input {...formFields.postalCode} />
              </Box>
              <Select {...formFields.countryCode} isSearchable />
            </Box>

            <FormSubmitButton
              loading={isSubmitting || isLoadingSubmitRequest}
              disabled={!!Object.keys(errors)?.length}
            />
          </FormBody>
        </form>
      )}
    </>
  )
}
