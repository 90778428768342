import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Box as IconCircle, Box as Row } from '@node-space/storybook-components/dist/Box'
import { Icon, IconName } from '@node-space/storybook-components/dist/Icon'
import { Text } from '@node-space/storybook-components/dist/Text'
import { Description } from 'components/Text/Description'
import { ExternalLinks } from 'constants/ExternalLinks'

type HeadsUpListItem = {
  iconName: IconName
  text: ReactNode
}

export const HeadsUp = () => {
  const { t } = useTranslation()

  const headsUpList: HeadsUpListItem[] = [
    {
      iconName: 'TimerIcon',
      text: t('verification.getStarted.info1'),
    },
    {
      iconName: 'SaveIcon',
      text: t('verification.getStarted.info2'),
    },
    {
      iconName: 'SupportIcon',
      text: (
        <Text testid="get-started-info-3" tag="span" size="sm" color="grey">
          {t('verification.getStarted.info3A')}{' '}
          <a href={ExternalLinks.contactSupport} target="_blank" rel="noreferrer">
            <Text size="sm" color="grey" className="inline-block underline underline-offset-4">
              {t('verification.getStarted.info3B')}
            </Text>{' '}
            <Box className="inline-block -mb-0.5">
              <Icon name="ExternalLinkIcon" color="grey-08" size="sm" />
            </Box>{' '}
          </a>
          {t('verification.getStarted.info3C')}
        </Text>
      ),
    },
  ]

  return (
    <Box flex direction="col" gapY={20} paddingT={16}>
      <Description>{t('verification.getStarted.title')}</Description>
      <Description>{t('verification.getStarted.subTitle')}</Description>
      <Box flex direction="col" gapY={20}>
        {headsUpList?.map(({ iconName, text }, itemIndex) => (
          <Row flex gapX={16} key={`${itemIndex}-${iconName}`}>
            <Box>
              <IconCircle flex borderRadius="full" size={40} centerChildren background="gray">
                <Icon name={iconName} color="grey-08" size="lg" />
              </IconCircle>
            </Box>
            {typeof text === 'string' ? <Description>{text}</Description> : text}
          </Row>
        ))}
      </Box>
    </Box>
  )
}
