import React from 'react'
import { Box as Body, Box } from '@node-space/storybook-components/dist/Box'
import { BackgroundType } from '@node-space/storybook-components/dist/components/Box/BoxTypes'
import Text from '@node-space/storybook-components/dist/Text'

interface NotificationCardProps {
  title: string
  iconBackground: BackgroundType
  iconComponent: React.ReactNode
  children: React.ReactNode
}

export const NotificationCard = ({
  title,
  iconBackground,
  iconComponent,
  children,
}: NotificationCardProps) => {
  return (
    <Box
      background="white"
      border="gray"
      borderRadius={8}
      paddingY={24}
      gapX={24}
      paddingX={32}
      className="relative xs:flex"
    >
      <Box flex justifyContent="center" className="xs:justify-start">
        <Box flex borderRadius="full" size={48} centerChildren background={iconBackground}>
          {iconComponent}
        </Box>
      </Box>
      <Body flex direction="col" paddingT={12} gapY={12}>
        <Text tag="h2" size="lg" weight="medium">
          {title}
        </Text>
        {children}
      </Body>
    </Box>
  )
}
