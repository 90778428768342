import { DynamicDocumentData } from 'types/dynamicFields'
import {
  BusinessInformationDocumentCodeList,
  CorporateOwnerDocumentCodeList,
  DocumentCategories,
  FinancialDocumentCodeList,
} from './constants'
import {
  BusinessInformationDocumentCode,
  CorporateOwnerDocumentCode,
  DocumentActionId,
  DocumentCategoryKey,
  FinancialDocumentCode,
  UploadedDocument,
} from './types'

export const getDocumentCategory = (document: DynamicDocumentData): DocumentCategoryKey => {
  const { code, value } = document || {}
  if (value?.contact) {
    return CorporateOwnerDocumentCodeList?.includes(code as CorporateOwnerDocumentCode)
      ? DocumentCategories.CORPORATE_OWNERS
      : DocumentCategories.INDIVIDUALS
  }
  if (BusinessInformationDocumentCodeList?.includes(code as BusinessInformationDocumentCode)) {
    return DocumentCategories.BUSINESS_INFORMATION
  }
  if (FinancialDocumentCodeList?.includes(code as FinancialDocumentCode)) {
    return DocumentCategories.FINANCIAL_DOCUMENTS
  }
  return DocumentCategories.INDUSTRY_DOCUMENTS
}

export const getDocumentTitleCode = (
  category: DocumentCategoryKey,
  documentCode: string
): string => {
  if (category !== DocumentCategories.BUSINESS_INFORMATION) return documentCode
  const mappedBusinessInformationCodes: Partial<Record<BusinessInformationDocumentCode, string>> = {
    proofOfAddress: 'registeredBusinessAddress',
    certificateOfIncorporation: 'yourCertificateOfIncorporation',
  }
  return mappedBusinessInformationCodes?.[documentCode] ?? documentCode
}

export const getUploadedFile = (
  uploadedDocuments: UploadedDocument[],
  queryDocument: DynamicDocumentData
) => {
  const uploadedDocument = uploadedDocuments?.find(
    ({ documentId }) =>
      documentId?.code === queryDocument?.code &&
      documentId?.contactReference === (queryDocument?.value?.contact?.reference ?? '')
  )
  return uploadedDocument?.file
}

export const getUploadedIndex = (
  uploadedDocuments: UploadedDocument[],
  queryDocumentId: DocumentActionId
) => {
  return uploadedDocuments?.findIndex(
    ({ documentId }) => Object.values(documentId)?.join() === Object.values(queryDocumentId)?.join()
  )
}
