import React from 'react'
import { Box as VerificationHeaderContainer } from '@node-space/storybook-components'
import { Description, Headline } from 'components/Text'

export interface VerificationHeaderProps {
  header: React.ReactNode
  subHeader?: React.ReactNode
  children?: React.ReactNode
}

export const VerificationHeader = ({ header, subHeader, children }: VerificationHeaderProps) => {
  return (
    <VerificationHeaderContainer
      flex
      direction="col"
      gapY={16}
      aria-label="Verification step header"
    >
      <Headline>{header}</Headline>
      {subHeader && <Description>{subHeader}</Description>}
      {children && children}
    </VerificationHeaderContainer>
  )
}
