import React from 'react'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { finpromSelfCategorisationPreferenceKey } from 'pages/Finprom/finpromPreferenceData'
import { SelfCategorisationResultPage } from './SelfCategorisationResultPage'
import { SelfCategorisationSteps } from './SelfCategorisationSteps'

export const SelfCategorisation = () => {
  const { accountPreferences } = useFinpromContext() || {}
  const selfCategorisationData = accountPreferences?.[finpromSelfCategorisationPreferenceKey]
  const showResultPage = !!selfCategorisationData?.isComplete

  return showResultPage ? (
    <SelfCategorisationResultPage category={selfCategorisationData?.category} />
  ) : (
    <SelfCategorisationSteps {...selfCategorisationData} />
  )
}
