import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useAuth } from '@node-space/hooks'
import { getUserAccounts } from 'services/AccountService'
import { UserAccount } from 'types/accounts'
import { isTestEnvironment } from 'utils/environment'

export const useUserAccountsQuery = (isEnabled = true) => {
  const { isAuthed } = useAuth()

  return useQuery<UserAccount[]>({
    queryKey: reactQueryKeys.userAccounts(),
    queryFn: () => getUserAccounts(),
    enabled: isTestEnvironment || (isAuthed && isEnabled),
    placeholderData: keepPreviousData,
  })
}
