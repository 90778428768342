import { CountryCodes } from 'constants/Countries'
import { AccountPreferenceData } from 'types/accounts'
import {
  AppropriatenessQuestionList,
  AppropriatenessStepKeys,
  AppropriatenessSuccessRate,
  FinpromResult,
  FinpromStepKeys,
  FinpromVerificationStepList,
  SelfCategorisationThreshold,
  YesNoValues,
} from './constants'
import {
  AppropriatenessData,
  AppropriatenessQuestionsData,
  finpromAppropriatenessTestPreferenceKey,
  finpromCoolOffPreferenceKey,
  FinpromCoolOffStatus,
  finpromRiskWarningPreferenceKey,
  finpromSelfCategorisationPreferenceKey,
} from './finpromPreferenceData'
import { HighNetWorthFormData } from './schemas/highNetWorthSchema'
import { RestrictedFormData } from './schemas/restrictedSchema'
import { SophisticatedFormData } from './schemas/sophisticatedSchema'
import {
  AppropriatenessQuestionKey,
  AppropriatenessResult,
  AppropriatenessStepKey,
  FinpromStepKey,
  FinpromVerificationStepKey,
} from './types'

export const getIsFinpromCountry = (countryCode: string): boolean => {
  return countryCode === CountryCodes.UK
}

export const getFinpromActiveStep = (accountPreferences: AccountPreferenceData): FinpromStepKey => {
  const coolOffStatus: FinpromCoolOffStatus = accountPreferences?.[finpromCoolOffPreferenceKey]
  if (!coolOffStatus) {
    return FinpromStepKeys.INITIAL
  }
  if (coolOffStatus === true) {
    return null
  }
  const completedStepsStatus: Record<FinpromVerificationStepKey, boolean> = {
    riskWarning: accountPreferences?.[finpromRiskWarningPreferenceKey]?.isComplete,
    selfCategorisation: accountPreferences?.[finpromSelfCategorisationPreferenceKey]?.isComplete,
    appropriatenessTest: accountPreferences?.[finpromAppropriatenessTestPreferenceKey]?.isComplete,
  }
  const nextStep: FinpromVerificationStepKey = FinpromVerificationStepList?.find(
    (stepKey: FinpromVerificationStepKey) => !completedStepsStatus?.[stepKey]
  )
  return nextStep
}

export const getAppropriatenessActiveFormStep = (
  appropriatenessData: AppropriatenessData
): AppropriatenessStepKey => {
  if (!appropriatenessData?.hasReadInstructions) return AppropriatenessStepKeys.INSTRUCTIONS
  if (!appropriatenessData?.questions) return AppropriatenessStepKeys.DUE_DILIGENCE
  const completedQuestionsList = Object.keys(appropriatenessData?.questions)
  const nextIncompleteQuestion = AppropriatenessQuestionList?.find(
    (stepKey: AppropriatenessStepKey) => !completedQuestionsList?.includes(stepKey)
  )
  const lastStepIndex = AppropriatenessQuestionList?.length - 1
  return nextIncompleteQuestion ?? AppropriatenessQuestionList?.[lastStepIndex]
}

/**
 * Validates if answers checked includes all correct answers and no incorrect answers
 * @returns {Boolean} If all correct answers and no incorrect answers are checked true is returned
 */
export const getIsAnswersCheckedCorrect = <AnswerType extends string>(
  correctAnswers: AnswerType[],
  checkedAnswers: AnswerType[]
): boolean => {
  return (
    correctAnswers?.every(correctAnswer => checkedAnswers?.includes(correctAnswer)) &&
    checkedAnswers?.length === correctAnswers?.length
  )
}

/**
 * Gets the pass result for the answers provided in the Restricted investor form
 * @returns {Boolean} If result is pass true is returned
 */
export const getRestrictedFormResult = (answers: RestrictedFormData): boolean => {
  const isAnswersYesToAll =
    answers?.isPercentageInvestedLess === YesNoValues.YES &&
    answers?.isPercentageIntendedToInvestLess === YesNoValues.YES
  if (!isAnswersYesToAll) return false
  const isPercentageValuesCorrect =
    Number(answers?.percentageInvested) < SelfCategorisationThreshold.INVESTMENT_PERCENTAGE &&
    Number(answers?.percentageIntendedToInvest) < SelfCategorisationThreshold.INVESTMENT_PERCENTAGE
  if (!isPercentageValuesCorrect) return false
  return answers?.isRiskAccepted
}

/**
 * Gets the pass result for the answers provided in the High Net Worth investor form
 * @returns {Boolean} If result is pass true is returned
 */
export const getHighNetWorthFormResult = (answers: HighNetWorthFormData): boolean => {
  const isIncomeMore = answers?.isAnnualIncomeMore === YesNoValues.YES
  const isAssetsMore = answers?.isNetAssetsMore === YesNoValues.YES
  const isAnswersYesToAny = isIncomeMore || isAssetsMore
  if (!isAnswersYesToAny) return false
  if (isIncomeMore && Number(answers?.annualIncomeValue) < SelfCategorisationThreshold.INCOME) {
    return false
  }
  if (isAssetsMore && Number(answers?.netAssetsValue) < SelfCategorisationThreshold.NET_ASSETS) {
    return false
  }
  return answers?.isRiskAccepted
}

/**
 * Gets the pass result for the answers provided in the Sophisticated investor form
 * @returns {Boolean} If result is pass true is returned
 */
export const getSophisticatedFormResult = (answers: SophisticatedFormData): boolean => {
  if (answers?.isCertificateReceived !== YesNoValues.YES) return false
  if (!answers?.authorisedFirm) return false
  return answers?.isRiskAccepted
}

/**
 * Gets the pass result for the answers provided in the Appropriateness test.
 * To pass the user needs to have at least 6 correct answers out of 7.
 * @returns {AppropriatenessResult} Total correct answers {number} and is success result (boolean}
 */
export const getAppropriatenessFormsResult = (
  questionData: AppropriatenessQuestionsData
): AppropriatenessResult => {
  const correctQuestionsList = Object.keys(questionData)?.filter(
    (key: AppropriatenessQuestionKey) => questionData?.[key]?.isCorrect
  )
  const correctQuestionsTotal = correctQuestionsList?.length
  const result =
    correctQuestionsTotal >= AppropriatenessSuccessRate
      ? FinpromResult.PASSED
      : FinpromResult.UNSUCCESSFUL
  return {
    correctQuestionsTotal,
    result,
  }
}

/**
 * Gets the updated total attempts by setting the initial attempt value to 1,
 * or incrementing the current attempts value with 1
 * @returns {Boolean} If result is pass true is returned
 */
export const getTotalAttempts = (currentAttempts: number): number => {
  return currentAttempts ? currentAttempts + 1 : 1
}

/**
 * Gets the updated unsuccessful attempts by setting the initial unsuccessful attempt value
 * to 0 if successful and 1 if unsuccessful,
 * or incrementing the current attempts value with 1 if unsuccessful
 * @returns {Boolean} If result is pass true is returned
 */
export const getUnsuccessfulAttempts = (
  currentAttempts: number,
  isSuccessfulAttempt: boolean
): number => {
  if (isSuccessfulAttempt) {
    return currentAttempts ?? 0
  }
  return getTotalAttempts(currentAttempts)
}
