import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { ExternalLinks } from 'constants/ExternalLinks'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'

export const BusinessDetailsHeader = () => {
  const { t } = useTranslation()

  const termsAction = (
    <TextAction
      text={t('termsOfUse')}
      linkTo={ExternalLinks.termsAndConditions}
      className="text-secondary-500 inline-block"
    />
  )

  return (
    <VerificationHeader
      header={t('verification.routes.businessDetails')}
      subHeader={
        <>
          {t('verification.businessDetails.description')} {termsAction}.
        </>
      }
    />
  )
}
