import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getGetStartedDetails } from 'services/VerificationService'

export const useGetStartedQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.getStarted(),
    queryFn: getGetStartedDetails,
  })
}
