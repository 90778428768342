import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { MappedErrorMessageState } from 'hooks/useMappedErrorMessage'
import { BaseErrorResponse } from 'types/errors'
import { Error } from 'types/types'
import { isRecapthcaLowScoreError } from 'utils/recaptcha'
import { CreateAccountErrorMessage } from '../components/CreateAccountErrorMessage'
import { CreateAccountError, CreateAccountErrorType } from '../types'

export const useCreateAccountErrors = setError => {
  const { t } = useTranslation()

  const getErrorType = (error: Error): CreateAccountErrorType => {
    if (error?.parameter === 'password' && error?.code === 'complexity') {
      setError(
        'password',
        { type: 'focus', message: t('passwordComplexityError') },
        { shouldFocus: true }
      )
      return CreateAccountError.INLINE
    }
    if (error?.parameter === 'emailAddress' && error?.code === 'unique') {
      setError(
        'emailAddress',
        { type: 'focus', message: t('emailAlreadyUsed') },
        { shouldFocus: true }
      )
      return CreateAccountError.INLINE
    }
    if (isRecapthcaLowScoreError(error?.message)) {
      return CreateAccountError.RECAPTCHA_LOW_SCORE
    }
  }

  const getErrorCodeType = (errorCode: number): CreateAccountErrorType => {
    switch (errorCode) {
      case 401:
        return CreateAccountError.UNAUTHORIZED
    }
  }

  const handleErrors = (
    sentryMessage: string,
    errors: BaseErrorResponse
  ): MappedErrorMessageState => {
    const errorCode = errors?.status
    const errorList = errors?.data?.errorList
    let errorType: CreateAccountErrorType
    let sentryTraceId: string

    if (!isEmpty(errorList)) {
      errorList.map((error: Error) => {
        errorType = getErrorType(error)
      })
    }

    if (errorType === CreateAccountError.INLINE) return

    if (!errorType) {
      errorType = getErrorCodeType(errorCode)
    }

    if (errorType !== CreateAccountError.RECAPTCHA_LOW_SCORE) {
      sentryTraceId = logSentryErrorAndGetTraceId(sentryMessage, errors)
    }

    const mappedRequestError: MappedErrorMessageState = {
      errorCode,
      sentryTraceId,
      handledMessage: !!errorType ? <CreateAccountErrorMessage errorType={errorType} /> : '',
      show: true,
    }

    return mappedRequestError
  }

  return { handleErrors }
}
