import React from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Box as GroupFields,
  Box as InlineFields,
} from '@node-space/storybook-components/dist/Box'
import DateOfBirth, {
  DateErrorType,
  DateType,
} from '@node-space/storybook-components/dist/DateOfBirth'
import { InputLabel } from '@node-space/storybook-components/dist/FormElements'
import Input from '@node-space/storybook-components/dist/Input'
import { Select, Option as SelectOption } from '@node-space/storybook-components/dist/Select'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useFormSetup } from 'hooks/forms/useFormSetup'
import { useDateOfBirthInput } from 'hooks/useDateOfBirthInput'
import { AccountContactSchema } from './accountContactSchema'
import { AccountContactActionsLoading } from './accountContactV2/types'
import { AccountContactWrapper } from './AccountContactWrapper'
import { initialAccountContactData } from './constants'
import { AccountContactFormData, AccountContactFormKeys, AccountContactLabels } from './types'

export interface AccountContactFormProps {
  accountContact: AccountContactFormData
  countryOptions: SelectOption[]
  labels: AccountContactLabels
  isEditing: boolean
  actionsLoading?: AccountContactActionsLoading
  hasTopPadding?: boolean
  onSave: (accountContact: AccountContactFormData) => void
  onDelete: () => void
}

export const AccountContactForm = ({
  accountContact,
  countryOptions,
  labels,
  isEditing,
  actionsLoading,
  onSave,
  onDelete,
}: AccountContactFormProps) => {
  const { t } = useTranslation()

  const {
    dateOfBirthLabels,
    hasDateOfBirthErrors,
    getDateOfBirthFields,
    setDateOfBirthInputValue,
    setDateOfBirthErrors,
    getDateOfBirthErrors,
    getDateOfBirthErrorText,
    formatDateOfBirthData,
    formatDateOfBirthInput,
  } = useDateOfBirthInput()

  const initialData = accountContact ?? initialAccountContactData
  const defaultValues: AccountContactFormData = {
    ...initialData,
    dateOfBirth: formatDateOfBirthData(initialData?.dateOfBirth),
  }

  const formSchema = AccountContactSchema(t)
  const { form, formValues, errors, control, handleSubmit } = useFormSetup<AccountContactFormData>(
    formSchema,
    defaultValues
  )
  const { setInputField, setSelectField } = useFormFields<AccountContactFormKeys>(
    formSchema,
    form,
    formValues
  )

  const formFields: Record<AccountContactFormKeys, any> = {
    firstName: setInputField({ key: 'firstName', useLabelAsPlaceholder: true, showLabel: false }),
    lastName: setInputField({ key: 'lastName', useLabelAsPlaceholder: true, showLabel: false }),
    dateOfBirth: {}, // handled as custom control
    address1: setInputField({ key: 'address1', useLabelAsPlaceholder: true, showLabel: false }),
    address2: setInputField({ key: 'address2', useLabelAsPlaceholder: true, showLabel: false }),
    city: setInputField({ key: 'city', useLabelAsPlaceholder: true, showLabel: false }),
    postalCode: setInputField({ key: 'postalCode', useLabelAsPlaceholder: true, showLabel: false }),
    countryCode: setSelectField({
      key: 'countryCode',
      options: countryOptions,
      placeholder: t('formFields.chooseCountry'),
      showLabel: false,
    }),
  }

  const onSubmit: SubmitHandler<typeof formValues> = (formValues: AccountContactFormData) => {
    const updatedAccountContact = {
      ...formValues,
      dateOfBirth: formatDateOfBirthInput(formValues.dateOfBirth),
    }
    onSave(updatedAccountContact)
  }

  return (
    <AccountContactWrapper
      addActionLabel={labels?.addButton}
      {...(actionsLoading && { actionsLoading })}
      actionsError={{
        isFormValuesError: !!Object.keys(errors)?.length || hasDateOfBirthErrors,
      }}
      isEditing={isEditing}
      onSubmit={handleSubmit(onSubmit)}
      onDelete={onDelete}
    >
      <Box>
        <InputLabel>{t('verification.businessOwners.legalNameOfPerson')}</InputLabel>
        <GroupFields flex direction="col" gapY={8}>
          <Input {...formFields.firstName} />
          <Input {...formFields.lastName} />
        </GroupFields>
      </Box>
      <Box>
        <Controller
          control={control}
          name="dateOfBirth"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DateOfBirth
              date={getDateOfBirthFields(value)}
              labels={{
                ...dateOfBirthLabels,
                errorMessage: getDateOfBirthErrorText(error),
              }}
              errors={getDateOfBirthErrors(error)}
              onChange={(date: DateType) => onChange(setDateOfBirthInputValue(date))}
              onValidate={(errors: DateErrorType) => setDateOfBirthErrors(errors)}
            />
          )}
        />
      </Box>
      <Box>
        <InputLabel>{t('verification.businessOwners.residentialAddress')}</InputLabel>
        <GroupFields flex direction="col" gapY={8}>
          <Input {...formFields.address1} />
          <Input {...formFields.address2} />
          <InlineFields flex gap={8}>
            <Box flexSize="fill" width="full">
              <Input {...formFields.city} />
            </Box>
            <Box flexSize="fill" width="full">
              <Input {...formFields.postalCode} />
            </Box>
          </InlineFields>
          <Select
            {...formFields.countryCode}
            loading={actionsLoading?.isFetchingCountryOptions}
            isSearchable
          />
        </GroupFields>
      </Box>
    </AccountContactWrapper>
  )
}
