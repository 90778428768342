import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { applyPasswordTests } from 'utils/password'

export interface BusinessReq {
  firstName: string
  lastName: string
  companyTradingName: string
  emailAddress: string
  password: string
  countryCode: string
  marketingConsent: boolean
}

export type BusinessReqKeys = keyof BusinessReq

const BusinessSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField, getWorkEmailYupField } = useYupFields()

  return yup.object<Partial<Record<BusinessReqKeys, yup.AnySchema>>>({
    firstName: getRequiredYupField({ label: t('firstName') }),
    lastName: getRequiredYupField({ label: t('lastName') }),
    companyTradingName: getRequiredYupField({ label: t('companyTradingName') }),
    emailAddress: getWorkEmailYupField(t('workEmail')),
    password: getRequiredYupField({ label: t('password') }).test({
      name: 'testPassword',
      test: (value, ctx) => {
        const { isValid, message } = applyPasswordTests(value, t)
        return isValid
          ? true
          : ctx.createError({
              message: message,
              path: 'password',
            })
      },
    }),
    countryCode: getRequiredYupField({ label: t('country') }),
    marketingConsent: yup.bool().label(t('subscribeToNewsletter')),
  })
}

export default BusinessSchema
