import { useMemo } from 'react'
import { useAccountPreferencesQuery } from 'hooks/queries/useAccountPreferencesQuery'
import { useBusinessAddressQuery } from 'hooks/queries/useBusinessAddressQuery'
import { AccountPreferenceData } from 'types/accounts'
import { getIsFinpromCountry } from '../utils'

export interface FinpromData {
  isFetchedFinpromData: boolean
  companyCountryCode: string
  accountPreferences: AccountPreferenceData
  refetchFinpromData: () => {}
}

export const useFinpromData = (isFinpromEnabled: boolean): FinpromData => {
  const {
    data: businessAddress,
    isFetched: isFetchedBusinessAddress,
    refetch: refetchBusinessAddress,
  } = useBusinessAddressQuery(isFinpromEnabled)

  const isFinpromCountry = useMemo(
    () => isFetchedBusinessAddress && getIsFinpromCountry(businessAddress?.countryCode),
    [isFetchedBusinessAddress, businessAddress?.countryCode]
  )

  const isFinpromApplicable = useMemo(
    () => isFinpromEnabled && isFinpromCountry,
    [isFinpromEnabled, isFinpromCountry]
  )

  const { data: accountPreferences = {}, isFetched: isFetchedAccountPreferences } =
    useAccountPreferencesQuery(isFinpromApplicable)

  const isFetchedFinpromData = useMemo(
    () => isFetchedBusinessAddress && isFetchedAccountPreferences,
    [isFetchedBusinessAddress, isFetchedAccountPreferences]
  )

  const refetchFinpromData = () => refetchBusinessAddress()

  return {
    isFetchedFinpromData,
    companyCountryCode: businessAddress?.countryCode,
    accountPreferences,
    refetchFinpromData,
  }
}
