import * as yup from 'yup'
import { TFunction } from 'i18next'
import { useYupFields } from 'hooks/forms/useYupFields'

export type SourceOfFundsFormData = {
  currencyType: string
  sourceOfFundsDescription: string
  cryptoAddress: string
}

export type SourceOfFundsFormKeys = keyof SourceOfFundsFormData

export const SourceOfFundsSchema = (t: TFunction<'translation', undefined>) => {
  const { getRequiredYupField, getConditionalYupField } = useYupFields()

  return yup.object<Partial<Record<SourceOfFundsFormKeys, yup.AnySchema>>>({
    currencyType: getRequiredYupField({
      label: t('verification.sourceOfFunds.currencyTypeLabel'),
      requiredErrorMessage: t('verification.sourceOfFunds.currencyTypeRequired'),
    }).nullable(),
    sourceOfFundsDescription: getRequiredYupField({
      label: t('verification.sourceOfFunds.descriptionLabel'),
      requiredErrorMessage: t('verification.sourceOfFunds.descriptionRequired'),
    }),
    cryptoAddress: getConditionalYupField<SourceOfFundsFormKeys>({
      label: t('verification.sourceOfFunds.cryptoAddressLabel'),
      fieldKey: 'currencyType',
      fieldValue: '2',
    }),
  })
}
