import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box as Wrapper,
  Modal,
  ModalActions,
  ModalSize,
  ResponseContainer,
  Icon,
} from '@node-space/storybook-components'

type ConfirmCompleteModalProps = {
  onClick: () => void
}

export const ConfirmCompleteModal = ({ onClick }: ConfirmCompleteModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal size={ModalSize.lg} visible closeModal={() => null}>
      <Wrapper tag="section" testid="complete-modal-wrapper">
        <ResponseContainer
          headline={t('verification.review.confirmCompleteHeadline')}
          description={t('verification.review.confirmCompleteDescription')}
          responseIcon={<Icon color="status-success-500" name="ClapIcon" size="lg" />}
        />
        <ModalActions
          actions={[
            {
              children: t('done'),
              onClick,
            },
          ]}
          alignActions="center"
        />
      </Wrapper>
    </Modal>
  )
}
