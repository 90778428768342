import React from 'react'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import { useFinpromAppropriatenessForm } from 'pages/Finprom/hooks/useFinpromAppropriatenessForm'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

export const CryptoAssetPaymentRisksOptions = {
  PHISHING_OR_CYBERATTACKS: 'phishingOrCyberattacks',
  OPERATIONAL_OUTAGES: 'operationalOutages',
  BVNK_INSOLVENCY: 'bvnkInsolvency',
  LAW_CHANGES: 'lawChanges',
  ALL_OF_ABOVE: 'allOfAbove',
} as const

type CryptoAssetPaymentRisksOption =
  (typeof CryptoAssetPaymentRisksOptions)[keyof typeof CryptoAssetPaymentRisksOptions]

const cryptoAssetPaymentRisksOptionList = Object.values(CryptoAssetPaymentRisksOptions)
const cryptoAssetPaymentRisksCorrectAnswer: CryptoAssetPaymentRisksOption =
  CryptoAssetPaymentRisksOptions.ALL_OF_ABOVE

export const StepCryptoAssetPaymentRisks = () => {
  const {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  } = useFinpromAppropriatenessForm<CryptoAssetPaymentRisksOption>()

  const cryptoAssetPaymentRisksOptions = getQuestionOptions(cryptoAssetPaymentRisksOptionList)

  const handleSubmit = () => {
    const isCorrectAnswer = questionAnswer === cryptoAssetPaymentRisksCorrectAnswer
    onSubmitQuestion(isCorrectAnswer, FinpromTrackingLabels.APPROPRIATENESS_STEP_DUE_DILIGENCE)
  }

  return (
    <Container>
      <Radio
        value={questionAnswer}
        options={cryptoAssetPaymentRisksOptions}
        onChange={handleSelectAnswer}
        error={isQuestionError}
        errorText={questionErrorText}
        hasParagraphLayout
      />
      {isErrorContinue && <FinpromSubmitError />}
      <FormSubmitButton
        disabled={isQuestionError}
        loading={isLoadingContinue}
        onClick={handleSubmit}
      />
    </Container>
  )
}
