import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box as Container, Box as Page } from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { ImageIcon } from '@node-space/storybook-components/dist/ImageIcon'
import { Text } from '@node-space/storybook-components/dist/Text'
import AbsoluteLayout from 'components/layout/AbsoluteLayout'
import { LogoHeader } from 'components/header/LogoHeader'

const NotAvailable = () => {
  const { t } = useTranslation()

  return (
    <AbsoluteLayout>
      <Page flex direction="col" width="full" minHeight="screen">
        <LogoHeader />
        <Container className="mt-4 py-40 px-8 h-full" background="gray-03">
          <ImageIcon
            width={237}
            height={248}
            src="assets/infographics/world-rotate-animation.svg"
          />
          <Container className="max-w-xl">
            <Text size="3xl" weight="semibold">
              {t('notAvailable.heading')}
            </Text>
            <Text color="disabled" size="lg" weight="normal">
              {t('notAvailable.description')}
            </Text>
            <Button onClick={() => window.open(process.env.SUPPORT_URL)} noStyling>
              <Text color="primary-500" size="lg" weight="normal">
                {t('notAvailable.contactSupport')}
              </Text>
            </Button>
          </Container>
        </Container>
      </Page>
    </AbsoluteLayout>
  )
}

export default NotAvailable
