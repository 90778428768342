import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { ExternalLinks } from 'constants/ExternalLinks'

export const SignUpAgreement = () => {
  const { t } = useTranslation()

  const textAction = {
    termsAndConditions: (
      <TextAction
        text={t('termsConditions')}
        className="text-secondary-500"
        linkTo={ExternalLinks?.termsAndConditions}
      />
    ),
    privacyPolicy: (
      <TextAction
        text={t('helpMenuPrivacyPolicy')}
        className="text-secondary-500"
        linkTo={ExternalLinks?.privacyPolicy}
      />
    ),
  }

  return (
    <Text size="sm" color="grey">
      {`${t('signUpAgreementIntro')}`} {textAction?.termsAndConditions}{' '}
      {`${t('signUpAgreementAnd')}`} {textAction?.privacyPolicy}
      {'.'}
    </Text>
  )
}
