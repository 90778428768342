import React from 'react'
import { useFeatureFlags } from '@node-space/hooks'
import { AccountRepresentative as AccountRepresentativeV1 } from './AccountRepresentativeV1/AccountRepresentative'
import { AccountRepresentative as AccountRepresentativeV2 } from './AccountRepresentativeV2/AccountRepresentative'

export const AccountRepresentative = () => {
  const { enableAccountContactsCrud } = useFeatureFlags()

  return !!enableAccountContactsCrud ? <AccountRepresentativeV2 /> : <AccountRepresentativeV1 />
}
