import { useLocation } from 'react-router-dom'
import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@node-space/hooks'
import { getProfile } from 'services/AuthService'
import { QueryMetaType } from 'types/reactQuery'
import { isTestEnvironment } from 'utils/environment'

export const useProfileQuery = (isEnabled = true) => {
  const { isAuthed } = useAuth()
  const location = useLocation()

  const meta: QueryMetaType = {
    errorAdditionalData: { location },
  }

  return useQuery({
    queryKey: reactQueryKeys.profile(),
    queryFn: getProfile,
    gcTime: Infinity,
    enabled: isTestEnvironment || (isAuthed && isEnabled),
    meta,
  })
}
