import React, { useMemo, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { useFeatureFlags } from '@node-space/hooks'
import {
  Box,
  Box as FieldGroup,
  Box as FormBody,
  Box as FormFields,
  Box as SplitFields,
  Box as TooltipLabel,
} from '@node-space/storybook-components/dist/Box'
import { Button } from '@node-space/storybook-components/dist/Button'
import { Callout } from '@node-space/storybook-components/dist/Callout'
import { CheckBox } from '@node-space/storybook-components/dist/CheckBox'
import { InputLabel } from '@node-space/storybook-components/dist/FormElements'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { Input } from '@node-space/storybook-components/dist/Input'
import { Select } from '@node-space/storybook-components/dist/Select'
import { Tooltip } from '@node-space/storybook-components/dist/Tooltip'
import { CountryCodes } from 'constants/Countries'
import { useFormFields } from 'hooks/forms/useFormFields'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useRecaptchaVersioning } from 'hooks/recaptcha/useRecaptchaVersioning'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { FormSubmitError } from 'pages/Verification/_components/FormSubmitError'
import { postRegisterUser } from 'services/AuthService'
import { PostRegisterUserReq } from 'types/authentication/PostRegisterUserReq'
import { BaseErrorResponse } from 'types/errors'
import { AccountTypes } from 'types/types'
import { GA, trackOnboarding } from 'utils/tracker'
import { filterCountries } from 'utils/utils'
import { useCreateAccountErrors } from '../hooks/useCreateAccountErrors'
import BusinessSchema, { BusinessReq, BusinessReqKeys } from '../schemas/businessSchema'
import { CreateAccountError } from '../types'
import { CreateAccountErrorMessage } from './CreateAccountErrorMessage'

const BusinessForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const [searchParams] = useSearchParams()

  const { recaptchaVersion, isLoadingRecaptcha, getRecaptchaToken, resetRecaptcha } =
    useRecaptchaVersioning('signup')

  const { data: countryOptions = [], isLoading: isLoadingCountries } = useCountriesQuery(false)
  const {
    enableMarketingConsent,
    enableSalesforceParam,
    enableSignupUk,
    disableSignupButtonUk,
    enableCountriesExclusionList,
  } = useFeatureFlags()

  const filteredCountriesList = filterCountries(countryOptions, enableCountriesExclusionList)

  const filteredCountryOptions = enableSignupUk
    ? filteredCountriesList
    : filteredCountriesList?.filter(country => country?.value !== 'GB')

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const defaultValues: BusinessReq = {
    firstName: '',
    lastName: '',
    companyTradingName: '',
    emailAddress: '',
    password: '',
    countryCode: '',
    marketingConsent: false,
  }

  const formSchema = BusinessSchema(t)
  const form = useForm<BusinessReq>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = form

  const { handleErrors } = useCreateAccountErrors(setError)

  const formValues = useWatch({ control })

  const { setInputField, setSelectField, setCheckboxField, setEmailField } =
    useFormFields<BusinessReqKeys>(formSchema, form, formValues)

  const formFields: { [key in BusinessReqKeys] } = {
    firstName: setInputField({ key: 'firstName', placeholder: t('firstName'), showLabel: false }),
    lastName: setInputField({ key: 'lastName', placeholder: t('lastName'), showLabel: false }),
    companyTradingName: setInputField({
      key: 'companyTradingName',
      placeholder: t('enterCompanyTradingName'),
      showLabel: false,
    }),
    emailAddress: setEmailField({ key: 'emailAddress' }),
    password: setInputField({ key: 'password', type: 'password', placeholder: t('enterPassword') }),
    countryCode: setSelectField({
      key: 'countryCode',
      showLabel: false,
      options: filteredCountryOptions,
    }),
    marketingConsent: setCheckboxField({ key: 'marketingConsent' }),
  }

  const isLoading = useMemo(
    () => isLoadingRecaptcha || isLoadingSubmit,
    [isLoadingRecaptcha, isLoadingSubmit]
  )

  const createNewUser = async ({
    firstName,
    emailAddress,
    lastName,
    companyTradingName,
    password,
    countryCode,
    marketingConsent,
  }) => {
    setIsLoadingSubmit(true)
    resetRequestError()
    try {
      const recaptchaToken: string = await getRecaptchaToken()

      if (!recaptchaToken) {
        setRequestError({
          handledMessage: (
            <CreateAccountErrorMessage errorType={CreateAccountError.RECAPTCHA_TOKEN} />
          ),
          show: true,
        })
        return
      }

      const postData: PostRegisterUserReq = {
        emailAddress,
        password,
        countryCode,
        accountType: AccountTypes.BUSINESS,
        recaptcha: recaptchaToken,
        recaptchaVersion: recaptchaVersion,
        ...(enableMarketingConsent && { marketingConsent: marketingConsent.toLocaleString() }),
        ...(enableSalesforceParam && { salesforceLeadId: searchParams.get('s_id') || null }),
      }

      await postRegisterUser(postData)

      //GA - New Registration
      GA.event({
        category: 'Registration',
        action: `A user successfully registered`,
      })

      //Amplitude - New Registration
      trackOnboarding('Started signup successfully - Business')

      resetRecaptcha()

      //Confirm Email
      navigate('/create-account/1', {
        state: {
          email: emailAddress,
          password,
          accountType: AccountTypes.BUSINESS,
          firstName,
          lastName,
          companyName: companyTradingName,
        },
      })
    } catch (errors) {
      const requestError = handleErrors('postRegisterBusinessAccount', errors as BaseErrorResponse)
      if (!!requestError) {
        setRequestError(requestError)
      }
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onSubmit: SubmitHandler<typeof formValues> = formValues => {
    const mappedData = formValues as BusinessReq
    createNewUser(mappedData)
  }

  const disableSignupUk = useMemo(
    () => disableSignupButtonUk && formValues?.countryCode === CountryCodes.UK,
    [disableSignupButtonUk, formValues?.countryCode]
  )

  return (
    <div>
      {requestError?.show && (
        <Box paddingB={24}>
          <FormSubmitError
            errorCode={requestError?.errorCode}
            sentryTraceId={requestError?.sentryTraceId}
            fallbackMessage={t('createAccountError.default')}
          >
            {requestError?.handledMessage}
          </FormSubmitError>
        </Box>
      )}
      {disableSignupUk && (
        <Box paddingB={24}>
          <Callout state="warning">
            We are temporarily unable to process sign-ups from the United Kingdom.
          </Callout>
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody flex direction="col" gapY={24}>
          <FormFields flex direction="col" gapY={20}>
            <FieldGroup>
              <InputLabel>{`${t('name')}`}</InputLabel>
              <SplitFields flex gapX={8}>
                <Input {...formFields.firstName} testid="business-signup-firstName" />
                <Input {...formFields.lastName} testid="business-signup-lastName" />
              </SplitFields>
            </FieldGroup>

            <Input
              {...formFields.emailAddress}
              testid="business-signup-emailAddress"
              label={
                <TooltipLabel flex alignItems="center" gapX={4}>
                  <span>{`${t('workEmail')}`}</span>
                  <Tooltip
                    id="tooltip-work-email"
                    position="right"
                    bodyContent={`${t('workEmailInputInfo')}`}
                    hasMaxWidth
                  >
                    <Icon name="InfoCircleIcon" size="sm" />
                  </Tooltip>
                </TooltipLabel>
              }
            />

            <Input
              {...formFields.companyTradingName}
              testid="business-signup-companyTradingName"
              label={
                <TooltipLabel flex alignItems="center" gapX={4}>
                  <span>{`${t('companyTradingName')}`}</span>
                  <Tooltip
                    id="tooltip-company-trading-name"
                    position="right"
                    bodyContent={`${t('companyTradingNameInfo')}`}
                    hasMaxWidth
                  >
                    <Icon name="InfoCircleIcon" size="sm" />
                  </Tooltip>
                </TooltipLabel>
              }
            />

            <Input {...formFields.password} testid="business-signup-password" />

            <Select
              {...formFields.countryCode}
              testid="business-signup-countryCode"
              loading={isLoadingCountries}
              label={
                <TooltipLabel flex alignItems="center" gapX={4}>
                  <span>{`${t('country')}`}</span>
                  <Tooltip
                    id="tooltip-country"
                    position="right"
                    bodyContent={`${t('countrySelectInfo')}`}
                    hasMaxWidth
                  >
                    <Icon name="InfoCircleIcon" size="sm" />
                  </Tooltip>
                </TooltipLabel>
              }
              isSearchable
            />

            {enableMarketingConsent && (
              <CheckBox
                {...formFields.marketingConsent}
                testid="business-signup-marketingConsent"
                labelColour="light"
              />
            )}
          </FormFields>

          <Button
            type="submit"
            full
            loading={isLoading}
            disabled={!isEmpty(errors) || disableSignupUk}
            testid="business-signup-submit-btn"
          >
            {`${t('signUp')}`}
          </Button>
        </FormBody>
      </form>
    </div>
  )
}

export default BusinessForm
