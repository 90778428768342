import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalBody, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { AccountContactActionError } from 'components/accountContact/AccountContactActionError'
import { AccountContactForm } from 'components/accountContact/accountContactV2/AccountContactForm'
import { AccountContactTypes } from 'components/accountContact/accountContactV2/constants'
import { AccountContactProps } from 'components/accountContact/accountContactV2/types'
import { getAccountContactName } from 'components/accountContact/accountContactV2/utils'
import { AccountContactLabels } from 'components/accountContact/types'

export const BusinessDirectorModal = ({
  initialData,
  actionsData,
  actionErrorStatus,
  onSave,
  onDelete,
  onClose,
}: AccountContactProps) => {
  const { t } = useTranslation()

  const handleDelete = () => {
    onDelete({
      reference: initialData?.reference,
      contactFullName: getAccountContactName(initialData),
    })
  }

  const businessDirectorLabels: AccountContactLabels = {
    addButton: t('verification.businessDirectors.addIndividualDirector'),
  }

  return (
    <Modal visible closeModal={onClose} allowOverFlow>
      {!actionsData?.idVerificationMessage?.showMessage && (
        <ModalNavBar title={t('verification.businessDirectors.addDirector')} onClose={onClose} />
      )}
      {!!actionErrorStatus?.errorType && (
        <ModalBody hasBottomPadding={false}>
          <AccountContactActionError
            {...actionErrorStatus}
            accountContactType={AccountContactTypes.BUSINESS_DIRECTOR}
          />
        </ModalBody>
      )}
      <AccountContactForm
        {...actionsData}
        accountContact={initialData}
        labels={businessDirectorLabels}
        onSave={onSave}
        onDelete={handleDelete}
      />
    </Modal>
  )
}
