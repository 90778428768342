import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box as Wrapper,
  Box as FormContainer,
  Modal,
  ModalNavBar,
  Input,
  ModalActions,
  ModalSize,
} from '@node-space/storybook-components'
import { useKeyPress } from '@node-space/hooks'
import UploadFile from 'pages/Verification/_components/UploadFile'
import { DocumentUpload } from 'types/verification'

interface UploadDocumentModalProps {
  onClose: () => void
  onAdd: (document: DocumentUpload) => void
  onEdit: (document: DocumentUpload) => void
  document: DocumentUpload
}

export const UploadDocumentModal = ({
  onClose,
  onAdd,
  onEdit,
  document,
}: UploadDocumentModalProps) => {
  const { t } = useTranslation()

  const [formDocoument, setFormDocument] = useState<DocumentUpload>(null)
  const [documentType, setDocumentType] = useState('')
  const [hasChanged, setHasChanged] = useState(false)

  const documentTypeError = useMemo(
    () =>
      (documentType?.length && !documentType?.trim()?.length) ||
      (hasChanged && !documentType?.length),
    [documentType, hasChanged]
  )

  useKeyPress('Escape', onClose)

  useEffect(() => {
    if (document) {
      setFormDocument(document)
      setDocumentType(document?.documentType)
    }
  }, [])

  const uploadDocument = async () => {
    formDocoument.documentType = documentType

    if (document) {
      onEdit(formDocoument)
    } else {
      onAdd(formDocoument)
    }
    onClose()
  }

  return (
    <Modal size={ModalSize.lg} visible={true} closeModal={onClose}>
      <ModalNavBar title={t('verification.uploadDocument')} onClose={onClose} />
      <Wrapper tag="section" testid="upload-modal-wrapper">
        <FormContainer
          flex
          direction="col"
          paddingX={24}
          paddingT={8}
          paddingB={24}
          gapY={16}
          testid="upload-document-form"
        >
          <UploadFile
            title={t('verification.uploadDocument')}
            name="documentUpload"
            file={formDocoument}
            setFile={setFormDocument}
          />
          <Input
            value={documentType}
            onChange={e => {
              !hasChanged && setHasChanged(true)
              setDocumentType(e.target.value)
            }}
            label={t('formFields.documentType')}
            testid="documentType"
            placeholder={t('formFields.shortDescription')}
            error={documentTypeError}
            errorText={t('formFields.documentTypeRequired')}
          />
        </FormContainer>
        <ModalActions
          actions={[
            {
              children: t('formFields.uploadDocument'),
              onClick: uploadDocument,
              disabled: !documentType?.trim()?.length || !formDocoument,
              type: 'submit',
              testid: 'uploadDocument',
            },
          ]}
        />
      </Wrapper>
    </Modal>
  )
}
