import { Option as SelectOption } from '@node-space/storybook-components/dist/Select'
import { VerificationRouteStatus } from 'constants/verification'
import { OwnershipTypes } from 'pages/Verification/BusinessOwners/constants'
import { CorporateOwnerFormData, OwnershipType } from 'pages/Verification/BusinessOwners/types'
import { Profile } from 'types/authentication/Profile'
import { PersonDetails } from 'types/identityVerification'
import { VerificationRouteStatus as VerificationRouteStatusType } from 'types/verification'
import {
  AccountContactTranslation,
  PersonDefaultOptions,
  PersonDefaultOptionsList,
} from './constants'
import {
  AccountContactData,
  AccountContactFormData,
  AccountContactOwnershipType,
  AccountContactType,
  PersonSelectorData,
  PersonSelectorTypes,
} from './types'

export const getAccountContactName = (accountContact: AccountContactData) => {
  if (!accountContact) return ''
  if (accountContact?.person) {
    return `${accountContact?.person?.firstName} ${accountContact?.person?.lastName}`
  }
  return accountContact?.company?.name ?? ''
}

export const getAccountContactsByType = (
  type: AccountContactType,
  accountContacts: AccountContactData[]
): AccountContactData[] => {
  return accountContacts?.filter(accountContact => accountContact?.type === type)
}

export const getAccountContactStepStatus = (
  accountContactType: AccountContactType,
  accountContactList: AccountContactData[]
): VerificationRouteStatusType => {
  if (!accountContactList?.length) return VerificationRouteStatus.PENDING
  const typeIndex = accountContactList?.findIndex(contact => contact?.type === accountContactType)
  return typeIndex === -1 ? VerificationRouteStatus.PENDING : VerificationRouteStatus.COMPLETED
}

export const getInitialMyselfOption = (profile: Profile): AccountContactData => ({
  person: {
    firstName: profile?.firstName,
    lastName: profile?.lastName,
  },
  contactInfo: {
    emailAddress: profile?.emailAddress,
  },
})

export const getIsPersonMyself = (person: PersonDetails, profile: Profile) => {
  return person?.firstName === profile?.firstName && person?.lastName === profile?.lastName
}

type PersonsData = { set: Set<string>; selectorData: PersonSelectorData }
export const getPersonSelectorData = (
  accountContacts: AccountContactData[],
  activeType?: AccountContactType
): PersonSelectorData => {
  const allPersonContacts = accountContacts?.filter(
    contact => !!contact?.person && (!!activeType ? contact?.type !== activeType : true)
  )
  if (!allPersonContacts?.length) return
  const initialPersonsData: PersonsData = {
    set: new Set(),
    selectorData: { personsData: [], personTypes: {} },
  }
  const personsData = allPersonContacts?.reduce((data, contact) => {
    const personReference = contact?.person?.reference
    const { set, selectorData } = data || {}
    if (!set?.has(personReference)) {
      set?.add(personReference)
      selectorData?.personsData?.push(contact)
      selectorData.personTypes[personReference] = [contact?.type]
    } else {
      selectorData?.personTypes?.[personReference].push(contact?.type)
    }
    return data
  }, initialPersonsData)
  return personsData?.selectorData
}

export const getOtherPersonOptions = (accountContacts: AccountContactData[]): SelectOption[] => {
  return accountContacts?.map(contact => ({
    value: contact?.reference,
    label: getAccountContactName(contact),
  }))
}

export const getPersonDisplayTypes = (types: PersonSelectorTypes, personReference: string, t) => {
  const personTypes = types?.[personReference]
  if (!personTypes?.length) return
  const translatedTypes = personTypes?.map(type =>
    t(`accountContact.typesLabel.${AccountContactTranslation?.[type]}`)
  )
  return translatedTypes?.join(t('accountContact.typesLabel.and'))
}

export const getIsOtherAccountContact = (reference: string) =>
  !PersonDefaultOptionsList?.includes(reference)

export type IntialSelectedReferenceData = {
  otherPersons: AccountContactData[]
  profile: Profile
  showMyselfOption?: boolean
  savedData?: AccountContactData
}
export const getIntialSelectedReference = ({
  otherPersons,
  profile,
  showMyselfOption = true,
  savedData,
}: IntialSelectedReferenceData) => {
  if (!savedData) {
    return showMyselfOption ? PersonDefaultOptions.MYSELF : PersonDefaultOptions.SOMEONE_ELSE
  }
  const { person: savedPerson } = savedData || {}
  if (getIsPersonMyself(savedPerson, profile)) {
    return PersonDefaultOptions.MYSELF
  }
  const otherPerson = otherPersons?.find(
    ({ person }) => person?.reference === savedPerson?.reference
  )
  if (otherPerson) return otherPerson?.reference
  return PersonDefaultOptions.SOMEONE_ELSE
}

export const getInitialFormValues = (
  accountContact: AccountContactData
): AccountContactFormData => {
  const { person } = accountContact || {}
  return {
    firstName: person?.firstName ?? '',
    lastName: person?.lastName ?? '',
    dateOfBirth: person?.dateOfBirth ?? '',
    address1: person?.address?.address1 ?? '',
    address2: person?.address?.address2 ?? '',
    city: person?.address?.city ?? '',
    postalCode: person?.address?.postalCode ?? '',
    countryCode: person?.address?.countryCode ?? '',
  }
}

export const getUpdatedAccountContactData = (
  initialData: AccountContactData,
  formValues: AccountContactFormData,
  isAddingExistingPerson: boolean
): AccountContactData => {
  const updatedData: AccountContactData = {
    ...initialData,
    person: {
      ...initialData?.person,
    },
  }
  updatedData.person.firstName = formValues?.firstName
  updatedData.person.lastName = formValues?.lastName
  updatedData.person.dateOfBirth = formValues?.dateOfBirth
  updatedData.person.address = {
    address1: formValues?.address1,
    address2: formValues?.address2,
    city: formValues?.city,
    postalCode: formValues?.postalCode,
    countryCode: formValues?.countryCode,
  }
  if (isAddingExistingPerson) {
    updatedData.reference = null
  }
  return updatedData
}

export const getInitialCompanyFormValues = (
  accountContact: AccountContactData
): CorporateOwnerFormData => {
  const { company, ownership } = accountContact || {}
  return {
    name: company?.name ?? '',
    countryCode: company?.countryCode ?? '',
    registrationNumber: company?.businessRegistrationNumber ?? '',
    ownershipPercentage: `${ownership?.percentage ?? ''}`,
    ownershipType: (ownership?.type?.toLowerCase() as OwnershipType) ?? OwnershipTypes.DIRECT,
  }
}

export const getUpdatedAccountContactCompanyData = (
  initialData: AccountContactData,
  formValues: CorporateOwnerFormData
): AccountContactData => {
  const percentage = formValues?.ownershipPercentage
  const updatedData: AccountContactData = {
    ...initialData,
    company: {
      name: formValues?.name,
      countryCode: formValues?.countryCode,
      businessRegistrationNumber: formValues?.registrationNumber,
    },
    ownership: {
      percentage: typeof Number(percentage) === 'number' ? Number(percentage) : null,
      type: formValues?.ownershipType?.toUpperCase() as AccountContactOwnershipType,
    },
  }
  return updatedData
}
