import { requestApi } from 'api/api'
import {
  AccountContactData,
  AccountContactPayload,
  AccountContactType,
} from 'components/accountContact/accountContactV2/types'
import { PersonDetails } from 'types/identityVerification'

export const getAccountPersons = (): Promise<PersonDetails[]> => {
  return requestApi({
    url: '/accounts-management/v1/accounts/persons',
    method: 'GET',
  })
}

export const getAccountContacts = (types: AccountContactType[]): Promise<AccountContactData[]> => {
  return requestApi({
    url: '/accounts-management/v1/accounts/contacts',
    method: 'GET',
    params: {
      ...(!!types?.length && { types: types?.join() }),
    },
  })
}

export const saveAccountContact = (data: AccountContactData): Promise<AccountContactData> => {
  return requestApi({
    url: `/accounts-management/v1/accounts/contacts`,
    method: 'PUT',
    data,
  })
}

export const deleteAccountContact = ({ type, reference }: AccountContactPayload): Promise<void> => {
  return requestApi({
    url: `/accounts-management/v1/accounts/contacts/${type}/${reference}`,
    method: 'DELETE',
  })
}

export const createIdVerificationLink = ({
  type,
  reference,
}: AccountContactPayload): Promise<string> => {
  return requestApi({
    url: `/accounts-management/v1/accounts/contacts/${type}/${reference}/id/url`,
    method: 'POST',
  })
}
