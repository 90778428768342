import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { useIndustryValues } from 'hooks/useIndustryOptions'

export type BusinessDetailsFormData = {
  legalEntityName: string
  legalEntityRegistrationNumber: string
  incomeTaxNumber: string
  industryId: string
  isOperationLicenseRequired: string
  operatingLicenseType: string
  operatingLicenseIssuer: string
}

export type BusinessDetailsFormKeys = keyof BusinessDetailsFormData

export const BusinessDetailsSchema = (
  t: TFunction<'translation', undefined>,
  enableSubIndustry: boolean
) => {
  const { getYupField, getRequiredYupField, getConditionalYupField } = useYupFields()

  return yup.object<Partial<Record<BusinessDetailsFormKeys, yup.AnySchema>>>({
    legalEntityName: getRequiredYupField({ label: t('verification.legalEntityName') }),
    legalEntityRegistrationNumber: getRequiredYupField({
      label: t('verification.registrationNumber'),
    }),
    incomeTaxNumber: getYupField({ label: t('verification.incomeTaxNumber') }),
    industryId: getRequiredYupField({ label: t('verification.industry') }).oneOf(
      useIndustryValues(enableSubIndustry)
    ),
    isOperationLicenseRequired: getRequiredYupField({
      label: t('verification.requiresOperatingLicence'),
      requiredErrorMessage: t('emptySelect'),
    }),
    operatingLicenseType: getConditionalYupField<BusinessDetailsFormKeys>({
      fieldKey: 'isOperationLicenseRequired',
      fieldValue: 'yes',
      label: t('operatingLicenseType'),
      requiredErrorMessage: t('operatingLicenseError.type'),
    }),
    operatingLicenseIssuer: getConditionalYupField<BusinessDetailsFormKeys>({
      fieldKey: 'isOperationLicenseRequired',
      fieldValue: 'yes',
      label: t('operatingLicenseIssuer'),
      requiredErrorMessage: t('operatingLicenseError.issuer'),
    }),
  })
}
