import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Option as RadioOption } from '@node-space/storybook-components/dist/components/Radio/Radio'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Container, Container as FormContainer } from 'components/Container'
import { Description } from 'components/Text'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels, selfCategorisationCategoryList } from 'pages/Finprom/constants'
import {
  finpromSelfCategorisationPreferenceKey,
  SelfCategorisationData,
} from 'pages/Finprom/finpromPreferenceData'
import { useFinpromPreferenceRequest } from 'pages/Finprom/hooks/useFinpromPreferenceRequest'
import { SelfCategorisationCategory } from 'pages/Finprom/types'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

type CategoryRadioOption = RadioOption & {
  value: SelfCategorisationCategory
}

export interface StepSelectCategoryProps {
  selectedCategory: SelfCategorisationCategory
  setSelectedCategory: React.Dispatch<React.SetStateAction<SelfCategorisationCategory>>
  onContinue: () => void
}

export const StepSelectCategory = ({
  selectedCategory,
  setSelectedCategory,
  onContinue,
}: StepSelectCategoryProps) => {
  const { t } = useTranslation()
  const { accountPreferences } = useFinpromContext() || {}
  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<SelfCategorisationData>()

  const [questionError, setQuestionError] = useState(false)

  const categoryOptions: CategoryRadioOption[] = selfCategorisationCategoryList?.map(
    (categoryKey: SelfCategorisationCategory) => ({
      value: categoryKey,
      label: `${t(`finprom.selfCategorisation.category.${categoryKey}.label`)}:`,
      secondaryLabel: t(`finprom.selfCategorisation.category.${categoryKey}.description`),
    })
  )

  const handleSelectOption = (value: SelfCategorisationCategory) => {
    setSelectedCategory(value)
    if (questionError) {
      setQuestionError(false)
    }
  }

  const onSubmit = () => {
    if (!selectedCategory) {
      setQuestionError(true)
      return
    }
    const currentData = accountPreferences?.[finpromSelfCategorisationPreferenceKey]
    const hasUpdatedCategory = selectedCategory !== currentData?.category
    if (hasUpdatedCategory) {
      const updatedData: SelfCategorisationData = {
        category: selectedCategory,
        attempts: currentData?.attempts,
      }
      submitPreference(
        finpromSelfCategorisationPreferenceKey,
        updatedData,
        FinpromTrackingLabels.SELF_CATEGORISATION_SELECT_CATEGORY
      )
    } else {
      onContinue()
    }
  }

  return (
    <Container>
      {isErrorContinue && <FinpromSubmitError />}
      <Description>{t('finprom.selfCategorisation.selectCategory.intro')}</Description>
      <Description>{t('finprom.selfCategorisation.selectCategory.chooseOption')}</Description>
      <FormContainer>
        <Radio
          value={selectedCategory}
          options={categoryOptions}
          onChange={handleSelectOption}
          error={questionError}
          errorText={t('emptySelect')}
          hasParagraphLayout
        />
        <FormSubmitButton disabled={questionError} loading={isLoadingContinue} onClick={onSubmit} />
      </FormContainer>
    </Container>
  )
}
