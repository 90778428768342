import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalNavBar } from '@node-space/storybook-components/dist/Modal'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { AccountContactForm } from 'components/accountContact/AccountContactForm'
import {
  AccountContactFormData,
  AccountContactLabels,
  AccountContactProps,
} from 'components/accountContact/types'
import { setTempId } from 'components/accountContact/utils'
import { Description } from 'components/Text'
import { OwnerTypes } from '../constants'
import { CorporateOwnerForm } from '../CorporateOwnerForm'
import { BusinessOwner, CorporateOwnerFormData, OwnerType, OwnerTypeOption } from '../types'

export const BusinessOwnerModal = ({
  initialData: businessOwner,
  isEditing,
  countryOptions,
  onSave,
  onDelete,
  onClose,
}: AccountContactProps<BusinessOwner>) => {
  const { t } = useTranslation()
  const [ownerType, setOwnerType] = useState<OwnerType>(
    businessOwner?.ownerType ?? OwnerTypes.INDIVIDUAL
  )

  const businessOwnerLabels: AccountContactLabels = {
    addButton: t('verification.businessOwners.addIndividualOwner'),
  }

  const ownerTypeRadioOptions: OwnerTypeOption[] = [
    { value: OwnerTypes.INDIVIDUAL, label: t('verification.businessOwners.individual') },
    { value: OwnerTypes.CORPORATE, label: t('verification.businessOwners.corporate') },
  ]

  const isIndividual = ownerType === OwnerTypes.INDIVIDUAL

  const handleOwnerTypeChange = (value: OwnerType) => {
    setOwnerType(value)
  }

  const handleSaveIndividual = (updatedData: AccountContactFormData) => {
    const id = businessOwner?.id ?? setTempId(`${updatedData?.firstName} ${updatedData?.lastName}`)
    const updatedIndividualOwner: BusinessOwner = {
      id,
      ownerType: OwnerTypes.INDIVIDUAL,
      individualOwner: updatedData,
    }
    onSave(updatedIndividualOwner)
  }

  const handleSaveCorporate = (updatedData: CorporateOwnerFormData) => {
    const id = businessOwner?.id ?? setTempId(updatedData?.name)
    const updatedCorporateOwner: BusinessOwner = {
      id,
      ownerType: OwnerTypes.CORPORATE,
      corporateOwner: updatedData,
    }
    onSave(updatedCorporateOwner)
  }

  const handleDelete = () => {
    onDelete(businessOwner?.id)
  }

  return (
    <Modal visible closeModal={onClose} allowOverFlow>
      <ModalNavBar title={t('verification.businessOwners.addOwner')} onClose={onClose} />
      <ModalBody spaceChildren={24} hasBottomPadding={false}>
        {!isEditing && (
          <Radio
            label={t('verification.businessOwners.typeLabel')}
            value={ownerType}
            options={ownerTypeRadioOptions}
            onChange={handleOwnerTypeChange}
            horizontal
            borderless={false}
          />
        )}
        <Description>
          {isIndividual
            ? t('verification.businessOwners.individualDescription')
            : t('verification.businessOwners.corporateDescription')}
        </Description>
      </ModalBody>

      {isIndividual ? (
        <AccountContactForm
          accountContact={businessOwner?.individualOwner}
          countryOptions={countryOptions}
          labels={businessOwnerLabels}
          isEditing={isEditing}
          hasTopPadding={false}
          onSave={handleSaveIndividual}
          onDelete={handleDelete}
        />
      ) : (
        <CorporateOwnerForm
          corporateOwner={businessOwner?.corporateOwner}
          isEditing={isEditing}
          countryOptions={countryOptions}
          onSave={handleSaveCorporate}
          onDelete={handleDelete}
        />
      )}
    </Modal>
  )
}
