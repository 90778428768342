import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box as Container, Button } from '@node-space/storybook-components'
import { isProduction, isSandbox } from 'utils/environment'
import { Amp } from 'utils/tracker'

export interface HeaderCTAProps {
  helpText?: string
  buttonText: string
  buttonUrl: string
  externalLink?: boolean
}

export const HeaderCTA = ({ helpText, buttonText, buttonUrl, externalLink }: HeaderCTAProps) => {
  const navigate = useNavigate()

  const goInternal = () => navigate(buttonUrl)
  const goExternal = () => window.open(buttonUrl)

  const onClickHandler = () => {
    if (buttonUrl === '/create-account') {
      if (isSandbox) Amp.track('DevX: SB: Click - Sign up button')
      if (isProduction) Amp.track('DevX: PD: Click - Sign up button')
      Amp.track('ONB - Sign up top link', {
        category: 'Signup',
        action: 'click',
      })
    }

    if (buttonUrl === '/login') {
      window.location.href = `${process.env.MERCHANT_PORTAL_URL}${buttonUrl}`
      return
    }

    externalLink ? goExternal() : goInternal()
  }

  return (
    <Container flex flexItemsCenter flexJustifyEnd className="flex-wrap pl-4">
      {helpText && <span className="text-gray-08 text-sm py-2">{helpText}</span>}
      <Button onClick={onClickHandler} secondary className={helpText ? ' ml-3' : ''}>
        {buttonText}
      </Button>
    </Container>
  )
}
