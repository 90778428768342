import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import { Icon } from '@node-space/storybook-components/dist/Icon'
import { SkeletonForm } from '@node-space/storybook-components/dist/SkeletonLoader'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import { useAccountContacts } from 'components/accountContact/useAccountContacts'
import { isTempId } from 'components/accountContact/utils'
import { Description } from 'components/Text'
import { VerificationTrackingLabels } from 'constants/verification'
import { useVerificationContext } from 'hooks/context/useVerificationContext'
import { useBusinessOwnersMutation } from 'hooks/mutations/useBusinessOwnersMutation'
import { useBusinessOwnersQuery } from 'hooks/queries/useBusinessOwnersQuery'
import { useIdVerification } from 'hooks/useIdVerification'
import { useMappedErrorMessage } from 'hooks/useMappedErrorMessage'
import { FormBody } from 'pages/Verification/_components/FormBody'
import { FormGetError } from 'pages/Verification/_components/FormGetError'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { FormSubmitError } from 'pages/Verification/_components/FormSubmitError'
import { NameTile, NameTilesContanier } from 'pages/Verification/_components/NameTile'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'
import { BaseErrorResponse } from 'types/errors'
import { emptyBusinessOwnerDetails, OwnerTypes } from '../constants'
import { BusinessOwner, BusinessOwnerRequestData, BusinessOwnerSubmitData } from '../types'
import { BusinessOwnerModal } from './BusinessOwnerModal'

export const BusinessOwners = () => {
  const { t } = useTranslation()

  const { isIdVerificationEnabled } = useIdVerification()
  const { requestError, setRequestError, resetRequestError } = useMappedErrorMessage()
  const { setUnsavedChanges, submitStep } = useVerificationContext()

  const {
    accountContacts,
    selectedAccountContact,
    countryOptions,
    isAccountContactOpen,
    isEditingAccountContact,
    isAccountContactsEmptyError,
    loadAccountContacts,
    getHasAccountContactsChanged,
    getIsAccountContactsValid,
    handleAddAccountContact,
    handleSaveAccountContact,
    handleEditAccountContact,
    handleDeleteAccountContact,
    handleCloseAccountContact,
  } = useAccountContacts<BusinessOwner>()

  const {
    data: businessOwnersData,
    isFetching: isFetchingBusinessOwners,
    isError: isGetError,
  } = useBusinessOwnersQuery()

  const { mutate: submitOwners, isPending: isLoadingSubmitRequest } = useBusinessOwnersMutation()

  const setLoadedOwners = () => {
    const businessOwners: BusinessOwner[] = businessOwnersData?.map(owner => {
      const ownerType = !!owner?.firstName ? OwnerTypes.INDIVIDUAL : OwnerTypes.CORPORATE
      const isIndividualOwner = ownerType === OwnerTypes.INDIVIDUAL
      const ownerData: BusinessOwner = {
        id: owner?.id,
        ownerType,
        individualOwner: isIndividualOwner
          ? {
              firstName: owner?.firstName,
              lastName: owner?.lastName,
              dateOfBirth: owner?.dateOfBirth,
              address1: owner?.address1,
              address2: owner?.address2,
              city: owner?.city,
              postalCode: owner?.postalCode,
              countryCode: owner?.countryCode,
            }
          : null,
        corporateOwner: !isIndividualOwner
          ? {
              name: owner?.name,
              countryCode: owner?.countryCode,
              registrationNumber: owner?.registrationNumber?.toLocaleString(),
              ownershipPercentage: owner?.ownershipPercentage,
              ownershipType: owner?.ownershipType,
            }
          : null,
      }
      return ownerData
    })
    loadAccountContacts(businessOwners)
  }

  const onSubmitOwners = () => {
    resetRequestError()

    const isValid = getIsAccountContactsValid()
    if (!isValid) {
      return
    }

    const businessOwnersSubmitData: BusinessOwnerRequestData[] = accountContacts?.map(owner => {
      const isIndividualOwner = owner?.ownerType === OwnerTypes.INDIVIDUAL
      const ownerDetails = isIndividualOwner ? owner?.individualOwner : owner?.corporateOwner
      const ownerSubmitData: BusinessOwnerRequestData = {
        ...(!isTempId(owner?.id) && { id: Number(owner?.id) }),
        ...emptyBusinessOwnerDetails,
        ...ownerDetails,
        ownerType: owner?.ownerType,
      }
      return ownerSubmitData
    })
    const data: BusinessOwnerSubmitData = {
      owners: businessOwnersSubmitData,
    }

    submitOwners(data, {
      onSuccess: () => {
        submitStep(VerificationTrackingLabels.BUSINESS_OWNERS)
      },
      onError: (error: BaseErrorResponse) => {
        const sentryTraceId = logSentryErrorAndGetTraceId('submitOwners', error)
        setRequestError({
          errorCode: error?.status,
          sentryTraceId,
          show: true,
        })
      },
    })
  }

  useEffect(() => {
    if (!isFetchingBusinessOwners && !!businessOwnersData?.length) {
      setLoadedOwners()
    }
  }, [isFetchingBusinessOwners])

  useEffect(() => {
    const isChanged = getHasAccountContactsChanged()
    setUnsavedChanges(isChanged)
  }, [accountContacts])

  return (
    <>
      <VerificationHeader header={t('verification.routes.businessOwners')}>
        <Description>{t('verification.businessOwners.descriptionRequirement')}</Description>
        <Description>{t('verification.businessOwners.descriptionWho')}</Description>
        {isIdVerificationEnabled && (
          <Description>{t('verification.businessOwners.descriptionIdVerification')}</Description>
        )}
      </VerificationHeader>
      {isFetchingBusinessOwners ? (
        <SkeletonForm name="Business owners" rowCount={1} />
      ) : isGetError ? (
        <FormGetError />
      ) : (
        <FormBody>
          {requestError?.show && (
            <FormSubmitError
              errorCode={requestError?.errorCode}
              sentryTraceId={requestError?.sentryTraceId}
            />
          )}

          {!!accountContacts?.length && (
            <NameTilesContanier>
              {accountContacts?.map(owner => (
                <NameTile
                  key={owner?.id}
                  tileId={owner?.id}
                  label={
                    owner?.ownerType === OwnerTypes.INDIVIDUAL
                      ? `${owner?.individualOwner?.firstName} ${owner?.individualOwner?.lastName}`
                      : owner?.corporateOwner?.name
                  }
                  onEdit={() => handleEditAccountContact(owner?.id)}
                  onDelete={() => handleDeleteAccountContact(owner?.id)}
                />
              ))}
            </NameTilesContanier>
          )}

          {isAccountContactsEmptyError && (
            <Callout
              message={t('verification.businessOwners.noAddedOwners')}
              state="error"
              showCloseButton={false}
            />
          )}

          <Button
            secondary
            iconElement={<Icon name="PlusIcon" color="primary-500" />}
            onClick={handleAddAccountContact}
            className="w-fit"
          >
            {t('verification.businessOwners.addOwner')}
          </Button>

          <FormSubmitButton
            loading={isLoadingSubmitRequest}
            disabled={isAccountContactsEmptyError}
            onClick={onSubmitOwners}
          />
        </FormBody>
      )}
      {isAccountContactOpen && (
        <BusinessOwnerModal
          initialData={selectedAccountContact}
          isEditing={isEditingAccountContact}
          countryOptions={countryOptions}
          onSave={handleSaveAccountContact}
          onDelete={handleDeleteAccountContact}
          onClose={handleCloseAccountContact}
        />
      )}
    </>
  )
}

export default BusinessOwners
