import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { logSentryErrorAndGetTraceId } from '@node-space/utils'
import {
  useDeleteAccountContactMutation,
  useSaveAccountContactMutation,
} from 'hooks/mutations/useAccountContactsMutation'
import { useCountriesQuery } from 'hooks/queries/useCountriesQuery'
import { useIdVerification } from 'hooks/useIdVerification'
import { useToastContext } from 'hooks/useToastContext'
import { BaseErrorResponse } from 'types/errors'
import { AccountContactErrorTypes, AccountContactTranslation } from './constants'
import {
  AccountContactActionsData,
  AccountContactData,
  AccountContactErrorStatus,
  AccountContactErrorType,
  AccountContactPayload,
  AccountContactsContext,
  AccountContactType,
  DeleteAccountContactData,
  onSaveAccountContact,
} from './types'
import { getAccountContactName, getAccountContactsByType, getPersonSelectorData } from './utils'

export const useAccountContactActions = (
  type: AccountContactType,
  accountContactsData: AccountContactsContext
) => {
  const { t } = useTranslation()
  const addToast = useToastContext()
  const { isIdVerificationEnabled, createIdVerificationLink, idVerificationMessage } =
    useIdVerification()

  const contactType = t(`accountContact.type.${AccountContactTranslation?.[type]}`)
  const contactList = getAccountContactsByType(type, accountContactsData?.list)
  const personSelectorData = getPersonSelectorData(accountContactsData?.list)

  const [selectedAccountContact, setSelectedAccountContact] = useState<AccountContactData>()
  const [deletingAccountContacts, setDeletingAccountContacts] = useState<string[]>([])
  const [actionErrorStatus, setActionErrorStatus] = useState<AccountContactErrorStatus>()
  const [isUpdatingDetails, setIsUpdatingDetails] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [createdReference, setCreatedReference] = useState<string>()

  const { data: countryOptions = [], isFetching: isFetchingCountryOptions } =
    useCountriesQuery(false)

  const { mutate: saveAccountContact, isPending: isSavingAccountContact } =
    useSaveAccountContactMutation()

  const { mutate: deleteAccountContact, isPending: isDeletingAccountContact } =
    useDeleteAccountContactMutation()

  const resetActionErrorStatus = () => {
    setActionErrorStatus(null)
  }

  const openDetails = () => {
    resetActionErrorStatus()
    setIsDetailsOpen(true)
  }

  const onAddDetails = () => {
    openDetails()
  }

  const onUpdateDetails = (reference: string) => {
    const selectedAccountContact: AccountContactData = contactList?.find(
      contact => contact?.reference === reference
    )
    setSelectedAccountContact(selectedAccountContact)
    setIsUpdatingDetails(true)
    openDetails()
  }

  const onCloseDetails = () => {
    resetActionErrorStatus()
    setIsDetailsOpen(false)
    setIsUpdatingDetails(false)
    setSelectedAccountContact(null)
    setCreatedReference(null)
  }

  const onSubmitSuccess = (toastMessage: string) => {
    addToast({ title: toastMessage, state: 'success' })
    accountContactsData?.refetch()
    onCloseDetails()
  }

  const onSaveSuccess = (contactFullName: string) => {
    onSubmitSuccess(
      t(`accountContact.${isUpdatingDetails ? 'update' : 'add'}.success`, {
        contactType,
        contactFullName,
      })
    )
  }

  const onSubmitError = (
    errorType: AccountContactErrorType,
    error: BaseErrorResponse,
    contactFullName: string
  ) => {
    const sentryTraceId = logSentryErrorAndGetTraceId(`${errorType} - ${type}`, error)
    setActionErrorStatus({ errorType, sentryTraceId, contactFullName })
  }

  const onCreateIdVerificationLink = (reference: string, contactFullName: string) => {
    const payload: AccountContactPayload = { type, reference }
    const onError = (error: BaseErrorResponse) =>
      onSubmitError(AccountContactErrorTypes.CREATE_ID_VERIFICATION_LINK, error, contactFullName)
    createIdVerificationLink?.onCreateLink({ payload, onError })
  }

  const onSave: onSaveAccountContact = (savedAccountContact, isNewPerson) => {
    resetActionErrorStatus()
    const contactFullName = getAccountContactName(savedAccountContact)
    const data = {
      ...savedAccountContact,
      type,
    }
    if (createdReference) {
      data.reference = createdReference
    }
    saveAccountContact(data, {
      onSuccess: ({ reference }) => {
        if (isNewPerson && isIdVerificationEnabled) {
          setCreatedReference(reference)
          onCreateIdVerificationLink(reference, contactFullName)
          return
        }
        onSaveSuccess(contactFullName)
      },
      onError: (error: BaseErrorResponse) => {
        onSubmitError(
          isUpdatingDetails
            ? AccountContactErrorTypes.UPDATE_CONTACT
            : AccountContactErrorTypes.ADD_CONTACT,
          error,
          contactFullName
        )
      },
    })
  }

  const onDelete = ({ reference, contactFullName }: DeleteAccountContactData) => {
    resetActionErrorStatus()
    const translationDetails = { contactType, contactFullName }
    setDeletingAccountContacts(currentList => {
      return !currentList?.includes(reference) ? [...currentList, reference] : currentList
    })
    deleteAccountContact(
      { type, reference },
      {
        onSuccess: () => {
          onSubmitSuccess(t(`accountContact.delete.success`, translationDetails))
        },
        onError: (error: BaseErrorResponse) => {
          onSubmitError(AccountContactErrorTypes.DELETE_CONTACT, error, contactFullName)
        },
        onSettled: () => {
          setDeletingAccountContacts(currentList => {
            return currentList?.filter(item => item !== reference)
          })
        },
      }
    )
  }

  const getIsAccountContactsValid = () => {
    const hasAccountContacts = !!contactList?.length
    if (!hasAccountContacts) {
      setActionErrorStatus({ errorType: AccountContactErrorTypes.EMPTY_CONTACTS })
    }
    return hasAccountContacts
  }

  const handleContinueIdVerificationMessage = (contactFullName: string) => {
    onSaveSuccess(contactFullName)
    idVerificationMessage?.setShowMessage(false)
  }

  const actionsData: AccountContactActionsData = {
    currentContactType: type,
    personSelectorData,
    countryOptions,
    isUpdatingDetails,
    idVerificationMessage: {
      ...idVerificationMessage,
      onContinue: handleContinueIdVerificationMessage,
    },
    loading: {
      isSaving: isSavingAccountContact || createIdVerificationLink?.isPending,
      isDeleting: isDeletingAccountContact,
      isFetchingCountryOptions,
    },
  }

  return {
    actionsData,
    contactList,
    selectedAccountContact,
    deletingAccountContacts,
    isDetailsOpen,
    errors: {
      actionErrorStatus,
    },
    getIsAccountContactsValid,
    onAddDetails,
    onUpdateDetails,
    onCloseDetails,
    onSave,
    onDelete,
  }
}
