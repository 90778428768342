import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '@node-space/hooks'
import { getVerificationRequestStatus } from 'services/AccountService'
import { isTestEnvironment } from 'utils/environment'
import { ONE_DAY } from 'utils/utils'

export const useVerificationRequestStatusQuery = (isEnabled = true) => {
  const { isAuthed } = useAuth()

  return useQuery({
    queryKey: reactQueryKeys.verificationRequestStatus(),
    queryFn: getVerificationRequestStatus,
    staleTime: ONE_DAY,
    enabled: isTestEnvironment || (isAuthed && isEnabled),
  })
}
