import { Amplitude } from 'utils/amplitude'
import { GoogleAnalytics } from 'utils/googleAnalytics'
import { Hotjar as HJ } from 'utils/hotJar'

const track = {
  Amp: Amplitude,
  GA: GoogleAnalytics,
  Hotjar: HJ,
}

export const Amp = Amplitude
export const GA = GoogleAnalytics
export const Hotjar = HJ

/**
 * This function tracks an Onboarding event in Amplitude.
 * @param {String} actionLabel is used in naming the tracking event and is is prepended with "ONB - ".
 * @param {String} actionType is an optional value which is defaulted to "click" if none provided.
 * Examples are "click", "load" and "verified".
 * @returns void
 */
export const trackOnboarding = (actionLabel: string, actionType?: string) =>
  Amp.track(`ONB - ${actionLabel}`, {
    category: 'Onboarding',
    action: actionType ?? 'click',
  })

export default track
