export const FinpromVerificationStepKeys = {
  RISK_WARNING: 'riskWarning',
  SELF_CATEGORISATION: 'selfCategorisation',
  APPROPRIATENESS_TEST: 'appropriatenessTest',
} as const

export const FinpromVerificationStepList = Object.values(FinpromVerificationStepKeys)

export const FinpromStepKeys = {
  INITIAL: 'initial',
  ...FinpromVerificationStepKeys,
  COOL_OFF: 'cool-off',
} as const

export const SelfCategorisationStepKeys = {
  SELECT_CATEGORY: 'selectCategory',
  CONFIRM_CATEGORY: 'confirmCategory',
} as const

export const selfCategorisationStepList = Object.values(SelfCategorisationStepKeys)

export const SelfCategorisationCategories = {
  RESTRICTED: 'restricted',
  HIGH_NET_WORTH: 'highNetWorth',
  SOPHISTICATED: 'sophisticated',
} as const

export const selfCategorisationCategoryList = Object.values(SelfCategorisationCategories)

export const SelfCategorisationThreshold = {
  INVESTMENT_PERCENTAGE: 10,
  INCOME: 100000,
  NET_ASSETS: 250000,
} as const

export const AppropriatenessQuestionKeys = {
  DUE_DILIGENCE: 'dueDiligence',
  INVESTING_IN_CRYPTO: 'investingInCrypto',
  CRYPTO_ASSET_SERVICES: 'cryptoAssetServices',
  CRYPTO_ASSET_PAYMENT_RISKS: 'cryptoAssetPaymentRisks',
  CRYPTO_ASSET_BVNK_OFFER: 'cryptoAssetBvnkOffer',
  FSCS_COVER: 'fscsCover',
  CRYPTO_ASSET_DIVERSITY: 'cryptoAssetDiversity',
} as const
export const AppropriatenessQuestionList = Object.values(AppropriatenessQuestionKeys)
export const AppropriatenessSuccessRate: number = AppropriatenessQuestionList?.length - 1

export const AppropriatenessStepKeys = {
  INSTRUCTIONS: 'instructions',
  ...AppropriatenessQuestionKeys,
} as const
export const AppropriatenessStepList = Object.values(AppropriatenessStepKeys)

export const YesNoValues = {
  YES: 'yes',
  NO: 'no',
} as const

export const FinpromResult = {
  PASSED: 'passed',
  UNSUCCESSFUL: 'unsuccessful',
} as const

export const FinpromTrackingLabels = {
  INITIAL_MODAL: 'initial modal',
  RISK_WARNING: 'risk warning step',
  SELF_CATEGORISATION_SELECT_CATEGORY: 'self categorisation select category step',
  SELF_CATEGORISATION_CONFIRM_CATEGORY: 'self categorisation confirm category step',
  SELF_CATEGORISATION_RETAKE_QUIZ: 'self categorisation retake quiz',
  SELF_CATEGORISATION_COMPLETED: 'self categorisation completed',
  SELF_CATEGORISATION_UPDATE: 'self categorisation update',
  APPROPRIATENESS_STEP_INSTRUCTIONS: 'appropriateness step 1 (instructions) continue',
  APPROPRIATENESS_STEP_DUE_DILIGENCE: 'appropriateness step 2 (due diligence) continue',
  APPROPRIATENESS_STEP_INVESTING_IN_CRYPTO: 'appropriateness step 3 (investing in crypto) continue',
  APPROPRIATENESS_STEP_CRYPTO_ASSET_SERVICES:
    'appropriateness step 4 (crypto asset services) continue',
  APPROPRIATENESS_STEP_CRYPTO_ASSET_PAYMENT_RISKS:
    'appropriateness step 5 (crypto asset payment risks) continue',
  APPROPRIATENESS_STEP_CRYPTO_ASSET_BVNK_OFFER:
    'appropriateness step 6 (crypto asset BVNK offer) continue',
  APPROPRIATENESS_STEP_FSCS_COVER: 'appropriateness step 7 (FSCS cover) continue',
  APPROPRIATENESS_STEP_CRYPTO_ASSET_DIVERSITY:
    'appropriateness step 8 (crypto asset diversity) continue',
  APPROPRIATENESS_STEPS_RESULT_PASSED: 'appropriateness result passed continue',
  APPROPRIATENESS_RETAKE_QUIZ: 'appropriateness retake quiz',
} as const
