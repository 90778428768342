import React from 'react'
import { SelfCategorisationCategories } from 'pages/Finprom/constants'
import { SelfCategorisationCategory } from 'pages/Finprom/types'
import { HighNetWorthInvestorForm } from '../forms/HighNetWorthInvestorForm'
import { RestrictedInvestorForm } from '../forms/RestrictedInvestorForm'
import { SophisticatedInvestorForm } from '../forms/SophisticatedInvestorForm'

export interface StepConfirmCategoryProps {
  selectedCategory: SelfCategorisationCategory
}

export const StepConfirmCategory = ({ selectedCategory }: StepConfirmCategoryProps) => {
  switch (selectedCategory) {
    case SelfCategorisationCategories.RESTRICTED:
      return <RestrictedInvestorForm />
    case SelfCategorisationCategories.HIGH_NET_WORTH:
      return <HighNetWorthInvestorForm />
    default:
      return <SophisticatedInvestorForm />
  }
}
