import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Box from '@node-space/storybook-components/dist/Box'
import { ButtonProps } from '@node-space/storybook-components/dist/Button'
import Callout from '@node-space/storybook-components/dist/Callout'
import Modal, { ModalActions, ModalBody } from '@node-space/storybook-components/dist/Modal'
import Text, { TextProps } from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Description } from 'components/Text'
import { RedirectUri } from 'constants/General'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import {
  finpromCoolOffPreferenceKey,
  type FinpromCoolOffStatus,
} from 'pages/Finprom/finpromPreferenceData'
import { useFinpromPreferenceRequest } from 'pages/Finprom/hooks/useFinpromPreferenceRequest'

export const InitialModal = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showBackToBusinessAddress, setIsGoBackToBusinessAddress, setActiveStep } =
    useFinpromContext()

  const { isLoadingContinue, isErrorContinue, submitPreference } =
    useFinpromPreferenceRequest<FinpromCoolOffStatus>()

  const sharedTextProps: TextProps = {
    tag: 'span',
    size: 'sm',
    color: 'inherit',
  }

  const onExit = () => navigate(RedirectUri.LOGOUT, { replace: true })

  const onBack = () => {
    setIsGoBackToBusinessAddress(true)
    setActiveStep(null)
  }

  const onContinue = () => {
    const preferenceValue = new Date()
    submitPreference(
      finpromCoolOffPreferenceKey,
      preferenceValue,
      FinpromTrackingLabels.INITIAL_MODAL
    )
  }

  const exitAction: ButtonProps = {
    children: t('exit'),
    secondary: true,
    disabled: isLoadingContinue,
    onClick: onExit,
  }

  const continueAction: ButtonProps = {
    children: t('continue'),
    loading: isLoadingContinue,
    onClick: onContinue,
  }

  return (
    <Modal visible closeModal={onExit}>
      <ModalBody spaceChildren={24}>
        <Description>{t('finprom.initialModal.introduction')}</Description>
        {!isErrorContinue && (
          <Callout state="info">
            {showBackToBusinessAddress ? (
              <>
                <Box>
                  <Text {...sharedTextProps}>{t('finprom.initialModal.callout')} </Text>
                  <Text {...sharedTextProps} weight="medium">
                    {t('goBackIncorrectInformation')}
                  </Text>
                </Box>
                <Box paddingT={12} className="text-secondary-500">
                  <TextAction
                    text={t('goBack')}
                    onClick={onBack}
                    inheritColor
                    className="no-underline"
                  />
                </Box>
              </>
            ) : (
              <Text {...sharedTextProps}>{t('finprom.initialModal.callout')} </Text>
            )}
          </Callout>
        )}
        {isErrorContinue && <FinpromSubmitError />}
      </ModalBody>
      <ModalActions actions={[exitAction, continueAction]} />
    </Modal>
  )
}
