import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDocumentTitle } from '@mantine/hooks'
import useAuthRedirect from 'utils/auth/hooks/useAuthRedirect'

export const LoginUser = () => {
  useDocumentTitle(`${process.env.SITE_TITLE}`)
  const navigate = useNavigate()
  const token = useAuthRedirect()

  useEffect(() => {
    if (!token) {
      navigate('/logout')
    }
  }, [token])

  return <></>
}
