import { useEffect } from 'react'
import { logSentryError } from '@node-space/utils'
import { useRecaptcha } from './useRecaptcha'

export const RecaptchaVersion = {
  V3: 'V3',
} as const
export type RecaptchaVersionType = (typeof RecaptchaVersion)[keyof typeof RecaptchaVersion]

export interface RecaptchaPayload {
  recaptcha: string
  recaptchaVersion?: RecaptchaVersionType
}

export const useRecaptchaVersioning = (action: string) => {
  const {
    isLoadingRecaptcha,
    loadRecaptcha,
    getRecaptchaToken: getRecaptchaTokenV3,
    unloadRecaptcha,
  } = useRecaptcha(action)

  const getRecaptchaToken = async (): Promise<string> => {
    try {
      return getRecaptchaTokenV3()
        .then(token => token)
        .catch(error => {
          logSentryError('getRecaptchaTokenV3', error)
          return ''
        })
    } catch (error) {
      logSentryError('getRecaptchaTokenGlobal', error)
    }
  }

  useEffect(() => {
    loadRecaptcha()
  }, [])

  return {
    isLoadingRecaptcha,
    recaptchaVersion: RecaptchaVersion.V3,
    getRecaptchaToken,
    resetRecaptcha: unloadRecaptcha,
  }
}
