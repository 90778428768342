import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@node-space/storybook-components/dist/Box'
import Text from '@node-space/storybook-components/dist/Text'
import { TextAction } from '@node-space/storybook-components/dist/TextAction'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { SupportEmail } from 'constants/General'
import { useFinpromContext } from 'hooks/context/useFinpromContext'
import { AppropriatenessQuestionList } from 'pages/Finprom/constants'
import { finpromAppropriatenessTestPreferenceKey } from 'pages/Finprom/finpromPreferenceData'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'
import { VerificationHeader } from 'pages/Verification/_components/VerificationHeader'

export const AppropriatenessResultPage = () => {
  const { t } = useTranslation()
  const { accountPreferences, setActiveStep } = useFinpromContext()

  const totalSteps = AppropriatenessQuestionList?.length
  const correctSteps =
    accountPreferences?.[finpromAppropriatenessTestPreferenceKey]?.correctQuestionsTotal

  const onContinue = () => {
    setActiveStep(null)
  }

  return (
    <Container>
      <VerificationHeader header={t('finprom.appropriateness.result.title')} />
      <Box>
        <Text size="sm" weight="medium">
          {t('finprom.appropriateness.result.passedResult', { correctSteps, totalSteps })}
        </Text>
        <Description>{t('finprom.appropriateness.result.thankYouForCompleting')}</Description>
      </Box>
      <Description>
        {t('finprom.appropriateness.passed.haveFurtherQuestions')}
        <TextAction text={SupportEmail.MERCHANTS} linkTo={`mailto:${SupportEmail.MERCHANTS}`} />.
      </Description>
      <FormSubmitButton disabled={false} loading={false} onClick={onContinue} />
    </Container>
  )
}
