import React from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { FinpromTrackingLabels } from 'pages/Finprom/constants'
import { useFinpromAppropriatenessForm } from 'pages/Finprom/hooks/useFinpromAppropriatenessForm'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

export const FscsCoverOptions = {
  CANNOT_COVER: 'cannotCover',
  CAN_COVER_RECENT_CUSTOMER: 'canCoverRecentCustomer',
  CAN_COVER_MAX_AMOUNT: 'canCoverMaxAmount',
} as const

type FscsCoverOption = (typeof FscsCoverOptions)[keyof typeof FscsCoverOptions]

const fscsCoverOptionList = Object.values(FscsCoverOptions)
const fscsCoverCorrectAnswer: FscsCoverOption = FscsCoverOptions.CANNOT_COVER

export const StepFscsCover = () => {
  const { t } = useTranslation()
  const {
    questionAnswer,
    isQuestionError,
    questionErrorText,
    isLoadingContinue,
    isErrorContinue,
    getQuestionOptions,
    handleSelectAnswer,
    onSubmitQuestion,
  } = useFinpromAppropriatenessForm<FscsCoverOption>()

  const fscsCoverOptions = getQuestionOptions(fscsCoverOptionList)

  const handleSubmit = () => {
    const isCorrectAnswer = questionAnswer === fscsCoverCorrectAnswer
    onSubmitQuestion(isCorrectAnswer, FinpromTrackingLabels.APPROPRIATENESS_STEP_FSCS_COVER)
  }

  return (
    <Container>
      <Description>{t('finprom.appropriateness.fscsCover.whichStatement')}</Description>
      <Radio
        value={questionAnswer}
        options={fscsCoverOptions}
        onChange={handleSelectAnswer}
        error={isQuestionError}
        errorText={questionErrorText}
        hasParagraphLayout
      />
      {isErrorContinue && <FinpromSubmitError />}
      <FormSubmitButton
        disabled={isQuestionError}
        loading={isLoadingContinue}
        onClick={handleSubmit}
      />
    </Container>
  )
}
