import { TFunction } from 'i18next'
import * as yup from 'yup'
import { useYupFields } from 'hooks/forms/useYupFields'
import { AccountContactFormKeys } from './types'

export const AccountContactSchema = (t: TFunction<'translation', undefined>) => {
  const { getYupField, getRequiredYupField } = useYupFields()

  return yup.object<Partial<Record<AccountContactFormKeys, yup.AnySchema>>>({
    firstName: getRequiredYupField({ label: t('formFields.firstName') }),
    lastName: getRequiredYupField({ label: t('formFields.lastName') }),
    dateOfBirth: getRequiredYupField({ label: t('formFields.dateOfBirth') }),
    address1: getRequiredYupField({ label: t('formFields.addressLine1') }),
    address2: getYupField({ label: t('formFields.addressLine2') }),
    city: getRequiredYupField({ label: t('formFields.city') }),
    postalCode: getRequiredYupField({ label: t('formFields.postalCode') }),
    countryCode: getRequiredYupField({
      label: t('formFields.chooseCountry'),
      requiredErrorMessage: t('formFields.countryIsRequired'),
    }),
  })
}
