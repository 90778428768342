import React from 'react'
import { FinpromResult } from 'pages/Finprom/constants'
import { FinpromResultType } from 'pages/Finprom/types'
import { PassedModal } from './result/PassedModal'
import { UnsuccessfulModalFirstAttempt } from './result/UnsuccessfulModalFirstAttempt'
import { UnsuccessfulModalSecondAttempt } from './result/UnsuccessfulModalSecondAttempt'
import { UnsuccessfulModalThirdAttempt } from './result/UnsuccessfulModalThirdAttempt'

export interface AppropriatenessResultModalProps {
  result: FinpromResultType
  attempt: number
}

export const AppropriatenessResultModal = ({
  result,
  attempt,
}: AppropriatenessResultModalProps) => {
  if (result === FinpromResult.PASSED) {
    return <PassedModal />
  }
  switch (attempt) {
    case 1:
      return <UnsuccessfulModalFirstAttempt />
    case 2:
      return <UnsuccessfulModalSecondAttempt />
    default:
      return <UnsuccessfulModalThirdAttempt />
  }
}
