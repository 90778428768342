import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import Box, { Box as Divider, Box as IntroBox } from '@node-space/storybook-components/dist/Box'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import { Option as RadioOption } from '@node-space/storybook-components/dist/components/Radio/Radio'
import { ErrorText, InputLabel } from '@node-space/storybook-components/dist/FormElements'
import Input from '@node-space/storybook-components/dist/Input'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import Text from '@node-space/storybook-components/dist/Text'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { Currencies } from 'constants/General'
import { useFormFields } from 'hooks/forms/useFormFields'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { YesNoValues } from 'pages/Finprom/constants'
import { useSubmitSelfCategorisation } from 'pages/Finprom/hooks/useSubmitSelfCategorisation'
import {
  HighNetWorthFormData,
  HighNetWorthFormKeys,
  HighNetWorthSchema,
} from 'pages/Finprom/schemas/highNetWorthSchema'
import { YesNoValueType } from 'pages/Finprom/types'
import { getHighNetWorthFormResult } from 'pages/Finprom/utils'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

type HighNetWorthRadioOption = RadioOption & {
  value: YesNoValueType
}

export const HighNetWorthInvestorForm = () => {
  const { t } = useTranslation()
  const { savedAnswers, isLoadingContinue, isErrorContinue, onSubmitCategory } =
    useSubmitSelfCategorisation<HighNetWorthFormData>() || {}

  const defaultValues: HighNetWorthFormData = {
    isAnnualIncomeMore: savedAnswers?.isAnnualIncomeMore,
    annualIncomeValue: savedAnswers?.annualIncomeValue ?? '',
    isNetAssetsMore: savedAnswers?.isNetAssetsMore,
    netAssetsValue: savedAnswers?.netAssetsValue ?? '',
    isRiskAccepted: !!savedAnswers?.isRiskAccepted,
  }

  const YesNoOptions: HighNetWorthRadioOption[] = [
    {
      value: YesNoValues.YES,
      secondaryLabel: t('yes'),
    },
    {
      value: YesNoValues.NO,
      secondaryLabel: t('no'),
    },
  ]

  const formSchema = HighNetWorthSchema(t)
  const form = useForm<HighNetWorthFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = form

  const formValues = useWatch({ control })

  const { setRadioField, setNumberInputField, setCheckboxField } =
    useFormFields<HighNetWorthFormKeys>(formSchema, form, formValues)

  const formFields: Record<HighNetWorthFormKeys, any> = {
    isAnnualIncomeMore: setRadioField({
      key: 'isAnnualIncomeMore',
      options: YesNoOptions,
      onChangeAdditional: value => {
        if (value !== YesNoValues.YES) {
          clearErrors('annualIncomeValue')
        }
      },
    }),
    annualIncomeValue: setNumberInputField({
      key: 'annualIncomeValue',
      placeholder: t('finprom.selfCategorisation.highNetWorthForm.questionAIncomePlaceholder'),
    }),
    isNetAssetsMore: setRadioField({
      key: 'isNetAssetsMore',
      options: YesNoOptions,
      onChangeAdditional: value => {
        if (value !== YesNoValues.YES) {
          clearErrors('netAssetsValue')
        }
      },
    }),
    netAssetsValue: setNumberInputField({
      key: 'netAssetsValue',
      placeholder: t('finprom.selfCategorisation.highNetWorthForm.questionBAssetsPlaceholder'),
    }),
    isRiskAccepted: setCheckboxField({ key: 'isRiskAccepted' }),
  }

  const incomeCurrencyPostLabel = (
    <Box divider="left" paddingL={12}>
      <Text size="sm" color="text-500">
        {Currencies.GBP}
      </Text>
    </Box>
  )

  const onSubmit = () => {
    const highNetWorthAnswers = formValues as HighNetWorthFormData
    const isHighNetWorthInvestor = getHighNetWorthFormResult(highNetWorthAnswers)
    onSubmitCategory(highNetWorthAnswers, isHighNetWorthInvestor)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        {isErrorContinue && <FinpromSubmitError />}
        <IntroBox>
          <InputLabel>{t('finprom.selfCategorisation.highNetWorthForm.statementTitle')}</InputLabel>
          <Description>
            {t('finprom.selfCategorisation.highNetWorthForm.statementParagraph')}
          </Description>
        </IntroBox>
        <Radio {...formFields.isAnnualIncomeMore} hasParagraphLayout />
        {formValues?.isAnnualIncomeMore === YesNoValues.YES && (
          <>
            <Description>
              {t('finprom.selfCategorisation.highNetWorthForm.questionAIncomeIntro')}
            </Description>
            <Input {...formFields.annualIncomeValue} postLabel={incomeCurrencyPostLabel} />
          </>
        )}
        <Divider divider="bottom" />
        <Radio {...formFields.isNetAssetsMore} hasParagraphLayout />
        {formValues?.isNetAssetsMore === YesNoValues.YES && (
          <>
            <Description>
              {t('finprom.selfCategorisation.highNetWorthForm.questionBAssetsIntro')}
            </Description>
            <Input {...formFields.netAssetsValue} postLabel={incomeCurrencyPostLabel} />
          </>
        )}
        <Divider divider="bottom" />
        <Box>
          <CheckBox {...formFields.isRiskAccepted} labelColour="light" />
          {errors?.isRiskAccepted && <ErrorText text={errors?.isRiskAccepted?.message} />}
        </Box>

        <FormSubmitButton disabled={!!Object.keys(errors)?.length} loading={isLoadingContinue} />
      </Container>
    </form>
  )
}
