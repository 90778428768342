import React, { useEffect, useState } from 'react'
import { useAccountContext } from 'hooks/context/useAccountContext'
import { useProfileContext } from 'hooks/context/useProfileContext'
import { CSATModal } from './CSATModal'
import { useFeatureFlags } from '@node-space/hooks'
import { useSearchParams } from 'react-router-dom'

export const CSAT_PARAM = 'csat'

export const CSATLaunch = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const showCsat = searchParams.get(CSAT_PARAM)

  const { enableCsatModal } = useFeatureFlags()
  const [showCSATSurveyModal, setShowCSATSurveyModal] = useState(false)

  const { profile } = useProfileContext()
  const { currentAccount } = useAccountContext()

  const onClickComplete = () => {
    setShowCSATSurveyModal(false)
    setSearchParams('')
  }

  useEffect(() => {
    if (showCsat) {
      setShowCSATSurveyModal(true)
    } else {
      setShowCSATSurveyModal(false)
      setSearchParams('')
    }
  }, [showCsat])

  return (
    <>
      {enableCsatModal && showCSATSurveyModal && profile?.emailAddress && (
        <>
          <CSATModal
            customer={{
              emailAddress: profile?.emailAddress,
              firstname: profile?.firstName,
              lastname: profile?.lastName,
              companyName: currentAccount?.name,
              customerID: `${profile?.id}`,
            }}
            onClose={onClickComplete}
          />
        </>
      )}
    </>
  )
}
