import { AccountContactFormData } from './types'

export const AccountContactTypes = {
  BUSINESS_OWNER: 'BUSINESS_OWNER',
  ACCOUNT_REPRESENTATIVE: 'ACCOUNT_REPRESENTATIVE',
  BUSINESS_DIRECTOR: 'BUSINESS_DIRECTOR',
} as const

export const TEMP_ID_TAG = 'tempId - '

export const initialAccountContactFormData: AccountContactFormData = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  address1: '',
  address2: '',
  city: '',
  postalCode: '',
  countryCode: '',
}

export const initialAccountContactData = {
  id: null,
  ...initialAccountContactFormData,
}
