import React from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import Box, { Box as Divider, Box as IntroBox } from '@node-space/storybook-components/dist/Box'
import CheckBox from '@node-space/storybook-components/dist/CheckBox'
import { Option as RadioOption } from '@node-space/storybook-components/dist/components/Radio/Radio'
import { ErrorText, InputLabel } from '@node-space/storybook-components/dist/FormElements'
import Icon from '@node-space/storybook-components/dist/Icon'
import Input from '@node-space/storybook-components/dist/Input'
import { Radio } from '@node-space/storybook-components/dist/Radio'
import { Container } from 'components/Container'
import { Description } from 'components/Text'
import { useFormFields } from 'hooks/forms/useFormFields'
import { FinpromSubmitError } from 'pages/Finprom/components/FinpromSubmitError'
import { YesNoValues } from 'pages/Finprom/constants'
import { useSubmitSelfCategorisation } from 'pages/Finprom/hooks/useSubmitSelfCategorisation'
import {
  RestrictedFormData,
  RestrictedFormKeys,
  RestrictedSchema,
} from 'pages/Finprom/schemas/restrictedSchema'
import { YesNoValueType } from 'pages/Finprom/types'
import { getRestrictedFormResult } from 'pages/Finprom/utils'
import { FormSubmitButton } from 'pages/Verification/_components/FormSubmitButton'

type RestrictedRadioOption = RadioOption & {
  value: YesNoValueType
}

export const RestrictedInvestorForm = () => {
  const { t } = useTranslation()
  const { savedAnswers, isLoadingContinue, isErrorContinue, onSubmitCategory } =
    useSubmitSelfCategorisation<RestrictedFormData>() || {}

  const percentagePostLabel = <Icon name="InterestIcon" />

  const defaultValues: RestrictedFormData = {
    isPercentageInvestedLess: savedAnswers?.isPercentageInvestedLess,
    percentageInvested: savedAnswers?.percentageInvested ?? '',
    isPercentageIntendedToInvestLess: savedAnswers?.isPercentageIntendedToInvestLess,
    percentageIntendedToInvest: savedAnswers?.percentageIntendedToInvest ?? '',
    isRiskAccepted: !!savedAnswers?.isRiskAccepted,
  }

  const optionsA: RestrictedRadioOption[] = [
    {
      value: YesNoValues.YES,
      secondaryLabel: t('finprom.selfCategorisation.restrictedForm.questionAYes'),
    },
    {
      value: YesNoValues.NO,
      secondaryLabel: t('finprom.selfCategorisation.restrictedForm.questionANo'),
    },
  ]

  const optionsB: RestrictedRadioOption[] = [
    {
      value: YesNoValues.YES,
      secondaryLabel: t('finprom.selfCategorisation.restrictedForm.questionBYes'),
    },
    {
      value: YesNoValues.NO,
      secondaryLabel: t('finprom.selfCategorisation.restrictedForm.questionBNo'),
    },
  ]

  const formSchema = RestrictedSchema(t)
  const form = useForm<RestrictedFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  })
  const {
    control,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = form

  const formValues = useWatch({ control })

  const { setRadioField, setNumberInputField, setCheckboxField } =
    useFormFields<RestrictedFormKeys>(formSchema, form, formValues)

  const formFields: Record<RestrictedFormKeys, any> = {
    isPercentageInvestedLess: setRadioField({
      key: 'isPercentageInvestedLess',
      options: optionsA,
      onChangeAdditional: value => {
        if (value !== YesNoValues.YES) {
          clearErrors('percentageInvested')
        }
      },
    }),
    percentageInvested: setNumberInputField({
      key: 'percentageInvested',
      placeholder: '0',
    }),
    isPercentageIntendedToInvestLess: setRadioField({
      key: 'isPercentageIntendedToInvestLess',
      options: optionsB,
      onChangeAdditional: value => {
        if (value !== YesNoValues.YES) {
          clearErrors('percentageIntendedToInvest')
        }
      },
    }),
    percentageIntendedToInvest: setNumberInputField({
      key: 'percentageIntendedToInvest',
      placeholder: '0',
    }),
    isRiskAccepted: setCheckboxField({ key: 'isRiskAccepted' }),
  }

  const onSubmit = () => {
    const restrictedInvestorAnswers = formValues as RestrictedFormData
    const isRestrictedInvestor = getRestrictedFormResult(restrictedInvestorAnswers)
    onSubmitCategory(restrictedInvestorAnswers, isRestrictedInvestor)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        {isErrorContinue && <FinpromSubmitError />}
        <IntroBox>
          <InputLabel>{t('finprom.selfCategorisation.restrictedForm.statementTitle')}</InputLabel>
          <Description>
            {t('finprom.selfCategorisation.restrictedForm.statementParagraphOne')}
          </Description>
          <Description>
            {t('finprom.selfCategorisation.restrictedForm.statementParagraphTwo')}
          </Description>
          <Description>
            {t('finprom.selfCategorisation.restrictedForm.statementParagraphThree')}
          </Description>
        </IntroBox>
        <Description>{t('finprom.selfCategorisation.restrictedForm.confirmCaregory')}</Description>
        <Radio {...formFields.isPercentageInvestedLess} hasParagraphLayout />
        {formValues?.isPercentageInvestedLess === YesNoValues.YES && (
          <>
            <Description>
              {t('finprom.selfCategorisation.restrictedForm.questionAPercentageIntro')}
            </Description>
            <Input {...formFields.percentageInvested} postLabel={percentagePostLabel} />
          </>
        )}
        <Divider divider="bottom" />
        <Radio {...formFields.isPercentageIntendedToInvestLess} hasParagraphLayout />
        {formValues?.isPercentageIntendedToInvestLess === YesNoValues.YES && (
          <>
            <Description>
              {t('finprom.selfCategorisation.restrictedForm.questionBPercentageIntro')}
            </Description>
            <Input {...formFields.percentageIntendedToInvest} postLabel={percentagePostLabel} />
          </>
        )}
        <Divider divider="bottom" />
        <Box>
          <CheckBox {...formFields.isRiskAccepted} labelColour="light" />
          {errors?.isRiskAccepted && <ErrorText text={errors?.isRiskAccepted?.message} />}
        </Box>
        <FormSubmitButton disabled={!!Object.keys(errors)?.length} loading={isLoadingContinue} />
      </Container>
    </form>
  )
}
