import { BusinessDocumentsPayload, DocumentCategories } from 'types/verification'

export const AccountContactDocumentCategories = {
  BUSINESS_OWNERS_CORPORATE: 'corporateOwners',
  BUSINESS_OWNERS_INDIVIDUAL: 'individualOwners',
  DIRECTORS: 'directors',
  ACCOUNT_REPRESENTATIVE: 'accountRepresentative',
} as const

export const AccountContactDocumentCategoryList = Object.values(AccountContactDocumentCategories)

export const payloadCategoryMapping: Record<string, DocumentCategories> = {
  accountDocuments: 'account',
  financeDocuments: 'finance',
  representativeDocuments: 'accountRepresentative',
  corporateOwnersDocuments: 'corporateOwners',
  individualOwnersDocuments: 'individualOwners',
  directorsDocuments: 'directors',
}

export const initialDocumentsPayload: BusinessDocumentsPayload = {
  accountDocuments: [],
  financeDocuments: [],
  representativeDocuments: [],
  corporateOwnersDocuments: {},
  individualOwnersDocuments: {},
  directorsDocuments: {},
}
