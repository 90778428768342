import { reactQueryKeys } from 'reactQueryKeys/reactQueryKeys'
import { useQuery } from '@tanstack/react-query'
import { getAllServicesNeeds } from 'services/VerificationService'

export const useServiceNeedsQuery = () => {
  return useQuery({
    queryKey: reactQueryKeys.serviceNeeds(),
    queryFn: getAllServicesNeeds,
  })
}
