import * as yup from 'yup'
import { TFunction } from 'i18next'
import { BusinessAddressFormFields } from 'types/verification'
import { useYupFields } from 'hooks/forms/useYupFields'

export type BusinessAddressFormKeys = keyof BusinessAddressFormFields

export const businessAddressSchema = (t: TFunction<'translation', string>) => {
  const { getYupField, getRequiredYupField } = useYupFields()

  return yup.object<Record<BusinessAddressFormKeys, yup.StringSchema>>({
    address1: getRequiredYupField({ label: t('formFields.addressLine1') }),
    address2: getYupField({ label: t('formFields.addressLine2') }),
    city: getRequiredYupField({ label: t('formFields.city') }),
    postalCode: getRequiredYupField({ label: t('formFields.postalCode') }),
    countryCode: getRequiredYupField({ label: t('country') }),
  })
}
