import React from 'react'
import { CSATIconType } from '../CSATIcon'

export const IconOkay = ({ size }: CSATIconType) => {
  return (
    <svg
      width={`${size}`}
      height={`${size}`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 20H21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 14.75C12.1904 14.75 12.75 14.1904 12.75 13.5C12.75 12.8096 12.1904 12.25 11.5 12.25C10.8096 12.25 10.25 12.8096 10.25 13.5C10.25 14.1904 10.8096 14.75 11.5 14.75Z"
        fill="currentColor"
      />
      <path
        d="M20.5 14.75C21.1904 14.75 21.75 14.1904 21.75 13.5C21.75 12.8096 21.1904 12.25 20.5 12.25C19.8096 12.25 19.25 12.8096 19.25 13.5C19.25 14.1904 19.8096 14.75 20.5 14.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
