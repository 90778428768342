import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box as DocumentItem, Box as DocumentList } from '@node-space/storybook-components/dist/Box'
import Callout from '@node-space/storybook-components/dist/Callout'
import Collapsible from '@node-space/storybook-components/dist/Collapsible'
import { DynamicDocumentData } from 'types/dynamicFields'
import { DocumentActions, DocumentCategoryKey, UploadedDocument } from '../helpers/types'
import { useCategoryDocuments } from '../helpers/useCategoryDocuments'
import { DocumentInput } from './DocumentInput'

export interface CategoryDocumentsProps {
  category: DocumentCategoryKey
  requiredDocuments: DynamicDocumentData[]
  uploadedDocuments: UploadedDocument[]
  documentActions: DocumentActions
}

export const CategoryDocuments = ({
  category,
  requiredDocuments,
  uploadedDocuments,
  documentActions,
}: CategoryDocumentsProps) => {
  const { t } = useTranslation()
  const { documentCount, mappedDocuments } = useCategoryDocuments(
    category,
    requiredDocuments,
    uploadedDocuments,
    documentActions
  )

  return (
    <Collapsible
      testid={`${category}-upload`}
      title={t(`uploadDocuments.categories.${category}`)}
      rightLabel={t('uploadDocuments.categoryDocumentsUploaded', documentCount)}
    >
      <DocumentList flex direction="col" gapY={16}>
        {mappedDocuments?.map(document => (
          <DocumentItem key={document?.title}>
            {document?.provided ? (
              <Callout
                state="success"
                message={`${document?.title} ${t(`uploadDocuments.alreadyUploaded`)}`}
              />
            ) : (
              <DocumentInput {...document} />
            )}
          </DocumentItem>
        ))}
      </DocumentList>
    </Collapsible>
  )
}
