const General = {
  COOKIE_NAME: process.env.COOKIE_NAME || 'cdCookie201805',
  IMPERSONATION_COOKIE_NAME: 'cdImpersonation',
  COOKIE_PERMANENT: 'cdPrevious',
}

export const CookieTime = {
  TEN_SECONDS: 10 / 86400,
  ONE_HOUR: 1 / 24,
  ONE_DAY: 1,
}

export const Timer = {
  ONE_MINUTE: 60,
  ONE_MINUTE_IN_SECONDS: 1000 * 60,
}

export const SalesForce = {
  PARDOT_DEVX_ENDPOINT: 'https://info.bvnk.com/l/1013952/2023-02-23/bmr',
}

export const RedirectUri = {
  DASHBOARD: '/',
  DEVX_SIGNUP_URL: '/create-dev-account',
  KYB_SIGNUP_URL: '/create-account',
  EXT_VERIFY_URL: '/verify',
  LOGIN: '/login',
  LOGOUT: '/logout',
  ACCOUNT_ERROR: '/account-error',
}

export const SupportEmail = {
  MERCHANTS: 'merchants@bvnk.com',
}

export const WindowTarget = {
  NEW_TAB: '_blank',
}

export const Currencies = {
  GBP: 'GBP',
}

export const loginUrl = `${process.env.MERCHANT_PORTAL_URL}${RedirectUri.LOGIN}`

export default General
