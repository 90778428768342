import React from 'react'
import { CSATFeedbackValueType } from './types'
import { CSATFeedbackValue } from './types'
import { IconBadSelected } from './icons/IconBadSelected'
import { IconBad } from './icons/IconBad'
import { IconOkaySelected } from './icons/IconOkaySelected'
import { IconOkay } from './icons/IconOkay'
import { IconGoodSelected } from './icons/IconGoodSelected'
import { IconGood } from './icons/IconGood'

export type CSATIconType = {
  size?: number
}

export type CSATIconProps = CSATIconType & {
  name: CSATFeedbackValueType
  selected: boolean
}

export const CSATIcon = ({ name, selected, size = 32 }: CSATIconProps) => {
  if (name === CSATFeedbackValue.BAD)
    return selected ? <IconBadSelected size={size} /> : <IconBad size={size} />
  if (name === CSATFeedbackValue.OKAY)
    return selected ? <IconOkaySelected size={size} /> : <IconOkay size={size} />
  return selected ? <IconGoodSelected size={size} /> : <IconGood size={size} />
}
